import axios from "axios";
import { useMutation, useQueryClient } from "react-query";
import { useContext } from "react";
import { AuthContext } from "../../Context/AuthContext";

const useSetTodaysDeal = () => {
  const { token } = useContext(AuthContext);
  const queryClient = useQueryClient();

  const setTodaysDealMutation = useMutation(
    (productId) =>
      axios.put(
        `${process.env.REACT_APP_SERVER_URL}/product/products/set-todays-deal/${productId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("products");
      },
    }
  );

  const setTodaysDeal = async (productId) => {
    try {
      await setTodaysDealMutation.mutateAsync(productId);
    } catch (error) {
      console.error("Error setting today's deal:", error);
      throw error;
    }
  };

  return { setTodaysDeal, isLoading: setTodaysDealMutation.isLoading };
};

export default useSetTodaysDeal;
