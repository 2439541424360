import { ReactComponent as FrameIcon } from "../../asset/Frame 100.svg";
import useFormDataReceiver from "../libs/receiver";
import useAllStates from "../../libs/getAllStates";
import { useNavigate } from "react-router-dom";



function Logisticsreceiever() {
    const navigate = useNavigate();
  const [formData] = useFormDataReceiver("shipFormData");
  const [receiverFormData, handleChanges] = useFormDataReceiver("recieverFormData");
  const { data: allStates } = useAllStates();

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form Data:", formData);
    navigate("/Logisticsitems");
  };
  return (
    <div className="logistics-sender">
      <div className="header">
        <p>Create Package</p>
      </div>
      <div className="ship-form">
        <FrameIcon />
        <form onSubmit={handleSubmit}>
          <p>where are you shipping to</p>
          <div className="inner">
            <label>
              Full Name Or Company Name*
              <input
                type="text"
                placeholder="Enter name"
                name="name"
                value={receiverFormData.name}
                onChange={handleChanges}
                required
              />
            </label>

            <label>
              Contact Name
              <input
                type="text"
                placeholder="Enter name"
                name="contact_name"
                value={receiverFormData.contact_name}
                onChange={handleChanges}
              />
            </label>

            <label>
              Address Line 1*
              <textarea
                placeholder="Enter address 1"
                name="address_1"
                value={receiverFormData.address_1}
                onChange={handleChanges}
                required
              ></textarea>
            </label>

            <label>
              Address Line 2*
              <textarea
                placeholder="Enter address 2"
                name="address_2"
                value={receiverFormData.address_2}
                onChange={handleChanges}
                required
              ></textarea>
            </label>

            <label>
              Email Address*
              <input
                type="text"
                placeholder="Email"
                name="email"
                value={receiverFormData.email}
                onChange={handleChanges}
                required
              />
            </label>

            <label>
              Phone Number*
              <div className="num">
                <select name="" id="">
                  <option value="+234">+234</option>
                  <option value="+1">+1</option>
                </select>
                <input
                  type="number"
                  placeholder="Phone Number"
                  name="phone_number"
                  onChange={handleChanges}
                  value={receiverFormData.phone_number}
                />
              </div>
            </label>

            <label>
              State*
                <select
                name="state"
                value={receiverFormData.state}
                onChange={handleChanges}
                required
              >
                <option value="">Select State</option>
                {allStates &&
                  allStates.map((state) => (
                    <option key={state._id} value={state.state}>
                      {state.state}
                    </option>
                  ))}
              </select>
            </label>

            <label>
              Zip Code
              <input
                type="number"
                placeholder="Zip code"
                name="zip_code"
                value={receiverFormData.zip_code}
                onChange={handleChanges}
                required
              />
            </label>
          </div>
          <div className="btn">
            <button onClick={() => navigate("/Logiscticsender")}>Back</button>
          <button>Proceed</button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default Logisticsreceiever
