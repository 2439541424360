import React, { useContext } from 'react';
import { Link ,useNavigate} from 'react-router-dom';
import { Fragment } from 'react';
import { FaRegUser } from "react-icons/fa6";
import { CiShop } from "react-icons/ci";
import { SlLike } from "react-icons/sl";
import { GoMail } from "react-icons/go";
import { SlSettings } from "react-icons/sl";
import { LuView } from "react-icons/lu";
import { MdOutlineRateReview } from "react-icons/md";
import { FiUserX } from "react-icons/fi";
import { FaRegAddressCard } from "react-icons/fa";
import { AuthContext } from '../../../Context/AuthContext';
import "./UserProfile.css"

const Usernavbar = () => {
    const {Logout} = useContext(AuthContext);
    const navigate = useNavigate()

    const handleLogOut = () => {
        Logout();
        navigate('/Market')
    }

    return (
        <Fragment>
        <div className='user-navbar'>
                <Link to='/UserProfile'> <FaRegUser style={{fontSize:'20px'}} />My Stex Profile</Link>
                <Link to='/customerorder'><CiShop style={{fontSize:'20px'}} />Orders</Link>
                <Link to='/customersaveditem'><SlLike style={{fontSize:'20px'}} />Saved items</Link>
                <Link to='/customermsginbox'><GoMail style={{fontSize:'20px'}}  />Inbox</Link>
                <Link to='/recentview'><LuView style={{fontSize:'20px'}} />Recently Viewed</Link>
                <Link to='/pendingreview'><MdOutlineRateReview style={{fontSize:'20px'}}/>Pending Evaluation</Link>
                <Link to='/addressbook'><FaRegAddressCard style={{fontSize:'20px'}}/>Address Book</Link>
                <Link to='/accountmanagement'><SlSettings style={{fontSize:'20px'}}  />Account Management</Link>
               <span>
               <button><FiUserX style={{fontSize:'20px'}}/>Delete Account</button>
            
               </span>
               <div className="btn">
               <button onClick= {handleLogOut}>Logout</button>
               </div>
        </div>
        </Fragment>
    );
}

export default Usernavbar;
