import { useMutation, useQueryClient } from "react-query";
import { useContext } from "react";
import { AuthContext } from "../../../../Context/AuthContext";

const markVehicleAsUnBooked = async (id, token) => {
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/charterer/unbook-vehicle/${id}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error("Failed to mark vehicle as UnBooked");
  }

  return response.json();
};

const useMarkVehicleAsUnBooked = () => {
  const queryClient = useQueryClient();
  const { token } = useContext(AuthContext);

  return useMutation((id) => markVehicleAsUnBooked(id, token), {
    onSuccess: () => {
      queryClient.invalidateQueries(["adminVehicle"]);
    },
  });
};

export default useMarkVehicleAsUnBooked;
