
import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import GetVehicleById from '../../libs/getVehiclesById';
import useDeleteVehicle from '../../libs/useDeleteVehicle';
import useEditVehicle from '../../libs/useEditVehicle';
import { ReactComponent as CautionIcon } from '../../../../../Assets/caution-svgrepo-com 1.svg';
import './VehicleDetail.css';


const Vehicledetail = () => {
  const { id } = useParams();
  const { data, isLoading, isError } = GetVehicleById(id);
  const [deleteOverlay, setDeleteOverlay] = useState(false);
  const [editOverlay, setEditOverlay] = useState(false);
  const [formData, setFormData] = useState({});
  const { deleteVehicle, isLoading: deleteLoading } = useDeleteVehicle();
  const { editVehicle, isLoading: editLoading } = useEditVehicle();
  const [items_value, setItems_value] = useState('0.00');

  const handleDeleteOverlay = () => setDeleteOverlay(true);

  const handleDeleteVehicle = async () => {
    try {
      await deleteVehicle.mutateAsync(id);
      setDeleteOverlay(false);
    } catch (error) {
      console.error('Error deleting vehicle:', error);
    }
  };

 

  const handleEditOverlay = () => setEditOverlay(true);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };



  useEffect(() => {
    if (data && data.vehicle) {
      setFormData({
        vehicleType: data.vehicle.vehicleType,
        description: data.vehicle.description,
        vehicleModel: data.vehicle.vehicleModel,
        numberOfSeats: data.vehicle.numberOfSeats,
        maxNumberOfPeople: data.vehicle.maxNumberOfPeople,
        powerType: data.vehicle.powerType,
        doors: data.vehicle.doors,
        fuelTankCapacity: data.vehicle.fuelTankCapacity,
        pricingPerDay: data.vehicle.pricingPerDay,
      });
    }
  }, [data]);


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await editVehicle.mutateAsync({ id, formData });
      setEditOverlay(false);
    } catch (error) {
      console.error('Error editing vehicle:', error);
    }
  };

  

  const handleItems_valueChange = (event) => {
    let inputValue = event.target.value.replace(/[^\d]/g, '');
    inputValue = Number(inputValue) / 100;

    const items_value = inputValue.toLocaleString('en-US', {
      style: 'currency',
      currency: 'NGN',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    setItems_value(items_value);
    setFormData(prevState => ({
      ...prevState,
      pricingPerDay: inputValue
    }));
  };

  return (
    <>
      {deleteOverlay && (
        <div className="vehicles-deletes">
          <div className="vehicle-deletes-card">
            <CautionIcon />
            <h3>Delete</h3>
            <p>Are you sure you want to delete this product?</p>
            <div className="btn">
              <button onClick={() => setDeleteOverlay(false)}>No</button>
              <button onClick={handleDeleteVehicle}>
                {deleteLoading ? 'Deleting...' : 'Yes'}
              </button>
            </div>
          </div>
        </div>
      )}

      {editOverlay && (
        <div className="vehicle-edit-overlay">
          <div className="edit-overlay-card">
            <h3>Edit Vehicle</h3>
            <form onSubmit={handleSubmit}>
              <span>
                <label>Price Per Day</label>
                <input
                  value={items_value}
                  onChange={handleItems_valueChange}
                  required
                />
              </span>
              <span>
                <label>Vehicle Type</label>
                <input type="text" name="vehicleType" value={formData.vehicleType} onChange={handleChange} />
              </span>
              <span>
                <label>Vehicle Model</label>
                <input type="text" name="vehicleModel" value={formData.vehicleModel} onChange={handleChange} />
              </span>
              <span>
                <label>Number Of Seats</label>
                <input type="tel" name="numberOfSeats" value={formData.numberOfSeats} onChange={handleChange} />
              </span>
              <span>
                <label>Max Number Of People</label>
                <input type="tel" name="maxNumberOfPeople" value={formData.maxNumberOfPeople} onChange={handleChange} />
              </span>
              <span>
                <label>Power Type</label>
                <input type="text" name="powerType" value={formData.powerType} onChange={handleChange} />
              </span>
              <span>
                <label>Doors</label>
                <input type="tel" name="doors" value={formData.doors} onChange={handleChange} />
              </span>
              <span>
                <label>Fuel Tank Cap.(Ltr)</label>
                <input type="tel" name="fuelTankCapacity" value={formData.fuelTankCapacity} onChange={handleChange} />
              </span>
              <span>
                <label>Description</label>
                <textarea type="text" name="description" value={formData.description} onChange={handleChange} />
              </span>
              <div className="btn">
                <button onClick={() => setEditOverlay(false)}>No</button>
                <button type="submit">
                  {editLoading ? 'EDITING...' : 'Submit'}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      <div className="charterer-vehicle-detail">
        {isLoading && <p style={{ fontFamily: 'Montserrat' }}>Loading...</p>}
        {isError && <p style={{ fontFamily: 'Montserrat' }}>Error: {isError.message}</p>}
        {data && data.vehicle && (
          <>
            <div className="charterer-vehicle-con1">
              <img src={data.vehicle.images[0]} alt="" />
            </div>
            <div className="charterer-vehicle-con2">
              <h3>{data.vehicle.vehicleType}</h3>
              <p>{data.vehicle.vehicleModel}</p>
              <p>Fuel Tank Capacity: {data.vehicle.fuelTankCapacity}</p>
              <h4>{data.vehicle.pricingPerDay
                ? data.vehicle.pricingPerDay.toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'NGN',
                })
                : ''}</h4>
              <p>{data.vehicle.description}</p>
              <div className="btn">
                <button onClick={handleEditOverlay}>EDIT</button>
                <button onClick={handleDeleteOverlay}>DELETE</button>
              </div>
              <Link to='/vehicle'>Back</Link>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Vehicledetail;