
import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { MdKeyboardDoubleArrowRight } from 'react-icons/md';
import Skeleton from 'react-loading-skeleton'; 
import 'react-loading-skeleton/dist/skeleton.css';
import useJeepCollection from '../CharterAdmin/libs/fetchJeepCollection'; 
import './AllCharter.css';

const Jeepcharterservice = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 8;
    const { jeepData, isLoading, isError } = useJeepCollection(currentPage);

    useEffect(() => {
        if (jeepData && jeepData.currentPage) {
            setCurrentPage(jeepData.currentPage);
        }
    }, [jeepData]);

    const handleScroll = useCallback(() => {
        if (
            window.innerHeight + document.documentElement.scrollTop === 
            document.documentElement.offsetHeight
        ) {
            if (currentPage < jeepData.totalPages) {
              
            }
        }
    }, [currentPage, jeepData]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [handleScroll]);

    if (isError) {
        return <div className='error'>No Jeep available now</div>;
    }

    if (isLoading) {
        return (
            <div className='vehicle-collection'>
                <span>
                    <Link to='/charteringservice'>Home</Link>
                    <MdKeyboardDoubleArrowRight />
                    <h3>Cars</h3>
                </span>
                <h3>JEEP COLLECTION</h3>
                <div className="wrapper">
                    {[...Array(8)].map((_, index) => (
                        <div key={index} className="vehicle-list">
                            <Skeleton height={250} width={300} count={1} />
                        </div>
                    ))}
                </div>
            </div>
        );
    }

    const { jeeps, totalJeeps } = jeepData; 

    if (!jeeps || jeeps.length === 0) {
        return <div>No jeeps available</div>; 
    }

    const handlePrevPage = () => {
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
      };
    
      const handleNextPage = () => {
        setCurrentPage((prevPage) => Math.min(prevPage + 1, jeepData.totalPages));
      };

    const totalPages = Math.ceil(totalJeeps / itemsPerPage);
    const startIndex = Math.min((currentPage - 1) * itemsPerPage + 1, totalJeeps);
    const endIndex = Math.min(startIndex + itemsPerPage - 1, totalJeeps);
  

    const clampWords = (text, maxWords) => {
        const words = text.split(' ');
        const clampedText = words.slice(0, maxWords).join(' ');
        return words.length > maxWords ? `${clampedText}...` : clampedText;
    };

    return (
        <div className='vehicle-collection'>
            <span>
                <Link to='/charteringservice'>Home</Link>
                <MdKeyboardDoubleArrowRight />
                <h3>Jeeps</h3>
            </span>
            <h3>JEEPS COLLECTION</h3>
            <div className="wrapper">
               {jeeps.map(jeep => (
                    <div key={jeep._id} className="vehicle-list">
                        <img src={jeep.images[0]} alt={jeep.vehicleModel} />
                        <h4>{jeep.vehicleModel}</h4>
                        <h3><p>Price Per Day:</p>{jeep.pricingPerDay
                      ? (jeep.pricingPerDay ).toLocaleString("en-US", {
                          style: "currency",
                          currency: "NGN",
                        })
                      : ""}</h3>
                        <p>{jeep.isHired ? 'Not available' : 'Available'}</p>
                        <p>{clampWords(jeep.description, 5)}<Link to={`/car-details/${jeep._id}`}>view details</Link></p>
                    </div>
                ))}
            </div>
            <div className="vehicle-collection-pagination">
                <span>{`Showing ${startIndex} - ${endIndex} of ${totalJeeps}`}</span>
                <div className="btn">
                    <button onClick={handlePrevPage} disabled={currentPage === 1}>
                        Prev
                    </button>
                    <button onClick={handleNextPage} disabled={currentPage === totalPages}>
                        Next
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Jeepcharterservice;
