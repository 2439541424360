import { useContext } from "react";
import { useQuery } from "react-query";
import { AuthContext } from "../../Context/AuthContext";

const PackageDataMetrics = () => {
  const { token } = useContext(AuthContext);

  const { data: packageMetrics, isLoading, isError } = useQuery(
    ["packageMetrics", token],
    async () => {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/packages/packages-metrics`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
        }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch package metrics");
      }
      return response.json();
    }
  );

  return { packageMetrics, isLoading, isError };
};

export default PackageDataMetrics;
