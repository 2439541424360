import React from 'react';
import "./International.css"

const International = () => {
    return (
        <div className='international-shipping'>
            <h3>COMING SOON!!!</h3>
        </div>
    );
}

export default International;
