
import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../../../Context/AuthContext';
import { FaArrowLeft } from "react-icons/fa";
import { ReactComponent as CloudLogo } from "../../../../Assets/bx_cloud-upload@3x.svg";
import "./Vehicle.css"

const defaultForm = {
  vehicleType: '',
  vehicleModel: '',
  numberOfSeats: '',
  maxNumberOfPeople: '',
  powerType: '',
  doors: '',
  description: '',
  fuelTankCapacity: '',
  state: ''
}

const Addvehicle = () => {
  const [formField, setField] = useState(defaultForm);
  const [images, setImages] = useState([]);
  const { vehicleType, vehicleModel, numberOfSeats, maxNumberOfPeople, powerType, doors, description, fuelTankCapacity, state } = formField;
  const { token } = useContext(AuthContext);
  const [error, setError] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  const [formattedPrice, setFormattedPrice] = useState('0.00');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setField({ ...formField, [name]: value });
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    setImages(files);
  };

  const handleResetImages = () => {
    setImages([]);
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setIsloading(true);

    const formData = new FormData();
    formData.append("vehicleType", formField.vehicleType);
    formData.append("vehicleModel", formField.vehicleModel);
    const numericPrice = parseFloat(formattedPrice.replace(/[^0-9.-]+/g, ""));
    formData.append("pricingPerDay", numericPrice);
    formData.append("description", formField.description);
    formData.append("powerType", formField.powerType);
    formData.append("state", formField.state);
    formData.append("doors", parseInt(formField.doors));
    formData.append("fuelTankCapacity", parseInt(formField.fuelTankCapacity));
    formData.append("numberOfSeats", parseInt(formField.numberOfSeats));
    formData.append("maxNumberOfPeople", parseInt(formField.maxNumberOfPeople));

    for (let i = 0; i < images.length; i++) {
      formData.append(`images`, images[i]);
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/charterer/create`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      );

      const resData = await response.json();

      if (!response.ok) {
        throw new Error(resData.message);
      }
      setField(defaultForm);
      setIsloading(false);
      setImages([]);
    } catch (error) {
      setError(error.message);
      setIsloading(false);
    }
  };


  const handlePriceChange = (event) => {
    let inputValue = event.target.value.replace(/[^\d]/g, '');
    inputValue = Number(inputValue) / 100;

    const formattedValue = inputValue.toLocaleString('en-US', {
      style: 'currency',
      currency: 'NGN',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    setFormattedPrice(formattedValue);
  };



  return (
    <div className='add-vehicle'>
      <h3>Vehicles</h3>
      <div className="wrapper">
        <form onSubmit={handleSubmit}>
          <div className="card">
            <span>
              <Link to="/vehicle"><FaArrowLeft /></Link>
              <h3>Add New Vehicle</h3>
            </span>
            {error && <p>{error}</p>}
            <button> {isLoading ? "saving..." : "save vehicle"}</button>
          </div>
          <div className="vehicle-image">
            <h3>PRODUCT IMAGES</h3>
            <div className="vehicle-image-span">
              {images.length > 0 ? (
                <>
                  {images.map((image, index) => (
                    <img
                      key={index}
                      src={URL.createObjectURL(image)}
                      alt={`Product ${index}`}
                    />
                  ))}
                  <button type="button" onClick={handleResetImages}>
                    Reset Images
                  </button>
                </>
              ) : (
                <div className="vehicle-image-card">
                  <label className="upload-label">
                    <CloudLogo />
                    <input
                      type="file"
                      accept="image/*"
                      multiple
                      onChange={handleImageChange}
                      style={{ display: "none" }}
                    />
                    <p>Upload images</p>
                  </label>
                </div>
              )}
            </div>
          </div>
          <div className="vehicle-input-detail">
            <h3>VEHICLE DETAILS</h3>
            <div className="wrapper">
              <span>
                <label>Vehicle Type</label>
                <select name="vehicleType" value={vehicleType} required onChange={handleChange}>
                  <option value="Select">Select</option>
                  <option value="car">Car</option>
                  <option value="jeep">Jeep</option>
                  <option value="sienna">Sienna</option>
                  <option value="bus">Bus</option>
                  <option value="executivebus">Executive Bus</option>
                </select>
              </span>
              <span>
                <label>Vehicle Model</label>
                <input type="text" name='vehicleModel' value={vehicleModel} placeholder='Enter' onChange={handleChange} required />
              </span>
              <span>
                <label>Number of Seater</label>
                <select name="numberOfSeats" value={numberOfSeats} onChange={handleChange}>
                  <option value="Select">Select</option>
                  {[...Array(70)].map((_, i) => (
                    <option key={i} value={i + 1}>{i + 1}</option>
                  ))}
                </select>
              </span>
              <span>
                <label>Pick up Location</label>
                <select name="state" value={state} required onChange={handleChange}>
                  <option value="Select">Select</option>
                  <option value="Lagos">Lagos</option>
                  <option value="Osun">Osun</option>
                  <option value="Oyo">Oyo</option>
                  <option value="Kwara">Kwara</option>
                  <option value="Ekiti">Ekiti</option>
                  <option value="Ondo">Ondo</option>
                </select>
              </span>
            </div>
            <div className="wrapper">
              <span>
                <label>Max no of people</label>
                <input type="number" name='maxNumberOfPeople' value={maxNumberOfPeople} placeholder='Enter' onChange={handleChange} min="1" required />
              </span>
              <span>
                <label>Power Type</label>
                <select name="powerType" value={powerType} onChange={handleChange}>
                  <option value="Select">Select</option>
                  <option value="Manual">Manual</option>
                  <option value="Auto">Auto</option>
                </select>
              </span>
              <span>
                <label>Door</label>
                <select name="doors" value={doors} onChange={handleChange}>
                  <option value="Select">Select</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                </select>
              </span>
              <span>
                <label>Fuel Tank Cap.(Ltr)</label>
                <input type="number" name='fuelTankCapacity' value={fuelTankCapacity} placeholder='Enter  Tank Cap.' onChange={handleChange}  required />
              </span>
            </div>
            <div className="description-card">
              <h3>DESCRIPTION</h3>
              <textarea name="description" value={description} onChange={handleChange}></textarea>
            </div>
            <div className="vehicle-price">
              <h3>PRICING</h3>
              <span>
                <label>Price/Day</label>
                <input type="tel" value={formattedPrice} onChange={handlePriceChange} required />
              </span>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Addvehicle;
