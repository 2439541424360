
import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CiUser } from "react-icons/ci";
import useFormData from "../../../Context/UseFormData";
import { TbSteeringWheel } from "react-icons/tb";
import { GiCarDoor } from "react-icons/gi";
import { BsFuelPump } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import Spinner from "../../../Spinner/Spinner";
import { ReactComponent as SuccessIcon } from "../../../Assets/promptIcon.svg";
import "./Passenger.css";

const defaultForm = {
  fullName: "",
  email: "",
  phoneNumber: "",
  address: "",
  nextOfKinFullName: "",
  nextOfKinAddress: "",
  nextOfKinPhoneNumber: "",
  paymentMethod: "",
  customerIdNumber: '',
  customerIdType: ''
};

const Passengerdetails = () => {
  const [form, setForm] = useState(defaultForm);
  const [formData] = useFormData("charterFormData");
  const [isLoading, setIsLoading] = useState(false);
  const [overlay, setOverlay] = useState(false);
  const [successCharterMsg, setSuccessCharterMsg] = useState(null);
  const navigate = useNavigate();

  const handleCharterSuccessMsg = () => {
    setSuccessCharterMsg(false);
    setOverlay(false);
    navigate('/charteringservice');
  };

  const {
    fullName,
    email,
    phoneNumber,
    address,
    nextOfKinFullName,
    nextOfKinPhoneNumber,
    nextOfKinAddress,
    paymentMethod,
    customerIdType,
    customerIdNumber,
    vehicleData,
  } = form;

  useEffect(() => {
    const storedVehicleData = JSON.parse(localStorage.getItem("vehicleData"));
    if (storedVehicleData) {
      setForm((prevForm) => ({
        ...prevForm,
        vehicleData: storedVehicleData,
      }));
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const payload = {
        vehicleId: form.vehicleData._id,
        startDate: formData.startDate,
        endDate: formData.endDate,
        pickupLocation: vehicleData?.state,
        totalAmount: vehicleData?.pricingPerDay,
        passengerDetails: {
          fullName: form.fullName,
          email: form.email,
          phoneNumber: form.phoneNumber,
          address: form.address,
          nextOfKin: {
            fullName: form.nextOfKinFullName,
            phoneNumber: form.nextOfKinPhoneNumber,
            address: form.nextOfKinAddress,
          }
        },
        paymentMethod: form.paymentMethod,
        customerIdType: form.customerIdType,
        customerIdNumber: form.customerIdNumber
      };

      const res = await fetch(`${process.env.REACT_APP_SERVER_URL}/charterer/hire`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      });

      const resData = await res.json();
      if (!res.ok) {
        throw new Error(resData.message);
      }
      setForm(defaultForm);
      setSuccessCharterMsg(resData);
      setOverlay(true);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      toast.error(error.message || "An error occurred");
      setIsLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  return (
    <>
      <ToastContainer />
      {overlay && (
        <div className="charter-success-msg">
          <div className="card">
            <SuccessIcon />
            <h3>Vehicle Charter Confirmed</h3>
            <p>Your vehicle order has been successfully confirmed!</p>
            <p>Pls go to pickup location to complete the order.</p>
            <div className="wrapper">
              <h3>Details:</h3>
              <p>Trip start: {successCharterMsg && formData?.startDate}</p>
              <p>Trip end: {successCharterMsg && formData?.endDate}</p>
              <h4>Thank you for choosing us!</h4>
              <button onClick={handleCharterSuccessMsg}>Back Home</button>
            </div>
          </div>
        </div>
      )}
      <div className="passenger-detail-mother">
        <p>We need to know who is chartering</p>
        <div className="wrapper">
          <div className="passenger-detail-card-1">
            <h3>Passanger Details</h3>
            <p>Please Enter Name as they appear on identification document</p>
            <form onSubmit={handleSubmit}>
              <div className="passenger-detail-con">
                <div className="passenger-detail-span">
                  <span>
                    <label>Enter Full Name</label>
                    <input
                      type="text"
                      name="fullName"
                      placeholder="Enter Full Name"
                      value={fullName}
                      onChange={handleChange}
                      required
                    />
                  </span>
                  <span>
                    <label>Customer ID Type</label>
                    <select name="customerIdType" value={customerIdType} onChange={handleChange} required>
                      <option value="Select Id type">Select Id type</option>
                      <option value="NIN">National ID</option>
                      <option value="Drivers licences">Drivers licences</option>
                      <option value="International Passport">International Passport</option>
                    </select>
                  </span>
                  <span>
                    <label>ID Number</label>
                    <input type="text" name="customerIdNumber" placeholder="Enter Card Number" value={customerIdNumber} onChange={handleChange} required />
                  </span>
                  <span>
                    <label>Enter Email Address</label>
                    <input
                      type="email"
                      name="email"
                      placeholder="Enter Email"
                      value={email}
                      onChange={handleChange}
                      required
                    />
                  </span>
                  <span>
                    <label>Enter Phone Number</label>
                    <input
                      type="tel"
                      name="phoneNumber"
                      placeholder="Enter Phone Number"
                      value={phoneNumber}
                      onChange={handleChange}
                      required
                    />
                  </span>
                  <span>
                    <label>Full Residential Address</label>
                    <input
                      type="text"
                      name="address"
                      placeholder="Enter Address"
                      value={address}
                      onChange={handleChange}
                      required
                    />
                  </span>
                </div>
                <div className="passenger-detail-summary">
                  <h3>Summary</h3>
                  <div className="card">
                    <img
                      src={vehicleData?.images[0]}
                      alt={vehicleData?.vehicleModel}
                    />
                    <div className="wrapper">
                      <h3>{vehicleData?.vehicleModel}</h3>
                      <span>
                        <CiUser />
                        <p>
                          Max no of people:{" "}
                          <span>{vehicleData?.maxNumberOfPeople}</span>
                        </p>
                      </span>
                      <span>
                        <TbSteeringWheel />
                        <p>Power Type: {vehicleData?.powerType}</p>
                      </span>
                      <span>
                        <GiCarDoor />
                        <p>{vehicleData?.doors} Doors</p>
                      </span>
                      <span>
                        <BsFuelPump />
                        <p>Fuel Tank Cap:{vehicleData?.fuelTankCapacity}</p>
                      </span>
                    </div>
                  </div>
                  <div className="passenger-detail-summary-card">
                    <span>
                      <p>Trip Start</p>
                      <h4>{formData?.startDate}</h4>
                    </span>
                    <span>
                      <p>Trip End</p>
                      <h4>{formData?.endDate}</h4>
                    </span>
                    <span>
                      <p>Pickup Locaton</p>
                      <h4>{vehicleData?.state}</h4>
                    </span>
                    <span>
                      <p>Total Amount</p>
                      <h3>
                        {vehicleData?.pricingPerDay.toLocaleString("en-US", {
                          style: "currency",
                          currency: "NGN",
                        })}
                        /day
                      </h3>
                    </span>
                    <button type="submit">
                      {isLoading ? <Spinner /> : "Book"}
                    </button>
                  </div>
                </div>
              </div>
              <div className="next-of-kin">
                <span>
                  <label>Next of Kin Name</label>
                  <input
                    type="text"
                    name="nextOfKinFullName"
                    placeholder="Enter Full Name"
                    value={nextOfKinFullName}
                    onChange={handleChange}
                    required
                  />
                </span>
                <span>
                  <label>Next of Kin Phone Number</label>
                  <input
                    type="tel"
                    name="nextOfKinPhoneNumber"
                    placeholder="Enter Phone Number"
                    value={nextOfKinPhoneNumber}
                    onChange={handleChange}
                    required
                  />
                </span>
                <span>
                  <label>Next of Kin Address</label>
                  <input
                    type="text"
                    name="nextOfKinAddress"
                    placeholder="Enter Address"
                    value={nextOfKinAddress}
                    onChange={handleChange}
                    required
                  />
                </span>
                <span>
                  <label>Payment Method</label>
                  <select name="paymentMethod" value={paymentMethod} onChange={handleChange} required>
                    <option value="Select ">Select </option>
                    <option value="POS">POS</option>
                    <option value="Cash">Cash</option>
                  </select>
                </span>
              </div>
              <div className="term-conditon">
                <h3>Terms and Conditions</h3>
                <p>
                  Please Note that Smart-Trans Express does not have a refund
                  policy, However our tickets valid for a Month, by Proceeding to
                  make payment, You agree to the terms and conditions of
                  Smart-Trans Express
                </p>
              </div>
              <div className="passenger-detail-card-2">
                <div className="detail-summary">
                  <h3>Summary</h3>
                  <div className="card">
                    <img
                      src={vehicleData?.images[0]}
                      alt={vehicleData?.vehicleModel}
                    />
                    <div className="wrapper">
                      <h3>{vehicleData?.vehicleModel}</h3>
                      <span>
                        <CiUser />
                        <p>
                          Max no of people:
                          <span>{vehicleData?.maxNumberOfPeople}</span>
                        </p>
                      </span>
                      <span>
                        <TbSteeringWheel />
                        <p>Power Type: {vehicleData?.powerType}</p>
                      </span>
                      <span>
                        <GiCarDoor />
                        <p>{vehicleData?.doors} Doors</p>
                      </span>
                      <span>
                        <BsFuelPump />
                        <p>Fuel Tank Cap:{vehicleData?.fuelTankCapacity}</p>
                      </span>
                    </div>
                  </div>
                  <div className="passenger-detail-summary-card">
                    <span>
                      <p>Trip Start</p>
                      <h4>{formData?.startDate}</h4>
                    </span>
                    <span>
                      <p>Trip End</p>
                      <h4>{formData?.endDate}</h4>
                    </span>
                    <span>
                      <p>Pickup Locaton</p>
                      <h4>{vehicleData?.state}</h4>
                    </span>
                    <span>
                      <p>Total Amount</p>
                      <h3>
                        {vehicleData?.pricingPerDay.toLocaleString("en-US", {
                          style: "currency",
                          currency: "NGN",
                        })}
                        /day
                      </h3>
                    </span>
                    <button type="submit">
                      {isLoading ? <Spinner /> : "Book"}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Passengerdetails;

