import { useQuery } from 'react-query';
import { useContext } from 'react';
import { AuthContext } from '../../Context/AuthContext';

const fetchRecentPackagesById = async (userId, token) => {
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/packages/recent-packages/${userId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error('Failed to fetch recent packages');
  }

  return response.json();
};

const useRecentPackagesById = () => {
  const { user, token } = useContext(AuthContext);

  return useQuery(['recentPackages', user.userId, token], () =>
    fetchRecentPackagesById(user.userId, token)
  );
};

export default useRecentPackagesById;
