import React, { useRef, useCallback } from "react";
import Spinner from "../../utils/spinner";
import { GoArrowLeft } from "react-icons/go";
import { useNavigate, useParams } from 'react-router-dom';
import useStoreProducts from "../libs/fetchStoreProducts";
import "./Storeproducts.css";

const Storeproducts = () => {
  const navigate = useNavigate();
  const { merchantId } = useParams();
  const { data, error, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } = useStoreProducts(merchantId);

  const clampDescription = (description, maxLength) => {
    if (description.length <= maxLength) return description;
    return description.slice(0, maxLength) + "...";
  };

  const observerRef = useRef();
  const lastStoreProductElementRef = useCallback(
    (node) => {
      if (isFetchingNextPage) return;

      if (observerRef.current) observerRef.current.disconnect();

      observerRef.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting && hasNextPage) {
            fetchNextPage();
          }
        },
        { threshold: 0.5 }
      );

      if (node) observerRef.current.observe(node);
    },
    [isFetchingNextPage, fetchNextPage, hasNextPage]
  );

  return (
    <div className="store-product">
      <div className="header">
         <GoArrowLeft
              style={{ fontSize: '24px', cursor: 'pointer' }}
              onClick={() => navigate("/Marketplacestore")}
         />
        <h3>Store Products</h3>
      </div>

      {isLoading && <Spinner />}
      {error && <p style={{ fontFamily: 'Montserrat' }}>No store products found!</p>}

      {data?.pages?.length > 0 ? (
        <div className="product-list">
          {data.pages.map((page, pageIndex) =>
            page.products.map((product, index) => {
              const isLastElement = pageIndex === data.pages.length - 1 && index === page.products.length - 1;
              return (
                <div
                  key={product._id}
                  className="product"
                  ref={isLastElement ? lastStoreProductElementRef : null}
                >
                  <img src={product?.images[0]} alt={product.name} />
                  <div className="card">
                    <h3>{product?.name}</h3>
                    <p>{product?.category}</p>
                    <span>
                      {product.price
                        ? product.price.toLocaleString("en-US", {
                            style: "currency",
                            currency: "NGN",
                          })
                        : ""}
                    </span>
                    <p>{clampDescription(product?.description, 40)}</p>
                  </div>
                </div>
              );
            })
          )}
        </div>
      ) : null}

      {isFetchingNextPage && (
        <div className="loading-more">
          <Spinner />
        </div>
      )}
    </div>
  );
};

export default Storeproducts;


