import useFetchRiderAssignmentById from "../Libs/useFetchRiderAssignmentById";
import Spinner from "../../utils/spinner";
import { Link } from "react-router-dom"

function Rideraccepted() {
    const {
        data: assignmentData,
        isLoading,
        isError,
        error,
      } = useFetchRiderAssignmentById();
    
    
      const filteredAssignments = assignmentData?.riderAssignments
        .filter((assignment) => assignment?.accept?.length > 0)
        .reverse();
    
      return (
        <div className="accepted-assignment">
            {isLoading && <Spinner />}
            {isError && <p>Error: {error.message}</p>}
          <div className="card">
            {!isLoading && !isError && (
              <>
                {filteredAssignments.length === 0 ? (
                  <p>No matching assignments found.</p>
                ) : (
                  filteredAssignments.map((assignment) => (
                    <Link key={assignment._id} className="accepted" to={`details/${assignment._id}`}>
                      <h3>ID {assignment._id.slice(0, 8).toUpperCase()}</h3>
                      <h2>Receiver : {`${assignment.package[0].receiver.name}`}</h2>
                      <span>{`${assignment.package[0].name}`}</span>
                      <p>
                        {`${assignment.package[0].receiver.state}, ${assignment.package[0].receiver.address_1}`}
                      </p>
                    </Link>
                  ))
                )}
              </>
            )}
          </div>
        </div>
      );
}

export default Rideraccepted
