import React, { Fragment, useState, useRef, useCallback } from "react";
import { ReactComponent as ReactIcon } from "../../asset/iconamoon_search-light.svg";
import Spinner from "../../utils/spinner";
import { Link , useNavigate} from "react-router-dom";
import useMerchants from "../libs/useMerchants";
import "./Marketplacestore.css";

function Marketplacestore() {
  const { data: merchants, error, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } = useMerchants();
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();

  
  const filteredMerchants = merchants?.pages?.flatMap((page) => page.merchants)?.filter((merchant) =>
    `${merchant.first_name} ${merchant.last_name}`.toLowerCase().includes(searchTerm.toLowerCase())
  );

 

  const observerRef = useRef();
  const lastMerchantElementRef = useCallback(
    (node) => {
      if (isFetchingNextPage) return;

      if (observerRef.current) observerRef.current.disconnect();

      observerRef.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting && hasNextPage) {
            fetchNextPage();
          }
        },
        { threshold: 0.5 }
      );

      if (node) observerRef.current.observe(node);
    },
    [isFetchingNextPage, fetchNextPage, hasNextPage]
  );

  return (
    <Fragment>
      <div className="markeplace-store-container">
        <div className="header">
          <h1>Stores</h1>
          <div className="in">
            <span>
              <ReactIcon />
              <input
                type="search"
                placeholder="Search Store by name"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </span>
          </div>
        </div>

        <div className="merchant-table-markeplace">
          {isLoading && <Spinner />}
          {error && <p>No Merchants found!</p>}

          {filteredMerchants && (
            <table>
              <thead>
                <tr>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Email</th>
                  <th>Blocked</th>
                  <th>Products</th>
                  <th>Verified</th>
                  <th>Active</th>
                  <th>Details</th>
                  <th>View Product</th>
                </tr>
              </thead>
              <tbody>
                {filteredMerchants.map((merchant, index) => (
                  <tr
                    key={merchant._id}
                    ref={index === filteredMerchants.length - 1 ? lastMerchantElementRef : null}
                  >
                    <td>{merchant.first_name}</td>
                    <td>{merchant.last_name}</td>
                    <td>{merchant.email}</td>
                    <td>{merchant.blocked ? "Yes" : "No"}</td>
                    <td>{merchant.products.length}</td>
                    <td>{merchant.isInfoVerify ? "Yes" : "No"}</td>
                    <td>{merchant.isActive ? "Yes" : "No"}</td>
                    <td>
                      <Link to={`/user/${merchant._id}`}>View Details</Link>
                    </td>
                    <td>
                     <button onClick={() => navigate(`/storeproducts/${merchant._id}`)}>View Details</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
        {isFetchingNextPage && (
          <div className="loading-more">
            <Spinner />
          </div>
        )}
      </div>
    </Fragment>
  );
}

export default Marketplacestore;


