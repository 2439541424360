import { ReactComponent as SearchIcon } from "../../asset/iconamoon_search-light.svg";
import { ReactComponent as RemoveIcon } from "../../asset/gg_remove.svg";
import { ReactComponent as TrashIcon } from "../../asset/basil_trash-outline.svg";
import { GoPlusCircle } from "react-icons/go";
import useFetchDrivers from "../libs/useFetchDriver";
import useDeactivateAccount from "../libs/deactivateAccount";
import useBlockUserById from "../libs/blockUserById";
import useUnblockUserById from "../libs/unblockUserById";
import React, { useState,useRef, useCallback, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import Spinner from "../../utils/spinner";
import "./super_admin_driver.css";

function SuperAdminDriver() {
  const {
    data, 
    isLoading, 
    isError, 
    fetchNextPage, 
    isFetchingNextPage, 
    hasNextPage 
  } = useFetchDrivers();
  const navigate = useNavigate()  

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedDriverId, setSelectedDriverId] = useState(null);
  const [blockOverlay, setBlockOverlay] = useState(false);
  const [unblockOverlay, setUnblockOverlay] = useState(false);
  const [deactivateOverlay, setDeactivateOverlay] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const blockDriver = useBlockUserById();
  const unblockDriver = useUnblockUserById();
  const deactivateDriver = useDeactivateAccount();

  const driversArray = data?.pages?.flatMap(page => page.drivers) || [];


  const filteredDrivers = driversArray.filter(
    driver =>
      driver.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      driver.last_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

 

  const handleUnblockedUser = async () => {
    try {
      if (!selectedDriverId) {
        console.error("User ID not available.");
        return;
      }

      await unblockDriver.mutateAsync({ userId: selectedDriverId });
      setUnblockOverlay(false);
      setSelectedDriverId(null);
    } catch (error) {
      console.error("Failed to unblock user:", error.message);
    }
  };

  const handleBlockUser = async () => {
    try {
      if (!selectedDriverId) {
        console.error("User ID not available.");
        return;
      }

      await blockDriver.mutateAsync({
        userId: selectedDriverId,
        blockData: { startDate, endDate },
      });
      setBlockOverlay(false);
      setSelectedDriverId(null);
    } catch (error) {
      console.error("Failed to block user:", error.message);
    }
  };

  const handleDeactivateAccount = async () => {
    try {
      if (!selectedDriverId) {
        console.error("User ID not available.");
        return;
      }

      await deactivateDriver.mutateAsync(selectedDriverId);
      setDeactivateOverlay(false)
      setSelectedDriverId(null);
    } catch (error) {
      console.error("Failed to deactivate account:", error.message);
    }
  };


  const clampWords = (text, maxWords) => {
    const words = text.split(" ");
    const clampedWords = words.slice(0, maxWords);
    return clampedWords.join(" ");
  };


  const observerRef = useRef();
  const lastDriverElementRef = useCallback(
    (node) => {
      if (isFetchingNextPage) return;
      if (observerRef.current) observerRef.current.disconnect();

      observerRef.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && hasNextPage) {
          fetchNextPage();
        }
      });

      if (node) observerRef.current.observe(node);
    },
    [isFetchingNextPage, fetchNextPage, hasNextPage]
  );

  return (
    <div className="super_admin_driver_container">
      <div className="header">
        <p>Driver</p>
        <div className="side">
          <span>
            <SearchIcon />
            <input
              type="search"
              placeholder="Search Driver"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </span>
          <button onClick={() => navigate("/Superadminregisterdriver")}>Register New</button>
        </div>
      </div>
      <div className="driver-table">
        {isLoading && <Spinner/>}
        {isError && <p>Error loading drivers</p>}
        {!isLoading && !isError && (
          <table>
          <thead>
            <tr>
            <th>First </th>
                <th>Last </th>
                <th>Adress</th>
                <th>Branch</th>
                <th>Email </th>
                <th>Phone </th>
                <th>Vehicle Type</th>
                <th>Vehicle Plate No</th>
                <th>Veh. Reg. No</th>
                <th>Driver Licence No</th>
                <th>Block</th>
                <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredDrivers.map((driver, index) => {
              const isLastElement = filteredDrivers.length === index + 1;
              return (
                <tr 
                  key={driver._id}
                  ref={isLastElement ? lastDriverElementRef : null}
                >
                  <td>{driver?.first_name}</td>
                  <td>{driver?.last_name}</td>
                  <td>{clampWords(driver?.address, 3)}</td>
                  <td>{driver?.branch_full_address || 'N/A'}</td>
                  <td>{driver?.email}</td>
                  <td>{driver?.phone_number}</td>
                  <td>{driver?.vehicle_type}</td>
                  <td>{driver?.vehicle_plate_number}</td>
                  <td>{driver?.vehicle_registration_number}</td>
                  <td>{driver?.drivers_license_number}</td>
                  <td>{driver?.blocked ? "Yes" : "No"}</td>
                  <td>
                    <div className="action">
                      {driver.blocked ? (
                        <GoPlusCircle
                          onClick={() => {
                            setUnblockOverlay(true);
                            setSelectedDriverId(driver._id)
                          }}
                        />
                      ) : (
                        <RemoveIcon
                          onClick={() => {
                            setBlockOverlay(true);
                            setSelectedDriverId(driver._id)
                          }}
                        />
                      )}
                      <TrashIcon
                        onClick={() => {
                          setDeactivateOverlay(true);
                         setSelectedDriverId(driver._id)
                        }}
                      />
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        )}
      </div>
      {isFetchingNextPage && <Spinner />}

      {blockOverlay && (
        <Fragment>
          <div className="driver-block-over-container">
            <form>
              <p>Are you sure you want to suspend this driver?</p>
              <label>
              Start Date
              <input
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </label>
            <label>
              End Date
              <input
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </label>
              <div className="btn">
                <button onClick={() => setBlockOverlay(false)}>Cancel</button>
                <button
                  type="button"
                  onClick={handleBlockUser}
                  disabled={blockDriver.isLoading}
                >
                  {blockDriver.isLoading ? "Blocking..." : "Confirm"}
                </button>
              </div>
              {blockDriver.isError && (
                <p className="error-message">
                  Error: {blockDriver.error.message}
                </p>
              )}
            </form>
          </div>
        </Fragment>
      )}

      {unblockOverlay && (
        <Fragment>
          <div className="driver-unblock-over-container">
            <div className="inner">
              <p>Are you sure you want to lift the suspension on this driver?</p>
              <div className="btn">
                <button onClick={() => setUnblockOverlay(false)}>Cancel</button>
                <button
                  type="button"
                  onClick={handleUnblockedUser}
                  disabled={unblockDriver.isLoading}
                >
                  {unblockDriver.isLoading ? "Unblocking..." : "Confirm"}
                </button>
              </div>
              {unblockDriver.isError && (
                <p className="error-message">
                  Error: {unblockDriver.error.message}
                </p>
              )}
            </div>
          </div>
        </Fragment>
      )}

      {deactivateOverlay && (
        <Fragment>
          <div className="driver-delete-over-container">
            <div className="delete-card">
              <TrashIcon />
              <span>Deactivate</span>
              <p>
                Are you sure you want to deactivate this driver? This action
                cannot be undone?
              </p>
              <div className="btn">
                <button onClick={() => setDeactivateOverlay(false)}>Cancel</button>
                <button
                  onClick={handleDeactivateAccount}
                  disabled={deactivateDriver.isLoading}
                >
                  {deactivateDriver.isLoading ? "Deactivating..." : "Confirm"}
                </button>
              </div>
              {deactivateDriver.isError && (
                <p className="error-message">
                  Error: {deactivateDriver.error.message}
                </p>
              )}
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
}

export default SuperAdminDriver;

