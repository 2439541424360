import { useMutation } from 'react-query';
import { useContext } from 'react';
import { AuthContext } from '../../Context/AuthContext';

const useRegisterMarketplaceManager = () => {
  const { token } = useContext(AuthContext);

  const registerMarketplaceManager = async (formData) => {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/users/register/marketplaceManager`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(formData),
    });

    if (!response.ok) {
      throw new Error('Marketplace manager registration failed');
    }

    return response.json();
  };

  return useMutation(registerMarketplaceManager);
};

export default useRegisterMarketplaceManager;
