
import { useContext } from 'react';
import { useQuery } from 'react-query';
import { AuthContext } from "../../../../Context/AuthContext";

const useCompletedCharters = (currentPage) => {
  const { token } = useContext(AuthContext);

  const fetchCompletedCharters = async () => {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/charterer/completed-charters?page=${currentPage}&perPage=10`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });
    if (!response.ok) {
      throw new Error('Failed to fetch completed trip');
    }
    return response.json();
  };

  return useQuery(['completedCharters', currentPage], fetchCompletedCharters);
};

export default useCompletedCharters;



