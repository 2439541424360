import { useQuery } from "react-query";
import axios from "axios";

const useGetBanners = () => {
  return useQuery("banners", async () => {
    const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/banners/getbanners`);
    return response.data;
  });
};

export default useGetBanners;
