import React, { useState } from "react";
import { NavLink as Link, Outlet } from "react-router-dom";
import { Fragment } from "react";
import { ReactComponent as LogoIcon } from "../Assets/Frame 1.svg";
import {ReactComponent as DeliveryTruck} from "../asset/fast-delivery-truck-svgrepo-com 13x.svg"
import {ReactComponent as LocationIcon} from "../asset/delivery-box-svgrepo-com 13x (1).svg"
import {ReactComponent as Vehicle} from "../asset/fluent_vehicle-truck-cube-24-regular3x.svg"
import {ReactComponent as MiniVan} from "../asset/minivan-taxi-svgrepo-com 13x.svg"
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
import "./Navbar.css";

const Navbar = () => {
  const [isMobile, setIsmobile] = useState(false);
  const [serviceDrop, setServiceDrop] = useState(false)
  const [ourService, setOurService] = useState(false)

  const handlemenu = () => {
    setIsmobile(!isMobile);
  };

  const handleToggleService = () => {
    setServiceDrop(!serviceDrop)
  }

  const NavLink = ({ isActive }) => ({
    color: isActive ? "#FFCD00" : "",
  });

  return (
    <Fragment>
      <div className="nav-container">
        <Link to="/" className="logo" onClick={() => setIsmobile(false)}>
          <LogoIcon />
        </Link>
        <div className="service-card-box"  onClick={handleToggleService}>
           <h3>Products</h3>
            {serviceDrop ? <IoIosArrowUp /> : <IoIosArrowDown />}
           </div>
           {serviceDrop &&  <div className='service-mother'>
            <Link to='/Sender' onClick={() => setServiceDrop(false)}><DeliveryTruck/>Nationwide Delivery</Link>
            <Link to='/Charteringservice'  onClick={() => setServiceDrop(false)}><MiniVan/>Chartering Service</Link>
            <Link to='/truckselection'onClick={() => setServiceDrop(false)}><Vehicle/>Freight Weight Shipping</Link>
            <Link to='/international' onClick={() => setServiceDrop(false)}><LocationIcon/>International Shipping</Link>
        </div>}
        <div className={`nav-link ${isMobile ? "mobile active" : ""}`} >
          <div className="our-service"   >
         <div className="mobile-service-drop" >
           <span onClick={() => setOurService(!ourService)}  >
           <h3>Products</h3>
            {ourService ? <IoIosArrowUp /> : <IoIosArrowDown />}
           </span>
           {ourService && <div className="mobile-service-drop-card" >
           <Link to='/Sender' onClick={() => {setIsmobile(false)}}><DeliveryTruck/> Nationwide Delivery</Link>
            <Link to='/Charteringservice' onClick={() => setIsmobile(false)}><MiniVan/>Chartering Service</Link>
            <Link to='/truckselection' onClick={() => setIsmobile(false)}><Vehicle/>Freight Weight Shipping</Link>
            <Link to='/International' onClick={() => setIsmobile(false)}><LocationIcon/>International Shipping</Link>
            </div>}
           </div>
         </div>

          <Link to="/Market" style={NavLink} target="_blank">
            Marketplace
          </Link>

          <Link to="/About" style={NavLink}  onClick={() => setIsmobile(false)}>
            About Us
          </Link>
          <Link to="/Contact" style={NavLink}  onClick={() => setIsmobile(false)}>
            Contact Us
          </Link>
          <Link to="/Blog" style={NavLink}  onClick={() => setIsmobile(false)}>
            Blog
          </Link>
        </div>
        <div className="authentication">
          <Link to="/SignUp" className="sign-up"  onClick={() => setIsmobile(false)}>
            Sign Up
          </Link>
          <Link to="/Login" className="login"  onClick={() => setIsmobile(false)}>
            Log In
          </Link>
        </div>
        <div className="mobile-menu" onClick={handlemenu}>
          {isMobile ? (
            <i className="fa-solid fa-xmark"></i>
          ) : (
            <i className="fa-solid fa-bars"></i>
          )}
        </div>
      </div>
      <Outlet />
    </Fragment>
  );
};

export default Navbar;
