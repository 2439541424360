// import { useQuery } from 'react-query';
// import { useContext } from 'react';
// import { AuthContext } from '../../Context/AuthContext';

// const fetchShipments = async (branchAddress, token) => {
//   const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/prehome/shipments/${branchAddress}`, {
//     headers: {
//       Authorization: `Bearer ${token}`,
//     },
//   });

//   if (!response.ok) {
//     const error = await response.json();
//     throw new Error(error.message || 'Failed to fetch shipments');
//   }

//   return response.json();
// };

// const useShipmentByUser = () => {
//   const { user, token } = useContext(AuthContext);

//   return useQuery(['preShipment', user.branch_full_address], () =>
//     fetchShipments(user.branch_full_address, token)
//   );
// };

// export default useShipmentByUser;


import { useInfiniteQuery } from 'react-query';
import { useContext } from 'react';
import { AuthContext } from "../../Context/AuthContext";

const fetchShipments = async ({ pageParam = 1 }, token, branchAddress) => {
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/prehome/shipments/${branchAddress}?page=${pageParam}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error('Failed to fetch shipments');
  }

  return response.json();
};

const useShipmentByUser = () => {
  const { token, user } = useContext(AuthContext);
  return useInfiniteQuery(
    ['preShipment', user.branch_full_address], 
    ({ pageParam }) => fetchShipments({ pageParam }, token, user),
    {
      getNextPageParam: (lastPage) => {
        const nextPage = lastPage.currentPage + 1;
        return nextPage <= Math.ceil(lastPage.totalShipments / 10) ? nextPage : undefined;
      },
    }
  );
};

export default useShipmentByUser;

