// import { useQuery } from 'react-query';
// import axios from 'axios';
// import { AuthContext } from "../../Context/AuthContext";
// import { useContext } from 'react';

// const usePackagesByBranch = () => {
//   const { token, user } = useContext(AuthContext);

//   const fetchPackagesByBranch = async () => {
//     const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/packages/get-by-branch/${user.branch_full_address}`, {
//       headers: {
//         Authorization: `Bearer ${token}`,
//       },
//     });

//     return response.data.packages;
//   };

//   return useQuery(['packagesByBranch', user.branch_full_address], fetchPackagesByBranch);
// };

// export default usePackagesByBranch;



import { useInfiniteQuery } from 'react-query';
import { useContext } from 'react';
import { AuthContext } from "../../Context/AuthContext";

const fetchPackagesByBranch = async ({ pageParam = 1 }, token, user) => {
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/packages/get-by-branch/${user.branch_full_address}?page=${pageParam}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error('Failed to fetch package by branch');
  }

  return response.json();
};

const usePackagesByBranch = () => {
  const { token, user } = useContext(AuthContext);

  return useInfiniteQuery(
    ['packagesByBranch', user.branch_full_address],
    ({ pageParam = 1 }) => fetchPackagesByBranch({ pageParam }, token, user.branch_full_address),
    {
      getNextPageParam: (lastPage) => {
        const nextPage = lastPage.currentPage + 1;
        return nextPage <= Math.ceil(lastPage.totalPackage / 10) ? nextPage : undefined;
      },
    }
  );
};

export default usePackagesByBranch;

