import React, {useState} from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import 'react-loading-skeleton/dist/skeleton.css';
import Spinner from "../../utils/spinner";
import { ReactComponent as CloudLogo } from "../../Assets/bx_cloud-upload@3x.svg";
import { IoArrowBack } from "react-icons/io5";
import "./Superadmincreateinvestor.css";


const defaultForm = {
  firstName: '',
  middleName: '',
  lastName: '',
  address: '',
  phoneNumber: '',
  email: '',
  nextOfKinName: '',
  nextOfKinNum: '',
  nextOfKinAddress: '',
  subscriptionAmount: '',
  passport: null
};

const Superadmincreateinvestor = () => {

    const [form, setForm] = useState(defaultForm);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);
    const [preview, setPreview] = useState(null);
    const navigate = useNavigate();
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setForm((prevForm) => ({
        ...prevForm,
        [name]: value
      }));
    };
  
    const handleFileChange = (e) => {
      const file = e.target.files[0];
      if (file.size > 2 * 1024 * 1024) {
        toast.error("File size exceeds 2MB limit");
      } else {
        setForm((prevForm) => ({
          ...prevForm,
          passport: file
        }));
        setPreview(URL.createObjectURL(file));
      }
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      setIsLoading(true);
      setError(false);
  
      try {
        const formData = new FormData();
        for (const key in form) {
          formData.append(key, form[key]);
        }
  
        const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/`, {
          method: 'POST',
          body: formData,
        });
  
        const resData = await response.json();
  
        if (!response.ok) {
          throw new Error(resData.message);
        }
  
        setForm(defaultForm);
        setPreview(null);
        setIsLoading(false);
        navigate('/superadmininvestor')
      } catch (error) {
        setIsLoading(false);
        setError(error.message);
      }
    };
  


    return (
        <div className="super_admin-create-investor">
        <div className="header">
        <IoArrowBack onClick={() => navigate('/superadmininvestor')} />
        <p>Create Stex Investor</p>
        </div>
        <form onSubmit={handleSubmit}>
            <div className="passport">
              {preview ? (
                <img src={preview} alt="Passport Preview" className="passport-preview" />
              ) : (
                <>
                <CloudLogo />
                <h3>Upload photograph</h3>
                <p>size: 2mb, jpg,jpeg,png</p>
                </>
              )}
              <input
                type="file"
                accept=".jpg, .jpeg, .png"
                onChange={handleFileChange}
                required
              />
            </div>
          <div className="suscriber-card">
            <span>
              <label>Fisrt Name</label>
          <input
            type="text"
            name="firstName"
            value={form.firstName}
            onChange={handleChange}
            placeholder="First Name"
            required
            />
            </span>
            <span>
              <label>Middle Name</label>
          <input
            type="text"
            name="middleName"
            value={form.middleName}
            onChange={handleChange}
            placeholder="Middle Name"
            required
            />
            </span>
       
  
          <span>
              <label>Last Name</label>
          <input
            type="text"
            name="lastName"
            value={form.lastName}
            onChange={handleChange}
            placeholder="Last Name"
            required
            />
            </span>
            <span>
              <label>Email</label>
          <input
            type="email"
            name="email"
            value={form.email}
            onChange={handleChange}
            placeholder="Enter Email"
            required
            />
            </span>
         
          <span>
              <label>Phone Number</label>
          <input
            type="tel"
            name="lastName"
            value={form.phoneNumber}
            onChange={handleChange}
            placeholder="Enter Phone Number"
            required
            />
            </span>
            <span>
              <label>Address</label>
          <input
            type="text"
            name="address"
            value={form.address}
            onChange={handleChange}
            placeholder="Enter Address"
            required
            />
            </span>
  
           <span>
              <label> Suscription Amount</label>
           <input
            type="tel"
            name="subscriptionAmount"
            value={form.subscriptionAmount}
            onChange={handleChange}
            placeholder="Enter Amount"
            required
            />
            </span>
          <span>
              <label>Next Of Kin Name</label>
          <input
            type="text"
            name="nextOfKinName"
            value={form.nextOfKinName}
            onChange={handleChange}
            placeholder="Enter Name"
            required
            />
            </span>
          
           <span>
              <label>Next Of Kin Number </label>
          <input
            type="tel"
            name="lastName"
            value={form.nextOfKinNum}
            onChange={handleChange}
            placeholder="Last Name"
            />
            </span>
           <span>
              <label>Next Of Kin Address </label>
          <input
            type="text"
            name="nextOfKinAddress"
            value={form. nextOfKinAddress}
            onChange={handleChange}
            placeholder="Last Name"
            />
            </span>

          <button type="submit" disabled={isLoading}>
            {isLoading ? <Spinner /> : "Create"}
          </button>
          </div>
        
          {error && <p style={{ color: 'red', textAlign: "center" }}>{error}</p>}
        </form>
        <ToastContainer position="top-right" className="toast" />
      </div>
    );
}

export default Superadmincreateinvestor;
