

import { useContext } from "react";
import { useQuery } from "react-query";
import { AuthContext } from "../../../Context/AuthContext";

const GetTruckById = (id) => {
  const { token } = useContext(AuthContext);

  const fetchTruck = async () => {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/freight/get-trucks/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json"
      }
    });

    if (!response.ok) {
      throw new Error("Failed to fetch Truck");
    }

    return response.json();
  };

  const { data, isLoading, isError } = useQuery(["trucksDetails", id], fetchTruck); 

  return { data, isLoading, isError }; 
};

export default GetTruckById;

