import { ReactComponent as SearchIcon } from "../../asset/iconamoon_search-light.svg";
import useGetRiders from "../libs/useGetRiders";
import { Fragment, useState, useRef, useCallback } from "react";
import { ReactComponent as RemoveIcon } from "../../asset/gg_remove.svg";
import { ReactComponent as TrashIcon } from "../../asset/basil_trash-outline.svg";
import { GoPlusCircle } from "react-icons/go";
import useDeactivateAccount from "../libs/deactivateAccount";
import useBlockUserById from "../libs/blockUserById";
import useUnblockUserById from "../libs/unblockUserById";
import Spinner from "../../utils/spinner";
import { useNavigate } from "react-router-dom";
import "./superadminrider.css";

function Superadminrider() {
  const { data: riders, isLoading, isError, fetchNextPage, hasNextPage, isFetchingNextPage } = useGetRiders();
  const ridersArray = riders?.pages.flatMap(page => page.riders) || [];
  const navigate = useNavigate(); 
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedRiderId, setSelectedRiderId] = useState(null);
  const [blockOverlay, setBlockOverlay] = useState(false);
  const [unblockOverlay, setUnblockOverlay] = useState(false);
  const [deactivateOverlay, setDeactivateOverlay] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const blockRider = useBlockUserById();
  const unblockRider = useUnblockUserById();
  const deactivateRider = useDeactivateAccount();

  const filteredRiders = ridersArray.filter(
    (rider) =>
      rider.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      rider.last_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleUnblockedUser = async () => {
    try {
      if (!selectedRiderId) return;

      await unblockRider.mutateAsync({ userId: selectedRiderId });
      setUnblockOverlay(false);
      setSelectedRiderId(null);
    } catch (error) {
      console.error("Failed to unblock user:", error.message);
    }
  };

  const handleBlockUser = async () => {
    try {
      if (!selectedRiderId) return;

      await blockRider.mutateAsync({
        userId: selectedRiderId,
        blockData: { startDate, endDate },
      });
      setBlockOverlay(false);
      setSelectedRiderId(null);
    } catch (error) {
      console.error("Failed to block user:", error.message);
    }
  };

  const handleDeactivateAccount = async () => {
    try {
      if (!selectedRiderId) return;

      await deactivateRider.mutateAsync(selectedRiderId);
      setDeactivateOverlay(false);
      setSelectedRiderId(null);
    } catch (error) {
      console.error("Failed to deactivate account:", error.message);
    }
  };

  const clampWords = (text, maxWords) => {
    const words = text.split(" ");
    const clampedWords = words.slice(0, maxWords);
    return clampedWords.join(" ");
  };

  const observerRef = useRef();
  const lastRiderElementRef = useCallback(
    (node) => {
      if (isFetchingNextPage) return;

      if (observerRef.current) observerRef.current.disconnect();

      observerRef.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasNextPage) {
          fetchNextPage();
        }
      });

      if (node) observerRef.current.observe(node);
    },
    [isFetchingNextPage, fetchNextPage, hasNextPage]
  );

  return (
    <Fragment>
      {blockOverlay && (
        <Fragment>
          <div className="rider-block-over-container">
            <form>
              <p>Are you sure you want to suspend this driver?</p>
              <label>
                Start Date
                <input
                  type="date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </label>
              <label>
                End Date
                <input
                  type="date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </label>
              <div className="btn">
                <button onClick={() => setBlockOverlay(false)}>Cancel</button>
                <button
                  type="button"
                  onClick={handleBlockUser}
                  disabled={blockRider.isLoading}
                >
                  {blockRider.isLoading ? "Blocking..." : "Confirm"}
                </button>
              </div>
              {blockRider.isError && (
                <p className="error-message">
                  Error: {blockRider.error.message}
                </p>
              )}
            </form>
          </div>
        </Fragment>
      )}

      {unblockOverlay && (
        <Fragment>
          <div className="rider-unblock-over-container">
            <div className="inner">
              <p>
                Are you sure you want to lift the suspension on this driver?
              </p>
              <div className="btn">
                <button onClick={() => setUnblockOverlay(false)}>Cancel</button>
                <button
                  type="button"
                  onClick={handleUnblockedUser}
                  disabled={unblockRider.isLoading}
                >
                  {unblockRider.isLoading ? "Unblocking..." : "Confirm"}
                </button>
              </div>
              {unblockRider.isError && (
                <p className="error-message">
                  Error: {unblockRider.error.message}
                </p>
              )}
            </div>
          </div>
        </Fragment>
      )}

      {deactivateOverlay && (
        <Fragment>
          <div className="rider-delete-over-container">
            <div className="delete-card">
              <TrashIcon />
              <span>Deactivate</span>
              <p>
                Are you sure you want to deactivate this driver? This action
                cannot be undone?
              </p>
              <div className="btn">
                <button onClick={() => setDeactivateOverlay(false)}>
                  Cancel
                </button>
                <button
                  onClick={handleDeactivateAccount}
                  disabled={deactivateRider.isLoading}
                >
                  {deactivateRider.isLoading ? "Deactivating..." : "Confirm"}
                </button>
              </div>
              {deactivateRider.isError && (
                <p className="error-message">
                  Error: {deactivateRider.error.message}
                </p>
              )}
            </div>
          </div>
        </Fragment>
      )}

      <div className="super_admin_rider">
        <div className="header">
          <p>Rider</p>
          <div className="inner">
            <span>
              <SearchIcon />
              <input 
                type="search" 
                placeholder="Search Rider" 
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)} 
              />
            </span>
            <button onClick={() => navigate("/Superadminregisterrider")}>Register New</button>
          </div>
        </div>
        <div className="rider-table">
          {isLoading && <Spinner />}
          {isError && <p>Error loading drivers</p>}
          {!isLoading && !isError && (
            <table>
              <thead>
                <tr>
                  <th>First</th>
                  <th>Last </th>
                  <th>Rider Address</th>
                  <th>Branch</th>
                  <th>Email</th>
                  <th>Phone </th>
                  <th>Cycle Type</th>
                  <th>Cycle Plate No</th>
                  <th>Cycle.Re.NO</th>
                  <th>Driver Licence no</th>
                  <th>Block</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {filteredRiders.map((rider, index) => (
                  <tr key={rider._id} ref={filteredRiders.length === index + 1 ? lastRiderElementRef : null}>
                    <td>{rider?.first_name}</td>
                    <td>{rider?.last_name}</td>
                    <td>{clampWords(rider?.address || "N/A", 3)}</td>
                    <td>{rider?.branch_full_address || "N/A"}</td>
                    <td>{rider?.email}</td>
                    <td>{rider?.phone_number || "N/A"}</td>
                    <td>{rider?.motorcycle_type || "N/A"}</td>
                    <td>{rider?.bike_plate_number || "N/A"}</td>
                    <td>{rider?.bike_registration_number || "N/A"}</td>
                    <td>{rider?.drivers_license_number || "N/A"}</td>
                    <td>{rider?.blocked ? "Yes" : "No"}</td>
                    <td>
                      <div className="action">
                        {rider.blocked ? (
                          <GoPlusCircle
                            onClick={() => {
                              setUnblockOverlay(true);
                              setSelectedRiderId(rider._id);
                            }}
                          />
                        ) : (
                          <RemoveIcon
                            onClick={() => {
                              setBlockOverlay(true);
                              setSelectedRiderId(rider._id);
                            }}
                          />
                        )}
                        <TrashIcon
                          onClick={() => {
                            setDeactivateOverlay(true);
                            setSelectedRiderId(rider._id);
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
        {isFetchingNextPage && <Spinner />}
      </div>
    </Fragment>
  );
}

export default Superadminrider;

