import { useMutation, useQueryClient } from 'react-query';
import { useContext } from 'react';
import { AuthContext } from '../../Context/AuthContext'; 

const useAssignPackagesToDriver = () => {
  const { token } = useContext(AuthContext);
  const queryClient = useQueryClient();

  const assignPackagesToDriver = async ({ userId, packageIds }) => {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/packages/assign-packages-driver`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        userId,
        packageIds,
      }),
    });

    if (!response.ok) {
      throw new Error('Error assigning packages to driver');
    }    
    queryClient.invalidateQueries('dispatchDetailsByCreatorId');
    queryClient.invalidateQueries('driverAssignmen');
    return response.json();
  };

  return useMutation(assignPackagesToDriver);
};

export default useAssignPackagesToDriver;
