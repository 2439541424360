
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import useFormData from "../../../Context/UseFormData";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { CiUser } from "react-icons/ci";
import { TbSteeringWheel } from "react-icons/tb";
import { ReactComponent as SeatIcon } from "../../../Assets/safety-seat-car-svgrepo-com 13x.svg";
import { BsFuelPump } from "react-icons/bs";
import { GiCarDoor } from "react-icons/gi";
import { useNavigate } from "react-router-dom";
import GetVehicleById from "../CharterAdmin/libs/getVehiclesById";
import Skeleton from 'react-loading-skeleton'; 
import 'react-loading-skeleton/dist/skeleton.css';
import "./CharterDetail.css";

const Charterdetail = () => {
  const { id } = useParams();
  const { data: vehicleData, isError } = GetVehicleById(id);
  const navigate = useNavigate();
  const [formData, handleChange] = useFormData("charterFormData");
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const totalImages = vehicleData?.vehicle?.images?.length || 0;

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % totalImages);
  };

  const handlePrevImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + totalImages) % totalImages);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    navigate("/passengerdetails");
  };

  useEffect(() => {
    if (vehicleData && vehicleData.vehicle) {
      localStorage.setItem("vehicleData", JSON.stringify(vehicleData.vehicle));
      const storedVehicleData = JSON.parse(localStorage.getItem("vehicleData"));
      console.log("Vehicle Data stored in local storage:", storedVehicleData);
    }
  }, [vehicleData]);
  
  if (isError) {
    return <div>Error fetching data</div>;
  }

  const vehicle = vehicleData?.vehicle;


 
  return (
    <div className="charter-detail-mother">
      <div className="vehicle">
        {vehicleData ? (
          <>
            <img
              src={vehicle.images[currentImageIndex]}
              alt={vehicle.vehicleModel}
              className="vehicle-image"
            />
            {vehicle.images.length > 1 && (
              <div className="image-navigation">
                <button
                  className="next-image"
                  onClick={handleNextImage}
                ><IoIosArrowForward /></button>
                <button
                  className="prev-image"
                  onClick={handlePrevImage}
                ><IoIosArrowBack /></button>
              </div>
            )}
          </>
        ) : (
          <Skeleton height={290} count={2} />
        )}
      </div>
      <div className="vehicle-detail">
        {vehicleData ? (
          <div className="vehicle-card-1">
              <h3>{vehicle.vehicleModel}</h3>
              <span>
                <CiUser />
                <p>Max no of people: {vehicle.maxNumberOfPeople}</p>
              </span>
              <div className="wrapper">
                <div className="card">
                  <span>
                    <SeatIcon />
                    <p>{vehicle.numberOfSeats} seats</p>
                  </span>
                  <span>
                    <TbSteeringWheel />
                    <p>Power Type: {vehicle.powerType}</p>
                  </span>
                  <span>
                    <BsFuelPump />
                    <p>Fuel Tank Cap:{vehicle?.fuelTankCapacity}</p>
                  </span>
                  <span><p>State:  {vehicle.state}</p></span>
                </div>
                <div className="card">
                  <span>
                    <GiCarDoor />
                    <p>{vehicle.doors} Doors</p>
                  </span>
                </div>
              </div>
              <div className="vehicle-span">
                <h3>DESCRIPTION</h3>
                <p>{vehicle.description}</p>
                <Link to='/charteringservice'>BACK</Link>
              </div>
          </div>
        ) : (
          <div className="skeleton-loading">
            <Skeleton height={150} width={600}/>
            <Skeleton height={20} count={5} />
          </div>
        )}
        <div className="vehicle-card-2">
          <span>
            <h3>
              {vehicle ? (
                vehicle.pricingPerDay.toLocaleString("en-US", {
                  style: "currency",
                  currency: "NGN",
                }) + "/day"
              ) : (
                <Skeleton width={400} />
              )}
            </h3>
          </span>
          <form onSubmit={handleSubmit}>
            <div className="charter-wrapper">
              <label>Trip Start</label>
              <div className="vehicle-combination">
                <input
                  type="date"
                  name="startDate"
                  value={formData.startDate || ""}
                  onChange={handleChange}
                  required
                />
                <input
                  type="time"
                  name="startTime"
                  value={formData.startTime || ""}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <span>
            <label>Trip End</label>
            <div className="vehicle-combinations">
              <input
                type="date"
                name="endDate"
                value={formData.endDate || ""}
                onChange={handleChange}
                required
              />
              <input
                type="time"
                name="endTime"
                value={formData.endTime || ""}
                onChange={handleChange}
                required
              />
            </div>
          </span>
          <button type="submit">Continue</button>
          </form>
        </div>
      </div>
      <div className="review">
        <h3>REVIEWS</h3>
      </div>
    </div>
  );
};

export default Charterdetail;




