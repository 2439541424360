import { useMutation, useQueryClient } from 'react-query';
import { useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../../Context/AuthContext';

const useUpdatePost = () => {
  const { token } = useContext(AuthContext);
  const queryClient = useQueryClient();

  const updatePostMutation = useMutation(
    async ({ id, updatedData }) => {
      const response = await axios.patch(`${process.env.REACT_APP_SERVER_URL}/blogs/update/${id}`, updatedData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('blogs');
        console.log('Post updated successfully');
      },
    }
  );

  const updatePost = async (id, updatedData) => {
    try {
      await updatePostMutation.mutateAsync({ id, updatedData });
    } catch (error) {
      console.error('Error updating post:', error);
    }
  };

  return {
    updatePost,
    isUpdating: updatePostMutation.isLoading,
  };
};

export default useUpdatePost;
