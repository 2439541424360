import { useQuery } from 'react-query'; 

const useRecommendedLimit = () => {
  const { data, isLoading, isError } = useQuery('recommendedLimit', async () => {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/product/all-products`);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  });

  return { data, isLoading, isError }; 
};

export default useRecommendedLimit;