import { useContext } from 'react';
import { AuthContext } from '../../Context/AuthContext';
import { useQueryClient } from 'react-query'; 

const useUpdateChartererById = () => {
  const { token } = useContext(AuthContext);
  const queryClient = useQueryClient(); 

  const updateChartererById = async (userId,userData) => {
    console.log('Updating charterer officer with data:', userData);
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/users/update/charterer/${userId}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(userData),
    });

    if (!response.ok) {
      throw new Error('Error updating charterer officer');
    }

    queryClient.invalidateQueries('chartererOfficers');

    return response.json();
  };

  return { updateChartererById };
};


export default useUpdateChartererById;
