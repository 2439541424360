import { useContext } from 'react';
import { AuthContext } from '../../Context/AuthContext';

const useSendMessageToalluser = () => {
  const { token, user } = useContext(AuthContext);

  const sendMessage = async (userId, messageContent) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/messages/send-to-all`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          messageContent,
          sender: user.userId,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to send message');
      }

      return response.json();
    } catch (error) {
      console.error('Error sending message:', error.message);
      throw error;
    }
  };

  return { sendMessage };
};

export default useSendMessageToalluser;
