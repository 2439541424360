import { Outlet, Link, useNavigate } from "react-router-dom";
import { Fragment, useState } from "react";
import { HiOutlineXMark } from "react-icons/hi2";
import { VscMenu } from "react-icons/vsc";
import { ReactComponent as LogoIcon } from "../../asset/Frame logo.svg";
import { ReactComponent as PersonIcon } from "../../asset/bi_person.svg";
import { MdOutlineArrowDropDown } from "react-icons/md";
import UserDataFetcher from "../../super_admin/libs/Getadminbyid";
import { AuthContext } from "../../Context/AuthContext";
import { useContext } from "react";

function Ridernavbar() {
    const { adminData } = UserDataFetcher();
  
    const [isMobile, setIsmobile] = useState(false);
    const {Logout} = useContext(AuthContext)
    const navigate = useNavigate()
    return (
      <Fragment>
        <div className="navbar-container-account">
          <div className="logo">
            <LogoIcon onClick={() => navigate("/")} />
            <span>
              Welcome back, <p>{adminData?.user?.first_name} {adminData?.user?.last_name}</p>
            </span>
          </div>
          
          <div className="drop">
            <div className="ic">
              <PersonIcon />
            </div>
            <MdOutlineArrowDropDown  className="arrow"/>
          </div>
          <div className="menubtn" onClick={() => setIsmobile(!isMobile)}>
            {isMobile ? <HiOutlineXMark /> : <VscMenu />}
          </div>
          <div  className={`nav-links ${isMobile ? "mobile-link active" : ""}`} onClick={() => setIsmobile(!isMobile)}>
          <Link to='/'>Home</Link>
          <Link to='/Rideraccepted'>My assignment</Link>
          <Link to='/Riderprofile'>Profile</Link>
          <button onClick={() => Logout()}>Logout</button>
          </div>
        </div>
        <Outlet />
      </Fragment>
    );
}

export default Ridernavbar
