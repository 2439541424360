import "./Superadminregisterrider.css";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { IoMdArrowBack } from "react-icons/io";
import useRegisterRider from "../libs/useRegisterRider";

function Superadminregisterrider() {
  const navigate = useNavigate();
  const registerRiderMutation = useRegisterRider();
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    country_code: "+234",
    phone_number: "",
    email: "",
    motorcycle_type: "",
    bike_plate_number: "",
    bike_registration_number: "",
    drivers_license_number: "",
    address: "",
    branch_full_address: "",
  });
  const [success, setSuccess] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await registerRiderMutation.mutateAsync(formData);
      setSuccess("Rider registered successfully, code sent to an Email");
      setTimeout(() => {
        setSuccess(false);
        navigate("/Superadminrider");
      }, 3000);
    } catch (error) {
      console.error("Registration failed:", error.message);
    }
  };
  return (
    <div className="register_rider_container">
      <div className="header">
        <IoMdArrowBack
          onClick={() => navigate("/Superadminrider")}
          className="icon"
        />
        <p>Register New Rider</p>
      </div>
      <div className="rider-register-form">
        <form onSubmit={handleSubmit}>
          <label>
            First Name
            <input
              type="text"
              name="first_name"
              onChange={handleInputChange}
              required
            />
          </label>

          <label>
            Last Name
            <input
              type="text"
              name="last_name"
              onChange={handleInputChange}
              required
            />
          </label>

          <label>
            Phone Number
            <div className="inner">
              <select name="country_code" onChange={handleInputChange} required>
                <option value="+234">+234</option>
                <option value="+1">+1</option>
                <option value="+95">+95</option>
              </select>
              <input
                type="text"
                name="phone_number"
                onChange={handleInputChange}
                required
              />
            </div>
          </label>

          <label>
            Email
            <input
              type="email"
              name="email"
              onChange={handleInputChange}
              required
            />
          </label>

          <label>
            Cycle Type
            <input
              type="text"
              name="motorcycle_type"
              onChange={handleInputChange}
              required
            />
          </label>

          <label>
            Cycle Plate Number
            <input
              type="text"
              name="bike_plate_number"
              onChange={handleInputChange}
              required
            />
          </label>

          <label>
            Cycle Registration Number
            <input
              type="text"
              name="bike_registration_number"
              onChange={handleInputChange}
              required
            />
          </label>

          <label>
            Drivers License Number
            <input
              type="text"
              name="drivers_license_number"
              onChange={handleInputChange}
              required
            />
          </label>

          <label>
            Driver(s) Full Address
            <textarea
              name="address"
              onChange={handleInputChange}
              required
            ></textarea>
          </label>

          <label>
            Branch
            <select
              name="branch_full_address"
              onChange={handleInputChange}
              required
            >
              <option value="">Select Branch</option>
              <option value="Branch 1">Branch 1</option>
              <option value="Branch 2">Branch 2</option>
            </select>
          </label>

          <button type="submit" disabled={registerRiderMutation.isLoading}>
            {registerRiderMutation.isLoading ? "Registering..." : "Register"}
          </button>
          {registerRiderMutation.isError && (
            <p className="error-message">
              Error: {registerRiderMutation.error.message}
            </p>
          )}
          {success && <p className="success-message">{success}</p>}
        </form>
      </div>
    </div>
  );
}

export default Superadminregisterrider;
