
import { useQuery } from 'react-query';
import { useContext } from 'react';
import { AuthContext } from '../../Context/AuthContext';

const fetchTotalActivePackagesById = async (userId, token) => {
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/packages/total-active-packages/${userId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error('Failed to fetch total active packages');
  }

  return response.json();
};

const useTotalActivePackagesById = () => {
  const { user, token } = useContext(AuthContext);

  return useQuery(['totalActivePackages', user.userId, token], () =>
    fetchTotalActivePackagesById(user.userId, token)
  );
};

export default useTotalActivePackagesById;
