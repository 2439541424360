// useUpdateShippingCostById
import { useContext } from 'react';
import { AuthContext } from '../../Context/AuthContext';
import { useMutation, useQueryClient } from 'react-query';

const useUpdateShippingCostById = () => {
  const { token } = useContext(AuthContext);
  const queryClient = useQueryClient();

  const updateShippingCostMutation = useMutation(
    async ({ id, costData }) => {
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/shipping/update-cost/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(costData),
      });

      if (!response.ok) {
        throw new Error('Error updating shipping cost');
      }

      return response.json();
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('shippingCost');
      },
    }
  );

  const updateShippingCostById = async (id, costData) => {
    try {
      await updateShippingCostMutation.mutateAsync({ id, costData });
    } catch (error) {
      console.error('Error updating shipping cost:', error);
    }
  };

  return { updateShippingCostById, isUpdating: updateShippingCostMutation.isLoading };
};

export default useUpdateShippingCostById;