import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"

function SimpleSlider() {
  const settings = {
    dots: false,
    autoplay: true,
    arrows:false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  return (
    <div className="about-slider-container">
      <Slider {...settings} className="slides">
        <div className="about-slider-1"> </div>
        <div className="about-slider-2"></div>
        <div className="about-slider-3"></div>
        <div className="about-slider-4"> </div>
      </Slider>
    </div>
  );
}

export default SimpleSlider;
