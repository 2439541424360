import React, { useContext, useState } from "react";
import ImageUpload from "../../utils/ImagePicker";
import { useMutation, useQueryClient } from "react-query";
import { AuthContext } from "../../Context/AuthContext";
import { IoMdArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import "./Superadmincreateblog.css";




function Superadmincreateblog() {
  const { token } = useContext(AuthContext);
  const queryClient = useQueryClient();
  const [formFeild, setFormfeild] = useState({
    title: "",
    description: "",
    author: "",
  });
  const [image, setImage] = useState(null);
  const [msg, setMsg] = useState(false)
  
  

  const navigate = useNavigate()

  const createBlogMutation = useMutation(
    async () => {
      // Create a new FormData instance
      const formData = new FormData();

      formData.append("title", formFeild.title);
      formData.append("description", formFeild.description);
      formData.append("author", formFeild.author);
      formData.append("image", image);

      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/blogs/post`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Failed to post blog");
      }

      return response.json();
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("blogs");
        setMsg("Blog posted successfully")
        setTimeout(() => {
          setMsg(false)
          navigate("/Superadminblog")
        }, 3000);
        
      },
    }
  );

  const handleImage = (file) => {
    setImage(file);
  };

  const handleChange = (name, value) => {
    setFormfeild((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handlePublish = () => {
    createBlogMutation.mutate();
  };

  return (
    <div className="super-admin-post-container">
      <div className="header">
        <span>
        <IoMdArrowBack onClick={() => navigate("/Superadminblog")} className="icon" />
        <p>New Post</p>
        </span>
        <button onClick={handlePublish} disabled={createBlogMutation.isLoading}>
          {createBlogMutation.isLoading ? "Publishing..." : "Publish"}
        </button>
      </div>
      <div className="post-container">
        <div className="image-box">
          <ImageUpload onChange={handleImage} />
        </div>
        <div className="blog-details">
          <input
            type="text"
            name="author"
            placeholder="Author"
            value={formFeild.author}
            onChange={(e) => handleChange("author", e.target.value)}
            required
          />
          <input
            type="text"
            name="title"
            placeholder="Title"
            value={formFeild.title}
            onChange={(e) => handleChange("title", e.target.value)}
            required
          />

         
          <textarea value={formFeild.description}
            onChange={(e) => handleChange("description", e.target.value)}
            placeholder="Write your blog content..."
            required></textarea>
        </div>
      </div>
      {msg && <p>{msg}</p>}
    </div>
  );
}

export default Superadmincreateblog;
