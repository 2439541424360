import { Fragment, useState, useEffect, useMemo } from "react";
import useGetBlogs from "../libs/useGetBlogs";
import { ReactComponent as WriteIcon } from "../../asset/system-uicons_write.svg";
import Spinner from "../../utils/spinner";
import { useNavigate } from "react-router-dom";
import { ReactComponent as TrashIcon } from "../../asset/basil_trash-outline.svg";
import useDeletePost from "../libs/useDeletePost";
import useUpdatePost from "../libs/useUpdatePost";
import "./Superadminblog.css";

export const formatDate = (dateString) => {
  const dateObject = new Date(dateString);

  const formattedDate = dateObject.toLocaleString("en-NG", {
    day: "numeric",
    month: "long",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
    timeZone: "Africa/Lagos",
  });

  return formattedDate;
};

function Superadminblog() {
  const { data: blogs, isLoading, isError } = useGetBlogs();

 
  const Blogs = useMemo(() => (blogs?.posts ? [...blogs.posts].reverse() : []), [blogs]);

  const navigate = useNavigate();

  const [selectedBlogId, setSelectedBlogId] = useState(null);
  const [deletePostOverlay, setDeletePostOverlay] = useState(false);
  const [editPostOverlay, setEditPostOverlay] = useState(false);
  const [editedData, setEditedData] = useState({ title: "", description: "" }); 

  const deletePost = useDeletePost();
  const updatePost = useUpdatePost();

  useEffect(() => {
    if (selectedBlogId) {
      const selectedBlog = Blogs.find((blog) => blog._id === selectedBlogId);
      if (selectedBlog) {
        setEditedData({ title: selectedBlog.title, description: selectedBlog.description });
      }
    }
  }, [selectedBlogId, Blogs]);

  const handleDeletePost = async () => {
    try {
      if (!selectedBlogId) {
        return;
      }

      setDeletePostOverlay(true);
      await deletePost.deletePost(selectedBlogId);
      setSelectedBlogId(null);
    } catch (error) {
      console.error("Failed to delete post", error.message);
    } finally {
      setDeletePostOverlay(false);
    }
  };

  const handleUpdatePost = async () => {
    try {
      if (!selectedBlogId) {
        return;
      }

      setEditPostOverlay(true);

      await updatePost.updatePost(selectedBlogId, editedData);
      
      setSelectedBlogId(null);
    } catch (error) {
      console.error("Failed to update post", error.message);
    } finally {
      setEditPostOverlay(false);
    }
  };

  return (
    <Fragment>
      {editPostOverlay && (
        <div className="blog-edit-over-container">
          <form className="delete-card">
            <p>Update Post</p>
            <input
              type="text"
              placeholder="Title"
              value={editedData.title}
              onChange={(e) => setEditedData((prev) => ({ ...prev, title: e.target.value }))}
            />
            <input
              type="text"
              placeholder="Description"
              value={editedData.description}
              onChange={(e) => setEditedData((prev) => ({ ...prev, description: e.target.value }))}
            />
            <div className="btn">
              <button onClick={() => setEditPostOverlay(false)}>Cancel</button>
              <button onClick={handleUpdatePost} disabled={updatePost.isUpdating}>
                {updatePost.isUpdating ? "Updating..." : "Confirm"}
              </button>
            </div>
          </form>
        </div>
      )}
      {deletePostOverlay && (
        <div className="blog-delete-over-container">
          <div className="delete-card">
            <TrashIcon />
            <span>Delete</span>
            <p>
              Are you sure you want to delete this post? This action cannot be undone?
            </p>
            <div className="btn">
              <button onClick={() => setDeletePostOverlay(false)}>Cancel</button>
              <button
                onClick={handleDeletePost}
                disabled={deletePost.isDeleting}
              >
                {deletePost.isDeleting ? "Deleting..." : "Confirm"}
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="super_admin_blog_container">
        <div className="header">
          <p>Blog</p>
          <span onClick={() => navigate("/Superadmincreateblog")}>
            <p>New Post</p>
            <WriteIcon />
          </span>
        </div>
        {isError && <p>An error occurred!</p>}
        {isLoading && <Spinner />}
        {!isLoading && !isError && (
          <div className="blogs-container">
            {Blogs.map((blog) => (
              <div key={blog._id} className="blog-item">
                <div className="wrapper">
                  <p>{formatDate(blog?.createdAt)}</p>
                  <h2>{blog?.title}</h2>
                  <span>{blog?.description}</span>
                  <div className="btn">
                    <button
                      onClick={() => {
                        setEditPostOverlay(true);
                        setSelectedBlogId(blog._id);
                      }}
                    >
                      Edit
                    </button>
                    <button
                      onClick={() => {
                        setDeletePostOverlay(true);
                        setSelectedBlogId(blog._id);
                      }}
                    >
                      Delete
                    </button>
                  </div>
                </div>
                <img src={blog?.image} alt="" />
              </div>
            ))}
          </div>
        )}
      </div>
    </Fragment>
  );
}

export default Superadminblog;

