import { useMutation, useQueryClient } from 'react-query';
import { useContext } from 'react';
import { AuthContext } from '../../../../Context/AuthContext';


const useEditProduct = () => {
  const queryClient = useQueryClient();
  const { token } = useContext(AuthContext);

  const editProduct = useMutation(
    async ({ id, formData }) => { 
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/product/update/product/${id}`, {
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData), 
      });

      if (!response.ok) {
        throw new Error('Failed to Edit product');
      }

      return response.json();
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('products');
        queryClient.invalidateQueries('productDetails');
      },
    }
  );

  return {
    editProduct,
    isLoading: editProduct.isLoading,
  };
};

export default useEditProduct;
