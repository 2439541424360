import React, { useState, useRef, useCallback } from 'react';
import useCustomers from '../libs/useCustomers';
import Spinner from '../../utils/spinner';
import { ReactComponent as SearchIcon } from '../../Assets/iconoir_search.svg';
import './marketplacecustomer.css';

const MarketplaceCustomer = () => {
  const { isLoading, error, data, fetchNextPage, hasNextPage, isFetchingNextPage } = useCustomers();
  const [searchQuery, setSearchQuery] = useState('');

  const filteredCustomers = data
    ? data.pages.flatMap((page) =>
        page.customers.filter((customer) => {
          const fullName = `${customer.first_name} ${customer.last_name}`.toLowerCase();
          return fullName.includes(searchQuery.toLowerCase());
        })
      )
    : [];

  const observerRef = useRef();
  const lastCustomerElementRef = useCallback(
    (node) => {
      if (isFetchingNextPage) return;

      if (observerRef.current) observerRef.current.disconnect();

      observerRef.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting && hasNextPage) {
            fetchNextPage();
          }
        },
        { threshold: 0.5 }
      );

      if (node) observerRef.current.observe(node);
    },
    [isFetchingNextPage, fetchNextPage, hasNextPage]
  );

  return (
    <div className="markplace-customer-container">
      <div className="header">
        <h1>Customers</h1>
        <span>
          <SearchIcon />
          <input
            type="search"
            placeholder="Search by names"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </span>
      </div>

      <div className="customer-table">
        <div>{isLoading && <Spinner />}</div>
        {!isLoading && error && <p>{error.message}</p>}
        {!isLoading && filteredCustomers.length > 0 && (
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Phone Number</th>
                <th>Email</th>
                <th>Blocked</th>
                <th>Active</th>
                <th>Orders</th>
                <th>Order Details</th>
              </tr>
            </thead>
            <tbody>
              {filteredCustomers.map((customer, index) => (
                <tr
                  key={customer._id}
                  ref={filteredCustomers.length === index + 1 ? lastCustomerElementRef : null}
                >
                  <td>{customer._id.slice(0, 8).toUpperCase()}</td>
                  <td>{customer.first_name}</td>
                  <td>{customer.last_name}</td>
                  <td>{customer.country_code} {customer.phone_number}</td>
                  <td>{customer.email}</td>
                  <td>{customer.blocked ? 'Yes' : 'No'}</td>
                  <td>{customer.isActive ? 'Yes' : 'No'}</td>
                  <td>15</td>
                  <td>
                    <button>View Details</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
      {isFetchingNextPage && (
        <div className="loading-more">
          <Spinner />
        </div>
      )}
    </div>
  );
};

export default MarketplaceCustomer;
