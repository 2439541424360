import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { MdKeyboardDoubleArrowRight } from 'react-icons/md';
import Skeleton from 'react-loading-skeleton'; 
import 'react-loading-skeleton/dist/skeleton.css';
import useSiennaCollection from '../CharterAdmin/libs/fetchSiennaCollection';
import './AllCharter.css';


const Siennacharterservice = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 8;
    const { siennaData, isLoading, isError } = useSiennaCollection(currentPage);

    useEffect(() => {
        if (siennaData && siennaData.currentPage) {
            setCurrentPage(siennaData.currentPage);
        }
    }, [siennaData]);

    const handleScroll = useCallback(() => {
        if (
            window.innerHeight + document.documentElement.scrollTop === 
            document.documentElement.offsetHeight
        ) {
            if (currentPage < siennaData.totalPages) {
              
            }
        }
    }, [currentPage, siennaData]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [handleScroll]);

    if (isError) {
        return <div className='error'>No Sienna available now</div>;
    }

    if (isLoading) {
        return (
            <div className='vehicle-collection'>
                <span>
                    <Link to='/charteringservice'>Home</Link>
                    <MdKeyboardDoubleArrowRight />
                    <h3>Siennas</h3>
                </span>
                <h3>SIENNAS COLLECTION</h3>
                <div className="wrapper">
                    {[...Array(8)].map((_, index) => (
                        <div key={index} className="vehicle-list">
                            <Skeleton height={250} width={300} count={1} />
                        </div>
                    ))}
                </div>
            </div>
        );
    }

    const { sienna, totalSienna } = siennaData; 

    if (!sienna || sienna.length === 0) {
        return <div>No siennas available</div>; 
    }

    const handlePrevPage = () => {
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
      };
    
      const handleNextPage = () => {
        setCurrentPage((prevPage) => Math.min(prevPage + 1, siennaData.totalPages));
      };

    const totalPages = Math.ceil(totalSienna / itemsPerPage);
    const startIndex = Math.min((currentPage - 1) * itemsPerPage + 1, totalSienna);
    const endIndex = Math.min(startIndex + itemsPerPage - 1, totalSienna);
  

    const clampWords = (text, maxWords) => {
        const words = text.split(' ');
        const clampedText = words.slice(0, maxWords).join(' ');
        return words.length > maxWords ? `${clampedText}...` : clampedText;
    };

    return (
        <div className='vehicle-collection'>
            <span>
                <Link to='/charteringservice'>Home</Link>
                <MdKeyboardDoubleArrowRight />
                <h3>Siennas</h3>
            </span>
            <h3>SIENNAS COLLECTION</h3>
            <div className="wrapper">
               {sienna.map(sienna => (
                    <div key={sienna._id} className="vehicle-list">
                        <img src={sienna.images[0]} alt={sienna.vehicleModel} />
                        <h4>{sienna.vehicleModel}</h4>
                        <h3><p>Price Per Day:</p>{sienna.pricingPerDay
                      ? (sienna.pricingPerDay ).toLocaleString("en-US", {
                          style: "currency",
                          currency: "NGN",
                        })
                      : ""}</h3>
                        <p>{sienna.isHired ? 'Not available' : 'Available'}</p>
                        <p>{clampWords(sienna.description, 5)}<Link to={`/car-details/${sienna._id}`}>view details</Link></p>
                    </div>
                ))}
            </div>
            <div className="vehicle-collection-pagination">
                <span>{`Showing ${startIndex} - ${endIndex} of ${totalSienna}`}</span>
                <div className="btn">
                    <button onClick={handlePrevPage} disabled={currentPage === 1}>
                        Prev
                    </button>
                    <button onClick={handleNextPage} disabled={currentPage === totalPages}>
                        Next
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Siennacharterservice;
