
import { useQuery } from 'react-query';

const useCharteredVehicles = (page) => {

  const fetchVehicles = async () => {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/charterer/vehicles?page=${page}`, {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    if (!response.ok) {
      throw new Error('Failed to fetch vehicles');
    }

    return response.json();
  };

  const { data: vehicles = [], isLoading, isError } = useQuery(['charteredVehicle', page], fetchVehicles);

  return { vehicles, isLoading, isError }; 
};

export default useCharteredVehicles;

