
import React, { useState } from 'react';
import Spinner from '../../Spinner/Spinner';
import { useNavigate } from 'react-router-dom';
import "./Token.css";


const defaultForm = {
  email: ''
};

const OtpRequest = () => {
  const [form, setForm] = useState(defaultForm);
  const { email } = form;
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/users/resend/activation-code`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(form)
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message);
      }
      setLoading(false);
      setForm(defaultForm);
      navigate(`/EmailVerificationForm?email=${encodeURIComponent(email)}`);
    } catch (error) {
      setLoading(false);
      console.error('Error requesting OTP:', error);
      setError(error.message);
    }
  };

  return (
    <div className='resend-request-card'>
      <h3>Resend activation code</h3>
      <form onSubmit={handleSubmit}>
        <span>
          <label>Email</label>
          <input
            type="email"
            name="email"
            value={email}
            onChange={handleChange}
            required
          />
        </span>
        <button type="submit" disabled={loading}>
          {loading ? <Spinner /> : "Request"}
        </button>
      </form>

      {error && <p style={{ color: 'red', fontFamily:'Montserrat' }}>{error}</p>}
    </div>
  );
};

export default OtpRequest;

