
import { useQuery } from 'react-query';

const useExecutiveCollection = (page) => {
  const fetchExecutive = async () => {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/charterer/vehicle/executivebus?page=${page}`, {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    if (!response.ok) {
      throw new Error('Failed to fetch executive');
    }

    return response.json();
  };

  const { data: executiveData = {}, isLoading, isError } = useQuery(['charteredExecutive', page], fetchExecutive);

  return { executiveData, isLoading, isError }; 
};

export default useExecutiveCollection; 
