import React, { useState } from 'react';
import { PiShoppingCartThin } from "react-icons/pi";
import MarketNavbar from '../MarketNav/MarketNavbar';
import Usernavbar from './usernavbar';
import { Link } from 'react-router-dom';
import "./UserProfile.css"



const Customerorder = () => {
    const [ongoingDeliver, setOngoingDeliver] = useState(true);
    const [cancleReturn, setCancelReturn] = useState(false);

    const handleOngoingDeliver = () => {
        setOngoingDeliver(true)
        setCancelReturn(false)
    }
    const handleCancelReturn = () => {
        setOngoingDeliver(false)
        setCancelReturn(true)
    }
    return (
        <>
        <MarketNavbar/>
        <div className='customer-order'>
            <Usernavbar/>
            <div className="customer-order-con">
            <h3>Orders</h3>
            <div className="customer-order-switch">
                <button onClick={handleOngoingDeliver}>ONGOING/DELIVERED</button>
                <button onClick={handleCancelReturn}>CANCELED/RETURNED</button>
            </div>
            {ongoingDeliver && (
            <div className="wrapper">
                <div className="order-circle">
                <PiShoppingCartThin style={{fontSize:'45px',color:'#d0300e'}} />
                </div>
                <h3>You have placed no orders yet!</h3>
                <p>All your orders will be saved here for you to access their state anytime.</p>
                <Link to='/Market' >START SHOPPING</Link>
            </div>
            )}
            {cancleReturn && (
            <div className="wrapper">
                <div className="order-circle">
                <PiShoppingCartThin style={{fontSize:'45px',color:'#d0300e'}} />
                </div>
                <h3>No Closed Orders to Display</h3>
                <p>All your Closed Orders will be saved here.</p>
                <Link to='/Market' >START SHOPPING</Link>
            </div>
            )}

            </div>
        </div>
        </>
    );
}

export default Customerorder;
