
import { useMutation, useQueryClient } from 'react-query';
import { useContext } from 'react';
import { AuthContext } from '../../../Context/AuthContext';

const cancelTruckOrder = async (id, token) => {
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/freight/trucks/${id}/setRequestingBookingToFalse`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Failed to cancel truck order');
  }

  return response.json();
};

const useCancelTruckOrder = () => {
  const queryClient = useQueryClient();
  const { token } = useContext(AuthContext);

  return useMutation((id) => cancelTruckOrder(id, token), {
    onSuccess: () => {
      queryClient.invalidateQueries(['neworderfreight', 'requestedHires']);
    },
  });
};

export default useCancelTruckOrder;
