import React from 'react';
import { Link } from 'react-router-dom';
import { BsChatDots } from "react-icons/bs";


const HelpCenter = () => {

    return (
        <div className='help-quick-link'>
            <Link to='/returnpolicy'>FAQS</Link>
            {/* <Link>Payment Option</Link> */}
            <Link to='/trackyourorder'>Track an Order</Link>
            <Link to='/returnpolicy'>Return & Refund</Link>
            <a href="/Contact">Contact Us</a>
            <div className="card">
            <a href="/" target='blank'><BsChatDots  style={{fontSize:'22px'}}/>Chat with Support</a>
            </div>
        </div>
    );
}

export default HelpCenter;
