import { useQuery } from 'react-query';
import axios from 'axios';
import { AuthContext } from '../../Context/AuthContext';
import { useContext } from 'react';

const useFetchRiderAssignmentById = (assignmentId) => {
  const { token, user } = useContext(AuthContext);

  const fetchRiderAssignment = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/packages/rider-assignments/${user.userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error('Error fetching rider assignment data:', error.response);
      throw error;
    }
  };

  return useQuery(['riderAssignment', assignmentId], fetchRiderAssignment, {});
};

export default useFetchRiderAssignmentById;
