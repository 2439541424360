import React, { useState, Fragment, useContext } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import MarketNavbar from "../MarketNav/MarketNavbar";
import GetProductById from "../Merchant/lib/getProductById";
import Skeleton from "react-loading-skeleton";
import { CartContext } from "../../../Context/CartContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-loading-skeleton/dist/skeleton.css";
import "./AllProductDetail.css";

const AllProductDetail = () => {
  const { id } = useParams();
  const { data } = GetProductById(id);
  const [topImageIndex, setTopImageIndex] = useState(0);
  const [descriptionVisible, setDescriptionVisible] = useState(false);
  const { addItemToCart } = useContext(CartContext);
  const [shippingPickupOverlay, setShippingPickupOverlay] = useState(false);
  const [shippingDoorOverlay, setShippingDoorOverlay] = useState(false);

  const notify = () => toast.success("Product added successfully to cart!");

  const handleDescriptionToggle = () => {
    setDescriptionVisible(!descriptionVisible);
  };

  const handleAddToCart = (product) => {
    addItemToCart(product);
    notify();
  };

  const handlePickupOverlay = () => {
    setShippingPickupOverlay(true);
  };
  const handleDoorOverlay = () => {
    setShippingDoorOverlay(true);
  };

  if (!data || !data.product) {
    return (
      <Fragment>
        <MarketNavbar />
        <div className="all-product-detail">
          <div>
            <h2>
              <Skeleton />
            </h2>
            <p>
              {" "}
              <Skeleton height={50} />
            </p>
            <p>
              {" "}
              <Skeleton width={1000} />
            </p>
            <p>
              <Skeleton height={50} />
            </p>
            <p>
              {" "}
              <Skeleton width={1000} />
            </p>
            <p>
              {" "}
              <Skeleton height={50} />
            </p>
            <p>
              {" "}
              <Skeleton width={1000} />
            </p>
            <p>
              <Skeleton count={3} />
            </p>
            <p>
              <Skeleton height={50} />
            </p>
            <p>
              {" "}
              <Skeleton width={1400} />
            </p>
            <div>
              {[...Array(3)].map((_, index) => (
                <Skeleton
                  key={index}
                  width={100}
                  height={100}
                  style={{ marginRight: "10px" }}
                />
              ))}
            </div>
          </div>
          <div className="customer-review">
            <h3>
              <Skeleton width={50} />
            </h3>
            <p>
              <Skeleton width={1400} />
            </p>
          </div>
        </div>
      </Fragment>
    );
  }

  const moveImageToTop = (index) => {
    setTopImageIndex(index);
  };

  const topImage = data.product.images[topImageIndex];

  return (
    <Fragment>
      <MarketNavbar />
      <div className="all-product-detail">
        <div className="wrapper">
          <div className="image">
            <span>
              <div className="cascaded-images-container">
                {data.product.images.map(
                  (image, index) =>
                    index !== topImageIndex && (
                      <img
                        key={index}
                        src={image}
                        alt={`Thumbnail ${index + 1}`}
                        onClick={() => moveImageToTop(index)}
                      />
                    )
                )}
              </div>
              <div className="top-image">
                <img src={topImage} alt="Main Product" />
              </div>
            </span>
            <div className="friend">
              <p>Share with friends</p>
              <span>
                <a href="/" target="blank">
                  <i className="fa-brands fa-facebook-f"></i>
                </a>
                <a href="/">
                  <i className="fa-brands fa-instagram"></i>
                </a>
                <a href="/">
                  {" "}
                  <i className="fa-brands fa-twitter"></i>
                </a>
                <a href="/">
                  <i className="fa-brands fa-whatsapp"></i>
                </a>
              </span>
            </div>
          </div>

          <div className="all-product-info">
            <div className="wrapper">
              <h3>{data.product.name}</h3>
              {data.product.category && (
                <p>Category: {data.product.category}</p>
              )}
              {data.product.color && <p>Color: {data.product.color}</p>}
              {data.product.brand && <p>Brand: {data.product.brand}</p>}
              <h4>
                {data.product.price
                  ? data.product.price.toLocaleString("en-US", {
                      style: "currency",
                      currency: "NGN",
                    })
                  : ""}
              </h4>
              <button onClick={() => handleAddToCart(data.product)}>
                Add to Cart{" "}
              </button>
              <div className="description">
                <span onClick={handleDescriptionToggle}>
                  <h3>DESCRIPTION</h3>
                  <i
                    className={`fa-solid fa-chevron-${
                      descriptionVisible ? "up" : "down"
                    }`}
                  ></i>
                </span>
                {descriptionVisible && <p>{data.product.description}</p>}
              </div>
            </div>
            <div className="shipping">
              <span>
                <h3>SHIPPING</h3>
              </span>
              <>
                <div className="shipping-wrapper">
                  <i className="fa-solid fa-hands-holding-circle"></i>
                  <div className="info">
                    <h3>Pickup Station</h3>
                    <p>Delivery Fees ₦ 250</p>
                    <p>
                      Arriving at pickup station between 29 March & 01 April
                      when you order within next 4hrs 27mins
                    </p>
                    <button onClick={handlePickupOverlay}>Details</button>
                  </div>
                </div>
                {shippingPickupOverlay && (
                  <div className="shipping-detail-overlay">
                    <div className="shipping-detail-overlay-card">
                      <span>
                        <h3>Delivery Details</h3>
                        <button onClick={() => setShippingPickupOverlay(false)}>
                          Ok
                        </button>
                      </span>
                      <h3>PICKUP STATION</h3>
                      <p>
                        Delivery time starts from the day you place your order
                        to the day your order arrives at the pickup station. You
                        will be notified of your order's arrival, and you have
                        to retrieve it within 5 days. If the order is not picked
                        up, it will be automatically cancelled.
                      </p>
                      <h4>DELIVERY FEE DETAILS</h4>
                      <div className="wrapper">
                        <p>Total Delivery Amount</p>
                        <h3>₦250</h3>
                      </div>
                    </div>
                  </div>
                )}
                {shippingDoorOverlay && (
                  <div className="shipping-detail-overlay">
                    <div className="shipping-detail-overlay-card">
                      <span>
                        <h3>Delivery Details</h3>
                        <button onClick={() => setShippingDoorOverlay(false)}>
                          Ok
                        </button>
                      </span>
                      <h3>DOOR DELIVERY</h3>
                      <p>
                        Delivery time starts from the day you place your order
                        to the day one of our associates makes a first attempt
                        to deliver to you. Delivery will be attempted 2 times
                        over 5 days (7.00 am to 5.30 pm) after which the item
                        will be cancelled, if you are unreachable or unable to
                        receive the order.
                      </p>
                      <h4>DELIVERY FEE DETAILS</h4>
                      <div className="wrapper">
                        <p>Total Delivery Amount</p>
                        <h3>₦620</h3>
                      </div>
                    </div>
                  </div>
                )}
                <div className="shipping-wrapper">
                  <i className="fa-solid fa-truck-moving"></i>
                  <div className="info">
                    <h3>Door Delivery</h3>
                    <p>Delivery Fees ₦ 620</p>
                    <p>
                      Ready for delivery between 29 March & 01 April when you
                      order within next 4hrs 27mins
                    </p>
                    <button onClick={handleDoorOverlay}>Details</button>
                  </div>
                </div>
                <div className="shipping-wrapper">
                  <i className="fa-solid fa-rotate-left"></i>
                  <div className="info">
                    <h3>Return Policy</h3>
                    <p>Free return within 14 days for ALL eligible items</p>
                    <Link to='/returnpolicy'>Details</Link>
                  </div>
                </div>
              </>
            </div>
          </div>
        </div>
        <div className="customer-review">
          <h3>Customer's Reviews</h3>
        </div>
      </div>
      <ToastContainer position="top-right" className="toast" />
    </Fragment>
  );
};

export default AllProductDetail;
