import { useQuery } from 'react-query';
import { useContext } from 'react';
import { AuthContext } from "../../../../Context/AuthContext";

const useVehicleOrders = () => {
  const { token } = useContext(AuthContext);

  const fetchVehicleOrders = async () => {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/charterer/v-order`, {
      method: 'GET',
      headers: {
        'Content-type': 'Application/json',
        'Authorization': `Bearer ${token}`
      }
    });

    if (!response.ok) {
      throw new Error('Failed to fetch vehicle orders');
    }
    return response.json();
  };

  return useQuery('vehicleOrders', fetchVehicleOrders);
};

export default useVehicleOrders;

