import { useInfiniteQuery } from 'react-query';
import { useContext } from 'react';
import { AuthContext } from "../../Context/AuthContext";

const fetchActivePackages = async ({ pageParam = 1 }, token, user) => {
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/packages/active-packages/${user.userId}?page=${pageParam}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error('Error fetching active packages');
  }

  return response.json();
};

const useGetActivePackages = () => {
  const { token, user } = useContext(AuthContext);
  return useInfiniteQuery(
    ['activePackages', user.userId], 
    ({ pageParam }) => fetchActivePackages({ pageParam }, token, user),
    {
      getNextPageParam: (lastPage) => {
        const nextPage = lastPage.currentPage + 1;
        return nextPage <= Math.ceil(lastPage.totalPackages / 10) ? nextPage : undefined;
      },
    }
  );
};

export default useGetActivePackages;




