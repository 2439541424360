import { useInfiniteQuery } from 'react-query';
import { AuthContext } from '../../Context/AuthContext';
import { useContext } from 'react';

const fetchCustomers = async ({ pageParam = 1, token }) => {
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/users/customers?page=${pageParam}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error('Failed to fetch customers');
  }

  return response.json();
};

const useCustomers = () => {
  const { token } = useContext(AuthContext);

  return useInfiniteQuery(
    'customers',
    ({ pageParam = 1 }) => fetchCustomers({ pageParam, token }),
    {
      getNextPageParam: (lastPage) => {
        const nextPage = lastPage.currentPage + 1;
        return nextPage <= Math.ceil(lastPage.totalCustomers / 10) ? nextPage : undefined;
      },
    }
  );
};

export default useCustomers;
