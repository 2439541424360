import React, { useState, useRef, useCallback } from "react";
import { ReactComponent as SearchIcon } from "../../asset/iconamoon_search-light.svg";
import useRidersByBranch from "../libs/useRidersByBranch";
import { ReactComponent as EllipIcon } from "../../asset/Ellipse 3685.svg";
import { ReactComponent as BlockedIcon } from "../../asset/Blocked 18.svg";
import Spinner from "../../utils/spinner";
import "./Logisticsdriverrider.css";

function Logisticsdriverrider() {
  const [searchQuery, setSearchQuery] = useState("");

  const {
    data: ridersData,
    isLoading: riderLoading,
    isError: isRiderError,
    fetchNextPage: fetchNextRiderPage,
    hasNextPage: hasNextRiderPage,
    isFetchingNextPage: isFetchingNextRiderPage,
  } = useRidersByBranch();

  const riderObserverRef = useRef();
  const lastRiderElementRef = useCallback(
    (node) => {
      if (isFetchingNextRiderPage) return;

      if (riderObserverRef.current) riderObserverRef.current.disconnect();

      riderObserverRef.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting && hasNextRiderPage) {
            fetchNextRiderPage();
          }
        },
        { threshold: 0.5 }
      );

      if (node) riderObserverRef.current.observe(node);
    },
    [isFetchingNextRiderPage, fetchNextRiderPage, hasNextRiderPage]
  );
 

  const formatRiderID = (id) => {
    return id.slice(0, 8).toUpperCase();
  };

  const riders = ridersData?.pages.flatMap((page) => page.riders) || [];
  const filteredRiders = riders.filter((rider) => {
    const fullName = `${rider.first_name} ${rider.last_name}`.toLowerCase();
    return fullName.includes(searchQuery.toLowerCase());
  });

  return (
    <div className="logistic-rider-driver">
      <div className="header">
        <p> Riders</p>
        <span>
          <SearchIcon />
          <input
            type="text"
            placeholder="Search by Name"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </span>
      </div>

      <div className="rider">
        {riderLoading ? (
          <Spinner />
        ) : isRiderError ? (
          <p style={{fontFamily:'Montserrat'}}>Error fetching riders</p>
        ) : (
          <div className="rider-by-branch">
            {filteredRiders.length > 0 ? (
              filteredRiders.map((rider, index) => (
                <div className="card" key={rider._id}
                ref={
                  index === filteredRiders.length - 1 
                  ? lastRiderElementRef 
                  : null
                }>
                  <h3>{`${rider?.first_name} ${rider?.last_name}`}</h3>
                  <span>ID : {formatRiderID(rider._id)}</span>
                  <span>Phone: {rider?.phone_number}</span>
                  <p>Rider</p>
                  <p>{rider?.blocked ? <BlockedIcon /> : <EllipIcon />}</p>
                </div>
              ))
            ) : (
              <p style={{fontFamily:'Montserrat'}}>No riders found</p>
            )}
          </div>
        )}
      </div>
      {isFetchingNextRiderPage && (
            <div className="loading-more">
              <Spinner />
            </div>
          )}
    </div>
  );
}

export default Logisticsdriverrider;
