import React from 'react';
import MarketNavbar from '../MarketNav/MarketNavbar';
import "./UserProfile.css"

const Recentview = () => {
    return (
        <>
        <MarketNavbar/>
        <div className='recent-view-product'>
           <h3>Recently Viewed Products</h3>
        </div>
        </>
    );
}

export default Recentview;
