import { useEffect, useState } from "react"

const ScrollToTop = () => {
    const [isvisible, setIsVisible] = useState(false);

    const  toggleVisibility = () => {
        if (window.pageYOffset  > 300) {
            setIsVisible(true)
        } else {
            setIsVisible(false)
        };
    };

    const scrollToTop = ()  => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    };

    useEffect(() => {
        window.addEventListener( 'scroll', toggleVisibility)
        return () => {
            window.removeEventListener('scroll', toggleVisibility)
        }
    }, []);

    return(
        <>
        {
            isvisible && (
                <div className="scroll-to-top" onClick={scrollToTop}>
              <i className="fa-solid fa-circle-arrow-up"></i>
                </div>
            )}
        </>
    )

};
export default ScrollToTop;