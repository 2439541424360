import { useInfiniteQuery } from 'react-query';
import { AuthContext } from '../../Context/AuthContext';
import { useContext } from 'react';

const fetchStoreProduct = async ({ pageParam = 1, token, merchantId }) => {
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/product/products/user/${merchantId}?page=${pageParam}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error('Failed to fetch Store products');
  }

  return response.json();
};

const useStoreProducts = (merchantId) => {
  const { token } = useContext(AuthContext);

  return useInfiniteQuery(
    'storeProducts',
    ({ pageParam = 1 }) => fetchStoreProduct({ pageParam, token, merchantId }),
    {
      getNextPageParam: (lastPage) => {
        const nextPage = lastPage.currentPage + 1;
        return nextPage <= Math.ceil(lastPage.totalProducts / 10) ? nextPage : undefined;
      },
      enabled: !!merchantId, 
      retry: false,
    }
  );
};

export default useStoreProducts;