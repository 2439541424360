import { Link } from "react-router-dom";
import UserDataFetcher from "../../../libs/getUserbyUserId";
import MarketNavbar from "../MarketNav/MarketNavbar";
import Usernavbar from "./usernavbar";
import "./UserProfile.css";

const UserProfile = () => {
    const { isLoading, isError, adminData } = UserDataFetcher();

    if (isLoading) {
        return <p>Loading...</p>;
      }
    
      if (isError) {
        return <p>Error fetching data</p>;
      }

  return (
    <>
<MarketNavbar/>
    <div className="user-profile-container">
      <Usernavbar/>
      <div className="user-profile-con">
      <h3>Profile Overview</h3>
      <div className="wrapper">
      <div className="card-1">
        <h3>PROFILE DETAILS</h3>
        <span>
        <h3>{adminData?.user?.first_name}</h3>
        <h3>{adminData?.user?.last_name}</h3>
        </span>
        <p>{adminData?.user?.email}</p>
        <p>{adminData?.user?.phone_number}</p>
      </div>
      <div className="card-2">
        <h3>ADDRESS BOOK</h3>
        <span>
          <h4>Your default shipping address:</h4>
          <p>No default shipping address available.</p>
          <Link>ADD DEFAULT ADDRESS</Link>
        </span>
        <p>{adminData?.user?.address}</p>
      </div>
      </div>
      </div>
    </div>
    </>
  );
};

export default UserProfile;
