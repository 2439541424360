import React from "react";
import { Link } from "react-router-dom";
import MarketNavbar from "../MarketNav/MarketNavbar";
import Usernavbar from "./usernavbar";
import { SlLike } from "react-icons/sl";

const Customersaveditem = () => {
  return (
    <>
      <MarketNavbar />
      <div className="customer-save-item">
        <Usernavbar/>
        <div className="customer-save-item-con">
        <h3>Saved Items</h3>
        <div className="wrapper">
          <div className="order-circle">
            <SlLike style={{ fontSize: "40px", color: "#d0300e" }} />
          </div>
          <h3>You haven’t saved an item yet!</h3>
          <p>
            Found something you like? Tap on the heart shaped icon next to the
            item to add it to your wishlist! All your saved items will appear
            here.
          </p>
          <Link to="/Market">START SHOPPING</Link>
        </div>
        </div>
      </div>
    </>
  );
};

export default Customersaveditem;
