import { Fragment } from "react";
import UserDataFetcher from "../../../../libs/getUserbyUserId";
import "./MerchantOverview.css"

const MerchantOverview = () => {
    const {adminData} = UserDataFetcher()
    return (
        <Fragment>
        <div className="merchant-overview-container">
            <h3>{adminData?.user?.first_name} {adminData?.user?.last_name} </h3>
            <p>Welcome to your Store, Sell your products seamlessly</p>
        </div>
        <div className="merchant-overview-wrapper">
            <div className="merchant-overview-box">
                <p>Sales Made</p>
                <div className="merchant-overview-line"></div>
                <h3>N400,000</h3>
            </div>
            <div className="merchant-overview-box">
                <p>Total Orders</p>
                <div className="merchant-overview-line"></div>
                <h3>N400,000</h3>
            </div>
            <div className="merchant-overview-box">
                <p>Pending Orders</p>
                <div className="merchant-overview-line"></div>
                <h3>N400,000</h3>
            </div>
        </div>
        <div className="merchant-overview-graph">
            <h3>Sales overview</h3>
            <p>4th February 2024</p>
        </div>

        <div className="merchant-recent-order">
            <h3>Recent Orders</h3>
            <div className="merchant-recent-container">
                <table>
                    <tr>
                        <th></th>
                        <th>Product Id</th>
                        <th>Product Name</th>
                        <th>Category</th>
                        <th>Price</th>
                        <th>Color</th>
                        <th>Order Date</th>
                        <th>Stock</th>
                    </tr>
                    <tr>
                        <img src="https://i.ibb.co/kH7BNY7/IMG-2538-12x.png" alt="" />
                        <td>Product Id</td>
                        <td>Maritime Rose</td>
                        <td>Fashion</td>
                        <td>N50,000</td>
                        <td>White</td>
                        <td>04-02-2024</td>
                    </tr>
                    <tr>
                        <img src="https://i.ibb.co/kH7BNY7/IMG-2538-12x.png" alt="" />
                        <td>Product Id</td>
                        <td>Maritime Rose</td>
                        <td>Fashion</td>
                        <td>N50,000</td>
                        <td>White</td>
                        <td>04-02-2024</td>
                    </tr>
                    <tr>
                        <img src="https://i.ibb.co/kH7BNY7/IMG-2538-12x.png" alt="" />
                        <td>Product Id</td>
                        <td>Maritime Rose</td>
                        <td>Fashion</td>
                        <td>N50,000</td>
                        <td>White</td>
                        <td>04-02-2024</td>
                    </tr>
                    <tr>
                        <img src="https://i.ibb.co/kH7BNY7/IMG-2538-12x.png" alt="" />
                        <td>Product Id</td>
                        <td>Maritime Rose</td>
                        <td>Fashion</td>
                        <td>N50,000</td>
                        <td>White</td>
                        <td>04-02-2024</td>
                    </tr>
                </table>
            </div>
        </div>
        </Fragment>
    );
}

export default MerchantOverview;
