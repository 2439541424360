import { useQuery } from 'react-query';
import axios from 'axios';
import { useContext } from 'react';
import { AuthContext } from '../../Context/AuthContext';

const useRevokeMerchantInfo = (merchantId) => {
const { token } = useContext(AuthContext);

  const fetchMerchantRevoke = async () => {
    try {
      const response = await axios.put(`${process.env.REACT_APP_SERVER_URL}/users/merchant/revoke/${merchantId}`, {}, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      throw new Error('Error verifying merchant. Please try again.');
    }
  };

  return useQuery(['revokeMerchant', merchantId],  fetchMerchantRevoke);
};

export default useRevokeMerchantInfo;
