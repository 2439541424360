

import React, { useState } from "react";
import Slider from "react-slick";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import useGetBanners from "../libs/useGetbannersFormmk";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import "./Banner.css";

function SimpleSlider() {
  const { data, isLoading, error } = useGetBanners();
  const [hoveredIndex, setHoveredIndex] = useState(-1);

  const settings = {
    dots: true,
    arrows: true,
    nextArrow: <FontAwesomeIcon icon={faChevronRight} className="custom-arrow right-arrow" />,
    prevArrow: <FontAwesomeIcon icon={faChevronLeft} className="custom-arrow left-arrow" />,
    autoplay: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(-1);
  };

  return (
    <div className="banner-slider-container">
      {isLoading ? (
        <div className="banner-slider">
          <Skeleton height={400} count={1} />
        </div >
      ) : error ? (
        <div>Error: {error.message}</div>
      ) : (
        <Slider {...settings} className="banner-slide">
          {data.banners.map((banner, index) => (
            <div
              key={index}
              className="banner-slider"
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={handleMouseLeave}
            >
              <img src={banner.imageUrl} alt={banner.altText} />
            </div>
          ))}
        </Slider>
      )}
    </div>
  );
}

export default SimpleSlider;



