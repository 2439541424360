import React, {useContext} from 'react';
import { Link } from 'react-router-dom';
import UserDataFetcher from '../../../../libs/getUserbyUserId';
import { AuthContext } from '../../../../Context/AuthContext';
import { useNavigate } from 'react-router-dom';
import "./Setting.css"


const Setting = () => {
    const { adminData } = UserDataFetcher();
    const { Logout} = useContext(AuthContext)
    const navigate = useNavigate()

    const handleLogout = () => {
        navigate('/')
        Logout()
    }

    return (
        <div className='charter-setting'>
       <div className="wrapper">
       <h3>Setting</h3>
       <div className="charter-account">
              <span>
                <p>First Name</p>
                <p>{adminData?.user?.first_name}</p>
              </span>
              <span>
                <p>Last Name</p>
                <p>{adminData?.user?.last_name}</p>
              </span>
              <span>
                <p>Email</p>
                <p>{adminData?.user?.email}</p>
              </span>
              <span>
                <p>Phone Number</p>
                <p>
                  {adminData?.user?.phone_number}
                </p>
              </span>
              <div className="charter-password">
              <Link to='/charterpassword'>Change Password</Link>
              </div>
              <div className="merchant-logout-button">
                <button onClick={handleLogout}>Logout</button>
              </div>
              
            </div>
       </div>
        </div>
    );
}

export default Setting;
