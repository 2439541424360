import { useQuery } from 'react-query';
import { useContext } from 'react';
import { AuthContext } from '../../../Context/AuthContext';

const useFreightTruck = (page, itemsPerPage) => {
  const { token, user } = useContext(AuthContext);

  const fetchTruck = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/freight/get-trucks-admin?state=${user?.state}&page=${page}&limit=${itemsPerPage}`, {
        method: "GET",
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

    if (!response.ok) {
      throw new Error('Failed to fetch Trucks');
    }

    return response.json();
  };

  const { data, isLoading, isError } = useQuery(['freightTruck', page], fetchTruck);

  return { trucks: data?.trucks || [], totalTrucks: data?.totalTrucks || 0, isLoading, isError };
};

export default useFreightTruck;


