
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import useAdminVehicle from "../libs/fetchAdminVehicles";
import { AuthContext } from "../../../../Context/AuthContext";
import { FaXmark } from "react-icons/fa6";
import { CiUser } from "react-icons/ci";
import { TbSteeringWheel } from "react-icons/tb";
import { ReactComponent as SeatIcon } from "../../../../Assets/safety-seat-car-svgrepo-com 13x.svg";
import { BsFuelPump } from "react-icons/bs";
import { GiCarDoor } from "react-icons/gi";
import Spinner from "../../../../Spinner/Spinner";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./Charter.css";

const defaultForm = {
  fullName: "",
  email: "",
  phoneNumber: "",
  address: "",
  nextOfKinFullName: "",
  nextOfKinAddress: "",
  nextOfKinPhoneNumber: "",
  paymentMethod: "",
  customerIdNumber: "",
  customerIdType: "",
  endDate: "",
  startDate: "",
};

const Order = () => {
  const { token } = useContext(AuthContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const { data, isLoading } = useAdminVehicle(currentPage);
  const [detailOverlay, setDetailOverlay] = useState(false);
  const [orderOverlay, setOrderOverlay] = useState(false);
  const [form, setForm] = useState(defaultForm);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const {
    fullName,
    email,
    phoneNumber,
    address,
    nextOfKinFullName,
    nextOfKinAddress,
    nextOfKinPhoneNumber,
    paymentMethod,
    customerIdNumber,
    customerIdType,
    startDate,
    endDate,
  } = form;

  const itemsPerPage = 6;

  const vehicles = data?.vehicles.reverse() || [];
  const totalItems = vehicles.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage + 1;
  const endIndex = Math.min(currentPage * itemsPerPage, totalItems);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleViewDetails = (vehicle) => {
    setSelectedVehicle(vehicle);
    setDetailOverlay(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const payload = {
      vehicleId: selectedVehicle._id,
      startDate: form.startDate,
      endDate: form.endDate,
      pickupLocation: selectedVehicle.state,
      totalAmount: selectedVehicle.pricingPerDay,
      passengerDetails: {
        fullName: form.fullName,
        email: form.email,
        phoneNumber: form.phoneNumber,
        address: form.address,
        nextOfKin: {
          fullName: form.nextOfKinFullName,
          phoneNumber: form.nextOfKinPhoneNumber,
          address: form.nextOfKinAddress,
        },
      },
      paymentMethod: form.paymentMethod,
      customerIdType: form.customerIdType,
      customerIdNumber: form.customerIdNumber,
    };

    try {
      const res = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/charterer/hire`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(payload),
        }
      );

      const resData = await res.json();
      if (!res.ok) {
        throw new Error(resData.message);
      }
      setForm(defaultForm);
      setOrderOverlay(false);
      setLoading(false);
      navigate('/bookrequest');
    } catch (error) {
      console.error("Error:", error.message);
      toast.error('Start date can not be more than 24 hours from now.');
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  return (
    <>
      {orderOverlay && (
        <div className="admin-order-overlay">
          <span>
            <button onClick={() => setOrderOverlay(false)}>
              <FaXmark style={{ fontSize: "30px" }} />
            </button>
          </span>
          <div className="wrapper">
            <form onSubmit={handleSubmit}>
              <div className="combination">
                <span>
                  <label>Full Name</label>
                  <input
                    type="text"
                    name="fullName"
                    value={fullName}
                    placeholder="Enter Full Name"
                    onChange={handleChange}
                    required
                  />
                </span>

                <span>
                  <label>Customer ID Type</label>
                  <select
                    name="customerIdType"
                    value={customerIdType}
                    onChange={handleChange}
                    required
                  >
                    <option value="Select Id type">Select Id type</option>
                    <option value="NIN">National ID</option>
                    <option value="Drivers licences">Drivers licences</option>
                    <option value="International Passport">
                      International Passport
                    </option>
                  </select>
                </span>
                <span>
                  <label>ID Number</label>
                  <input
                    type="text"
                    name="customerIdNumber"
                    value={customerIdNumber}
                    placeholder="Enter ID Number"
                    onChange={handleChange}
                    required
                  />
                </span>
              </div>

              <div className="combination">
                <span>
                  <label>Enter Email Address</label>
                  <input
                    type="text"
                    name="email"
                    value={email}
                    placeholder="Enter Email"
                    onChange={handleChange}
                    required
                  />
                </span>
                <span>
                  <label>Enter Phone Number</label>
                  <input
                    type="text"
                    name="phoneNumber"
                    value={phoneNumber}
                    placeholder="Enter Phone Number"
                    onChange={handleChange}
                    required
                  />
                </span>
                <span>
                  <label>Full Residential Address</label>
                  <input
                    type="text"
                    name="address"
                    value={address}
                    placeholder="Enter Full Residential Address"
                    onChange={handleChange}
                    required
                  />
                </span>
              </div>
              <div className="combination">
                <span>
                  <label>Next of Kin Name</label>
                  <input
                    type="text"
                    name="nextOfKinFullName"
                    value={nextOfKinFullName}
                    placeholder="Enter Full Name"
                    onChange={handleChange}
                    required
                  />
                </span>
                <span>
                  <label>Next of Kin Address</label>
                  <input
                    type="text"
                    name="nextOfKinAddress"
                    value={nextOfKinAddress}
                    placeholder="Enter Full Address"
                    onChange={handleChange}
                    required
                  />
                </span>
                <span>
                  <label>Next of Kin Phone Number</label>
                  <input
                    type="text"
                    name="nextOfKinPhoneNumber"
                    value={nextOfKinPhoneNumber}
                    placeholder="Enter Phone Number"
                    onChange={handleChange}
                    required
                  />
                </span>
              </div>

              <div className="combination">
                <span>
                  <label>Payment Method</label>
                  <select
                    name="paymentMethod"
                    value={paymentMethod}
                    onChange={handleChange}
                    required
                  >
                    <option value="Select ">Select </option>
                    <option value="POS">POS</option>
                    <option value="Cash">Cash</option>
                  </select>
                </span>

                <span>
                  <label>Start Date</label>
                  <input
                    type="date"
                    name="startDate"
                    value={startDate}
                    onChange={handleChange}
                    required
                  />
                </span>
                <span>
                  <label>End Date</label>
                  <input
                    type="date"
                    name="endDate"
                    value={endDate}
                    onChange={handleChange}
                    required
                  />
                </span>
              </div>
              <button type="submit" disabled={loading}>
                {loading ? <Spinner /> : "Book"}
              </button>
            </form>
          </div>
          <ToastContainer position="top-right" className="toast" />
        </div>
      )}
      {detailOverlay && selectedVehicle && (
        <div className="admin-vehicle-overlay">
          <div className="wrapper">
            <span>
              <button onClick={() => setDetailOverlay(false)}>
                <FaXmark style={{ fontSize: "20px" }} />
              </button>
            </span>
            <div className="admin-vehicle-detail">
              <img
                src={selectedVehicle.images[0]}
                alt={selectedVehicle.vehicleModel}
              />
              <div className="admin-vehicle-span">
                <div className="card">
                  <h3>{selectedVehicle.vehicleType}</h3>
                  <p>{selectedVehicle.vehicleModel}</p>
                  <h4>
                    {selectedVehicle.pricingPerDay
                      ? selectedVehicle.pricingPerDay.toLocaleString("en-US", {
                          style: "currency",
                          currency: "NGN",
                        })
                      : ""}
                  </h4>
                  <span>
                    <SeatIcon />
                    <p>Max no of Seat: {selectedVehicle.numberOfSeats}</p>
                  </span>
                  <span>
                    <BsFuelPump />
                    <p>Fuel Tank Cap: {selectedVehicle.fuelTankCapacity}</p>
                  </span>
                </div>
                <div className="card">
                  <span>
                    <CiUser />
                    <p>Max no of people: {selectedVehicle.maxNumberOfPeople}</p>
                  </span>
                  <span>
                    <TbSteeringWheel />
                    <p>Power Type: {selectedVehicle.powerType}</p>
                  </span>
                  <span>
                    <GiCarDoor />
                    <p>{selectedVehicle.doors} Doors</p>
                  </span>
                  <p>State: {selectedVehicle.state}</p>
                </div>
              </div>
              <h3>DESCRIPTION</h3>
              <p>{selectedVehicle.description}</p>
              <button onClick={() => setOrderOverlay(true)}>Order</button>
            </div>
          </div>
        </div>
      )}
      <div className="admin-order">
        <div className="wrapper">
          <h3>Charter_Admin Order</h3>
        </div>
        <div className="admin-mother">
          <table className="vehicle-table">
            <thead>
              <tr>
                <th>Image</th>
                <th>Type</th>
                <th>Model</th>
                <th>Seats</th>
                <th>Max People</th>
                <th>Power Type</th>
                <th>Doors</th>
                <th>Price/Day</th>
                <th>State</th>
                <th>Details</th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                Array.from({ length: 10 }).map((_, index) => (
                  <tr key={index}>
                    <td><Skeleton /></td>
                    <td><Skeleton /></td>
                    <td><Skeleton /></td>
                    <td><Skeleton /></td>
                    <td><Skeleton /></td>
                    <td><Skeleton /></td>
                    <td><Skeleton /></td>
                    <td><Skeleton /></td>
                    <td><Skeleton /></td>
                    <td><Skeleton /></td>
                  </tr>
                ))
              ) : (
                vehicles.slice(startIndex - 1, endIndex).map((vehicle) => (
                  <tr key={vehicle._id}>
                    <td>
                      <img
                        src={vehicle.images[0]}
                        alt={vehicle.vehicleModel}
                        className="vehicle-image"
                      />
                    </td>
                    <td>{vehicle.vehicleType}</td>
                    <td>{vehicle.vehicleModel}</td>
                    <td>{vehicle.numberOfSeats}</td>
                    <td>{vehicle.maxNumberOfPeople}</td>
                    <td>{vehicle.powerType}</td>
                    <td>{vehicle.doors}</td>
                    <td>
                      {vehicle.pricingPerDay
                        ? vehicle.pricingPerDay.toLocaleString("en-US", {
                            style: "currency",
                            currency: "NGN",
                          })
                        : ""}
                    </td>
                    <td>{vehicle.state}</td>
                    <td>
                      <button onClick={() => handleViewDetails(vehicle)}>
                        View
                      </button>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
        <div className="pagination">
          <span>{`Showing ${startIndex} - ${endIndex} of ${totalItems}`}</span>
          <div className="btn">
            <button onClick={handlePrevPage} disabled={currentPage === 1}>
              Prev
            </button>
            <button
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Order;


