import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useRegisterChartererManager from '../libs/useRegisterChartererManager';
import { IoMdArrowBack } from "react-icons/io";
import "./CreateChartererOfficer.css";

const Createchartererofficer = () => {
  const navigate = useNavigate();
  const [success, setSuccess] = useState(false);

  const registerChartererManagerMutation = useRegisterChartererManager();

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    country_code: "+234",
    phone_number: "",
    email: "",
    branch_full_address: "",
    address: "",
    state: ""
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await registerChartererManagerMutation.mutateAsync(formData);
      setSuccess('Charterer manager registered successfully, code sent to an Email');
      setTimeout(() => {
        setSuccess(false);
        navigate("/superadmincharterer");
      }, 3000);
    } catch (error) {
      console.error("Registration failed:", error.message);
    }
  };

  return (
    <div className='register-charterer-container'>
      <div className="header">
        <IoMdArrowBack
          onClick={() => navigate("/superadmincharterer")}
          className="icon"
        />
        <p>Register New Charterer Admin</p>
      </div>

      <div className="manager-register-form">
        <form onSubmit={handleSubmit}>
          <label>
            First Name
            <input
              type="text"
              name="first_name"
              value={formData.first_name}
              onChange={handleInputChange}
              required
            />
          </label>

          <label>
            Last Name
            <input
              type="text"
              name="last_name"
              value={formData.last_name}
              onChange={handleInputChange}
              required
            />
          </label>

          <label>
            Phone Number
            <div className="inner">
              <select name="country_code" value={formData.country_code} onChange={handleInputChange} required>
                <option value="+234">+234</option>
                <option value="+1">+1</option>
                <option value="+95">+95</option>
              </select>
              <input
                type="text"
                name="phone_number"
                value={formData.phone_number}
                onChange={handleInputChange}
                required
              />
            </div>
          </label>

          <label>
            Email
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              required
            />
          </label>

          <label>
            Branch Full Address
            <input
              name="branch_full_address"
              value={formData.branch_full_address}
              onChange={handleInputChange}
              required
            ></input>
          </label>

          <label>
            Address
            <input
              name="address"
              value={formData.address}
              onChange={handleInputChange}
              required
            />
          </label>

          <label>
            State
            <input
              name="state"
              value={formData.state}
              onChange={handleInputChange}
              required
            />
          </label>

          <button type="submit" disabled={registerChartererManagerMutation.isLoading}>
            {registerChartererManagerMutation.isLoading ? "Registering..." : "Register"}
          </button>
          {registerChartererManagerMutation.isError && (
            <p className="error-message">
              Error: {registerChartererManagerMutation.error.message}
            </p>
          )}
          {success && <p className="success-message">{success}</p>}
        </form>
      </div>
    </div>
  );
};

export default Createchartererofficer;
