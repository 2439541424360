import React, { Fragment, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Spinner from "../../utils/spinner";
import useUserById from "../libs/usMerchantdetail";
import useBusinessMerchant from "../libs/useBusinessMerchant";
import useVerifyMerchant from "../libs/useVerifyMerchant";
import useRevokeMerchantInfo from "../libs/useRevokeMerchantInfo";
import { GoArrowLeft } from "react-icons/go";
// import useRejectMerchant from "../libs/useRejectMerchantInfo";
import {  useQueryClient } from "react-query";
import "./merchnatmdetails.css";

function Marketmdetails() {
    const [verifyOverlay, setVerifyOverlay] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  const [revokeOverlay, setRevokeOverlay] = useState(false);
  const [isRevoking, setIsRevoking] = useState(false);
  const queryClient = useQueryClient();

  
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    data: user,
    error: userError,
    isLoading: userLoading,
  } = useUserById(id);

  const {
    data: businessData,
    error: businessError,
    isLoading: businessLoading,
  } = useBusinessMerchant(id);

  const {  error: verifyError,  refetch: fetchVerification } = useVerifyMerchant(id);

  const handleVerify = async () => {
    setIsVerifying(true);
    try {
      await fetchVerification();
      queryClient.invalidateQueries("user");
      setVerifyOverlay(false);
    } catch (error) {
      console.error("Error verifying merchant:", error);
    } finally {
      setIsVerifying(false);
    }
  };
  

  const { refetch:revokeMerchantInfo, error: revokeError } = useRevokeMerchantInfo(id); 

  const handleRevoke = async () => { 
    setIsRevoking(true);
    try {
      await revokeMerchantInfo(); 
      queryClient.invalidateQueries("user");
      setRevokeOverlay(false);
    } catch (error) {
      console.error("Error revoking merchant info:", error);
    } finally {
      setIsRevoking(false);
    }
  };

  



  return (
    <Fragment>
        {verifyOverlay && (
        <div className="verifyOverlay">
          <div className="card">
            <h1>Approve</h1>
            <p>Are you sure you want to approve this merchant?</p>
            <div className="btn">
              <button
                onClick={() => {
                  setVerifyOverlay(false);
                }}
              >
                No
              </button>
              <button onClick={handleVerify} disabled={isVerifying}>
                {isVerifying ? "Verifying..." : "Yes"}
              </button>
            </div>
            {verifyError && (
              <p>{verifyError.message}</p>
            )}
          </div>
        </div>
      )}

      {revokeOverlay && (
        <div className="revokeOverlay">
          <div className="card">
            <h1>Revoke</h1>
            <p>Are you sure you want to revoke this merchant's info?</p>
            <div className="btn">
              <button onClick={() => {
                setRevokeOverlay(false);
              }}>No</button>
              <button onClick={handleRevoke} disabled={isRevoking}>
                {isRevoking ? "Revoking..." : "Yes"}
              </button>
            </div>
            {revokeError && <p>{revokeError.message}</p>}
          </div>
        </div>
      )}

      
      
      <div className="merchant-details-container">
        <div className="header">
          <span>
            <GoArrowLeft
              className="icon"
              onClick={() => navigate("/Marketplacestore")}
            />
            <h1>Merchant Details</h1>
          </span>
          <div className="btn">
          <button
              disabled={user?.isInfoVerify}
              style={{
                backgroundColor: user?.isInfoVerify ? "#ccc" : "",
                color: "#fff",
                cursor: user?.isInfoVerify ? "not-allowed" : "",
              }}
              onClick={() => setVerifyOverlay(true)}
            >
              Approve
            </button>

            <button onClick={() => setRevokeOverlay(true)}>Revoke</button>
            <button>Print</button>
          </div>
        </div>
        {userLoading && <Spinner />}
        {userError && <p>{userError.message}</p>}
        {!userLoading && !userError && (
          <div className="card">
            <span>
              <p>ID</p>
              <h3> {user?._id.slice(0, 8).toUpperCase()}</h3>
            </span>
            <span>
              <p>First Name</p>
              <h3>{user?.first_name}</h3>
            </span>
            <span>
              <p>Last Name</p>
              <h3>{user?.last_name}</h3>
            </span>
            <span>
              <p>Email</p>
              <h3>{user?.email}</h3>
            </span>
            <span>
              <p>Phone Number</p>
              <h3>
                {user?.country_code} {user?.phone_number}
              </h3>
            </span>
            <span>
              <p>Blocked</p>
              <h3>{user?.blocked ? "Yes" : "No"}</h3>
            </span>
            <span>
              <p>Verify</p>
              <h3>{user?.isInfoVerify ? "Yes" : "No"}</h3>
            </span>
          </div>
        )}
        {businessData && (
          <div className="business-info">
            <div className="in">
              <h1>Business Information</h1>
            </div>
            {businessError && <p>{businessError.message}</p>}
            {!businessLoading && !businessError && (
              <div className="card">
                <span>
                  <p>Bank Account Details</p>
                  <h3>{businessData.bankAccountDetails}</h3>
                </span>
                <span>
                  <p>Business Name</p>
                  <h3>{businessData.businessName}</h3>
                </span>
                <span>
                  <p>Business Type</p>
                  <h3>{businessData.businessType}</h3>
                </span>
                <span>
                  <p>Business Address</p>
                  <h3>{businessData.businessAddress}</h3>
                </span>
                <span>
                  <p>Contact Person</p>
                  <h3>{businessData.contactPerson}</h3>
                </span>
                <span>
                  <p>Contact Email</p>
                  <h3>{businessData.contactEmail}</h3>
                </span>
                <span>
                  <p>Contact Phone Number</p>
                  <h3>{businessData.contactPhoneNumber}</h3>
                </span>
                <span>
                  <p>Business Description</p>
                  <h3>{businessData.businessDescription}</h3>
                </span>
                <span>
                  <p>Business License/Permit</p>
                  <h3>{businessData.businessLicenseOrPermit}</h3>
                </span>
                <span>
                  <p>Tax Identification Number</p>
                  <h3>{businessData.taxIdentificationNumber}</h3>
                </span>
                <span>
                  <p>Goods and Services Tax Registration</p>
                  <h3>{businessData.goodsAndServicesTaxRegistration}</h3>
                </span>
                <span>
                  <p>Customs Identification Number</p>
                  <h3>{businessData.customsIdentificationNumber}</h3>
                </span>
                <span>
                  <p>Product Information</p>
                  <h3>{businessData.productInformation}</h3>
                </span>
                <span>
                  <p>Returns and Refunds Policy</p>
                  <h3>{businessData.returnsAndRefundsPolicy}</h3>
                </span>
                <span>
                  <p>Customer Support Information</p>
                  <h3>{businessData.customerSupportInformation}</h3>
                </span>
                <span>
                  <p>Value Added Tax Registration</p>
                  <h3>{businessData.valueAddedTaxRegistration}</h3>
                </span>
              </div>
            )}
          </div>
        )}
      </div>
    </Fragment>
  );
}

export default Marketmdetails;
