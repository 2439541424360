import React from "react";
import { Link } from "react-router-dom";
import MarketNavbar from '../MarketNav/MarketNavbar';
import Usernavbar from './usernavbar';
import { MdOutlineRateReview } from "react-icons/md";
import "./UserProfile.css"

const Pendingreview = () => {
  return (
    <>
      <MarketNavbar />
      <div className="pending-review">
        <Usernavbar />
        <div className="pending-review-con">
          <h3>Pending Evaluation</h3>
          <div className="wrapper">
            <div className="order-circle">
              <MdOutlineRateReview style={{ fontSize: "45px", color: " #d0300e" }} />
            </div>
            <h3>You have no orders waiting for feedback</h3>
            <p>
              After getting your products delivered, you will be able to rate
              and review them. Your feedback will be published on the product
              page to help all Stex's users get the best shopping experience!
            </p>
          <Link to='/Market'>START SHOPPING</Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Pendingreview;
