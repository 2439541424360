import { useInfiniteQuery } from 'react-query';
import { useContext } from 'react';
import { AuthContext } from '../../../../Context/AuthContext';

const fetchProducts = async ({ pageParam = 1, token, userId }) => {
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/product/products/user/${userId}?page=${pageParam}`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}` 
    }
  });

  if (!response.ok) {
    throw new Error('Failed to fetch products');
  }

  return response.json();
};

const useMerchantProduct = () => {
  const { token, user } = useContext(AuthContext);

  return useInfiniteQuery(
    'merchantProducts',
    ({ pageParam }) => fetchProducts({ pageParam, token, userId: user.userId }),
    {
      getNextPageParam: (lastPage) => {
        const nextPage = lastPage.currentPage + 1;
        return nextPage <= Math.ceil(lastPage.totalProducts / 10) ? nextPage : undefined;
      },
    }
  );
};

export default useMerchantProduct;




