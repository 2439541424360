import React from 'react';
import MarketNavbar from '../MarketNav/MarketNavbar';
import Usernavbar from './usernavbar';
import { LuMailOpen } from "react-icons/lu";
import "./UserProfile.css"

const Customermsginbox = () => {
    return (
        <>
        <MarketNavbar/>
        <div className='customer-message-inbox'>
            <Usernavbar/>
            <div className='customer-message-inbox-con'>
            <h3>Inbox Messages</h3>
            <div className="wrapper">
                <div className="order-circle">
                <LuMailOpen style={{fontSize:'45px',color:' #d0300e'}} />
                </div>
                <h3>You don't have any messages</h3>
                <p>Here you will be able to see all the messages that we send you. Stay tuned</p>
            </div>
            </div>

        </div>
        </>
    );
}

export default Customermsginbox;
