
    
  export const formatDate = (dateString) => {
    const dateObject = new Date(dateString);

    const formattedDate = dateObject.toLocaleString("en-NG", {
      day: "numeric",
      month: "long",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour24: true,
      timeZone: "Africa/Lagos",
    });

    return formattedDate;
  };

