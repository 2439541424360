import { useContext } from 'react';
import { useQuery } from 'react-query';
import { AuthContext } from '../../../../Context/AuthContext';

const useProductCategories = () => {
  const { token } = useContext(AuthContext);

  const fetchProductCategories = async () => {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/productcategory/get/product/categories`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch product categories');
    }

    return response.json();
  };

  return useQuery('productCategories', fetchProductCategories);
};

export default useProductCategories;
