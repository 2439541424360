import useRegisterSupport from "../libs/useRegisterSupport";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { IoMdArrowBack } from "react-icons/io";
import "./Superadminregistersupporr.css"

function Supperadminregistersupport() {
    const registerSupportMutation = useRegisterSupport();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    country_code: "+234",
    phone_number: "",
    email: "",
    address: "",
  });

  const [success, setSuccess] = useState(false)

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await registerSupportMutation.mutateAsync(formData);
      setSuccess('Support registered successfully, code sent to an Email')
      setTimeout(() => {
        setSuccess(false)
        navigate("/Superadminsupport");
      }, 3000);
    } catch (error) {
      console.error("Registration failed:", error.message);
    }
  };
  return (
    <div className="register-markeplace-container">
    <div className="header">
       <IoMdArrowBack
         onClick={() => navigate("/Superadminsupport")}
         className="icon"
       />
       <p>Register New Support</p>
     </div>

     <div className="manager-register-form">
       <form onSubmit={handleSubmit}>
         <label>
           First Name
           <input
             type="text"
             name="first_name"
             onChange={handleInputChange}
             required
           />
         </label>

         <label>
           Last Name
           <input
             type="text"
             name="last_name"
             onChange={handleInputChange}
             required
           />
         </label>

         <label>
           Phone Number
           <div className="inner">
             <select name="country_code" onChange={handleInputChange} required>
               <option value="+234">+234</option>
               <option value="+1">+1</option>
               <option value="+95">+95</option>
             </select>
             <input
               type="text"
               name="phone_number"
               onChange={handleInputChange}
               required
             />
           </div>
         </label>

         <label>
           Email
           <input
             type="email"
             name="email"
             onChange={handleInputChange}
             required
           />
         </label>

         

         <label>
           Driver(s) Full Address
           <textarea
             name="address"
             onChange={handleInputChange}
             required
           ></textarea>
         </label>


         <button type="submit" disabled={registerSupportMutation.isLoading}>
           {registerSupportMutation.isLoading ? "Registering..." : "Register"}
         </button>
         {registerSupportMutation.isError && (
           <p className="error-message">
             Error: {registerSupportMutation.error.message}
           </p>
         )}
         {success && <p className="success-message">{success}</p>}
       </form>
     </div>
   </div>
  )
}

export default Supperadminregistersupport
