import { useContext } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { AuthContext } from "../../../../Context/AuthContext";

const useCharterOnTrip = (currentPage) => {
  const { token } = useContext(AuthContext);
  const queryClient = useQueryClient();

  const fetchCharterOnTrip = async () => {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/charterer/charters-on-trip?page=${currentPage}&perPage=10`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });
    if (!response.ok) {
      throw new Error('Failed to fetch CharterOnTrip');
    }
    return response.json();
  };

  return useQuery(['charterOnTrip', currentPage], fetchCharterOnTrip, {
    onSuccess: () => {
      queryClient.invalidateQueries('BookedRequest');
    },
  });
};

export default useCharterOnTrip;




