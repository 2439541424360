
import React, { useContext, useState } from "react";
import axios from "axios";
import Spinner from "../../../Spinner/Spinner";
import { Link, useNavigate } from "react-router-dom";
import { LiaEyeSlashSolid } from "react-icons/lia";
import { LiaEyeSolid } from "react-icons/lia";
import { FaArrowLeft } from "react-icons/fa";
import { AuthContext } from "../../../Context/AuthContext";
import "./ChangePassword.css";

const defaultformField = {
  oldPassword: "",
  newPassword: "",
};

const SupportChangePass = () => {
  const [formfield, setFormField] = useState(defaultformField);
  const { oldPassword, newPassword } = formfield;
  const [error, setError] = useState(null);
  const [showPass1, setShowPass1] = useState(false);
  const [showPass2, setShowPass2] = useState(false);
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(false); 
  const navigate = useNavigate();
  const {token, user} = useContext(AuthContext)

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormField({ ...formfield, [name]: value });
  };

  const handleShowPass1 = () => {
    setShowPass1(!showPass1);
  };
  const handleShowPass2 = () => {
    setShowPass2(!showPass2);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

   
  try {
    setLoading(true);
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/users/change-password/${user.userId}`,
      { oldPassword, newPassword },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    console.log("Password changed successfully", response.data);
    setSuccess("Password changed successfully");
    setTimeout(() => {
      setSuccess(null);
      navigate('/SupportSetting'); 
    }, 3000);

    setError(null);
  } catch (error) {
    setLoading(false);
    console.error("Error changing password", error.response.data);
    setError("Error changing password. Please try again.");
  }
};

  return (
    <div className="change-password-form">
      <form onSubmit={handleSubmit}>
        <div className="wrapper">
          <Link to="/SupportSetting">
            <FaArrowLeft />
          </Link>
          <h3>Change Password</h3>
        </div>
        <span>
          <label>Old Password </label>
          <div className="pass">
            <input
              type={showPass1 ? "text" : "password"}
              name="oldPassword"
              placeholder="Enter Password"
              value={oldPassword}
              onChange={handleChange}
              required
            />
            <wrapper onClick={handleShowPass1}>
              {showPass1 ? <LiaEyeSlashSolid /> : <LiaEyeSolid />}
            </wrapper>
          </div>
        </span>

        <span>
          <label> New Password</label>
          <div className="pass">
            <input
              type={showPass2 ? "text" : "password"}
              name="newPassword"
              placeholder="Enter Password"
              value={newPassword}
              onChange={handleChange}
              required
            />
            <wrapper onClick={handleShowPass2}>
              {showPass2 ? <LiaEyeSlashSolid /> : <LiaEyeSolid />}
            </wrapper>
          </div>
        </span>
        <button type="submit" disabled={loading}>
          {loading ? <Spinner /> : "Change Password"}
        </button>
        {error && <p style={{color:'red', fontFamily:'Montserrat'}}>{error}</p>}
        {success && <p style={{ color: "green", fontFamily:'Montserrat' }}>{success}</p>}
      </form>
    </div>
  );
};

export default SupportChangePass;
