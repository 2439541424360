import { useQuery } from "react-query";
import { useContext } from "react";
import { AuthContext } from "../../Context/AuthContext";
import { useParams } from "react-router-dom";

const fetchHomePreShipmentById = async (id, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_SERVER_URL}/prehome/gethomepreshipment/${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      withCredentials: true,
    }
  );

  if (!response.ok) {
    throw new Error("Error fetching home pre-shipment by ID");
  }

  return response.json();
};

const useGetHomePreShipmentById = () => {
  const { token } = useContext(AuthContext);
  const { id } = useParams(); 

  if (!token) {
    throw new Error("Token is not available. Make sure it's properly set up in AuthContext.");
  }

  return useQuery(["homePreShipmentDetails", id], () => fetchHomePreShipmentById(id, token));
};

export default useGetHomePreShipmentById;
