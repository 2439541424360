import { useMutation, useQueryClient } from 'react-query';
import { useContext } from 'react';
import { AuthContext } from '../Context/AuthContext';

const markTicketAsSolved = async (ticketId, token) => {
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/publicmsgs/tickets/mark-as-solved/${ticketId}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error('Failed to mark ticket as solved');
  }

  return response.json();
};

const useMarkTicketAsSolved = () => {
  const queryClient = useQueryClient();
  const { token } = useContext(AuthContext);

  return useMutation((ticketId) => markTicketAsSolved(ticketId, token), {
    onSuccess: () => {
      queryClient.invalidateQueries(['publicMessages']); 
    },
  });
};

export default useMarkTicketAsSolved;
