import { AuthContext } from "../Context/AuthContext";
import { useContext } from "react";
import { useQuery } from "react-query";

const FetchAdminMessage = () => {
  const { token, user } = useContext(AuthContext);

  const fetchAdminMessages = async () => {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/messages/get-messages/${user.userId}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error("Failed to fetch admin messages");
    }

    const data = await response.json();
    console.log("Admin Messages:", data);

    
    if (!Array.isArray(data.messages)) {
      throw new Error("Admin messages is not an array");
    }

    return data.messages;
  };

  const { data: adminMessages, isLoading, isError } = useQuery("adminMessages", fetchAdminMessages);

  return { adminMessages, isLoading, isError };
};

export default FetchAdminMessage;
