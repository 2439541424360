import { ReactComponent as FrameIcon } from "../../asset/Frame 100.svg";
import useFormDataSender from "../libs/sender";
import useAllStates from "../../libs/getAllStates";
import { useNavigate } from "react-router-dom";
import "./Logisticscreatepackages.css";

function Logiscticsender() {
  const navigate = useNavigate();
  const [formData, handleChange] = useFormDataSender("shipFormData");
    const { data: allStates } = useAllStates();
  

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form Data:", formData);
    navigate("/Logisticsreceiever");
  };
  return (
    <div className="logistics-sender">
      <div className="header">
        <p>Create Package</p>
      </div>
      <div className="ship-form">
        <FrameIcon />
        <form onSubmit={handleSubmit}>
          <p>where are you shipping from</p>
          <div className="inner">
            <label>
              Full Name Or Company Name*
              <input
                type="text"
                placeholder="Enter name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </label>

            <label>
              Contact Name
              <input
                type="text"
                placeholder="Enter name"
                name="contact_name"
                value={formData.contact_name}
                onChange={handleChange}
              />
            </label>

            <label>
              Address Line 1*
              <textarea
                placeholder="Enter address 1"
                name="address_1"
                value={formData.address_1}
                onChange={handleChange}
                required
              ></textarea>
            </label>

            <label>
              Address Line 2*
              <textarea
                placeholder="Enter address 2"
                name="address_2"
                value={formData.address_2}
                onChange={handleChange}
                required
              ></textarea>
            </label>

            <label>
              Email Address*
              <input
                type="text"
                placeholder="Email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </label>

            <label>
              Phone Number*
              <div className="num">
                <select name="" id="">
                  <option value="+234">+234</option>
                  <option value="+1">+1</option>
                </select>
                <input
                  type="number"
                  placeholder="Phone Number"
                  name="phone_number"
                  onChange={handleChange}
                  value={formData.phone_number}
                />
              </div>
            </label>

            <label>
              State*
               <select
                name="state"
                value={formData.state}
                onChange={handleChange}
                required
              >
                <option value="">Select State</option>
                {allStates &&
                  allStates.map((state) => (
                    <option key={state._id} value={state.state}>
                      {state.state}
                    </option>
                  ))}
              </select>
            </label>

            <label>
              Zip Code
              <input
                type="number"
                placeholder="Zip code"
                name="zip_code"
                value={formData.zip_code}
                onChange={handleChange}
                required
              />
            </label>
          </div>
          <button>Proceed</button>
        </form>
      </div>
    </div>
  );
}

export default Logiscticsender;
