import { useContext } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { AuthContext } from "../../Context/AuthContext";

const fetchRiderAssignmentById = async (assignmentId, token) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/packages/fetchRiderAssignment/${assignmentId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch rider assignment");
    }

    return response.json();
  } catch (error) {
    throw new Error(error.message);
  }
};

const useFetchRiderAssignmentById = () => {
  const { token } = useContext(AuthContext);
  const { assignmentId } = useParams();

  return useQuery(["riderAssignment", assignmentId], () =>
    fetchRiderAssignmentById(assignmentId, token)
  );
};

export default useFetchRiderAssignmentById;
