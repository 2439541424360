import React, { Fragment } from "react";
import { useQuery } from "react-query";
import { formatDate } from "../../utils/formatDate";
import { Link } from "react-router-dom";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import "./Blog.css";

const fetchLatestBlogData = async () => {
  const response = await fetch(
    `${process.env.REACT_APP_SERVER_URL}/blogs/getlatest`,
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  );
  const data = await response.json();
  return data.header;
};

const fetchAllBlogData = async () => {
  const response = await fetch(
    `${process.env.REACT_APP_SERVER_URL}/blogs/getblogs`,
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  );
  const data = await response.json();
  return data.posts;
};

const clampWords = (text, maxWords) => {
  const words = text.split(' ');
  const clampedText = words.slice(0, maxWords).join(' ');

  return words.length > maxWords ? `${clampedText}...` : clampedText;
};

const Blog = () => {
  const {
    data: latestBlogData,
    isLoading: isLatestLoading,
    isError: isLatestError,
  } = useQuery("latestBlogData", fetchLatestBlogData, {
    staleTime: 1000,
  });

  const {
    data: allBlogData,
    isLoading: isAllLoading,
    isError: isAllError,
  } = useQuery("allBlogData", fetchAllBlogData, {
    staleTime: 1000,
  });
  

  if (isLatestLoading || isAllLoading) {
    return (
      <div className="blog-mother-card">
        <Skeleton height={200} width={1500}  />
        <Skeleton height={150}  width={400} count={3} />
        <Skeleton height={150}  width={400} count={3}  />
      </div>
    )
  }

  if (isLatestError || isAllError) {
    return (
      <div>
        <p>Error fetching data</p>
      </div>
    );
  }

  if (!Array.isArray(allBlogData) || allBlogData.length === 0) {
    console.error('API response is not a non-empty array:', allBlogData);
    return <p>No blog data available</p>;
  }

  return (
    <Fragment>
      <div className="blog-mother-card">
        {!isLatestLoading && !isLatestError && (
          <span>
            <div className="blog-con-1">
              <img src={latestBlogData.image} alt="" />
            </div>
            <div className="blog-con-2">
              <h3>{latestBlogData.title}</h3>
              <p>{latestBlogData.description}</p>
              <h4>{latestBlogData.author}</h4>
              <p>{formatDate(latestBlogData.createdAt)}</p>
            </div>
          </span>
        )}
      </div>
      <div className="blog-card-container">
        {allBlogData.map((blog, index) => (
          <div key={index} className="blog-info">
            <img src={blog.image} alt={blog.title} />
            <h3>{blog.title}</h3>
            <p>{clampWords(blog.description, 20)}<Link to={`/blog/${blog._id}`}>Read More</Link></p>
            <p>{blog.author}</p>
            <p>{formatDate(blog.createdAt)}</p>
           
          </div>
        ))}
      </div>
    </Fragment>
  );
};

export default Blog;
