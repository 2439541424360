import { useQuery } from 'react-query';
import { useContext } from 'react';
import { AuthContext } from '../../Context/AuthContext';

const useGetBlogs = () => {
  const { token } = useContext(AuthContext);

  const getBlogs = async () => {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/blogs/getallblogs`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch blogs: ${response.status} ${response.statusText}`);
    }

    return response.json();
  };

  return useQuery('blogs', getBlogs);
};

export default useGetBlogs;
