import { useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import { AuthContext } from '../../Context/AuthContext';
import { useContext } from 'react';

const useUpdatePackageStatusToDelayed = () => {
    const { token } = useContext(AuthContext);
    const queryClient = useQueryClient();
  
    const mutation = useMutation(async (data) => {
      const [packageId, updatedData] = data;
  
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_SERVER_URL}/updatepackages/updatePackageStatusToDelayed/${packageId}`,
          updatedData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
  
        return response.data;
      } catch (error) {
        throw error;
      }
    }, {
      onSuccess: () => {
        queryClient.invalidateQueries('riderAssignment');
      },
    });
  
    return {
      mutation,
      updatePackageStatusToDelayed: async (packageId, updatedData) => {
        try {
          const result = await mutation.mutateAsync([packageId, updatedData]);
          return result;
        } catch (error) {
          throw error;
        }
      },
    };
  };
  
  export default useUpdatePackageStatusToDelayed;
  