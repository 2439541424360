import { useMutation, useQueryClient } from "react-query";
import { AuthContext } from "../../Context/AuthContext";
import { useContext } from "react";

const createProductCategory = async (categoryData, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_SERVER_URL}/productcategory/create/product/category`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(categoryData),
    }
  );

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.message || "Failed to create category");
  }

  return response.json();
};

const useCreateProductCategory = () => {
  const queryClient = useQueryClient();
  const { token } = useContext(AuthContext);

  return useMutation((categoryData) => createProductCategory(categoryData, token), {
    onSuccess: () => {
      queryClient.invalidateQueries("productCatories");
    },
  });
};

export default useCreateProductCategory;
