import { useQuery } from 'react-query';
import { useContext } from 'react';
import { AuthContext } from '../../../Context/AuthContext';

const useTruckOrders = () => {
  const { token, user } = useContext(AuthContext);

  const fetchTruckOrders = async () => {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/hiretruck/truck-recent-orders?state=${user.state}`, {
      method: 'GET',
      headers: {
        'Content-type': 'Application/json',
        'Authorization': `Bearer ${token}`
      }
    });

    if (!response.ok) {
      throw new Error('Failed to fetch the last 24 hours Trucks orders');
    }
    return response.json();
  };

  return useQuery('TruckOrders', fetchTruckOrders);
};

export default useTruckOrders;