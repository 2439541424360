import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { FaArrowLeft } from "react-icons/fa6";
import { AuthContext } from '../../../../Context/AuthContext';
import { ReactComponent as CloudLogo } from "../../../../Assets/bx_cloud-upload@3x.svg";
import "./Truck.css";

const defaultForm = {
    truckType: '',
    tonnage: '',
    truckLength: '',
    description: '',
    truckModel: '',
};


const Addtrucks = () => {
    const [formField, setFormField] = useState(defaultForm);
    const [images, setImages] = useState([]);
    const { token, user } = useContext(AuthContext);
    const [error, setError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [formattedPrice, setFormattedPrice] = useState('');
    const { truckType, tonnage, truckLength, description,  truckModel } = formField;

  
    const userState = user?.state || '';

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormField({ ...formField, [name]: value });
    };

    const handleImageChange = (e) => {
        const files = Array.from(e.target.files);
        setImages(files);
    };

    const handleResetImages = () => {
        setImages([]);
    };

    const handlePriceChange = (event) => {
        let inputValue = event.target.value.replace(/[^\d]/g, '');
        inputValue = Number(inputValue) / 100;
    
        const formattedValue = inputValue.toLocaleString('en-US', {
          style: 'currency',
          currency: 'NGN',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
        setFormattedPrice(formattedValue);
      };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null);
        setIsLoading(true);

        const formData = new FormData();
        formData.append("truckType", truckType);
        formData.append("truckModel", truckModel);
        const numericPrice = parseFloat(formattedPrice.replace(/[^\d.-]/g, ''));
        formData.append("pricePerDay", numericPrice);
        formData.append("description", description);
        formData.append("state", userState);
        formData.append("tonnage", parseFloat(tonnage));
        formData.append("truckLength", parseFloat(truckLength));

        for (let i = 0; i < images.length; i++) {
            formData.append("images", images[i]);
        }

        try {
            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/freight/create/trucks`,
                {
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    body: formData,
                }
            );

            const resData = await response.json();

            if (!response.ok) {
                throw new Error(resData.message);
            }
            setFormField(defaultForm);
            setIsLoading(false);
            setImages([]);
        } catch (error) {
            setError(error.message);
            setIsLoading(false);
        }
    };

    return (
        <div className='add-truck'>
            <h3>Freight_Add Truck</h3>
            <div className="wrapper">
                <form onSubmit={handleSubmit}>
                    <div className="card">
                        <span>
                            <Link to='/freighttruck'>
                                <FaArrowLeft />
                                Add Truck
                            </Link>
                        </span>
                        {error && <p style={{fontFamily:'Montserrat', textAlign:'center', width:'30%'}}>{error}</p>}
                        <button> {isLoading ? "saving..." : "Save Truck"}</button>
                    </div>
                    <div className="truck-image">
                        <h3>TRUCK IMAGE</h3>
                        <div className="truck-image-span">
                            {images.length > 0 ? (
                                <>
                                    {images.map((image, index) => (
                                        <img
                                            key={index}
                                            src={URL.createObjectURL(image)}
                                            alt={`Product ${index}`}
                                        />
                                    ))}
                                    <button type="button" onClick={handleResetImages}>
                                        Reset Images
                                    </button>
                                </>
                            ) : (
                                <div className="truck-image-card">
                                    <label className="upload-label">
                                        <CloudLogo />
                                        <input
                                            type="file"
                                            accept="image/*"
                                            multiple
                                            onChange={handleImageChange}
                                            style={{ display: "none" }}
                                        />
                                        <p>Upload images</p>
                                    </label>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="truck-input-detail">
                        <h3>TRUCK DETAILS</h3>
                        <div className="wrapper">
                            <span>
                                <label>Truck Type</label>
                                <select name="truckType" value={truckType} onChange={handleChange} required>
                                    <option value="">Select Truck</option>
                                    <option value="Mini Truck">Mini Truck</option>
                                    <option value="Pickup Truck">Pickup Truck</option>
                                    <option value="Cargo Van">Cargo Van</option>
                                    <option value="Small Box Truck">Small Box Truck</option>
                                    <option value="Medium Box Truck">Medium Box Truck</option>
                                    <option value="Large Box Truck">Large Box Truck</option>
                                    <option value="Semi-Trailer Truck ">Semi-Trailer Truck </option>
                                </select>
                            </span>
                            <span>
                                <label>Truck Model</label>
                                <select name="truckModel" value={truckModel} onChange={handleChange} required>
                                    <option value="">Select Model</option>
                                    <option value="Ford">Ford</option>
                                    <option value="Mercedes">Mercedes</option>
                                    <option value="Nissan">Nissan</option>
                                    <option value="Toyota">Toyota</option>
                                    <option value="Honda">Honda</option>
                                    <option value="Mack">Mack</option>
                                    <option value="Volvo">Volvo</option>
                                    <option value="Chevrolet">Chevrolet</option>
                                </select>
                            </span>
                            <span>
                                <label>Tonnage</label>
                                <select name="tonnage" value={tonnage} onChange={handleChange} required>
                                    <option value="">Select Tonnage</option>
                                    <option value="1 ton">1 ton</option>
                                    <option value="1.5 tons">1.5 tons</option>
                                    <option value="2 tons">2 tons</option>
                                    <option value="3 to 5 tons">3 to 5 tons</option>
                                    <option value="6 to 8 tons">6 to 8 tons</option>
                                    <option value="10 to 15 tons">10 to 15 tons</option>
                                    <option value="20 to 25 tons">20 to 25 tons</option>
                                </select>
                            </span>
                            <span>
                                <label>Truck Length</label>
                                <select name="truckLength" value={truckLength} onChange={handleChange} required>
                                    <option value="">Select Length</option>
                                    <option value="10 to 12 feet">10 to 12 feet</option>
                                    <option value="15 to 20 feet">15 to 20 feet</option>
                                    <option value="20 to 24 feet">20 to 24 feet</option>
                                    <option value="24 to 28 feet">24 to 28 feet</option>
                                    <option value="28 to 32 feet">28 to 32 feet</option>
                                </select>
                            </span>
                        </div>
                    </div>
                    <div className="truck-description-card">
                        <h3>DESCRIPTION</h3>
                        <textarea name="description" value={description} onChange={handleChange} required></textarea>
                    </div>
                    <div className="truck-price-perday">
                        <h3>PRICING</h3>
                        <span>
                <label>Price/Day</label>
                <input type="tel" value={formattedPrice} onChange={handlePriceChange} required  placeholder='0.00
                '/>
              </span>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default Addtrucks;

