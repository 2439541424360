import { useContext } from "react";
import { useMutation, useQueryClient } from "react-query";
import { AuthContext } from "../../Context/AuthContext";

const rejectPackage = async (driverAssignmentId, token) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/packages/rejectpackage/${driverAssignmentId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error("Failed to reject package");
    }

    return response.json();
  } catch (error) {
    throw new Error(error.message);
  }
};

const useRejectPackage = () => {
  const { token } = useContext(AuthContext);
  const queryClient = useQueryClient();

  return useMutation((driverAssignmentId) => rejectPackage(driverAssignmentId, token), {
    onSuccess: () => {
      queryClient.invalidateQueries("driverAssignment");
      queryClient.invalidateQueries("dispatchDetailsByCreatorId");
    },
  });
};

export default useRejectPackage;
