
import { useMutation, useQueryClient} from 'react-query'; 
import { useContext } from 'react';
import { AuthContext } from '../../../../Context/AuthContext';
import { useNavigate } from 'react-router-dom';

const useDeleteProduct = () => {
  const queryClient = useQueryClient();
  const { token } = useContext(AuthContext); 
  const navigate = useNavigate(); 

  const deleteProduct = useMutation(
    async (pid) => {
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/product/products/${pid}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`, 
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Failed to delete product');
      }

      return response.json();
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('products');
        navigate('/MerchantProducts'); 
      },
    }
  );

  return {
    deleteProduct,
    isLoading: deleteProduct.isLoading,
  };
};

export default useDeleteProduct;

