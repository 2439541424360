import React, { useState, useEffect } from 'react';
import { CiSearch } from "react-icons/ci";
import { FiPlusCircle } from "react-icons/fi";
import { HiDotsVertical } from "react-icons/hi";
import { Link } from 'react-router-dom';
import { ReactComponent as TruclLength } from "../../../../Assets/iconoir_truck-length.svg";
import { ReactComponent as TonneWeight } from "../../../../Assets/la_weight-hanging.svg";
import useFreightTruck from '../../libs/fetchAllTrucks';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import "./Truck.css";

const Freighttruck = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const itemsPerPage = 8;

  const { trucks, isLoading, isError, totalTrucks } = useFreightTruck(currentPage, itemsPerPage);

  useEffect(() => {
    setCurrentPage(1); 
  }, [searchQuery]);

  const filteredTrucks = trucks.filter(truck =>
    truck._id.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const totalPages = Math.ceil(totalTrucks / itemsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(prevPage => prevPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(prevPage => prevPage - 1);
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  return (
    <div className="freight-truck">
      <div className="wrapper">
        <h3>Trucks</h3>
        <span>
          <Link to="/addtrucks">
            <FiPlusCircle />
            Add Truck
          </Link>
          <form onSubmit={(e) => e.preventDefault()}>
            <button>
              <CiSearch />
            </button>
            <input
              type="text"
              placeholder="Search Truck by Id"
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </form>
        </span>
      </div>
      <div className="freight-truck-mother">
        {isLoading ? (
          Array(itemsPerPage).fill().map((_, index) => (
            <div className="truck-card" key={index}>
              <Skeleton height={150} width={250} count={1} />
              <div className="wrapper">
                <div className="truck-span">
                  <Skeleton width={100} />
                  <Skeleton width={100} />
                </div>
                <Skeleton width={150} />
                <span>
                  <Skeleton width={100} />
                </span>
                <Skeleton width={100} />
              </div>
            </div>
          ))
        ) : isError ? (
          <p style={{textAlign:'center', fontFamily:'Montserrat'}}>No Truck available now</p>
        ) : (
          filteredTrucks.map((truck) => (
            <div className="truck-card" key={truck._id}>
              <img src={truck.images[0]} alt={truck.truckType} />
              <div className="wrapper">
                <div className="truck-span">
                  <h3>{truck.truckType}</h3>
                  <Link to={`/truck/${truck._id}`}><HiDotsVertical /></Link>
                </div>
                <span>
                  <TruclLength />
                  <p>{truck.truckLength} ft</p>
                </span>
                <span>
                  <TonneWeight />
                  <p>Tonnage: {truck.tonnage} Tons</p>
                </span>
                <h4>{truck.pricePerDay
                  ? (truck.pricePerDay).toLocaleString("en-US", {
                    style: "currency",
                    currency: "NGN",
                  })
                  : ""}</h4>
                <p>
                 Status:
                 {truck.notAvailable && "Not Available"}
                  {truck.onTrip && "On Trip"}
                  {truck.isBooked && "Booked"}
                  {truck.onRepairing && "On Repair"}
                  {(!truck.notAvailable && !truck.onTrip && !truck.isBooked && !truck.onRepairing) && "Available"}
                </p>
              </div>
            </div>
          ))
        )}
      </div>
      <div className="pagination">
        <span>{`Showing ${(currentPage - 1) * itemsPerPage + 1} - ${Math.min(currentPage * itemsPerPage, totalTrucks)} of ${totalTrucks}`}</span>
        <div className="btn">
          <button onClick={handlePrevPage} disabled={currentPage === 1}>
            Prev
          </button>
          <button onClick={handleNextPage} disabled={currentPage === totalPages}>
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default Freighttruck;







