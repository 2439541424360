import { useContext } from 'react';
import { useQuery } from 'react-query';
import { AuthContext } from "../../../Context/AuthContext";

const useOnRepairTruck = (page) => {
  const { token, user } = useContext(AuthContext);

  const fetchOnRepairTruck = async () => {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/freight/get-trucks-on-repair?state=${user?.state}&page=${page}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });
    if (!response.ok) {
      throw new Error('Failed to fetch on-repair truck');
    }
    return response.json();
  };

  return useQuery(['onRepairTruck', page], fetchOnRepairTruck);
};

export default useOnRepairTruck;
