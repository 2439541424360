import React from 'react';
import "./Marketoverview.css";
import useStatistics from "../libs/useStatistics";
import Spinner from "../../utils/spinner";

function Marketoverview() {
  const { data: statistics, error, isLoading } = useStatistics();

 

  return (
    <div className="market-overview-container">
      <div className="header">
        <h1>Overview</h1>
      </div>
      {!isLoading && error && <p>No data available.</p>}
      <div className="card-overview">
        <div className="box">
          {isLoading ? (
            <Spinner />
          ) : (
            <>
              <p>Total Products</p>
              <span>{statistics.totalProducts}</span>
            </>
          )}
        </div>
        <div className="box">
          {isLoading ? (
            <Spinner />
          ) : (
            <>
              <p>Total Orders</p>
              <span>{statistics.totalOrders}</span>
            </>
          )}
        </div>
        <div className="box">
          {isLoading ? (
            <Spinner />
          ) : (
            <>
              <p>Completed Orders</p>
              <span>{statistics.completedOrders}</span>
            </>
          )}
        </div>
        <div className="box">
          {isLoading ? (
            <Spinner />
          ) : (
            <>
              <p>Pending Orders</p>
              <span>{statistics.pendingOrders}</span>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Marketoverview;
