
import React from "react";
import { useQuery } from "react-query";
import { useParams, Link } from "react-router-dom";
import { formatDate } from "../../utils/formatDate";
import { FaArrowLeft } from "react-icons/fa";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const fetchBlogById = async (blogId) => {
  const response = await fetch(
    `${process.env.REACT_APP_SERVER_URL}/blogs/getblog/${blogId}`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  const data = await response.json();
  return data.post;
};

const fetchAllOtherBlogs = async (currentBlogId) => {
  const response = await fetch(
    `${process.env.REACT_APP_SERVER_URL}/blogs/getblogs`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  const data = await response.json();
  return data.posts.filter((post) => post.id !== currentBlogId);
};

const BlogDetail = () => {
  const { blogId } = useParams();
  const {
    data: blogData,
    isLoading,
    isError,
  } = useQuery(["blogById", blogId], () => fetchBlogById(blogId), {
    staleTime: 1000,
  });

  const {
    data: otherBlogsData,
    isLoading: isOtherBlogsLoading,
    isError: isOtherBlogsError,
  } = useQuery("allOtherBlogs", () => fetchAllOtherBlogs(blogId), {
    staleTime: 1000,
  });

  if (isLoading || isOtherBlogsLoading) {
    return (
      <>
      <div className="blog-detail-container">
        <Skeleton height={470} width={900} style={{ marginTop: "10px" }} />
        <Skeleton height={150}  width={400} count={3} style={{ marginTop: "10px" }} />
      </div>
      </>
    );
  }

  if (isError || isOtherBlogsError) {
    return <p>Error fetching data</p>;
  }

  if (!blogData) {
    console.error("Error in fetching blog post:", blogData);
    return <p>No blog data available</p>;
  }

  return (
    <div className="blog-detail-container">
      <div className="blog-detail-con-1">
        <img src={blogData.image} alt={blogData.title} />
        <h3>{blogData.title}</h3>
        <p>{blogData.description}</p>
        <p>{blogData.author}</p>
        <p>{formatDate(blogData.createdAt)}</p>
        <Link to="/Blog">
          <FaArrowLeft style={{ fontSize: "20px" }} />
        </Link>
      </div>
      <div className="blog-detail-con-2">
        <h3>More from Blog </h3>
        {otherBlogsData.map((otherBlog, index) => (
          <div key={index} className="other-blog-info">
            <span>
              <p>{formatDate(otherBlog.createdAt)}</p>
              <h3>{otherBlog.title}</h3>
            </span>
            <img src={otherBlog.image} alt={otherBlog.title} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default BlogDetail;

