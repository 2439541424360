
import { useQuery } from 'react-query';
import { useContext } from 'react';
import { AuthContext } from '../Context/AuthContext';

const useClosedMessagesCount = () => {
  const { token } = useContext(AuthContext);

  const fetchClosedMessagesCount = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/publicmsgs/total-close-tickets`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
 

      const data = await response.json();
      return data.closedTicketCount;
    } catch (error) {
      throw new Error('Failed to fetch data');
    }
  };

  const { data: closedMessageCount, isLoading, isError } = useQuery('closedMessageCount', fetchClosedMessagesCount);

  return { closedMessageCount, loading: isLoading, error: isError };
};

export default useClosedMessagesCount;