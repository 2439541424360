import React, { Fragment, useState } from "react";
import { useQuery } from "react-query";
import { AuthContext } from "../Context/AuthContext";
import { GlobalContext } from "../GlobalContext/GlobalContext";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { FaTrash } from "react-icons/fa";
import { ReactComponent as CautionIcon } from "../Assets/caution-svgrepo-com 1.svg";
import useDeletePublicMessages from "./useDeletePublicMessages";
import useMarkTicketAsSolved from "./useMarkTicketAsSolved";
import Spinner from "../Spinner/Spinner";

const PublicMessage = () => {
  const { token } = useContext(AuthContext);
  const { isPublicMessageOpen, setIsPublicMessageOpen } = useContext(GlobalContext);
  const [selectedMessageIds, setSelectedMessageIds] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [individualSelection, setIndividualSelection] = useState({});
  const [deleteOverlay, setDeleteOverlay] = useState(false);
  const [selectedMessageIndex, setSelectedMessageIndex] = useState(null);
  const [selectMsgId, setSlectMsgId] = useState(null)
  const [closeMsgOverlay, setCloseMsgOverlay] = useState(false)
  const { mutate,  isError } = useMarkTicketAsSolved();
  const [closeLodaing, setCloseloading] = useState(false)

 

  const clampWords = (text, maxWords) => {
    const words = text.split(" ");
    const clampedWords = words.slice(0, maxWords);
    return clampedWords.join(" ");
  };

  const { data, error, isLoading } = useQuery(
    "publicMessages",
    async () => {
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/publicmsgs/getall-public-messages`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch public messages");
      }

      const fetchedData = await response.json();
      console.log(fetchedData)
      return fetchedData;
    }
  );


  const deletePublicMessagesMutation = useDeletePublicMessages();

  if (isLoading) {
    return <Spinner/>;
  }

  if (error) {
    return `Error: ${error.message}`;
  }

  const handleMessageClick = (messageId) => {
    const selectedMessageIndex = data.publicMessages.findIndex(
      (message) => message._id === messageId
    );
    setSelectedMessageIndex(selectedMessageIndex);
    setIsPublicMessageOpen(true);
  };

  const handleCheckboxChange = (messageId) => {
    setIndividualSelection((prevSelection) => ({
      ...prevSelection,
      [messageId]: !prevSelection[messageId],
    }));
  };

  const handleSelectAll = () => {
    setSelectAll((prevSelectAll) => !prevSelectAll);
    setSelectedMessageIds((prevSelected) =>
      !selectAll ? data.publicMessages.map((message) => message._id) : []
    );
    setIndividualSelection({});
  };

  const handleDeleteSelectedMessages = () => {
    const selectedIds = Object.keys(individualSelection).filter(
      (id) => individualSelection[id]
    );
    if (selectedMessageIds.length > 0 || selectedIds.length > 0) {
      setDeleteOverlay(true);
    }
  };

  const confirmDelete = async () => {
    
    try {
      const idsToDelete = selectedMessageIds.length > 0
      ? selectedMessageIds
      : Object.keys(individualSelection).filter(id => individualSelection[id]);
      
      await deletePublicMessagesMutation.mutateAsync(idsToDelete);
      setDeleteOverlay(false);
    } catch (error) {
      console.error("Error deleting messages:", error);
    }
  };


  const handleMarkAsSolved = () => {
    setCloseloading(true)
    mutate(selectMsgId, {
      onSuccess: () => {
        setCloseMsgOverlay(false);
        setCloseloading(false)
      },
      onError: (error) => {
        console.error("Error marking message as solved:", error);
      },
    });
  };
  

  return (
    <Fragment>

{closeMsgOverlay && (
        <div className="closemsg-overlay">
          <div className="card">
            <p>Are you sure you want mark this message as Closed?</p>
            <div className="btn">
              <button
                onClick={() => {
                  setCloseMsgOverlay(false);
                  setSlectMsgId(null);
                }}
              >
                No
              </button>
              <button onClick={handleMarkAsSolved} disabled={closeLodaing}>
                {closeLodaing ? 'Solving...' : 'Yes'}
              </button>
              {isError && (
                <div>Error occurred while marking as solved: {isError.message}</div>
              )}
            </div>
          </div>
        </div>
      )}

      {deleteOverlay && (
        <div className="delete-overlay">
          <div className="card">
            <CautionIcon />
            <h3>Delete</h3>
            <p>Are you sure you want to delete the selected message(s)?</p>
            <div className="btn">
              <button onClick={() => setDeleteOverlay(false)}>No</button>
              <button onClick={confirmDelete} disabled={deletePublicMessagesMutation.isLoading}>
                {deletePublicMessagesMutation.isLoading ? "Deleting..." : "Yes"}
              </button>
            </div>
          </div>
        </div>
      )}
      {isPublicMessageOpen && selectedMessageIndex !== null && data.publicMessages && data.publicMessages[selectedMessageIndex] && (
        <div className="overlay-msg">
          <div className="card">
            <div className="header">
              <span>
                <h3>{data.publicMessages[selectedMessageIndex].fullname}</h3>
                <p>{data.publicMessages[selectedMessageIndex].email}</p>
              </span>
              <p>
                {new Intl.DateTimeFormat("en-US", {
                  timeZone: "Africa/Lagos",
                }).format(new Date(data.publicMessages[selectedMessageIndex].createdAt))}
              </p>
            </div>
            <div className="content-box">
              <p>{data.publicMessages[selectedMessageIndex].content}</p>
            </div>
            <div className="btn">
              <button onClick={() => setIsPublicMessageOpen(false)}>
                Close
              </button>
            </div>
          </div>
        </div>
      )}

      <div className="public-msg-table">
        <div className="header">
          <span>
          <input
            type="checkbox"
            checked={selectAll}
            onChange={handleSelectAll}
          />
          <p>Select All</p>
          </span>

          <span>
          <FaTrash onClick={handleDeleteSelectedMessages} className="icon" />
          </span>
        </div>
        <table>
            <tr>
              <th></th>
              <th>Full Name</th>
              <th>Email</th>
              <th>Phone </th>
              <th>Content</th>
              <th>Created </th>
              <th>Ticket No</th>
              <th> Status</th>
              <th>Details</th>
            </tr>
          <tbody>
            {Array.isArray(data.publicMessages) &&
              data.publicMessages.map((message) => (
                <tr key={message._id}>
                  <td>
                    <input
                      type="checkbox"
                      checked={selectAll || individualSelection[message._id]}
                      onChange={() => handleCheckboxChange(message._id)}
                    />
                  </td>
                  <td>{message.fullname}</td>
                  <td>{message.email}</td>
                  <td>{message.phoneNumber}</td>
                  <td>{clampWords(message.content, 2)}</td>
                  <td>
                    {new Intl.DateTimeFormat("en-US", {
                      timeZone: "Africa/Lagos",
                    }).format(new Date(message.createdAt))}
                  </td>
                  <td>{message.tickets[0]?.ticketCode}</td>
                  <td>{message.tickets[0]?.status}</td>
                  <td>
                    <Link
                      onClick={(e) => {
                        e.stopPropagation();
                        handleMessageClick(message._id);
                      }}
                    >
                      View Details
                    </Link>
                  </td>
                  <td>
                    <button onClick={() => {
                      setSlectMsgId(message.tickets[0]._id);
                      setCloseMsgOverlay(true)
                    }}>Solved</button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </Fragment>
  );
};

export default PublicMessage;



