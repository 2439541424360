
import React, { useState, useEffect } from "react";
import { CiSearch } from "react-icons/ci";
import { FaXmark } from "react-icons/fa6";
import { CiUser } from "react-icons/ci";
import { ReactComponent as SeatIcon } from "../../../../Assets/safety-seat-car-svgrepo-com 13x.svg";
import { BsFuelPump } from "react-icons/bs";
import { GiCarDoor } from "react-icons/gi";
import { TbSteeringWheel } from "react-icons/tb";
import { Fragment } from "react";
import useRequestedHires from "../libs/fetchNewOrder";
import useMarkVehicleAsUnBooked from "../libs/fetchUnBooked";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import "./Charter.css";


const Charters = () => {
  const [viewDetail, setViewDetail] = useState(false);
  const [selectedCharter, setSelectedCharter] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const { data, isLoading, isError } = useRequestedHires(currentPage);
  const { mutate: markAsUnBooked, isLoading: isMarkingAsUnBooked } = useMarkVehicleAsUnBooked();
  const [filteredData, setFilteredData] = useState([]);

  const itemsPerPage = 10;
  const totalItems = data?.requestedHires?.length || 0;
  const startIndex = (currentPage - 1) * itemsPerPage + 1;
  const endIndex = Math.min(startIndex + itemsPerPage - 1, totalItems);

  const handleNextPage = () => {
    if (currentPage < data?.totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  useEffect(() => {
    if (searchQuery) {
      const filtered = data?.requestedHires?.filter((charter) =>
        charter._id.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredData(filtered);
    } else {
      setFilteredData(data?.requestedHires);
    }
  }, [searchQuery, data]);

  const handleViewDetail = (charter) => {
    setSelectedCharter(charter);
    setViewDetail(true);
  };

  const clampWords = (text, maxWords) => {
    const words = text.split(" ");
    const clampedWords = words.slice(0, maxWords);
    return clampedWords.join(" ");
  };

  const handleMarkAsUnBooked = () => {
    if (selectedCharter) {
      markAsUnBooked(selectedCharter._id, {
        onSuccess: () => {
          setViewDetail(false);
          setFilteredData(prevData =>
            prevData.map(item =>
              item._id === selectedCharter._id
                ? { ...item, vehicle: { ...item.vehicle, isBooked: true } }
                : item
            )
          );
          toast.success('Vehicle marked as Booked successfully');
        },
        onError: (error) => {
          console.error("Error marking vehicle as Booked:", error);
          toast.error('Failed to mark vehicle as Booked');
        },
      });
    }
  };



  return (
    <Fragment>
      {viewDetail && selectedCharter && ( 
        <div className="chartered-vehicle-detail">
          <div className="wrapper">
            <span>
              <h3>{selectedCharter._id}</h3> 
              <button onClick={() => setViewDetail(false)}>
                <FaXmark style={{ fontSize: "20px" }} />
              </button>
            </span>
            <div className="chartered-vehicle-detail-span">
              <div className="card-1">
                <div className="wrapper">
                  <img src={selectedCharter?.vehicle?.images[0]} alt="" /> 
                  <div className="inner">
                    <h3>{selectedCharter?.vehicle?.vehicleModel}</h3> 
                    <h4>
                    {selectedCharter?.totalAmount
                  ? selectedCharter?.totalAmount.toLocaleString("en-US", {
                      style: "currency",
                      currency: "NGN",
                    })
                  : ""}
                    </h4>
                    <span>
                      <div className="box">
                        <CiUser />
                        <p>Max No of peo : {selectedCharter?.vehicle?.numberOfSeats}</p> 
                      </div>
                    </span>
                    <span>
                      <div className="box">
                        <TbSteeringWheel />
                        <p>Power Type:{selectedCharter?.vehicle?.powerType}</p>
                      </div>
                    </span>
                    <span>
                      <div className="box">
                        <SeatIcon />
                        <p>{selectedCharter?.vehicle?.numberOfSeats} seats</p> 
                      </div>
                      <div className="box">
                        <BsFuelPump />
                        <p>{selectedCharter?.vehicle?.fuelTankCapacity}L </p> 
                      </div>
                      <div className="box">
                        <GiCarDoor />
                        <p>{selectedCharter?.vehicle?.doors} doors</p> 
                      </div>
                    </span>
                  </div>
                </div>
                <div className="description-detail">
                  <h3>DESCRIPTION</h3>
                  <p>{selectedCharter?.vehicle?.description}</p> 
                </div>
                <button onClick={handleMarkAsUnBooked} disabled={isMarkingAsUnBooked}>
                    { isMarkingAsUnBooked ? "Canceling...." : "Cancel Order"}
                   </button>
              </div>
              <ToastContainer position="top-right" className="toast" />

              <div className="card-2">
                <div className="wrapper">
                  <h3>Passenger Details</h3>
                  <span>
                    <p>Full Name:</p>
                    <p>{selectedCharter?.passengerDetails?.fullName}</p> 
                  </span>
                  <span>
                    <p>Email:</p>
                    <p>{selectedCharter.passengerDetails?.email}</p> 
                  </span>
                  <span>
                    <p>Phone Number:</p>
                    <p>{selectedCharter.passengerDetails?.phoneNumber}</p> 
                  </span>
                  <span>
                    <p>Address:</p>
                    <h4>{selectedCharter.passengerDetails?.address}</h4> 
                  </span>
                  <span>
                    <p>Payment Method:</p>
                    <p>{selectedCharter.paymentMethod}</p> 
                  </span>
                  <span>
                    <p>ID Type:</p>
                    <p>{selectedCharter.customerIdType}</p> 
                  </span>
                  <span>
                    <p>ID Number:</p>
                    <p>{selectedCharter.customerIdNumber}</p> 
                  </span>
                  
                </div>
                <div className="wrapper">
                  <h3>Next of Kin Details</h3>
                  <span>
                    <p>Full Name</p>
                    <p>{selectedCharter.passengerDetails?.nextOfKin?.fullName}</p> 
                  </span>
                  <span>
                    <p>Phone Number</p>
                    <p>{selectedCharter.passengerDetails?.nextOfKin?.phoneNumber}</p> 
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="charters">
        <div className="wrapper">
          <h3>Charters_New Order</h3>
          <form onSubmit={(e) => e.preventDefault()}>
            <button type="submit">
              <CiSearch />
            </button>
            <input
              type="text"
              placeholder="Search New Order by Id"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </form>
        </div>
        <div className="charter-mothers">
          <table>
            <thead>
              <tr>
                <th>Hired ID</th>
                <th>Hire Location</th>
                <th>Vehicle Category</th>
                <th>Vehicle Model</th>
                <th>Hire From</th>
                <th>Hire To</th>
                <th>Amount</th>
                <th>Status</th>
                <th>Details</th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                Array.from({ length: 10 }).map((_, index) => (
                  <tr key={index}>
                    <td><Skeleton /></td>
                    <td><Skeleton /></td>
                    <td><Skeleton /></td>
                    <td><Skeleton /></td>
                    <td><Skeleton /></td>
                    <td><Skeleton /></td>
                    <td><Skeleton /></td>
                    <td><Skeleton /></td>
                    <td><Skeleton /></td>
                  </tr>
                ))
              ) : isError ? (
                <tr>
                  <td colSpan="9"><p style={{textAlign:'center'}}>No vehicle order available</p></td>
                </tr>
              ) : (
                filteredData &&
                filteredData.map((charter) => (
                  <tr key={charter._id}>
                    <td>{charter._id.slice(0, 5).toUpperCase()}</td>
                    <td>{clampWords(charter.pickupLocation, 4)}</td>
                    <td>{charter?.vehicle?.vehicleType}</td>
                    <td>{charter?.vehicle?.vehicleModel}</td>
                    <td>
                      {new Intl.DateTimeFormat("en-US", {
                        timeZone: "Africa/Lagos",
                      }).format(new Date(charter.startDate))}
                    </td>
                    <td>
                      {new Intl.DateTimeFormat("en-US", {
                        timeZone: "Africa/Lagos",
                      }).format(new Date(charter.endDate))}
                    </td>
                    <td>
                      {charter.totalAmount
                        ? charter.totalAmount.toLocaleString("en-US", {
                            style: "currency",
                            currency: "NGN",
                          })
                        : ""}
                    </td>
                    <td>
                      <span>
                        {charter.requestingHiring ? "New Order" : "Completed"}
                      </span>
                    </td>
                    <td>
                      <button onClick={() => handleViewDetail(charter)}>
                        View
                      </button>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
        <div className="pagination">
            <span>
              {`Showing ${startIndex} - ${endIndex} of ${totalItems}`}
            </span>
            <div className="btn">
              <button onClick={handlePrevPage} disabled={currentPage === 1}>
                Prev
              </button>
              <button
                onClick={handleNextPage}
                disabled={currentPage === data?.totalPages}
              >
                Next
              </button>
            </div>
          </div>
      </div>
    </Fragment>
  );
};

export default Charters;

// import React, { useState, useEffect } from "react";
// import { CiSearch } from "react-icons/ci";
// import { FaXmark } from "react-icons/fa6";
// import { CiUser } from "react-icons/ci";
// import { ReactComponent as SeatIcon } from "../../../../Assets/safety-seat-car-svgrepo-com 13x.svg";
// import { BsFuelPump } from "react-icons/bs";
// import { GiCarDoor } from "react-icons/gi";
// import { TbSteeringWheel } from "react-icons/tb";
// import { Fragment } from "react";
// import useRequestedHires from "../libs/fetchNewOrder";
// import useMarkVehicleAsUnBooked from "../libs/fetchUnBooked";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import Skeleton from "react-loading-skeleton";
// import "react-loading-skeleton/dist/skeleton.css";
// import "./Charter.css";

// const Charters = () => {
//   const [viewDetail, setViewDetail] = useState(false);
//   const [selectedCharter, setSelectedCharter] = useState(null);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [searchQuery, setSearchQuery] = useState("");
//   const { data, isLoading, isError } = useRequestedHires(currentPage);
//   const { mutate: markAsUnBooked, isLoading: isMarkingAsUnBooked } = useMarkVehicleAsUnBooked();
//   const [filteredData, setFilteredData] = useState([]);

//   const itemsPerPage = 10;
//   const totalItems = data?.requestedHires?.length || 0;
//   const startIndex = (currentPage - 1) * itemsPerPage + 1;
//   const endIndex = Math.min(startIndex + itemsPerPage - 1, totalItems);

//   useEffect(() => {
//     if (searchQuery) {
//       const filtered = data?.requestedHires?.filter((charter) =>
//         charter._id.toLowerCase().includes(searchQuery.toLowerCase())
//       );
//       setFilteredData(filtered);
//     } else {
//       setFilteredData(data?.requestedHires);
//     }
//   }, [searchQuery, data]);

//   const handleNextPage = () => {
//     if (currentPage < data?.totalPages) {
//       setCurrentPage((prevPage) => prevPage + 1);
//     }
//   };

//   const handlePrevPage = () => {
//     if (currentPage > 1) {
//       setCurrentPage((prevPage) => prevPage - 1);
//     }
//   };

//   const handleViewDetail = (charter) => {
//     setSelectedCharter(charter);
//     setViewDetail(true);
//   };

//   const clampWords = (text, maxWords) => {
//     const words = text.split(" ");
//     return words.slice(0, maxWords).join(" ");
//   };

//   const handleMarkAsUnBooked = () => {
//     if (selectedCharter && selectedCharter.vehicle) {
//       markAsUnBooked(selectedCharter.vehicle._id, {
//         onSuccess: () => {
//           setViewDetail(false);
//           setFilteredData((prevData) =>
//             prevData.map((item) =>
//               item._id === selectedCharter._id
//                 ? { ...item, vehicle: { ...item.vehicle, isBooked: false } }
//                 : item
//             )
//           );
//           toast.success("Vehicle marked as UnBooked successfully");
//         },
//         onError: (error) => {
//           console.error("Error marking vehicle as UnBooked:", error);
//           if (error.message === "Vehicle not found.") {
//             toast.error("Vehicle not found. Please refresh the page.");
//           } else {
//             toast.error("Failed to mark vehicle as UnBooked");
//           }
//         },
//       });
//     }
//   };
  
  

//   return (
//     <Fragment>
//       {viewDetail && selectedCharter && (
//         <div className="chartered-vehicle-detail">
//           <div className="wrapper">
//             <span>
//               <h3>{selectedCharter._id}</h3>
//               <button onClick={() => setViewDetail(false)}>
//                 <FaXmark style={{ fontSize: "20px" }} />
//               </button>
//             </span>
//             <div className="chartered-vehicle-detail-span">
//               <div className="card-1">
//                 <div className="wrapper">
//                   <img src={selectedCharter?.vehicle?.images[0]} alt="" />
//                   <div className="inner">
//                     <h3>{selectedCharter?.vehicle?.vehicleModel}</h3>
//                     <h4>
//                       {selectedCharter?.totalAmount
//                         ? selectedCharter?.totalAmount.toLocaleString("en-US", {
//                             style: "currency",
//                             currency: "NGN",
//                           })
//                         : ""}
//                     </h4>
//                     <span>
//                       <div className="box">
//                         <CiUser />
//                         <p>Max No of people: {selectedCharter?.vehicle?.numberOfSeats}</p>
//                       </div>
//                     </span>
//                     <span>
//                       <div className="box">
//                         <TbSteeringWheel />
//                         <p>Power Type: {selectedCharter?.vehicle?.powerType}</p>
//                       </div>
//                     </span>
//                     <span>
//                       <div className="box">
//                         <SeatIcon />
//                         <p>{selectedCharter?.vehicle?.numberOfSeats} seats</p>
//                       </div>
//                       <div className="box">
//                         <BsFuelPump />
//                         <p>{selectedCharter?.vehicle?.fuelTankCapacity}L</p>
//                       </div>
//                       <div className="box">
//                         <GiCarDoor />
//                         <p>{selectedCharter?.vehicle?.doors} doors</p>
//                       </div>
//                     </span>
//                   </div>
//                 </div>
//                 <div className="description-detail">
//                   <h3>DESCRIPTION</h3>
//                   <p>{selectedCharter?.vehicle?.description}</p>
//                 </div>
//                 <button onClick={handleMarkAsUnBooked} disabled={isMarkingAsUnBooked}>
//                   {isMarkingAsUnBooked ? "Canceling..." : "Cancel Order"}
//                 </button>
//               </div>
//               <ToastContainer position="top-right" className="toast" />

//               <div className="card-2">
//                 <div className="wrapper">
//                   <h3>Passenger Details</h3>
//                   <span>
//                     <p>Full Name:</p>
//                     <p>{selectedCharter?.passengerDetails?.fullName}</p>
//                   </span>
//                   <span>
//                     <p>Email:</p>
//                     <p>{selectedCharter.passengerDetails?.email}</p>
//                   </span>
//                   <span>
//                     <p>Phone Number:</p>
//                     <p>{selectedCharter.passengerDetails?.phoneNumber}</p>
//                   </span>
//                   <span>
//                     <p>Address:</p>
//                     <h4>{selectedCharter.passengerDetails?.address}</h4>
//                   </span>
//                   <span>
//                     <p>Payment Method:</p>
//                     <p>{selectedCharter.paymentMethod}</p>
//                   </span>
//                   <span>
//                     <p>ID Type:</p>
//                     <p>{selectedCharter.customerIdType}</p>
//                   </span>
//                   <span>
//                     <p>ID Number:</p>
//                     <p>{selectedCharter.customerIdNumber}</p>
//                   </span>
//                 </div>
//                 <div className="wrapper">
//                   <h3>Next of Kin Details</h3>
//                   <span>
//                     <p>Full Name:</p>
//                     <p>{selectedCharter.passengerDetails?.nextOfKin?.fullName}</p>
//                   </span>
//                   <span>
//                     <p>Phone Number:</p>
//                     <p>{selectedCharter.passengerDetails?.nextOfKin?.phoneNumber}</p>
//                   </span>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       )}
//       <div className="charters">
//         <div className="wrapper">
//           <h3>Charters - New Order</h3>
//           <form onSubmit={(e) => e.preventDefault()}>
//             <button type="submit">
//               <CiSearch />
//             </button>
//             <input
//               type="text"
//               placeholder="Search New Order by Id"
//               value={searchQuery}
//               onChange={(e) => setSearchQuery(e.target.value)}
//             />
//           </form>
//         </div>
//         <div className="charter-mothers">
//           <table>
//             <thead>
//               <tr>
//                 <th>Hired ID</th>
//                 <th>Hire Location</th>
//                 <th>Vehicle Category</th>
//                 <th>Vehicle Model</th>
//                 <th>Hire From</th>
//                 <th>Hire To</th>
//                 <th>Amount</th>
//                 <th>Status</th>
//                 <th>Details</th>
//               </tr>
//             </thead>
//             <tbody>
//               {isLoading ? (
//                 Array.from({ length: 10 }).map((_, index) => (
//                   <tr key={index}>
//                     <td><Skeleton /></td>
//                     <td><Skeleton /></td>
//                     <td><Skeleton /></td>
//                     <td><Skeleton /></td>
//                     <td><Skeleton /></td>
//                     <td><Skeleton /></td>
//                     <td><Skeleton /></td>
//                     <td><Skeleton /></td>
//                     <td><Skeleton /></td>
//                   </tr>
//                 ))
//               ) : isError ? (
//                 <tr>
//                   <td colSpan="9">
//                     <p style={{ textAlign: "center" }}>No vehicle order available</p>
//                   </td>
//                 </tr>
//               ) : (
//                 filteredData &&
//                 filteredData.map((charter) => (
//                   <tr key={charter._id}>
//                     <td>{charter._id.slice(0, 5).toUpperCase()}</td>
//                     <td>{clampWords(charter.pickupLocation, 4)}</td>
//                     <td>{charter?.vehicle?.vehicleType}</td>
//                     <td>{charter?.vehicle?.vehicleModel}</td>
//                     <td>
//                       {new Intl.DateTimeFormat("en-US", {
//                         timeZone: "Africa/Lagos",
//                       }).format(new Date(charter.startDate))}
//                     </td>
//                     <td>
//                       {new Intl.DateTimeFormat("en-US", {
//                         timeZone: "Africa/Lagos",
//                       }).format(new Date(charter.endDate))}
//                     </td>
//                     <td>
//                       {charter.totalAmount
//                         ? charter.totalAmount.toLocaleString("en-US", {
//                             style: "currency",
//                             currency: "NGN",
//                           })
//                         : ""}
//                     </td>
//                     <td>
//                       <span>{charter.requestingHiring ? "New Order" : "Completed"}</span>
//                     </td>
//                     <td>
//                       <button onClick={() => handleViewDetail(charter)}>View</button>
//                     </td>
//                   </tr>
//                 ))
//               )}
//             </tbody>
//           </table>
//         </div>
//         <div className="pagination">
//           <span>{`Showing ${startIndex} - ${endIndex} of ${totalItems}`}</span>
//           <div className="btn">
//             <button onClick={handlePrevPage} disabled={currentPage === 1}>
//               Prev
//             </button>
//             <button onClick={handleNextPage} disabled={currentPage === data?.totalPages}>
//               Next
//             </button>
//           </div>
//         </div>
//       </div>
//     </Fragment>
//   );
// };

// export default Charters;

