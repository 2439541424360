// import { useQuery } from 'react-query';
// import { useContext } from 'react';
// import { AuthContext } from '../../Context/AuthContext';

// const useGetMarketplaceManagers = () => {
//   const { token } = useContext(AuthContext);

//   const getMarketplaceManagers = async () => {
//     const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/users/marketplaceManagers`, {
//       method: 'GET',
//       headers: {
//         Authorization: `Bearer ${token}`,
//       },
//     });

//     if (!response.ok) {
//       throw new Error('Failed to fetch marketplace managers');
//     }

//     return response.json();
//   };

//   return useQuery('marketplaceManagers', getMarketplaceManagers);
// };

// export default useGetMarketplaceManagers;

import { useInfiniteQuery } from 'react-query';
import { useContext } from 'react';
import { AuthContext } from "../../Context/AuthContext";

const getMarketplaceManagers = async ({ pageParam = 1 }, token) => {
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/users/marketplaceManagers?page=${pageParam}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error('Failed to fetch marketplace managers');
  }

  return response.json(); 
};

const useGetMarketplaceManagers = () => {
  const { token } = useContext(AuthContext);

  return useInfiniteQuery(
    ['marketplaceManagers'], 
    ({ pageParam = 1 }) => getMarketplaceManagers({ pageParam }, token),
    {
      getNextPageParam: (lastPage) => {
        const { currentPage, totalMarketplaceManagers } = lastPage;
        const totalPages = Math.ceil(totalMarketplaceManagers / 10); 
        return currentPage < totalPages ? currentPage + 1 : undefined;
      },
    }
  );
};

export default useGetMarketplaceManagers;



