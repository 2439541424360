import { Fragment, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import UserDataFetcher from "../../../../libs/getUserbyUserId";
import useBusinessMerchant from "../lib/fetchBusinessMerchant";
import BusinessInfoForm from "./BusinessInfoForm";
import ChangePasswordForm from "./MerchantSecurity";
import { AuthContext } from "../../../../Context/AuthContext";
import Footer from "../../../../Footer/Footer";

import "./MerchantAcount.css";

const MerchantAccount = () => {
  const { Logout } = useContext(AuthContext);
  const [userInfo, setUserInfo] = useState(true);
  const [businessInfo, setBusinessInfo] = useState(false);
  const [updatebusInfo, setUpdateBusInfo] = useState(false);
  const [security, setSecurity] = useState(false);
  const navigate = useNavigate();

  const { adminData } = UserDataFetcher();
  const { data: businessData } = useBusinessMerchant();
  console.log(businessData);

  const handleUserInfo = () => {
    setUserInfo(true);
    setBusinessInfo(false);
    setSecurity(false);
    setUpdateBusInfo(false);
  };

  const handleBusinessInfo = () => {
    setUserInfo(false);
    setBusinessInfo(true);
    setSecurity(false);
    setUpdateBusInfo(false);
  };

  const handleSecurity = () => {
    setUserInfo(false);
    setBusinessInfo(false);
    setSecurity(true);
    setUpdateBusInfo(false);
  };

  const handleUpdateBusInfo = () => {
    setUpdateBusInfo(true);
    setUserInfo(false);
    setBusinessInfo(false);
    setSecurity(false);
  };

  const handleLogout = () => {
    Logout();
    navigate("/");
  };

  return (
    <Fragment>
      <div className="merchant-account">
        <h3>Account</h3>
      </div>
      <div className="merchant-account-card">
        <div className="wrapper">
          <span>
            <button onClick={handleUserInfo}>User Information</button>
            {businessData && <button onClick={handleBusinessInfo}>Business Information</button>}
            {adminData?.user && !adminData.user.isInfoVerify && (
  <button onClick={handleUpdateBusInfo}>
    Update Business Information
  </button>
)}

            <button onClick={handleSecurity}>Security</button>
          </span>

          {userInfo && (
            <div className="merchant-account-box">
              <span>
                <p>First Name</p>
                <p>{adminData?.user?.first_name}</p>
              </span>
              <span>
                <p>Last Name</p>
                <p>{adminData?.user?.last_name}</p>
              </span>
              <span>
                <p>Email</p>
                <p>{adminData?.user?.email}</p>
              </span>
              <span>
                <p>Phone Number</p>
                <p>
                  {adminData?.user?.country_code}{" "}
                  {adminData?.user?.phone_number}
                </p>
              </span>
              <span>
                <p>Address</p>
                <p>{adminData?.user?.address || "NA"}</p>
              </span>
              <div className="merchant-logout-button">
                <button onClick={handleLogout}>Logout</button>
              </div>
            </div>
          )}

          {businessInfo && (
            <div className="merchant-account-box">
              <span>
                <p>Business Name</p>
                <p>{businessData?.merchant?.businessName}</p>
              </span>
              <span>
                <p>Business Type</p>
                <p>{businessData?.merchant?.businessType}</p>
              </span>
              <span>
                <p>Business Address</p>
                <p>{businessData?.merchant?.businessAddress}</p>
              </span>
              <span>
                <p>Contact Person</p>
                <p>{businessData?.merchant?.contactPerson}</p>
              </span>
              <span>
                <p>Contact Email</p>
                <p>{businessData?.merchant?.contactEmail}</p>
              </span>
              <span>
                <p>Contact Phone Number</p>
                <p>{businessData?.merchant?.contactPhoneNumber}</p>
              </span>
              <span>
                <p>Business Description</p>
                <p>{businessData?.merchant?.businessDescription}</p>
              </span>
              <span>
                <p>Bank Account Details</p>
                <p>{businessData?.merchant?.bankAccountDetails}</p>
              </span>
              <span>
                <p>Tax Identification Number</p>
                <p>{businessData?.merchant?.taxIdentificationNumber}</p>
              </span>
              <span>
                <p>Business License Permit</p>
                <p>{businessData?.merchant?.businessLicenseOrPermit}</p>
              </span>
              <span>
                <p>Value Added Tax Registrtion</p>
                <p>{businessData?.merchant?.valueAddedTaxRegistration}</p>
              </span>
              <span>
                <p>Good & Services Registration</p>
                <p>{businessData?.merchant?.goodsAndServicesTaxRegistration}</p>
              </span>
              <span>
                <p>Business Registration Number</p>
                <p>{businessData?.merchant?.businessRegistrationNumber}</p>
              </span>
              <span>
                <p>Custom Identification Number</p>
                <p>{businessData?.merchant?.customsIdentificationNumber}</p>
              </span>
              <span>
                <p>Products Information</p>
                <p>{businessData?.merchant?.productInformation}</p>
              </span>
              <span>
                <p>Returns & Refunds Policy</p>
                <p>{businessData?.merchant?.returnsAndRefundsPolicy}</p>
              </span>
              <span>
                <p>Customer Support Information</p>
                <p>{businessData?.merchant?.customerSupportInformation}</p>
              </span>
            </div>
          )}

          {
            updatebusInfo && (
                <div className="merchant-account-box">
                    <BusinessInfoForm/>
                </div>
            )
          }

          {security && <div className="merchant-account-box">
            <ChangePasswordForm/>
            </div>}
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

export default MerchantAccount;
