import React, { Fragment,useState } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as SearchIcon } from "../../asset/iconoir_search.svg";
import useTotalPackagesById from "../libs/fetchTotalPackagesById";
import useTotalActivePackagesById from "../libs/fetchTotalActivePackagesById";
import useRecentPackagesById from "../libs/fetchRecentPackagesById";
import usePreshipmentFetchShipmentByUser from "../libs/fetchPreShipments";
import useTrackPackage from "../libs/useTrackPackage";
import getRidersByBranch from "../libs/getRidersByBranch";
import Spinner from "../../utils/spinner";
import "./Logisticsoverview.css";



function Logisticoverview() {
  const { data: totalPackages, isLoading, isError } = useTotalPackagesById();
  const {
    data: totalActivePackages,
    isLoading: isLoadings,
    isError: isErrors,
  } = useTotalActivePackagesById();

  const {
    data: recentPackages,
    isLoading: recentPackagesLoading,
    isError: recentPackagesError,
  } = useRecentPackagesById();

  const {
    ridersData,
    isLoading: riderLoading,
    isError: riderError,
  } = getRidersByBranch();

  const [trackingNumber, setTrackingNumber] = useState("");
  const {
    data: trackingInfo,
    isLoading: trackingLoading,
    isError: trackingError,
  } = useTrackPackage(trackingNumber);
  console.log(trackingInfo);

  const { data: shipment } = usePreshipmentFetchShipmentByUser();

  const [trackingOverlay, ssetTrackingOverlay] = useState(false);

  return (
    <Fragment>
      {trackingOverlay && (
        <div className={`tracking-card ${trackingLoading ? "loading" : ""}`}>
          <div className="card">
            {trackingLoading ? (
              <Spinner />
            ) : trackingError ? (
              <div className="track-error">
                <p>Shipment not found.</p>
                <button onClick={() => ssetTrackingOverlay(false)}>
                  Close
                </button>
              </div>
            ) : (
              <Fragment>
                <span>
                <h1>Track shipment</h1>
                <button onClick={() => {
            setTrackingNumber("")
            ssetTrackingOverlay(false)
          }}>close</button>
                </span>
                <div className="del">
                  <p>Delivery Address</p>
                  <div className="in">
                    <p>from</p>
                    <span>
                      {trackingInfo?.sender.state},{" "}
                      {trackingInfo?.sender.address_1}
                    </span>
                  </div>
                  <div className="in">
                    <p>to</p>
                    <span>
                      {trackingInfo?.receiver.state},{" "}
                      {trackingInfo?.receiver.address_1}
                    </span>
                  </div>
                </div>
                <div className="tracking-his">
                  <div className="way">
                    <div className="in">
                      <p>Waybill Number</p>
                      <span>{trackingInfo?.waybill?.waybillNumber}</span>
                    </div>
                    <div className="in">
                      <p>Estimated Delivery Date</p>
                      <span>
                        {trackingInfo?.estimatedDeliveryDate
                          ? new Date(
                              trackingInfo?.estimatedDeliveryDate
                            ).toLocaleDateString()
                          : "N/A"}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="status">
                  <p>Tracking History</p>
                  {trackingInfo?.statusHistory.map((status, index) => (
                    <div key={index} className="inner">
                      <div className="in">
                        <div className="up">
                        <span> {status.status}</span>
                        {status?.state && <p> {status?.state} {status?.city}</p>}
                        </div>
                        <p>
                         
                          {new Date(status.createdAt).toLocaleDateString()}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </Fragment>
            )}
          </div>
         
        </div>
      )}

      <div className="logistic-admin-overview">
        <div className="header">
          <p>Overview</p>

          <span>
            <input
              type="text"
              placeholder="Enter tracking number"
              value={trackingNumber}
              onChange={(e) => setTrackingNumber(e.target.value)}
              required
            />

            <div
              className="rd"
              onClick={() => trackingNumber && ssetTrackingOverlay(true)}
            >
              <SearchIcon />
            </div>
          </span>
        </div>
        <div className="box-card">
          <div className="box">
            <p>Total Package</p>
            {isLoading ? (
              <Spinner />
            ) : isError ? (
              <p>Error fetching total packages</p>
            ) : (
              <p>{totalPackages.totalPackages}</p>
            )}
          </div>
          <div className="box">
            <p>Total Active Package</p>
            {isLoadings ? (
              <Spinner />
            ) : isErrors ? (
              <p>Error fetching total active packages</p>
            ) : (
              <p>{totalActivePackages?.totalActivePackages}</p>
            )}
          </div>
          <div className="box">
            <p>Pick Up Orders</p>
            <p>{shipment?.totalShipments}</p>
          </div>
          <div className="box">
            <p> Riders</p>
            {riderLoading ? (
              <Spinner />
            ) : riderError ? (
              <p>Error fetching total rider</p>
            ) : (
              <p>{ridersData?.totalRiders}</p>
            )}
          </div>
        </div>
        <span>
          <h2>Recent Packages</h2>
        </span>
        <div className="recent-package-table">
          {recentPackagesLoading ? (
            <Spinner />
          ) : recentPackagesError ? (
            <p>Error fetching recent packages</p>
          ) : (
            <table>
              <thead>
                <tr>
                  <th>Tracking Number</th>
                  <th>Sender Name</th>
                  <th>Receiver Name</th>
                  <th>Item Name</th>
                  <th>Quantity</th>
                  <th>Shipping Cost</th>
                  <th>Estimated Delivery Date</th>
                  <th>Details</th>
                </tr>
              </thead>
              <tbody>
                {recentPackages.recentPackages.map((pkg, index) => (
                  <tr key={index}>
                    <td>{pkg.tracking_number}</td>
                    <td>{pkg.sender.name}</td>
                    <td>{pkg.receiver.name}</td>
                    <td>{pkg.name}</td>
                    <td>{pkg.quantity}</td>
                    <td>
                      {pkg.shipping_cost
                        ? (pkg.shipping_cost / 100).toLocaleString("en-US", {
                            style: "currency",
                            currency: "NGN",
                          })
                        : ""}
                    </td>

                    <td>
                      {pkg.estimated_delivery_date
                        ? new Date(
                            pkg.estimated_delivery_date
                          ).toLocaleDateString("en-NG", {
                            weekday: "long",
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                          })
                        : ""}
                    </td>
                    <td>
                      <Link to={`/details/${pkg._id}`}>View Details</Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </Fragment>
  );
}

export default Logisticoverview;
