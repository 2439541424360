import React, { useState } from "react";
import { Fragment } from "react";
import MarketNavbar from "../Marketplace/MarketNav/MarketNavbar";
import { FaBarcode } from "react-icons/fa";
import { GiBrokenPottery } from "react-icons/gi";
import { GiUnderwearShorts } from "react-icons/gi";
import { FaBoxOpen } from "react-icons/fa";
import { GiSuperMushroom } from "react-icons/gi";
import { GiTreeFace } from "react-icons/gi";
import { GiBookmarklet } from "react-icons/gi";
import "./RetunPolicy.css";

const Returnpolicy = () => {
  const [instruction, setInstruction] = useState(true);
  const [eligible, setEligible] = useState(false);
  const [faq, setFaq] = useState(false);
  const [requirement, setRequirement] = useState(false);

  const handleInstruction = () => {
    setInstruction(true);
    setEligible(false);
    setFaq(false);
    setRequirement(false);
  };

  const handleEligible = () => {
    setInstruction(false);
    setEligible(true);
    setFaq(false);
    setRequirement(false);
  };

  const handleFaq = () => {
    setInstruction(false);
    setEligible(false);
    setFaq(true);
    setRequirement(false);
  };

  const handleRequirement = () => {
    setInstruction(false);
    setEligible(false);
    setFaq(false);
    setRequirement(true);
  };

  return (
    <Fragment>
      <MarketNavbar />
      <div className="return-policy">
        <h3>RETURN AND REFUNDS POLICY</h3>
        <p>
          At Smart Trans Express (STEX) , we are committed to ensuring your
          satisfaction with every purchase you make on our platform. We
          understand that sometimes, despite our best efforts, situations may
          arise where you need to return or exchange a product. That's why we
          have formulated a comprehensive Return and Refund Policy designed to
          provide clarity, convenience, and peace of mind to our valued
          customers. Our policy outlines the procedures and guidelines for
          returning or exchanging products purchased through our e-commerce
          platform. We strive to make the process as seamless and hassle-free as
          possible, ensuring that you can shop with confidence knowing that your
          satisfaction is our priority.
        </p>

        <div className="return-mother">
          <div className="switch">
            <button onClick={handleInstruction}>Return Instruction</button>
            <button onClick={handleEligible}>
              Non Eligible Items for Return
            </button>
            <button onClick={handleFaq}>
              Frequently Asked Questions(FAQs)
            </button>
            <button onClick={handleRequirement}>Return Requirement</button>
          </div>
          <div className="return-card">
            {instruction && (
              <div className="return-info">
                <div className="wrapper">
                  <span>
                    <h3>STEP 1:</h3>
                    <h4>Initiate Return Request:</h4>
                    <p>
                      Log in to your STEX account,Navigate to the "My Orders"
                      section or the specific order you wish to return and
                      Select the item(s) you want to return and click on the
                      "Return" or "Request Return" option.
                    </p>
                  </span>

                  <span>
                    <h3>STEP 2</h3>
                    <h4>Provide Reason for Return:</h4>
                    <p>
                      Indicate the reason for the return from the provided
                      options (e.g., wrong item received, defective product,
                      changed mind). If applicable, provide additional details
                      or comments regarding the reason for return to assist us
                      in processing your request efficiently.
                    </p>
                  </span>
                  <span>
                    <h3>STEP 3</h3>
                    <h4>Choose Return Method</h4>
                    <p>
                      Select your preferred return method (e.g., self-return,
                      courier pickup) based on the options available in your
                      location and the nature of the product being returned. If
                      opting for a courier pickup, provide your address and
                      convenient pickup timing for scheduling.
                    </p>
                  </span>
                  <span>
                    <h3>STEP 4</h3>
                    <h4>Prepare Return Package:</h4>
                    <p>
                      Carefully package the item(s) you wish to return, ensuring
                      they are securely wrapped and adequately protected to
                      prevent damage during transit Include all original
                      packaging, accessories, and documentation that came with
                      the product(s), if applicable.
                    </p>
                  </span>
                  <span>
                    <h3>STEP 5</h3>
                    <h4>Generate Return Label (if applicable):</h4>
                    <p>
                      If a return label is provided by STEX , print it out and
                      affix it securely to the return package. Ensure all
                      necessary information, including the return address and
                      tracking number, is clearly visible on the label.
                    </p>
                  </span>
                </div>
                <div className="wrapper">
                  <span>
                    <h3>STEP 6</h3>
                    <h4>Schedule Return Pickup:</h4>
                    <p>
                      Take it to nearest STEX pickup station or schedule a
                      convenient pickup time with the designated carrier. Ensure
                      someone is available to hand over the return package to
                      the courier during the scheduled pickup window.
                    </p>
                  </span>
                  <span>
                    <h3>STEP 7</h3>
                    <h4>Track Return Status:</h4>
                    <p>
                      After initiating the return request and dispatching the
                      return package, monitor its progress using the provided
                      tracking information. You can track the return status
                      through STEX's website.
                    </p>
                  </span>
                  <span>
                    <h3>STEP 8</h3>
                    <h4>Receive Refund or Exchange:</h4>
                    <p>
                      Once the returned item(s) are received and inspected by
                      STEX , we will process your refund or exchange based on
                      the return policy guidelines. Refunds will be issued to
                      the original payment method used for the purchase, while
                      exchanges will be processed promptly, subject to product
                      availability.
                    </p>
                  </span>
                  <span>
                    <h3>STEP 9</h3>
                    <h4>Confirmation Notification:</h4>
                    <p>
                      Upon completion of the return process, you will receive a
                      confirmation notification via email or SMS, confirming the
                      refund amount or the status of the exchange.
                    </p>
                  </span>
                  <span>
                    <h3>STEP 10</h3>
                    <h4>Contact Customer Support (if needed):</h4>
                    <p>
                      If you encounter any difficulties or require assistance at
                      any stage of the return process, feel free to reach out to
                      our dedicated customer support team for prompt assistance
                      and resolution. At Smart Trans Express (STEX), we are
                      committed to ensuring a hassle-free and transparent return
                      experience for our customers.
                    </p>
                  </span>
                </div>
              </div>
            )}
          </div>
          <div className="return-card">
            {eligible && (
              <div className="non-eleigible">
                <h3>Non Eligible Items for Return</h3>
                <div className="card">
                  <div className="wrapper">
                    <span>
                      <FaBoxOpen
                        style={{ fontSize: "35px", color: "#1f4798" }}
                      />
                      <p>
                        Products that have been altered from their original or
                        opened by an authorized personnel without permission.
                      </p>
                    </span>
                    <span>
                      <GiSuperMushroom
                        style={{ fontSize: "35px", color: "#1f4798" }}
                      />
                      <p>
                        Perishable goods cannot be returned except a valid
                        reason is raised at the point of delivery with
                        affirmation from the dispatcher.
                      </p>
                    </span>
                    <span>
                      <GiTreeFace
                        style={{ fontSize: "35px", color: "#1f4798" }}
                      />
                      <p>
                        Products in beauty, health and personal care category.
                      </p>
                    </span>
                    <span>
                      <GiBookmarklet
                        style={{ fontSize: "35px", color: "#1f4798" }}
                      />
                      <p>Books and CDs</p>
                    </span>
                  </div>
                  <div className="wrapper">
                    <span>
                      <FaBarcode
                        style={{ fontSize: "35px", color: "#1f4798" }}
                      />
                      <p>
                        Product with tampered or missing serial Universal
                        Product Code numbers (UPC).
                      </p>
                    </span>
                    <span>
                      <GiBrokenPottery
                        style={{ fontSize: "35px", color: "#1f4798" }}
                      />
                      <p>Products damaged due to misuse.</p>
                    </span>
                    <span>
                      <GiUnderwearShorts
                        style={{ fontSize: "35px", color: "#1f4798" }}
                      />
                      <p>
                        Jewellery, innerwear, bed sheets, lingerie and socks.
                      </p>
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="return-card">
            {faq && (
              <div className="return-faq">
                <h3>Frequently Asked Questions (FAQs)</h3>
                <div className="card">
                  <div className="wrapper">
                    <span>
                      <h3>What is your return policy?</h3>
                      <p>
                        Our return policy allows customers to return eligible
                        items within a specified timeframe for a refund or
                        exchange. Please refer to our Return Policy page for
                        detailed information.
                      </p>
                    </span>
                    <span>
                      <h3>How long do I have to return an item?</h3>
                      <p>
                        Typically, you have 14days from the date of delivery to
                        initiate a return request.
                      </p>
                    </span>
                    <span>
                      <h3>Can I return an item if I've changed my mind?</h3>
                      <p>
                        Yes, we accept returns for items if you've changed your
                        mind. However, please ensure the item is in its original
                        condition and packaging for a full refund.
                      </p>
                    </span>
                    <span>
                      <h3>Is there a restocking fee for returns?</h3>
                      <p>
                        We do not charge a restocking fee for returns, unless
                        otherwise stated for specific products.
                      </p>
                    </span>
                    <span>
                      <h3>
                        Do I need to return the item in its original packaging?
                      </h3>
                      <p>
                        Yes, we recommend returning the item in its original
                        packaging to ensure safe transit and processing of your
                        return.
                      </p>
                    </span>
                    <span>
                      <h3>What if my item arrives damaged or defective?</h3>
                      <p>
                        If your item arrives damaged or defective, please
                        contact our customer support team immediately for
                        assistance and we will arrange for a replacement or
                        refund.
                      </p>
                    </span>
                    <span>
                      <h3>How do I initiate a return?</h3>
                      <p>
                        You can initiate a return by logging into your account,
                        accessing the order history, and selecting the item(s)
                        you wish to return. Follow the prompts to complete the
                        return request.
                      </p>
                    </span>
                    <span>
                      <h3>
                        Can I return an item without a return authorization?
                      </h3>
                      <p>
                        We encourage customers to obtain a return authorization
                        before returning an item to ensure smooth processing.
                        However, please reach out to our customer support team
                        if you encounter any issues.
                        <a
                          href="mailto:"
                          target="blank"
                        >
                          infosupport@smarttransexpress.com
                        </a>
                      </p>
                    </span>
                    <span>
                      <h3>
                        Is there a time limit for initiating a return request?
                      </h3>
                      <p>
                        Yes, we recommend initiating a return request within 7
                        days from the date of delivery for prompt processing.
                      </p>
                    </span>
                    <span>
                      <h3>What are my options for returning an item?</h3>
                      <p>
                        You can return an item via self-return or request a
                        courier pickup, depending on the options available in
                        your location and the nature of the product.
                      </p>
                    </span>
                  </div>
                  <div className="wrapper">
                    <span>
                      <h3>Do I have to pay for return shipping?</h3>
                      <p>
                        Return shipping costs may vary depending on the reason
                        for return. Please refer to our Return Policy for
                        details on return shipping fees.
                      </p>
                    </span>
                    <span>
                      <h3>Can I exchange an item instead of returning it?</h3>
                      <p>
                        Yes, we offer exchanges for eligible items. You can
                        indicate your preference for an exchange when initiating
                        the return request.
                      </p>
                    </span>
                    <span>
                      <h3>How long does it take to process a return?</h3>
                      <p>
                        Once we receive your returned item, it typically takes
                        10-14 business days to process the return and issue a
                        refund or exchange.
                      </p>
                    </span>
                    <span>
                      <h3>When will I receive my refund?</h3>
                      <p>
                        Yes, you can return items purchased with a discount or
                        promotion. The refund amount will reflect the discounted
                        price paid for the item.
                      </p>
                    </span>
                    <span>
                      <h3>What if I received the wrong item?</h3>
                      <p>
                        If you received the wrong item, please contact our
                        customer support team immediately for assistance. We
                        will arrange for the correct item to be sent to you or
                        provide a refund.
                        <a
                          href="mailto:"
                          target="blank"
                        >
                          infosupport@smarttransexpress.com
                        </a>
                      </p>
                    </span>
                    <span>
                      <h3>Do you offer returns for international orders?</h3>
                      <p>
                        Yes, we offer returns for international orders. However,
                        please note that return shipping fees and customs duties
                        may apply.
                      </p>
                    </span>
                    <span>
                      <h3>
                        What if I no longer have the original packaging or
                        receipt?
                      </h3>
                      <p>
                        While having the original packaging and receipt
                        facilitates the return process, please contact our
                        customer support team for assistance if you no longer
                        have them.
                        <a
                          href="mailto:"
                          target="blank"
                        >
                          infosupport@smarttransexpress.com
                        </a>
                      </p>
                    </span>
                    <span>
                      <h3>
                        Can I return if my item is outside the warranty period?
                      </h3>
                      <p>
                        No, you will be required to refer the item to the
                        service centre. Kindly
                        <a
                          href="mailto:"
                          target="blank"
                        >
                          infosupport@smarttransexpress.com
                        </a>
                      </p>
                    </span>
                    <span>
                      <h3>How can I track the status of my return?</h3>
                      <p>
                        You can track the status of your return by logging into
                        your account and accessing the order history.
                        Alternatively, you can contact our customer support team
                        for updates on your return status.
                      </p>
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="return-card">
            {requirement && (
              <div className="return-requirement">
                <h3>Requirements for Returns</h3>
                <div className="card">
                  <table>
                    <tr>
                      <th>REASONS FOR RETURN</th>
                      <th>DESCRIPTION</th>
                      <th>TAGS/LABELS ATTACHED</th>
                      <th>ORIGINAL PACKAGING</th>
                      <th>COMPLETE ACCESSORIES & FREE GIFTS</th>
                      <th>NEW CONDITION</th>
                      <th>NOT DAMAGED</th>
                    </tr>

                    <tr>
                      <td>Changed mind request</td>
                      <td>
                        Product unused, (this applies to products in fashion
                        category only).
                      </td>
                      <td>ok</td>
                      <td>ok</td>
                      <td>ok</td>
                      <td>ok</td>
                      <td>yes</td>
                    </tr>
                    <tr>
                      <td>Wrong Items</td>
                      <td>
                        Products delivered differently from what was displayed
                        on the website, the return will be authorized after
                        validation and once the item is returned, item cost and
                        shipping fee will be refunded.
                      </td>
                      <td>ok</td>
                      <td>ok</td>
                      <td>ok</td>
                      <td>ok</td>
                      <td>yes</td>
                    </tr>
                    <tr>
                      <td>Incomplete Item</td>
                      <td>
                        Product delivered in partial from what was displayed on
                        the website. A return will be authorized after
                        validation if the item cannot be completed. Once the
                        item is returned, the item cost and shipping fee will be
                        refunded.
                      </td>
                      <td>no</td>
                      <td>ok</td>
                      <td>ok</td>
                      <td>ok</td>
                      <td>yes</td>
                    </tr>
                    <tr>
                      <td>Defective Items</td>
                      <td>
                        Product delivered has manufacturers defects was
                        delivered dead on arrival, return will be authorized
                        after validation.
                      </td>
                      <td>no</td>
                      <td>ok</td>
                      <td>ok</td>
                      <td>ok</td>
                      <td>yes</td>
                    </tr>
                    <tr>
                      <td>Damaged (in transit)</td>
                      <td>
                        Product has visible damage, return will be authorized
                        after validation. Complaint must be laid within 24
                        hours.
                      </td>
                      <td>ok</td>
                      <td>ok</td>
                      <td>ok</td>
                      <td>ok</td>
                      <td>yes</td>
                    </tr>
                    <tr>
                      <td>Product Standard</td>
                      <td>
                        Product delivered not working up to specification,
                        cannot perform specified functions, return will be
                        authorized after validation.
                      </td>
                      <td>ok</td>
                      <td>ok</td>
                      <td>ok</td>
                      <td>ok</td>
                      <td>yes</td>
                    </tr>
                  </table>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Returnpolicy;
