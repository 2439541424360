import { useMutation, useQueryClient } from 'react-query';
import { useContext } from 'react';
import { AuthContext } from '../../../../Context/AuthContext';


const useEditVehicle = () => {
  const queryClient = useQueryClient();
  const { token } = useContext(AuthContext);

  const editVehicle = useMutation(
    async ({ id, formData }) => { 
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/charterer/vehicles/${id}`, {
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData), 
      });

      if (!response.ok) {
        throw new Error('Failed to Edit vehicle');
      }

      return response.json();
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('charteredVehicle');
        queryClient.invalidateQueries('vehiclesDetails');
      },
    }
  );

  return {
    editVehicle,
    isLoading: editVehicle.isLoading,
  };
};

export default useEditVehicle;
