import React, { useState, useRef, useCallback } from "react";
import useActiveDispatches from "../libs/useActiveDispatches";
import Spinner from "../../utils/spinner";
import { ReactComponent as Searchicon } from "../../asset/iconamoon_search-light.svg";

function LogisticsActiveDispatches() {
  const { data, isLoading, isError, fetchNextPage, hasNextPage, isFetchingNextPage } = useActiveDispatches();
  const [searchQuery, setSearchQuery] = useState('');

  const filteredDispatches = data?.pages.flatMap(page => 
    page.dispatches.filter(dispatch => 
      dispatch.packages[0].tracking_number.toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  const observerRef = useRef();
  const lastProductElementRef = useCallback(
    node => {
      if (isFetchingNextPage) return;

      if (observerRef.current) observerRef.current.disconnect();

      observerRef.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && hasNextPage) {
          fetchNextPage();
        }
      }, { threshold: 1.0 });

      if (node) observerRef.current.observe(node);
    },
    [isFetchingNextPage, fetchNextPage, hasNextPage]
  );

  return (
    <div className='logistic-active-dispatch'>
      <div className="header">
        <p>Dispatch</p>
        <span>
          <Searchicon />
          <input
            type="search"
            placeholder="Search by Tracking Number"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </span>
      </div>
      <div className="dispatch-table">
        {isLoading ? (
          <Spinner />
        ) : isError ? (
          <p style={{fontFamily:'Montserrat'}}>No dispatch found</p>
        ) : (
          <table>
            <thead>
              <tr>
                <th>Tracking ID</th>
                <th>Items Name</th>
                <th>Status</th>
                <th>Location</th>
                <th>Estimated Delivery Date</th>
                <th>Dispatch Status</th>
                <th>Driver</th>
                <th>Rider</th>
              </tr>
            </thead>
            <tbody>
              {filteredDispatches.map((dispatch, index) => {
                const latestStatus = dispatch.packages[0].status.slice(-1)[0]; 
                const isLastItem = filteredDispatches.length === index + 1;
                return (
                  <tr
                    key={index}
                    ref={isLastItem ? lastProductElementRef : null}
                  >
                    <td>{dispatch.packages[0]?.tracking_number}</td>
                    <td>{dispatch.packages[0]?.name}</td>
                    <td>{latestStatus.status}</td>
                    <td>{latestStatus.city ? `${latestStatus.city}, ${latestStatus.state}` : 'N/A'}</td>
                    <td>{new Date(dispatch.packages[0]?.estimated_delivery_date).toLocaleDateString()}</td>
                    <td>{dispatch.isAssignedToDriver ? 'Assigned' : 'Not Assigned'}</td>
                    <td>{dispatch.assignedDrivers.length > 0 ? dispatch.assignedDrivers[0].name : 'NA'}</td>
                    <td>{dispatch.assignedRiders.length > 0 ? dispatch.assignedRiders[0].name : 'NA'}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
      {isFetchingNextPage && (
        <div className="loading-more">
          <Spinner />
        </div>
      )}
    </div>
  );
}

export default LogisticsActiveDispatches;


