import React, { useState, useContext, Fragment } from "react";
import { Link } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import { ReactComponent as CloudLogo } from "../../../../Assets/bx_cloud-upload@3x.svg";
import useProductCategories from "../lib/fetchProductCategory";
import { AuthContext } from "../../../../Context/AuthContext";
import "./MerchantProduct.css";

const defaultForm = {
  name: "",
  category: "",
  color: "",
  description: "",
  brand: "",
  sizeSmall: "",
  sizeMedium: "",
  sizeLarge: "",
  sizeSmallextraLarge: "",
  sizeXXLarge: "",
  sizeXXXLarge: "",
  quantity:""
};

const AddProduct = () => {
  const [formField, setField] = useState(defaultForm);
  const [images, setImages] = useState([]);
  const {
    category,
    color,
    name,
    description,
    brand,
    sizeLarge,
    sizeMedium,
    sizeSmall,
    sizeSmallextraLarge,
    sizeXXLarge,
    sizeXXXLarge,
    quantity,
  } = formField;
  const { data: productCategories } = useProductCategories();
  const { user, token } = useContext(AuthContext);
  const [error, setError] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  const [msg, setMsg] = useState(false);
  const [overlay, setOverlay] = useState(false);
  const [formattedPrice, setFormattedPrice] = useState('0.00');

  const handleChange = (e) => {
    const { name, value } = e.target;
    const newValue = name === 'quantity' ? parseFloat(value) : value;
    setField({ ...formField, [name]: newValue });
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    setImages(files);
  };

  const handleResetImages = () => {
    setImages([]);
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setIsloading(true);

    const formData = new FormData();
    formData.append("name", formField.name);
    formData.append("category", formField.category);
    formData.append("color", formField.color);
    formData.append("quantity", formField.quantity);
    const numericPrice = parseFloat(formattedPrice.replace(/[^0-9.-]+/g,""));
    formData.append("price", numericPrice);
    formData.append("description", formField.description);
    formData.append("brand", formField.brand);
    formData.append("sizeLarge", formField.sizeLarge);
    formData.append("sizeMedium", formField.sizeMedium);
    formData.append("sizeXXLarge", formField.sizeXXLarge);
    formData.append("sizeXXXLarge", formField.sizeXXXLarge);
    formData.append("sizeSmall", formField.sizeSmall);
    formData.append("sizeSmallextraLarge", formField.sizeSmallextraLarge);
    formData.append("creator", user.userId);

    for (let i = 0; i < images.length; i++) {
      formData.append(`images`, images[i]);
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/product/create/product`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      );

      const resData = await response.json();

      if (!response.ok) {
        throw new Error(resData.message);
      }
      setField(defaultForm);
      setImages([]);
      setIsloading(false);
      setMsg(true);
      setOverlay(true);
    } catch (error) {
      setError(error.message);
      setIsloading(false);
      // setOverlay(true)
    }
  };

  

  const handlePriceChange = (event) => {
    let inputValue = event.target.value.replace(/[^\d]/g, '');
    inputValue = Number(inputValue) / 100;

    const formattedValue = inputValue.toLocaleString('en-US', {
      style: 'currency',
      currency: 'NGN', 
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    setFormattedPrice(formattedValue);
  };


  return (
    <Fragment>
      {overlay && (
        <div className="product-merchant-overlay">
          <div className="card">
            {msg && <p>Product created successfully!</p>}
            <div className="btn">
              <button onClick={() => setOverlay(false)}>Okay</button>
            </div>
          </div>
        </div>
      )}
      {/* {overlay && (
        <div className="product-merchant-overlay">
          <div className="error-card">
            <div className="error-tag">
              <h3>An Error Occured</h3>
            </div>
            {error && <p>{error}</p>}
            <div className="btn">
              <button onClick={() => setOverlay(false)}>Okay</button>
            </div>
          </div>
        </div>
      )} */}

      <div className="add-product-container">
        <form onSubmit={handleSubmit}>
          <div className="wrapper">
            <span>
              <Link to="/MerchantProducts">
                <FaArrowLeft />
              </Link>
              <h3>Add Product</h3>
            </span>
            {error && <p>{error}</p>}
            <button> {isLoading ? "saving..." : "save product"}</button>
          </div>
          <div className="merchant-product-image">
            <h3>PRODUCT IMAGES</h3>
            <div className="merchant-images">
              {images.length > 0 ? (
                <>
                  {images.map((image, index) => (
                    <img
                      key={index}
                      src={URL.createObjectURL(image)}
                      alt={`Product ${index}`}
                    />
                  ))}
                  <button type="button" onClick={handleResetImages}>
                    Reset Images
                  </button>
                </>
              ) : (
                <div className="merchant-image-card">
                  <label className="upload-label">
                    <CloudLogo />
                    <input
                      type="file"
                      accept="image/*"
                      multiple
                      onChange={handleImageChange}
                      style={{ display: "none" }}
                    />
                    <p>Upload images</p>
                  </label>
                </div>
              )}
            </div>
          </div>

          <div className="merchant-product-tag-detail">
            <h3>PRODUCT DETAILS</h3>
          </div>
          <div className="product-mother">
            <span>
              <label>CATEGORY</label>
              <select
                name="category"
                value={category}
                onChange={handleChange}
                required
              >
                <option value="Select">Select</option>
                {productCategories &&
                  productCategories.categories.map((category) => (
                    <option key={category._id} value={category.categoryName}>
                      {category.categoryName}
                    </option>
                  ))}
              </select>
            </span>
            <span>
              <label> NAME</label>
              <input
                type="text"
                name="name"
                placeholder="Enter Product Name"
                value={name}
                onChange={handleChange}
                required
              />
            </span>
            <span>
              
              <label>PRICE</label>
              <input
        type="text"
        value={formattedPrice}
        onChange={handlePriceChange}
      />
            </span>
          </div>
          
         <div className="product-combination">
         <span>
            <label>COLOR</label>
            <select name="color" value={color} onChange={handleChange} required>
              <option value="Select">Select</option>
              <option value="Yellow">Yellow</option>
              <option value="Red">Red</option>
              <option value="Blue">Blue</option>
              <option value="Green">Green</option>
              <option value="Orange">Orange</option>
              <option value="Purple">Purple</option>
              <option value="Pink">Pink</option>
              <option value="Brown">Brown</option>
              <option value="Cyan">Cyan</option>
              <option value="Magenta">Magenta</option>
              <option value="Lime">Lime</option>
              <option value="Teal">Teal</option>
              <option value="Turquoise">Turquoise</option>
              <option value="Silver">Silver</option>
              <option value="Gold">Gold</option>
              <option value="Indigo">Indigo</option>
              <option value="Maroon">Maroon</option>
            </select>
          </span>
          <span>
              <label>BRAND</label>
              <input
                type="text"
                name="brand"
                placeholder="Enter Brand"
                value={brand}
                onChange={handleChange}
                required
              />
            </span>
          <span>
              <label>QUANTITY</label>
              <input
                type="number"
                name="quantity"
                placeholder="Enter Quantity"
                value={quantity}
                onChange={handleChange}
                required
              />
            </span>
          </div>
          <div className="merchant-product-tag-detail">
            <h3>SIZE</h3>
          </div>
          <div className="product-mother">
            <span>
              <label>SIZE : SMALL</label>
              <input
                type="text"
                name="sizeSmall"
                placeholder="Enter Size"
                value={sizeSmall}
                onChange={handleChange}
              />
            </span>
            <span>
              <label>SIZE : MEDIUM</label>
              <input
                type="text"
                name="sizeMedium"
                placeholder="Enter Size"
                value={sizeMedium}
                onChange={handleChange}
              />
            </span>
            <span>
              <label>SIZE : LARGE</label>
              <input
                type="text"
                name="sizeLarge"
                placeholder="Enter Size"
                value={sizeLarge}
                onChange={handleChange}
              />
            </span>
          </div>
          <div className="product-mother">
            <span>
              <label>SIZE : X LARGE</label>
              <input
                type="text"
                name="sizeXXLarge"
                placeholder="Enter Size"
                value={sizeXXLarge}
                onChange={handleChange}
              />
            </span>
            <span>
              <label>SIZE : XX LARGE</label>
              <input
                type="text"
                name="sizeXXXLarge"
                placeholder="Enter Size"
                value={sizeXXXLarge}
                onChange={handleChange}
              />
            </span>
            <span>
              <label>SIZE : XXX LARGE</label>
              <input
                type="text"
                name="sizeSmallextraLarge"
                placeholder="Enter Size"
                value={sizeSmallextraLarge}
                onChange={handleChange}
              />
            </span>
          </div>
          <div className="merchant-product-description">
            <h3>PRODUCT DESCRIPTION</h3>
          </div>
          <textarea
            name="description"
            value={description}
            onChange={handleChange}
          />
        </form>
      </div>
    </Fragment>
  );
};

export default AddProduct;
