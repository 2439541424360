import axios from "axios";
import { useMutation, useQueryClient } from "react-query";
import { useContext } from "react";
import { AuthContext } from "../../Context/AuthContext";

const useSetHotDeal = () => {
  const { token } = useContext(AuthContext);
  const queryClient = useQueryClient();

  const setHotDealMutation = useMutation(
    (productId) =>
      axios.put(
        `${process.env.REACT_APP_SERVER_URL}/product/products/set-hot-deal/${productId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("products");
      },
    }
  );

  const setHotDeal = async (productId) => {
    try {
      await setHotDealMutation.mutateAsync(productId);
    } catch (error) {
      console.error("Error setting hot deal:", error);
      throw error;
    }
  };

  return { setHotDeal, isLoading: setHotDealMutation.isLoading };
};

export default useSetHotDeal;
