import { useMutation, useQueryClient } from "react-query";
import axios from "axios";
import { AuthContext } from "../../Context/AuthContext";
import { useContext } from "react";

const useUpdatePackageLeftOriginFacility = () => {
  const { token } = useContext(AuthContext);
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (data) => {
      const [packageId, updatedData] = data;
      return axios.post(
        `${process.env.REACT_APP_SERVER_URL}/updatepackages/leftoriginfacility/${packageId}`,
        updatedData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("driverAssignment");
      },
    }
  );

  return {
    mutation,
    updatePackageLeftOriginFacility: async (packageId, updatedData) => {
      try {
        const result = await mutation.mutateAsync([packageId, updatedData]);
        return result.data;
      } catch (error) {
        throw error;
      }
    },
  };
};

export default useUpdatePackageLeftOriginFacility;
