import React, { Fragment, useState } from "react";
import { ReactComponent as SearchIcon } from "../../../asset/iconamoon_search-light.svg";
// import usePackagesByBranch from "../../libs/usePackagesByBranch";
import usePackagesByBranch from "../../libs/usePackagesByBranchWith";
import { Link, useNavigate } from "react-router-dom";
import useUpdatePackageStatusToDelivered from "../../../Rider_account/Libs/useUpdatePackageStatusToDelivered";
import allStatesAndCities from "../../../utils/state_and_city";
import Spinner from "../../../utils/spinner";
import "./Logisticsortcenter.css";

function Logisticsortcenter() {
  const { data: packages, isLoading, isError } = usePackagesByBranch();
  console.log(packages)
  const { updatePackageStatusToDelivered } = useUpdatePackageStatusToDelivered();
  const [searchTerm, setSearchTerm] = useState("");
  const [packageId, setPackageId] = useState(null);
  const [updateOverlay, setUpdateOverlay] = useState(false);
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [receivedBy, setReceivedBy] = useState("");
  const [updating, setUpdating] = useState(false);
  const [errors, setErrors] = useState(null);

  const navigate = useNavigate()

  const handleUpdate = async (e) => {
    e.preventDefault();
    setUpdating(true);
    setErrors(null);

    try {
      const updateData = {
        state_id: selectedState,
        city: selectedCity,
        receivedBy: receivedBy,
      };
      await updatePackageStatusToDelivered(packageId, updateData);

      setPackageId(null);
      setUpdateOverlay(false);
      setSelectedState("");
      setSelectedCity("");
      setReceivedBy("");
    } catch (error) {
      setErrors(error.message);
    } finally {
      setUpdating(false);
    }
  };

  const filteredPackages = packages
    ? packages.filter((pkg) =>
        pkg.tracking_number.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : [];

  const getLatestStatus = (statusArray) => {
    if (statusArray && statusArray.length > 0) {
      return statusArray[statusArray.length - 1].status;
    }
    return "Unknown";
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString("en-US", options);
  };

  return (
    <Fragment key="logisticsortcenter-fragment">
      {updateOverlay && (
        <div className="updatepkg-over">
          <div className="card">
            <p>Trip Status</p>
            <form onSubmit={handleUpdate}>
              <>
                <select
                  value={selectedState}
                  onChange={(e) => {
                    setSelectedState(e.target.value);
                    setSelectedCity("");
                  }}
                >
                  <option value="">Select State</option>
                  {Object.keys(allStatesAndCities).map((state) => (
                    <option key={state} value={state}>
                      {state}
                    </option>
                  ))}
                </select>
                <select
                  value={selectedCity}
                  onChange={(e) => setSelectedCity(e.target.value)}
                >
                  <option value="">Select City</option>
                  {selectedState &&
                    allStatesAndCities[selectedState].map((city) => (
                      <option key={city} value={city}>
                        {city}
                      </option>
                    ))}
                </select>
              </>
              <input
                type="text"
                placeholder="Received by"
                value={receivedBy}
                onChange={(e) => setReceivedBy(e.target.value)}
              />
              <div className="btn">
                <button
                  onClick={() => {
                    setPackageId(null);
                    setUpdateOverlay(false);
                    setSelectedState("");
                    setSelectedCity("");
                    setReceivedBy("");
                  }}
                >
                  Cancel
                </button>
                <button disabled={updating}>
                  {updating ? "Updating..." : "Update"}
                </button>
              </div>
              {errors && <p>{errors}</p>}
            </form>
          </div>
        </div>
      )}

      <div className="sort-center-pkg">
        <div className="header">
          <div className="in">
          <p>Sort Center Arrival</p>
          <button onClick={() => navigate("/Logisticsortassigned")}>Assign</button>
          </div>
          <span>
            <SearchIcon />
            <input
              type="search"
              placeholder="Search by Tracking Number"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </span>
        </div>
        <div className="sort-center-table">
          {isLoading ? <Spinner /> : null}

          {isError ? (
            <tr>
              <p style={{fontFamily:'Montserrat', marginTop:'10px'}}>No packages found for the provided branch full address</p>
            </tr>
          ) : (
            filteredPackages && filteredPackages.length > 0 ? (
              <table>
                <thead>
                  <tr>
                    <th>Tracking ID</th>
                    <th>Receiver’s Name</th>
                    <th>Item</th>
                    <th>Status</th>
                    <th>Delivery Mthod</th>
                    <th>Est Date</th>
                    <th>AssignedToRider</th>
                    <th>Details</th>
                    <th>Mark</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredPackages.map((pkg) => (
                    <tr key={pkg.id}>
                      <td>{pkg.tracking_number}</td>
                      <td>{pkg.receiver.name}</td>
                      <td>{pkg.name}</td>
                      <td>{getLatestStatus(pkg.status)}</td>
                      <td>{pkg.delivery_method}</td>
                      <td>{formatDate(pkg.estimated_delivery_date)}</td>
                      <td>{pkg.isAssignedToRider ? "Yes" : "No"}</td>
                      <td>
                        <Link to={`/details/${pkg.id}`}>Details</Link>
                      </td>
                      <td>
                        <button
                          onClick={() => {
                            setPackageId(pkg._id);
                            setUpdateOverlay(true);
                          }}
                          disabled={getLatestStatus(pkg.status) === "Delivered"}
                        >
                          Mark as Delivered
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              isLoading ? null : <p>No packages found.</p>
            )
          )}
        </div>
      </div>
    </Fragment>
  );
}

export default Logisticsortcenter;

