
import { useMutation } from 'react-query';

const generateOneTimeToken = async (formData) => {
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/users/request-One-Time-Verification-Code`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email: formData.email,
    }),
  });

  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.message || 'Failed to generate one-time token');
  }
  return data.message; 
};

const useOneTimeToken = () => {
  return useMutation(generateOneTimeToken);
};

export default useOneTimeToken;

