
import useTrackingSupportForm from "../../libs/fetchPackageBySupport";
import { ReactComponent as SearchIcon } from "../../Assets/iconoir_search.svg";
import { useState } from "react";
import  Spinner from "../../Spinner/Spinner"
import "./TrackPackage.css";

const TrackPackage = () => {
  const [trackingNumber, setTrackingNumber] = useState("");
  const { error, trackPackage, data:packageInfo,isLoading, setError } = useTrackingSupportForm();


  const handleTrackingSubmit = async (e) => {
    e.preventDefault();
    setError(false)
    trackPackage(trackingNumber);
  };

  return (
    <div className="track-package-container">
      <div className="header">
        <p>Track Packages</p>
        <form onSubmit={handleTrackingSubmit}>
          <button type="submit">
          <SearchIcon  style={{width:'18px', height:'18px'}}/>
          </button>
          <input
            type="text"
            name="tracking_number"
            value={trackingNumber}
            onChange={(e) => setTrackingNumber(e.target.value)}
            placeholder="Enter tracking number"
            required
          />
        </form>
      </div>
      <div className="wrapper">
       
        <div style={{ color: "red", margin: ' auto', fontFamily: 'Montserrat' }}>
        {isLoading && <Spinner/>}
        </div>
        {error && (
          <div style={{ color: "red", margin: ' auto', fontFamily: 'Montserrat' }}>
            <p>{typeof error === 'string' ? error : 'An error occurred.'}</p>
          </div>
        )}
        {packageInfo  && (
          <>
            <span>
              <h3>ID {packageInfo._id}</h3>
              <p> {new Date(packageInfo?.dateCreated).toLocaleString()}</p>
            </span>
            <div className="sender-card">
             <span> <h3>SENDER'S INFO</h3></span>
              <div className="sender-card-box">
                <div className="package-span">
                <span>
                <p>Sender's Full Name</p>
                <h3> {packageInfo.sender.name}</h3>
                </span>
                <span>
                  <p>Home Address</p>
                  <h3>{packageInfo.sender.address_1}</h3>
                </span>
                <span>
                  <p>State</p>
                <h3>{packageInfo.sender.state}</h3>
                </span>
                </div>
                <div className="package-span">
                <span>
                  <p>Other Name</p>
                  <h3> {packageInfo.sender.contact_name}</h3>
                </span>
               <span>
                <p>Other Address</p>
                <h3>{packageInfo.sender.address_2}</h3>
               </span>
                <span>
                  <p>Zip Code</p>
                  <h3>{packageInfo.sender.zip_code}</h3>
                </span>
                </div>
                <div className="package-span">
                <span>
                  <p>Email</p>
                  <h3>{packageInfo.sender.email}</h3>
                </span>
               <span>
               <p>Phone Number</p>
                <h3>{packageInfo.sender.phone_number}</h3>
               </span>
                </div>
              </div>
            </div>
            <div className="receiver-card">
              <span><h3>RECEIVER'S INFO</h3></span>
              <div className="receiver-card-box">
               <div className="package-span">
               <span>
                <p>Receiver's Full Name</p>
                <h3>{packageInfo.receiver.name}</h3>
               </span>
               <span>
                <p>Home Address</p>
                <h3>{packageInfo.receiver.address_1}</h3>
               </span>
                <span>
                  <p>State</p>
                  <h3>{packageInfo.receiver.state}</h3>
                </span>
               </div>
               <div className="package-span">
               <span>
                <p>Other Name</p>
                <h3>{packageInfo.receiver.contact_name}</h3>
               </span>
               <span>
                <p>Other Address</p>
                <h3>{packageInfo.receiver.address_2}</h3>
               </span>
              <span>
                <p>Zip Code</p>
                <h3>{packageInfo.receiver.zip_code}</h3>
              </span>
               </div>
                <div className="package-span">
                <span>
                  <p>Email</p>
                  <h3>{packageInfo.receiver.email}</h3>
                </span>
                <span>
                  <p>Phone Number</p>
                  <h3>{packageInfo.receiver.phone_number}</h3>
                </span>
                </div>
              </div>
            </div>
            <div className="package-info-card">
             <span><h3>PACKAGE INFO</h3></span>
             <div className="package-info-box">
              <div className="package-span">
              <span>
  <p>Assigned to Driver</p>
  <h3>{packageInfo.isAssignedToDriver ? "Yes" : "No"}</h3>
</span>
<span>
  <p>Assigned to Rider</p>
  <h3>{packageInfo.isAssignedToRider ? "Yes" : "No"}</h3>
</span>

              <span>
                <p>Name</p>
                <h3>{packageInfo.name}</h3>
              </span>
              <span>
                <p>Weight</p>
              <h3>{packageInfo.weight}</h3>
              </span>
             <span>
              <p>Quantity</p>
              <h3>{packageInfo.quantity}</h3>
             </span>
             <span>
              <p>Branch Address</p>
             <h3>{packageInfo.creator.branch_full_address}</h3>
             </span>
              </div>
              <div className="package-span">
              <span>
                <p>Shipping Cost</p>
              <h3>{packageInfo.shipping_cost}</h3>
              </span>
             <span>
              <p>Value</p>
             <h3>{packageInfo.value}</h3>
             </span>
             <span>
                <p>Delivery Method</p>
                <h3>{packageInfo.delivery_method}</h3>
              </span>
             <span>
              <p>Payment Method</p>
              <h3>{packageInfo.payment_method}</h3>
             </span>
              </div>
              <div className="package-span">
             <span>
              <p>Tracking Number</p>
             <h3>{packageInfo.tracking_number}</h3>
             </span>
              <span>
                <p>Way Bill Number</p>
              <h3>{packageInfo.waybill.waybillNumber}</h3>
              </span>
              <span>
                <p>Bar Code</p>
                <img
                src={`data:image/png;base64,${packageInfo.waybill.barcode}`}
                alt="Barcode"
              />
              </span>
              </div>
             </div>
            </div>

            <div className="package-status-card">
  <span>
    <h3>PACKAGE STATUS</h3>
  </span>
  <div className="package-status-box">
    {packageInfo.status.map((statusItem) => (
      <div key={statusItem._id} className="item-status">
       <span>
        <p>Status</p>
       <h3>{statusItem.status}</h3>
       </span>
      <span>
        <p>created At</p>
      <h3>{new Date(statusItem.createdAt).toLocaleString()}</h3>
      </span>
        {
          statusItem.state && (
            <span>
              <p>State</p>
              <h3>{statusItem.state}</h3>
            </span>
          )}
        {
          statusItem.city && (
            <span>
              <p>City</p>
              <h3>{statusItem.city}</h3>
            </span>
          )}
        {
          statusItem.receivedBy && (
            <span>
              <p>Received By</p>
              <h3>{statusItem.receivedBy}</h3>
            </span>
          )}
      </div>
    ))}
  </div>
</div>
          </>
        )}
        
      </div>
    </div>
  );
};

export default TrackPackage;


