import React from 'react';
import { Fragment } from 'react';
import {ReactComponent as SearchIcon} from "../../Assets/iconoir_search.svg"
import "./Tracking.css";

const Trackyourorder = () => {

    return (
        <Fragment>
        <div className='track-your-order'>
            <img src="https://i.ibb.co/vvhxKCL/Frame-1-3x.png" alt="" />
            <h3>Track An Order</h3>
         <form>
              <input
                type="text"
                placeholder="Enter track number"
                />
              <button><SearchIcon/></button>
            </form>
        </div>
    </Fragment>
    );
}

export default Trackyourorder;
