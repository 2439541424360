import React, { useRef, useState, useCallback } from "react";
import { CiSearch } from "react-icons/ci";
import { FiPlusCircle } from "react-icons/fi";
import { Link } from "react-router-dom";
import { BsThreeDotsVertical } from "react-icons/bs";
import useMerchantProduct from '../lib/fetchMerchantProduct';
import Spinner from "../../../Spinner/Spinner";
import "./MerchantProduct.css";

const MerchantProducts = () => {
    const {
        data,
        isLoading,
        isError,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage
    } = useMerchantProduct();

    const [searchQuery, setSearchQuery] = useState('');

    const filteredProducts = data?.pages.flatMap(page =>
        page.products.filter(product =>
            product.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            product.category.toLowerCase().includes(searchQuery.toLowerCase())
        )
    );

    const clampDescription = (description, maxLength) => {
        if (description.length <= maxLength) return description;
        return description.slice(0, maxLength) + "...";
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const observerRef = useRef();
    const lastProductElementRef = useCallback((node) => {
        if (isFetchingNextPage) return;

        if (observerRef.current) observerRef.current.disconnect();

        observerRef.current = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting && hasNextPage) {
                fetchNextPage();
            }
        }, { threshold: 0.5 });

        if (node) observerRef.current.observe(node);
    }, [isFetchingNextPage, fetchNextPage, hasNextPage]);

    return (
        <div className="merchant-product-container">
            <div className="wrapper">
                <h3>Products</h3>
                <span>
                    <form>
                        <button><CiSearch /></button>
                        <input type="text" placeholder="Search Product" value={searchQuery} onChange={handleSearchChange} />
                    </form>
                    <Link to='/AddProduct'><FiPlusCircle />Add Products</Link>
                </span>
            </div>
            <div className="merchant-product-card">
                {isLoading && <div style={{display:'flex', alignItems:'center', justifyContent:'center',width:'100%'}}><Spinner/></div>}
                {isError && <div>Error fetching products</div>}
                {filteredProducts?.map((product, index) => {
                    if (filteredProducts.length === index + 1) {
                        return (
                            <div ref={lastProductElementRef} key={product._id} className="product-card-info">
                                <img src={product.images[0]} alt="" />
                                <span>
                                    <h3>{product.name}</h3>
                                    <Link to={`/product/${product._id}`}><BsThreeDotsVertical /></Link>
                                </span>
                                <p> {product.category}</p>
                                <p> {product.color}</p>
                                <p>{product.price ? product.price.toLocaleString("en-US", { style: "currency", currency: "NGN" }) : ""}</p>
                                <p>{product.brand}</p>
                                <p>{clampDescription(product?.description, 40)}</p>
                            </div>
                        );
                    } else {
                        return (
                            <div key={product._id} className="product-card-info">
                                <img src={product.images[0]} alt="" />
                                <span>
                                    <h3>{product.name}</h3>
                                    <Link to={`/product/${product._id}`}><BsThreeDotsVertical /></Link>
                                </span>
                                <p> {product.category}</p>
                                <p> {product.color}</p>
                                <p>{product.price ? product.price.toLocaleString("en-US", { style: "currency", currency: "NGN" }) : ""}</p>
                                <p>{product.brand}</p>
                                <p>{clampDescription(product?.description, 40)}</p>
                            </div>
                        );
                    }
                })}
            </div>
            {isFetchingNextPage && (
          <div className="loading-more">
            <Spinner />
          </div>
        )}
        </div>
    );
};

export default MerchantProducts;


