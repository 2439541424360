import React, { useState, Fragment, useEffect } from 'react';
import { CiSearch } from "react-icons/ci";
import { FaXmark } from "react-icons/fa6";
import useRequestedHires from '../../libs/fetchNewOrderTruck';
import useCancelTruckOrder from '../../libs/useCancelTruckOrder'; 
import { ReactComponent as TruckIcon } from "../../../../Assets/iconoir_truck-length.svg";
import { ReactComponent as Tonnage } from "../../../../Assets/la_weight-hanging.svg";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import Spinner from "../../../../Spinner/Spinner";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./FreightCharter.css";

const Neworderfreight = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [viewDetail, setViewDetail] = useState(false);
  const [selectedTruck, setSelectedTruck] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const { data, isLoading, isError } = useRequestedHires(currentPage);
  const { mutate: cancelTruckOrder, isLoading: isCancelingOrder } = useCancelTruckOrder(); 

  const itemsPerPage = 8;

  useEffect(() => {
    if (data) {
      if (searchQuery) {
        const filteredHireTrucks = data.hireTrucks.filter(request =>
          request._id.toLowerCase().includes(searchQuery.toLowerCase())
        );
        setFilteredData(filteredHireTrucks);
      } else {
        setFilteredData(data.hireTrucks);
      }
    } else {
      setFilteredData([]);
    }
  }, [searchQuery, data]);


  const totalTrucks = data ? data.totalHireTrucks : 0;
  const totalPages = data ? data.totalPages : 1;

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };

  const handleViewDetail = (request) => {
    setSelectedTruck(request);
    setViewDetail(true);
  };

  const handleCancelOrder = () => {
    if (selectedTruck) {
      cancelTruckOrder(selectedTruck.truckId._id, {
        onSuccess: () => {
          setViewDetail(false);
          toast.success('Truck order canceled successfully');
        },
        onError: (error) => {
          console.error("Error canceling truck order:", error);
          toast.error('Failed to cancel truck order');
        },
      });
    }
  };

  return (
    <Fragment>
      {viewDetail && selectedTruck && (
        <div className="freight-hire-detail">
          <div className="wrapper">
            <span>
              <h3>{selectedTruck._id}</h3>
              <button onClick={() => setViewDetail(false)}>
                <FaXmark style={{ fontSize: "20px" }} />
              </button>
            </span>
            <div className="freight-hire-detail-span">
              <div className="card-1">
                <div className="wrapper">
                  <img src={selectedTruck?.truckId?.images[0]} alt="" />
                  <div className="inner">
                    <h3>{selectedTruck?.truckId?.truckType}</h3>
                    <h4>
                      {selectedTruck?.price
                        ? selectedTruck?.price.toLocaleString("en-US", {
                            style: "currency",
                            currency: "NGN",
                          })
                        : ""}
                    </h4>
                    <span>
                      <TruckIcon />
                      <p>Truck Length:</p>
                      {selectedTruck.truckId?.truckLength}
                    </span>
                    <span>
                      <Tonnage />
                      <p>Tonnage:</p>
                      {selectedTruck.truckId?.tonnage}
                    </span>
                  </div>
                </div>
                <div className="freight-hire-description">
                  <h3>DESCRIPTION</h3>
                  <p>{selectedTruck?.truckId?.description}</p>
                </div>
                <div className='btn'>
                  <button onClick={handleCancelOrder} disabled={isCancelingOrder}>
                    {isCancelingOrder ? <Spinner /> : "Cancel"}
                  </button>
                </div>
                <ToastContainer position="top-right" className="toast" />
              </div>
              <div className="card-2">
                <div className="wrapper">
                  <h3>Hirer Details</h3>
                  <span>
                    <p>Full Name:</p>
                    <p>{selectedTruck.fullName}</p>
                  </span>
                  <span>
                    <p>Contact Name:</p>
                    <p>{selectedTruck.contactName}</p>
                  </span>
                  <span>
                    <p>Email:</p>
                    <p>{selectedTruck.email}</p>
                  </span>
                  <span>
                    <p>Phone Number:</p>
                    <p>{selectedTruck.phoneNumber}</p>
                  </span>
                  <span>
                    <p>Address 1:</p>
                    <p>{selectedTruck.address1}</p>
                  </span>
                  <span>
                    <p>Address 2:</p>
                    <p>{selectedTruck.address2}</p>
                  </span>
                  <span>
                    <p>Zip-Code:</p>
                    <p>{selectedTruck.zipcode}</p>
                  </span>
                  <span>
                    <p>Destination:</p>
                    <p>{selectedTruck.destination}</p>
                  </span>
                  <span>
                    <p>Pick Up Location:</p>
                    <p>{selectedTruck.pickUpLocation}</p>
                  </span>
                  <span>
                    <p>Hire Date:</p>
                    <h4>{new Date(selectedTruck.hireDate).toLocaleDateString()}</h4>
                  </span>
                  <span>
                    <p>Return Date:</p>
                    <h4>{new Date(selectedTruck.returnDate).toLocaleDateString()}</h4>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="freight-charters">
        <div className="wrapper">
          <h3>Freight_New Order</h3>
          <form onSubmit={(e) => e.preventDefault()}>
            <button type="submit">
              <CiSearch />
            </button>
            <input
              type="text"
              placeholder="Search Truck by Id"
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </form>
        </div>
        <div className="freight-charter-mothers">
          {isLoading ? (
            <table>
              <thead>
                <tr>
                  <th>Truck ID</th>
                  <th>Full Name</th>
                  <th>Truck Type</th>
                  <th>Total Amount</th>
                  <th>Hire Date</th>
                  <th>Return Date</th>
                  <th>Status</th>
                  <th>Cancel Order</th>
                  <th>Details</th>
                </tr>
              </thead>
              <tbody>
                {Array.from({ length: 10 }).map((_, index) => (
                  <tr key={index}>
                    <td><Skeleton /></td>
                    <td><Skeleton /></td>
                    <td><Skeleton /></td>
                    <td><Skeleton /></td>
                    <td><Skeleton /></td>
                    <td><Skeleton /></td>
                    <td><Skeleton /></td>
                    <td><Skeleton /></td>
                    <td><Skeleton /></td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : isError ? (
            <p style={{textAlign:'center', fontFamily:'Montserrat'}}>No New Freight Order available</p>
          ) : (
            <table>
              <thead>
                <tr>
                  <th>Truck ID</th>
                  <th>Full Name</th>
                  <th>Truck Type</th>
                  <th>Total Amount</th>
                  <th>Hire Date</th>
                  <th>Return Date</th>
                  <th>Status</th>
                  <th>Details</th>
                </tr>
              </thead>
              <tbody>
                {filteredData.map((request) => (
                  <tr key={request._id}>
                    <td>{request.truckId._id.slice(0, 5).toUpperCase()}</td>
                    <td>{request.fullName}</td>
                    <td>{request.truckId.truckType}</td>
                    <td>
                      {request.price
                        ? request.price.toLocaleString("en-US", {
                            style: "currency",
                            currency: "NGN",
                          })
                        : ""}
                    </td>
                    <td>{new Date(request.hireDate).toLocaleDateString()}</td>
                    <td>{new Date(request.returnDate).toLocaleDateString()}</td>
                    <td><span>New Order</span></td>
                    <td>
                      <button
                        className="view-detail-btn"
                        onClick={() => handleViewDetail(request)}
                      >
                        View 
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
        <div className="pagination">
            <span>{`Showing ${(currentPage - 1) * itemsPerPage + 1} - ${Math.min(currentPage * itemsPerPage, totalTrucks)} of ${totalTrucks}`}</span>
            <div className="btn">
              <button onClick={handlePrevPage} disabled={currentPage === 1}>
                Prev
              </button>
              <button
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
              >
                Next
              </button>
            </div>
          </div>
      </div>
    </Fragment>
  );
};

export default Neworderfreight;
