
import { useQuery } from 'react-query';

const useJeepCollection = (page) => {
  const fetchJeeps = async () => {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/charterer/vehicle/jeep?page=${page}`, {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    if (!response.ok) {
      throw new Error('Failed to fetch jeeps');
    }

    return response.json();
  };

  const { data: jeepData = {}, isLoading, isError } = useQuery(['charteredJeeps', page], fetchJeeps);

  return { jeepData, isLoading, isError }; 
};

export default useJeepCollection; 
