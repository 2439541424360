import React, { useState, useEffect } from 'react';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import { ReactComponent as TruckIcon } from "../../../Assets/iconoir_truck-length.svg";
import { ReactComponent as Tonnage } from "../../../Assets/la_weight-hanging.svg";
import { ReactComponent as SuccessIcon } from "../../../Assets/promptIcon.svg";
import { useParams, useNavigate } from 'react-router-dom';
import useSelectedTruck from '../libs/fetchSelectedTruck';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import './SelectedTruck.css';

const defaultForm = {
  fullName: '',
  phoneNumber: '',
  contactName: '',
  address1: '',
  address2: '',
  zipcode: '',
  email: '',
  pickUpLocation: '',
  destination: '',
  hireDate: '',
  returnDate: '',
}

const SelectedTruckDetails = () => {
  const { id } = useParams();
  const { data: truck, isLoading, isError } = useSelectedTruck(id);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [successCharterMsg, setSuccessCharterMsg] = useState(null);
  const [overlay, setOverlay] = useState(false);
  const [form, setForm] = useState(defaultForm);

  const navigate = useNavigate();

  useEffect(() => {
    const storedTruck = JSON.parse(localStorage.getItem('selectedTruck'));
    if (storedTruck && storedTruck._id === id) {
      setForm((prevForm) => ({ ...prevForm, truck: storedTruck, pricePerDay: storedTruck.pricePerDay }));
    }
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  }

  const handleCharterSuccessMsg = () => {
    setSuccessCharterMsg(false);
    setOverlay(false);
    navigate('/truckselection');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const payload = {
        truckId: form.truck._id,
        price: form.pricePerDay,
        fullName: form.fullName,
        contactName: form.contactName,
        email: form.email,
        phoneNumber: form.phoneNumber,
        address1: form.address1,
        address2: form.address2,
        zipcode: form.zipcode,
        pickUpLocation: form.pickUpLocation,
        destination: form.destination,
        hireDate: form.hireDate,
        returnDate: form.returnDate
      };
      const res = await fetch(`${process.env.REACT_APP_SERVER_URL}/hiretruck/hire`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      });
      const resData = await res.json();
      if (!res.ok) {
        throw new Error(resData.message);
      }
      setForm(defaultForm);
      setSuccessCharterMsg(payload);
      setOverlay(true);
      setLoading(false);
    } catch (error) {
      console.error(error);
      toast.error(error.message || "An error occurred");
      setLoading(false);
    }
  };

  if (isLoading) {
    return <div style={{fontFamily: 'Montserrat', textAlign:'center'}}>Loading...</div>;
  }

  if (isError) {
    return <div style={{fontFamily: 'Montserrat', textAlign:'center'}}>Error loading truck details.</div>;
  }

  if (!truck) {
    return <div style={{fontFamily: 'Montserrat', textAlign:'center'}}>No truck details available.</div>;
  }

  const totalImages = truck.images?.length || 0;

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % totalImages);
  };

  const handlePrevImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + totalImages) % totalImages);
  };

  return (
    <>
      <ToastContainer />
      {overlay && (
        <div className="freight-success-msg">
          <div className="card">
            <SuccessIcon />
            <h3>Truck hire Confirmed</h3>
            <p>Your Truck order has been successfully confirmed!</p>
            <p>Pls go to pickup location to complete the order.</p>
            <div className="wrapper">
              <h3>Details:</h3>
              <p>Trip start: {successCharterMsg && successCharterMsg.hireDate}</p>
              <p>Trip end: {successCharterMsg && successCharterMsg.returnDate}</p>
              <h4>Thank you for choosing us!</h4>
              <button onClick={handleCharterSuccessMsg}>Back Home</button>
            </div>
          </div>
        </div>
      )}

      <div className='selected-truck-details'>
        <div className='truck-details-images'>
          {totalImages > 0 && (
            <img
              src={truck.images[currentImageIndex]}
              alt={truck.truckModel}
              className="vehicle-image"
            />
          )}
          {totalImages > 1 && (
            <div className="image-navigation">
              <button className="next-image" onClick={handleNextImage}>
                <IoIosArrowForward />
              </button>
              <button className="prev-image" onClick={handlePrevImage}>
                <IoIosArrowBack />
              </button>
            </div>
          )}
        </div>
        <div className="wrapper">
          <div className="selected-truck-detail-card-1">
            <h3>{truck.truckType}</h3>
            <p>Model: {truck.truckModel}</p>
            <p><TruckIcon /> Length: {truck.truckLength} ft</p>
            <p><Tonnage /> Tonnage: {truck.tonnage} Tons</p>
            <p>State: {truck.state}</p>
            <h4>Price Per Day: {truck.pricePerDay
              ? (truck.pricePerDay).toLocaleString("en-US", {
                style: "currency",
                currency: "NGN",
              })
              : ""}</h4>
            <p>{truck.description}</p>
          </div>
          <div className="selected-truck-detail-card-2">
            <form onSubmit={handleSubmit}>
              <div className="selected-truck-combination">
                <span>
                  <label>Full Name</label>
                  <input type="text" name='fullName' value={form.fullName} onChange={handleChange} required />
                </span>
                <span>
                  <label>Contact Name</label>
                  <input type="text" name='contactName' value={form.contactName} onChange={handleChange} required />
                </span>
              </div>
              <div className="selected-truck-combination">
                <span>
                  <label>Email</label>
                  <input type="text" name='email' value={form.email} onChange={handleChange} required />
                </span>
                <span>
                  <label>Phone Number</label>
                  <input type="text" name='phoneNumber' value={form.phoneNumber} onChange={handleChange} required />
                </span>
              </div>
              <div className="selected-truck-combination">
                <span>
                  <label>Address 1</label>
                  <input type="text" name='address1' value={form.address1} onChange={handleChange} required />
                </span>
                <span>
                  <label>Address 2</label>
                  <input type="text" name='address2' value={form.address2} onChange={handleChange} required />
                </span>
              </div>
              <div className="selected-truck-combination">
                <span>
                  <label>Zip-Code</label>
                  <input type="tel" name='zipcode' value={form.zipcode} onChange={handleChange} required />
                </span>
              </div>
              <div className="selected-truck-combination">
                <span>
                  <label>Pick Up Location (Address & State)</label>
                  <input type="text" name='pickUpLocation' value={form.pickUpLocation} onChange={handleChange} required />
                </span>
                <span>
                  <label>Destination (Address & State)</label>
                  <input type="text" name='destination' value={form.destination} onChange={handleChange} required />
                </span>
              </div>
              <div className="selected-truck-combination">
                <span>
                  <label>Hiring Date</label>
                  <input type="date" name='hireDate' value={form.hireDate} onChange={handleChange} required />
                </span>
                <span>
                  <label>Return Date</label>
                  <input type="date" name='returnDate' value={form.returnDate} onChange={handleChange} required />
                </span>
              </div>
              <button type='submit' disabled={loading}>
                {loading ? "Booking..." : "Book"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default SelectedTruckDetails;

