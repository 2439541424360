import React from 'react';
import {ReactComponent as SearchIcon} from "../../asset/iconamoon_search-light.svg";
import { useNavigate } from 'react-router-dom';
import "./Superadmininvestors.css"

const Superadmininvestor = () => {
    const navigate = useNavigate();

    return (
        <div className='super-admin-investor'>
            <div className="header">
                <p>Stex's Investor</p>
                <div className="in">
                    <span>
                        <SearchIcon/>
                    <input 
                        type="search" 
                        placeholder='search investor by id' 
                        />
                    </span>
                    <button onClick={() => navigate('/superadmincreateinvestor')}>Create Investor</button>
                </div>
            </div>
            <div className="stex-investor-table">
                <table>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Image</th>
                            <th>First</th>
                            <th>Middle</th>
                            <th>Last</th>
                            <th>Address</th>
                            <th>Phone</th>
                            <th>Email</th>
                            <th>Nokname</th>
                            <th>Noknumber</th>
                            <th>Nokadd</th>
                            <th>Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default Superadmininvestor;
