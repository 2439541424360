import React, { Fragment, useState, useContext } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { RxQuestionMarkCircled } from "react-icons/rx";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { PiShoppingCartThin } from "react-icons/pi";
import { VscMenu } from "react-icons/vsc";
import { HiOutlineXMark } from "react-icons/hi2";
import { FaRegUser } from "react-icons/fa6";
import { CiShop } from "react-icons/ci";
import { SlLike } from "react-icons/sl";
import { GoMail } from "react-icons/go";
import { SlSettings } from "react-icons/sl";
import { IoIosArrowForward } from "react-icons/io";
import { LuView } from "react-icons/lu";
import { MdOutlineRateReview } from "react-icons/md";
import { FiUserX } from "react-icons/fi";
import { FaRegAddressCard } from "react-icons/fa";
import {ReactComponent as SearchIcon} from "../../../Assets/iconoir_search.svg"
import HelpCenter from "../HelpCenter/MarketHelpCenter";
import UserDropdown from "../MarketUser/UserDropdown";
import UserDataFetcher from "../../../libs/getUserbyUserId";
import { CartContext } from "../../../Context/CartContext";
import { AuthContext } from "../../../Context/AuthContext";
import CateMenu from "../ProductCategory/CateMenu";
import { CiUser } from "react-icons/ci";
import useSearchProducts from "../Merchant/lib/fetchSearchProduct";

const MarketNavbar = () => {
  const [helpCenterDrop, setHelpCenterDrop] = useState(false);
  const [userDrop, setUserDrop] = useState(false);
  const [userMobileDrop, setUserMobileDrop] = useState(false);
  const [cateMenuDrop, setCateMenuDrop] = useState(false);
  const { cartCount } = useContext(CartContext);
  const { adminData } = UserDataFetcher();
  const {isLoggedIn, Logout} = useContext(AuthContext)
  const [searchQuery, setSearchQuery] = useState("");
  const [productClicked, setProductClicked] = useState(false);
  const navigate = useNavigate();


const handleLogOut = () => {
    Logout();
    navigate('/Market')
}

  const { isLoading, error, searchResults } = useSearchProducts(searchQuery);

  const handleCateMenuDrop = () => {
    setCateMenuDrop(!cateMenuDrop);
  };

  const handleHelpCenter = () => {
    setHelpCenterDrop(!helpCenterDrop);
  };

  const handleUserDrop = () => {
    setUserDrop(!userDrop);
  };

  const handleUserMoblieDrop = () => {
    setUserMobileDrop(!userMobileDrop)
  }

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    setProductClicked(false);
  };

  const handleProductClick = () => {
    setProductClicked(true);
  };

  return (
    <Fragment>
      {cateMenuDrop && (
        <div className={`drop-down-wrapper ${cateMenuDrop ? "mobile active" : ""}`}>
          <CateMenu />
        </div>
      )}
      <div className="market-shop-nav">
        <span>
          <div className="logo-menu">
            {cateMenuDrop ? (
              <HiOutlineXMark
                className="react-menu"
                onClick={handleCateMenuDrop}
                style={{ fontSize: "25px" }}
              />
            ) : (
              <VscMenu
                className="react-menu"
                onClick={handleCateMenuDrop}
                style={{ fontSize: "25px" }}
              />
            )}
            <Link to="/Market">
              <img
                src="https://i.ibb.co/SywHnkP/Frame-1-1.png"
                alt=""
                onClick={() => setCateMenuDrop(false)}
              />
            </Link>
          </div>
          <div className="user-cart" >
            <CiUser style={{ fontSize: "25px" }} onClick={handleUserMoblieDrop} />
            {userMobileDrop && <div className="user-mobile-drops">
            {!isLoggedIn && 
   <div className="user-mobile-dropdown-card">
    <Link to='/Login'><h3>Login/Signup</h3></Link>
    </div>
    }
               {isLoggedIn && 
   <div className="user-mobile-dropdown-card">
   <div className="user-mobile-dropdown-con">
   <span>
        <h3>Hi,</h3>
        <h3>{adminData?.user?.first_name}</h3>
        <h3>{adminData?.user?.last_name}</h3>
        </span>
        <p>{adminData?.user?.email}</p>
   </div>
    <div className="wrapper">
    <Link to='/UserProfile'><span><FaRegUser style={{fontSize:'20px'}} />My Profile</span> <IoIosArrowForward /></Link>
    <Link to='/customerorder'><span><CiShop style={{fontSize:'20px'}} />Orders</span><IoIosArrowForward /></Link>
    <Link to='/customersaveditem'><span><SlLike style={{fontSize:'20px'}} />Saved items</span><IoIosArrowForward /></Link>
    <Link to='/customermsginbox'><span><GoMail style={{fontSize:'20px'}}  />Inbox</span><IoIosArrowForward /></Link>
    <Link to='/recentview'><span><LuView style={{fontSize:'20px'}} />Recently Viewed</span><IoIosArrowForward /></Link>
    <Link to='/pendingreview'><span><MdOutlineRateReview style={{fontSize:'20px'}}/>Pending Evaluation</span><IoIosArrowForward /></Link>
    <Link to='/addressbook'><span><FaRegAddressCard style={{fontSize:'20px'}}/>Address Book</span><IoIosArrowForward /></Link>
    <Link to='/accountmanagement'><span><SlSettings style={{fontSize:'20px'}}  />Account Management</span><IoIosArrowForward /></Link>
    <button><FiUserX style={{fontSize:'20px'}}/>Delete Account</button>
    </div>
    <button onClick={handleLogOut}>Logout</button>
    </div>
    }
              </div>}
            <div style={{position: "relative", display: "flex",justifyContent: "center",alignItems: "center", }}onClick={() => navigate("/CartListView")} >
              <div style={{position: "absolute", top: "-15px",right: "-2px", padding: "10px",color: "#d0300e",}} >
                {cartCount > 0 ? <p>{cartCount}</p> : null}
              </div>
              <PiShoppingCartThin style={{ fontSize: "30px" }} />
            </div>
          </div>

          <div className="search-input">
            <form>
              <input
                type="text"
                placeholder="Search products, name"
                value={searchQuery}
                onChange={handleSearch}
              />
              <button><SearchIcon/></button>
            </form>
            {!isLoading && error && <p>{error}</p>}
            {!isLoading && !error && !productClicked && searchResults && typeof searchResults === "object" && searchResults.products && (
              <ul className="search-suggestions">
                <div className="search-suggestion-card">
                  {searchResults.products.map((product) => (
                    <Link to={`/product/${product._id}`} key={product._id} onClick={handleProductClick}>
                      <p>{product.name}</p>
                    </Link>
                  ))}
                </div>
              </ul>
            )}
          </div>
          <div className="market-combination">
            <div className="help-center-drop" onClick={handleHelpCenter}>
              <div className="help-center-box">
                <RxQuestionMarkCircled style={{ fontSize: "25px" }} />
                <p> Help</p>
                {helpCenterDrop ? <IoIosArrowUp /> : <IoIosArrowDown />}
              </div>
              {helpCenterDrop && <HelpCenter />}
            </div>

      <div className="user-circle" onClick={handleUserDrop}>
        <FaRegUser style={{fontSize:'20px'}} />
        {!isLoggedIn && <p>Account</p>}
      <div className="user-name">
      <p>{adminData?.user?.first_name}</p>
      </div>
      {userDrop && <UserDropdown />}
        {userDrop ? <IoIosArrowUp /> : <IoIosArrowDown />}
      </div>
      
            <Link to="/CartListView" className="cart-count">
              <PiShoppingCartThin style={{ fontSize: "30px" }} />
              <span>My Cart</span>
              {cartCount > 0 ? <p>{cartCount}</p> : null}
            </Link>
          </div>
        </span>
        <div className="rear-search-input">
          <form>
            <input
              type="text"
              placeholder="Search products, name"
              value={searchQuery}
              onChange={handleSearch}
            />
             <button><SearchIcon/></button>
          </form>
          {!isLoading && error && <p>{error}</p>}
          {!isLoading && !error && !productClicked && searchResults && typeof searchResults === "object" && searchResults.products && (
            <ul className="search-suggestions">
              <div className="search-suggestion-card">
                {searchResults.products.map((product) => (
                  <Link to={`/product/${product._id}`} key={product._id} onClick={handleProductClick}>
                    <p>{product.name}</p>
                  </Link>
                ))}
              </div>
            </ul>
          )}
        </div>
      </div>
      <Outlet />
    </Fragment>
  );
};

export default MarketNavbar;





