
import { useMutation } from 'react-query';
import { useContext } from 'react';
import { AuthContext } from '../../Context/AuthContext';

const useChangePassword = () => {
  const { token, user } = useContext(AuthContext);

  const changePasswordMutation = async ({ oldPassword, newPassword }) => {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/users/change-password/${user.userId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ oldPassword, newPassword }),
    });

    if (!response.ok) {
      throw new Error('Failed to change password');
    }

    return response.json();
  };

  const { mutate: changePassword } = useMutation(changePasswordMutation);

  return { changePassword };
};

export default useChangePassword;
