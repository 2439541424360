import { useQuery } from "react-query";
import { useContext } from "react";
import { AuthContext } from "../../Context/AuthContext";

const fetchAllPackages = async (token) => {
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/packages/getsome-packages`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    credentials: "include",
  });

  if (!response.ok) {
    throw new Error("No shipment found.");
  }

  return response.json();
};

const useAllPackages = () => {
  const { token } = useContext(AuthContext);
  const { data: packages, isLoading, isError } = useQuery("allPackages", () => fetchAllPackages(token));

  return { packages, isLoading, isError };
};

export default useAllPackages;


