
import React, { createContext, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { useGetCurrentUser } from "../libs/fetchCurrentUser ";

export const AuthContext = createContext({
  isLoggedIn: false,
  user: null,
  token: null,
  Login: () => {},
});

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);

  const { data: currentUserData, isLoading: isCurrentUserLoading } =
    useGetCurrentUser(token);

  const Login = useCallback((user) => {
    setIsLoggedIn(true);
    setUser(user);
    setToken(user.token);
    Cookies.set("authToken", user.token, { expires: 9 / 24 });
  }, []);

  
  useEffect(() => {
    const authToken = Cookies.get("authToken");

    if (authToken) {
      setToken(authToken);
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
      setUser(null);
      setToken(null);
      Cookies.remove("authToken");
    }
  }, [navigate]);

  useEffect(() => {
    if (!isCurrentUserLoading && currentUserData) {
      setUser(currentUserData);
    }
  }, [isCurrentUserLoading, currentUserData]);

  const Logout = useCallback(() => {
    setIsLoggedIn(false);
    setUser(null);
    setToken(null);
    Cookies.remove("authToken");
    navigate("/Login");
  }, [navigate]);

  const value = {
    isLoggedIn,
    user,
    token,
    Login,
    Logout,
  };

  return (
    <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
  );
};
