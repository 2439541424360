import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import useRegisterFreightManager from '../libs/useRegisterFreightManager';
import { IoMdArrowBack } from "react-icons/io";
import "./CreateFreightOfficer.css"


const Createfreightofficer = () => {

    const navigate = useNavigate();
    const [success, setSuccess] = useState(false);
  
    const registerFreightManagerMutation = useRegisterFreightManager();
  
    const [formData, setFormData] = useState({
      first_name: "",
      last_name: "",
      country_code: "+234",
      phone_number: "",
      email: "",
      address: "",
      state: ""
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      };

      const handleSubmit = async (e) => {
        e.preventDefault();
        try {
          await registerFreightManagerMutation.mutateAsync(formData);
          setSuccess('Freight manager registered successfully, code sent to an Email');
          setTimeout(() => {
            setSuccess(false);
            navigate("/superadminfreight");
          }, 3000);
        } catch (error) {
          console.error("Registration failed:", error.message);
        }
      };
    
    


    return (
        <div className='register-freight-container'>
        <div className="header">
          <IoMdArrowBack
            onClick={() => navigate("/superadminfreight")}
            className="icon"
          />
          <p>Register New Freight Admin</p>
        </div>
  
        <div className="manager-register-form">
          <form onSubmit={handleSubmit}>
            <label>
              First Name
              <input
                type="text"
                name="first_name"
                value={formData.first_name}
                onChange={handleInputChange}
                required
              />
            </label>
  
            <label>
              Last Name
              <input
                type="text"
                name="last_name"
                value={formData.last_name}
                onChange={handleInputChange}
                required
              />
            </label>
  
            <label>
              Phone Number
              <div className="inner">
                <select name="country_code" value={formData.country_code} onChange={handleInputChange} required>
                  <option value="+234">+234</option>
                  <option value="+1">+1</option>
                  <option value="+95">+95</option>
                </select>
                <input
                  type="text"
                  name="phone_number"
                  value={formData.phone_number}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </label>
  
            <label>
              Email
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                required
              />
            </label>
  
            <label>
              Address
              <input
                name="address"
                value={formData.address}
                onChange={handleInputChange}
                required
              />
            </label>
  
            <label>
              State
              <input
                name="state"
                value={formData.state}
                onChange={handleInputChange}
                required
              />
            </label>
  
            <button type="submit" disabled={registerFreightManagerMutation.isLoading}>
              {registerFreightManagerMutation.isLoading ? "Registering..." : "Register"}
            </button>
            {registerFreightManagerMutation.isError && (
              <p className="error-message">
                Error: {registerFreightManagerMutation.error.message}
              </p>
            )}
            {success && <p className="success-message">{success}</p>}
          </form>
        </div>
      </div>
    );
}

export default Createfreightofficer;
