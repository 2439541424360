import { useMutation, useQueryClient } from 'react-query';
import { useContext } from 'react';
import { AuthContext } from '../../../../Context/AuthContext';

const markVehicleAsBooked = async (id, token) => {
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/charterer/set-hired/${id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error('Failed to mark vehicle as Booked');
  }

  return response.json();
};

const useMarkVehicleAsBooked = () => {
  const queryClient = useQueryClient();
  const { token } = useContext(AuthContext);

  return useMutation((id) => markVehicleAsBooked(id, token), {
    onSuccess: () => {
      queryClient.invalidateQueries(['BookedRequest']); 
    },
  });
};

export default useMarkVehicleAsBooked;
