import { NavLink as Link, Outlet } from "react-router-dom";
import { Fragment } from "react";
import { ReactComponent as FrameIcon } from "../../asset/Frame 1.svg";
import { BiCategoryAlt } from "react-icons/bi";
import { ReactComponent as BpersonIcon } from "../../asset/bi_person.svg";
import { ReactComponent as MenuIcon } from "../../asset/menu-down.svg";
import UserDataFetcher from "../../super_admin/libs/Getadminbyid";
import { IoBicycleSharp } from "react-icons/io5";
import { CiSettings } from "react-icons/ci";
import { BiChat } from 'react-icons/bi';
import { BsHandbag } from "react-icons/bs";
import { TbCategoryPlus } from "react-icons/tb";
import { PiImagesSquareThin } from "react-icons/pi";
import { AiOutlineUserSwitch } from "react-icons/ai";
import { CiShoppingCart } from "react-icons/ci";
import "./marketnavbar.css"

function Marketnavbar() {
  const { adminData } = UserDataFetcher();

  const NavLinkLCss = ({ isActive }) => {
    return {
      backgroundColor: isActive ? "#284697" : "",
      color: isActive ? "#fff" : "",
    };
  };
  return (
    <Fragment>
    <div className="market_admin_header">
      <p>
        Welcome back, <span>{adminData?.user?.first_name}</span>{" "}
        <span>{adminData?.user?.last_name}</span>
      </p>
      <div className="side">
        <BpersonIcon className="round" />
        <MenuIcon />
      </div>
    </div>
    <div className="market-nav-container">
      <FrameIcon />
      <div className="links">
        <Link to="/" style={NavLinkLCss}>
          <BiCategoryAlt className="icon" /> Overview
        </Link>
        <Link to="/markeplaceproductss" style={NavLinkLCss}>
        <CiShoppingCart className="icon" /> Products
        </Link>
        <Link to="/marketplaceadminbanner" style={NavLinkLCss}>
        <PiImagesSquareThin className="icon" /> Banners
        </Link>
        <Link to="/Marketplacestore" style={NavLinkLCss}>
        <BsHandbag  className="icon"/> Stores
        </Link>
        <Link to="/Marketplacecategory" style={NavLinkLCss}>
        <TbCategoryPlus className="icon"/> Categories
        </Link>
        <Link to="/markplaceadminorder" style={NavLinkLCss}>
        <IoBicycleSharp className="icon" />  Orders
        </Link>
        <Link to="/markeplacecustomer" style={NavLinkLCss}>
        <AiOutlineUserSwitch className="icon" />  Customers
        </Link>
        <Link to='/markeplaceadminmsg' style={NavLinkLCss}>
        <BiChat style={{fontSize:'24px'}} />
        Broadcast Message
        </Link>
        <Link to="/marketplacesettingss" style={NavLinkLCss}>
        <CiSettings  className="icon"/> Settings
        </Link>
      </div>
    </div>
    <Outlet />
  </Fragment>
  )
}

export default Marketnavbar
