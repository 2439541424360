
import React, { useState } from 'react';
import "./RequestCode.css";

const RequestCodeForm = () => {
  const [loading, setLoading] = useState(false);
  const [successMsg, setSuccessMsg] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const email = localStorage.getItem('userEmail');

    if (!email) {
      console.error('Email not found in local storage');
      setLoading(false);
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/users/resend-requestpassword-resset`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email }), 
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }

      setLoading(false);
      setSuccessMsg("Reset Code resent successfully!");

      setTimeout(() => {
        setSuccessMsg(null);
      }, 3000);
    } catch (error) {
      console.error('Password reset failed:', error.message);
      setLoading(false);
    }
  };

  return (
    <div className='request-code-form'>
      <form onSubmit={handleSubmit}>
        <div className="wrapper">
          <p>Didn't get the Code?</p>
          <button type="submit" disabled={loading}>
            {loading ? "Resending..." : "Resend"}
          </button>
        </div>
        {successMsg && <p style={{ color: "green", fontFamily:'Montserrat' }}>{successMsg}</p>}
      </form>
    </div>
  );
};

export default RequestCodeForm;

