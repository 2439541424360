import { useContext, useState } from "react";
import {AuthContext} from "../../../Context/AuthContext"

const useSearchTrucks = () => {
    const {token} = useContext(AuthContext)
  const [searchResults, setSearchResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [searchError, setSearchError] = useState(null);

  const searchTrucks = async (criteria) => {
    setIsSearching(true);
    setSearchError(null);
    try {
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/freight/searchTrucks`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(criteria)
      });
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || 'Failed to search trucks');
      }
      setSearchResults(data.trucks);
    } catch (error) {
      setSearchError(error.message);
    } finally {
      setIsSearching(false);
    }
  };

  return { searchResults, isSearching, searchError, searchTrucks };
};

export default useSearchTrucks;
