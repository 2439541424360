import { useContext } from 'react';
import { useQuery } from 'react-query';
import { AuthContext } from "../../../Context/AuthContext";

const useCompletedTruck = (page) => {
  const { token, user } = useContext(AuthContext);

  const fetchCompletedTruck = async () => {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/hiretruck/completed?state=${user?.state}&page=${page}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });
    if (!response.ok) {
      throw new Error('Failed to fetch completed truck ');
    }
    return response.json();
  };

  return useQuery(['completedTruck', page], fetchCompletedTruck);
};

export default useCompletedTruck;



