import React, { useContext, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { IoIosRemoveCircleOutline } from "react-icons/io";
// import { IoMdPaperPlane } from "react-icons/io";
import { LiaShippingFastSolid } from "react-icons/lia";
import useRecommendedLimit from "../libs/fetchLimitRecommended";
import MarketNavbar from "../MarketNav/MarketNavbar";
import { CartContext } from "../../../Context/CartContext";
import { ReactComponent as CautionIcon } from "../../../Assets/caution-svgrepo-com 1.svg";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-loading-skeleton/dist/skeleton.css";
import { AuthContext } from "../../../Context/AuthContext";

const CartListView = () => {
  const { data: recommendedLimit, isLoading: recommendedLoading, isError: recommendedError } = useRecommendedLimit();
  const [removingOverlay, setRemovingOverlay] = useState(false);
  const [itemToRemove, setItemToRemove] = useState(null); 
  const {
    cartItems,
    removeItemFromCart,
    clearItemFromCart,
    addItemToCart,
    totalCart,
  } = useContext(CartContext);
  const { isLoggedIn, user } = useContext(AuthContext);
  const navigate = useNavigate();

  const notify = () => toast.success("Product added successfully to cart!");
  const notifies = () => toast.success("Product removed successfully from cart!");

  useEffect(() => {
    if (cartItems.length === 0) {
      navigate("/EmptyCart");
    }
  }, [cartItems, navigate]);

  const handleDecreaseItem = (cartItem) => {
    removeItemFromCart(cartItem);
    notifies();
  };

  const handleRemoveItem = () => {
    clearItemFromCart(itemToRemove); 
    setRemovingOverlay(false);
  };

  const handleIncreaseCart = (cartItem) => {
    addItemToCart(cartItem);
    notify();
  };

  const handleCheckOut = () => {
    if (isLoggedIn && user?.role === 'customer') {
      navigate("/ShippingDetail");
    } else {
      navigate("/Login");
    }
  };

  const handleRemovingOverlay = (cartItem) => {
    setItemToRemove(cartItem); 
    setRemovingOverlay(true);
  };

  return (
    <>
      <MarketNavbar />
      <div className="cart-list-container">
        {cartItems.length > 0 && (
          <div className="cart-card-container">
            {cartItems.map((cartItem) => (
              <div className="cart-check" key={cartItem._id}>
                <div className="cart-con-1">
                  <div className="cart-span-1">
                    {cartItem.images && cartItem.images.length > 0 && (
                      <img src={cartItem.images[0]} alt={cartItem.description} />
                    )}
                    {removingOverlay && itemToRemove?._id === cartItem._id && ( 
                      <div className="removal-overlay">
                        <div className="removing-card">
                          <CautionIcon />
                          <h3>Delete</h3>
                          <p>Are you sure you want to remove this item from cart?</p>
                          <div className="btn">
                            <button onClick={() => setRemovingOverlay(false)}>No</button>
                            <button onClick={handleRemoveItem}>Yes</button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="cart-span-2">
                    <h3>{cartItem.name}</h3>
                    <p>
                      {cartItem.quantity && cartItem.price
                        ? (cartItem.quantity * cartItem.price).toLocaleString("en-US", {
                            style: "currency",
                            currency: "NGN",
                          })
                        : 0}
                    </p>
                    <h4>
                      {/* Smart <IoMdPaperPlane style={{ color: " #d0300e " }} /> */}
                      Smart <LiaShippingFastSolid style={{ color: " #d0300e " }} />
                      <span>Express</span>
                    </h4>
                  </div>
                </div>
                <div className="cart-con-2">
                  <span>
                    <h3>Quantity</h3>
                    <div className="add-remove-button">
                      <button onClick={() => handleDecreaseItem(cartItem)}>
                        <i className="fa-solid fa-minus"></i>
                      </button>
                      <p> {cartItem.quantity}</p>
                      <button onClick={() => handleIncreaseCart(cartItem)}>
                        <i className="fa-solid fa-plus"></i>
                      </button>
                    </div>
                  </span>
                  <button onClick={() => handleRemovingOverlay(cartItem)}>
                    <IoIosRemoveCircleOutline style={{ fontSize: "30px" }} />
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}
        {cartItems.length > 0 && (
          <div className="order-summary">
            <div className="order-summary-card">
              <span>
                <h3>Subtotal</h3>
                <p>
                  {totalCart
                    ? totalCart.toLocaleString("en-US", {
                        style: "currency",
                        currency: "NGN",
                      })
                    : ""}
                </p>
              </span>
              <p>Taxes and Shipping Calculated at checkout</p>
              <button onClick={handleCheckOut}> Checkout </button>
            </div>
          </div>
        )}
      </div>

      <div className="special-product-con">
        <span>
          <h3>RECOMMENDED</h3>
          <Link to="/recommendedproduct">VIEW ALL</Link>
        </span>
        {recommendedLoading ? (
          <div className="special-product-con">
            <Skeleton count={5} height={100} width={250} />
            <Skeleton count={5} height={10} width={200} />
            <Skeleton count={5} height={10} width={180} />
            <Skeleton count={5} height={10} width={150} />
          </div>
        ) : recommendedError ? (
          <div className="errors-msg">Error fetching recommended product</div>
        ) : Array.isArray(recommendedLimit.products) && recommendedLimit.products.length > 0 ? (
          <div className="special-product-card">
            {recommendedLimit.products.slice(0, 5).map((deal) => (
              <Link to={`/product/${deal._id}`} key={deal._id} className="special-product-info">
                <img src={deal.images[0]} alt="" />
                <p>{deal.name}</p>
                <h3>
                  {deal.price
                    ? deal.price.toLocaleString("en-US", {
                        style: "currency",
                        currency: "NGN",
                      })
                    : ""}
                </h3>
              </Link>
            ))}
          </div>
        ) : (
          <div className="errors-msg">No recommended product available</div>
        )}
      </div>
      <ToastContainer position="top-right" className="toast" />
    </>
  );
};

export default CartListView;

