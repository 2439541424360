import { useQueryClient, useMutation } from 'react-query';
import axios from 'axios';

const useShippingCost = () => {
  const queryClient = useQueryClient();

  const fetchShippingCost = async ({ sender_state, receiver_state, weight, quantity,  items_value }) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/shipping/calculate`, {
        sender_state: sender_state,
        receiver_state: receiver_state,
        weight: weight,
        quantity: quantity,
        items_value: items_value
      });

      return response.data; 
    } catch (error) {
      throw new Error('Error fetching shipping cost');
    }
  };

  const mutation = useMutation(fetchShippingCost, {
    onSuccess: (data) => {

      queryClient.invalidateQueries('shippingCost');
    },
  });

  return { mutation };
};

export default useShippingCost;