import { useState, useContext, Fragment } from "react";
import axios from "axios";
import { useMutation, useQueryClient } from "react-query";
import { PiImagesSquareThin } from "react-icons/pi";
import { AuthContext } from "../../Context/AuthContext";
import useGetBanners from "../libs/useGetBanners";
import useDeleteBanner from "../libs/useDeleteBanner";
import Spinner from "../../utils/spinner";
import { ReactComponent as CautionIcon } from "../../asset/caution-svgrepo-com 1.svg";
import "./marketplaceadminbanner.css";

const MarketplaceadminBanner = () => {
  const { token } = useContext(AuthContext);
  const [images, setImages] = useState([]);
  const [msg, setMsg] = useState(null);
  const [error, setError] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [UploadOverlay, setUploadoverlay] = useState(false);
  const [deleteOverlay, setDeleteOverlay] = useState(false);
  const [selectBannerId, setSelectedBannerId] = useState(null);

  const queryClient = useQueryClient();

  const {  deleteBanner, isLoading: deleteLoading  } = useDeleteBanner();

  const {
    data: banners,
    isLoading: bannersLoading,
    error: bannersError,
  } = useGetBanners();

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    setImages(files);
  };

  const handleResetImages = () => {
    setImages([]);
  };

  const uploadImage = async () => {
    setIsUploading(true);
    const formData = new FormData();
    images.forEach((image) => {
      formData.append("images", image);
    });

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/banners/create/banner`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setIsUploading(false);
      return response.data.imageUrl;
    } catch (error) {
      setIsUploading(false);
      throw error;
    }
  };

  const { mutate: uploadMutation } = useMutation(uploadImage, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("banners");
      setMsg("Image uploaded successfully");
      setTimeout(() => {
        setUploadoverlay(false);
        setImages([]);
        setMsg("");
      }, 2000);
    },
    onError: (error) => {
      console.error("Image upload failed:", error);
      setError(error.message);
    },
  });

  const handleUpload = () => {
    uploadMutation();
  };



const handleDeleteBanner = async () => {
    await deleteBanner(selectBannerId);
    setDeleteOverlay(false);
    setSelectedBannerId(null);
  };
  

  return (
    <Fragment>
      {deleteOverlay && (
        <div className="update-banner-overlay">
          <div className="cards">
            <CautionIcon />
            <h3>Delete</h3>
            <p>Are you sure you want to delete this image?</p>
            <div className="btn">
              <button
                onClick={() => {
                  setDeleteOverlay(false);
                  setSelectedBannerId(null);
                }}
              >
                No
              </button>
              <button onClick={handleDeleteBanner} disabled={deleteLoading}>
                {deleteLoading ? "Deleting..." : "Yes"}
              </button>
            </div>
          </div>
        </div>
      )}
      {UploadOverlay && (
        <div className="update-banner-overlay">
          <div className="card">
            <div className="merchant-product-image">
              <h3>Upload banner images</h3>
              <div className="merchant-image">
                {images.length > 0 ? (
                  <>
                    {images.map((image, index) => (
                      <img
                        key={index}
                        src={URL.createObjectURL(image)}
                        alt=""
                      />
                    ))}
                    <button type="button" onClick={handleResetImages}>
                      Reset Images
                    </button>
                    <button type="button" onClick={handleUpload}>
                      {isUploading ? "Uploading..." : "Upload"}
                    </button>
                  </>
                ) : (
                  <div className="merchant-image-card">
                    <label className="upload-label">
                      <PiImagesSquareThin />
                      <input
                        type="file"
                        accept="image/*"
                        multiple
                        onChange={handleImageChange}
                        style={{ display: "none" }}
                      />
                      <p>Upload images</p>
                    </label>
                  </div>
                )}
              </div>
            </div>
            {msg && <p>{msg}</p>}
            {error && <p>Something went wrong, Please try again.</p>}
            <button
              onClick={() => {
                setUploadoverlay(false);
                setImages([]);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      )}

      <div className="marketplace-admin-banner">
        <div className="header">
          <h1>BANNER IMAGES</h1>
          <button onClick={() => setUploadoverlay(true)}>Upload Images</button>
        </div>

        <div className="banner-container">
          {bannersLoading && <Spinner />}
          {bannersError && <p>No banners available</p>}
          {banners &&
            banners.banners.map((banner) => (
              <div className="card" key={banner._id}>
                <img src={banner?.imageUrl} alt={`Banner ${banner._id}`} />
                <button
                  onClick={() => {
                    setDeleteOverlay(true);
                    setSelectedBannerId(banner._id);
                    console.log(banner._id);
                  }}
                >
                  Delete
                </button>
              </div>
            ))}
        </div>
      </div>
    </Fragment>
  );
};

export default MarketplaceadminBanner;
