import React, { Fragment, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { LiaEyeSlashSolid, LiaEyeSolid } from "react-icons/lia";
import useLoginForm from "../../Hook/useLoginForm";
import "./Login.css";

const Login = () => {
  const { formData, loading, error, handleChange, handleLogin, showTotp } = useLoginForm();
  const [showPass, setShowPass] = useState(false);
  const navigate = useNavigate();

  const handleShowPass = () => {
    setShowPass(!showPass);
  };

  const handleLoginWithStorage = async (e) => {
    e.preventDefault();
    try {
      await handleLogin(e);
      if (!error && !showTotp) {
        localStorage.setItem("email", formData.email);
        localStorage.setItem("password", formData.password);
      }
    } catch (err) {
      console.error("Login error:", err);
    }
  };

  return (
    <Fragment>
      <div className="log-in-form">
        <h3>Welcome Back</h3>
        <form onSubmit={handleLoginWithStorage}>
          <span>
            <label>Email Address</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </span>
          <span>
            <label>Password</label>
            <div className="pass">
              <input
                type={showPass ? "text" : "password"}
                name="password"
                value={formData.password}
                onChange={handleChange}
                required
              />
              <div onClick={handleShowPass} className="password-toggle">
                {showPass ? <LiaEyeSlashSolid /> : <LiaEyeSolid />}
              </div>
            </div>
          </span>
          {showTotp && (
            <span>
              <label>Token</label>
              <input
                type="password"
                name="otp"
                value={formData.otp}
                onChange={handleChange}
                required
              />
            </span>
          )}
          <Link to="/ForgetPass">Forgot Password?</Link>
          <button type="submit" disabled={loading}>
            {loading ? "Authenticating..." : "Login"}
          </button>
          {error && <p style={{ color: "red" }}>{error}</p>}
          <p onClick={() => navigate("/Signup")}>
            Don't have an Account? <span>Sign Up</span>
          </p>
        </form>
      </div>
    </Fragment>
  );
};

export default Login;








  


  
