
import { useState } from 'react';

const useChartererSearch = () => {
    const [data, setData] = useState({ vehicles: [], totalPages: 0, totalVehicles: 0 });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const searchByState = async (state, page = 1) => {
        setLoading(true);
        setError(null);

        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/charterer/search-by-state?page=${page}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ state })
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Network response was not ok');
            }

            const result = await response.json();
            setData(result);
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    return { data, loading, error, searchByState };
};

export default useChartererSearch;


