import { useContext, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { AuthContext } from "../../../../Context/AuthContext";
import Spinner from "../../../Spinner/Spinner"



const BusinessInfoForm = () => {
  const {user, token } = useContext(AuthContext);
  const queryClient = useQueryClient();
  const [formData, setFormData] = useState({
    businessName: "",
    businessType: "",
    businessAddress: "",
    contactPerson: "",
    contactEmail: "",
    contactPhoneNumber: "",
    businessDescription: "",
    bankAccountDetails: "",
    taxIdentificationNumber: "",
    businessLicenseOrPermit: "",
    valueAddedTaxRegistration: "",
    goodsAndServicesTaxRegistration: "",
    businessRegistrationNumber: "",
    customsIdentificationNumber: "",
    productInformation: "",
    returnsAndRefundsPolicy: "",
    customerSupportInformation: ""
  });

  const [successMessage, setSuccessMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);



const mutation = useMutation(
    async (formData) => {
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/users/update/merchants/${user.userId}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify(formData)
      });

      if (!response.ok) {
        throw new Error("Failed to submit business information");
      }

      return response.json();
    },
    {
      onSuccess: () => {
        setSuccessMessage("Business information submitted successfully!");
        queryClient.invalidateQueries('businessMerchant');
        setTimeout(() => {
          setSuccessMessage("");
          setIsLoading(false)
        }, 3000);
      },
      onError: (error) => {
        console.error("Error submitting business information:", error);
      },
      onSettled: () => {
        setIsLoading(false);
      }
    }
  );

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    mutation.mutate(formData);
    setIsLoading(true);
  };

  return (
    <div className="merchant-business-info-card">
       <h3>Update Your Business Infomation</h3>
      <form onSubmit={handleSubmit}>
        <span>
            <label>Business Name</label>
        <input type="text" name="businessName" value={formData.businessName} onChange={handleChange} required />
        </span>
        <span>
            <label>Business Type</label>
        <input type="text" name="businessType" value={formData.businessType} onChange={handleChange} required />
        </span>
        <span>
            <label>Business Address</label>
        <input type="text" name="businessAddress" value={formData.businessAddress} onChange={handleChange} />
        </span>
        <span>
            <label> Contact Person</label>
        <input type="text" name="contactPerson" value={formData.contactPerson} onChange={handleChange} required />
        </span>
        <span>
            <label>Contact Email</label>
        <input type="email" name="contactEmail" value={formData.contactEmail} onChange={handleChange} required/>
        </span>
        <span>
            <label>Contact Phone Number</label>
        <input type="tel" name="contactPhoneNumber" value={formData.contactPhoneNumber} onChange={handleChange} required/>
        </span>
        <span>
            <label> Business Description</label>
        <input type="text" name="businessDescription" value={formData.businessDescription} onChange={handleChange} required />
        </span>
        <span>
            <label>Bank Account Details</label>
        <input type="text" name="bankAccountDetails" value={formData.bankAccountDetails} onChange={handleChange} required />
        </span>
        <span>
            <label>Tax Identification Number</label>
        <input type="text" name="taxIdentificationNumber" value={formData.taxIdentificationNumber} onChange={handleChange} required />
        </span>
        <span>
            <label>Business License Or Permit</label>
        <input type="text" name="businessLicenseOrPermit" value={formData.businessLicenseOrPermit} onChange={handleChange} required/>
        </span>
        <span>
            <label>Value Added Tax Registration</label>
        <input type="text" name="valueAddedTaxRegistration" value={formData.valueAddedTaxRegistration} onChange={handleChange} required />
        </span>
        <span>
            <label>Goods And Services Tax Registration</label>
        <input type="text" name="goodsAndServicesTaxRegistration" value={formData.goodsAndServicesTaxRegistration} onChange={handleChange} required />
        </span>
        <span>
            <label>Business Registration Number</label>
        <input type="text" name="businessRegistrationNumber" value={formData.businessRegistrationNumber} onChange={handleChange} required/>
        </span>
        <span>
            <label>Customs Identification Number</label>
        <input type="text" name="customsIdentificationNumber" value={formData.customsIdentificationNumber} onChange={handleChange} required />
        </span>
        <span>
            <label>Product Information</label>
        <input type="text" name="productInformation" value={formData.productInformation} onChange={handleChange} required />
        </span>
        <span>
            <label> Returns And Refunds Policy</label>
        <input type="text" name="returnsAndRefundsPolicy" value={formData.returnsAndRefundsPolicy} onChange={handleChange} required />
        </span>
        <span>
            <label>Customer Support Information</label>
        <input type="text" name="customerSupportInformation" value={formData.customerSupportInformation} onChange={handleChange} required/>
        </span>
        
        <button type="submit" disabled={isLoading}>
            {isLoading ? <Spinner/> : "Submit"}
        </button>
      </form>
      {successMessage && <p style={{fontFamily:'Montserrat',textAlign:'center'}}>{successMessage}</p>}
    </div>
  );
};

export default BusinessInfoForm;
