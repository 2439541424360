import { NavLink as Link, Outlet } from "react-router-dom";
import { Fragment, useState } from "react";
import "./MerchantAdmin.css";

const MerchantAdminNav = () => {
    const [isMobile, setIsMobile] = useState(false);

    const handleMobile = () => {
        setIsMobile(!isMobile)
    };

    const NavAdmin = ({ isActive }) => ({
        color: isActive ? "#284697" : "",
        fontSize: isActive ? '20px' : ""
    });

    return (
        <Fragment>
            <div className="merchant-admin-nav">
             <Link><img src="https://i.ibb.co/SywHnkP/Frame-1-1.png" alt="" /></Link>
               <div className={`navlink ${isMobile ? "mobile-link active" : ""}`} onClick={() => setIsMobile(false)}> 
                <Link to='/' style={NavAdmin}>Overview</Link>
                <Link to='/MerchantProducts' style={NavAdmin}>Products</Link>
                <Link to='/MerchantOrder' style={NavAdmin}>Orders</Link>
                <Link to='/MerchantAccount' style={NavAdmin}>Account</Link>
               </div>
               <div className="mobile-menu" onClick={handleMobile}>
               {isMobile ? (
            <i className="fa-solid fa-xmark"></i>
          ) : (
            <i className="fa-solid fa-bars"></i>
          )}
              
               </div>
            </div>
            <Outlet />
        </Fragment>
    );
};

export default MerchantAdminNav;
