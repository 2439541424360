import "./superadminregsiterlogistics.css";
import useRegisterLogisticsOfficer from "../libs/useRegisterLogisticsOfficer";
import { IoMdArrowBack } from "react-icons/io";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

function Superadminresgisterlogistics() {
  const [formData, setFormData] = useState({
    email: "",
    first_name: "",
    last_name: "",
    phone_number: "",
    country_code: "+234",
    branch_full_address: "",
    address: "",
  });

  const [errorMessage, setErrorMessage] = useState("");
  const [successMssg, setSuccessMsg] = useState(false);
  const navigate = useNavigate();

  const registerMutation = useRegisterLogisticsOfficer();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    setErrorMessage(null);
  };

  const handleRegister = async () => {
    try {
      if (
        formData.email === "" ||
        formData.first_name === "" ||
        formData.last_name === "" ||
        formData.phone_number === "" ||
        formData.country_code === "" ||
        formData.branch_full_address === "" ||
        formData.address === ""
      ) {
        setErrorMessage("Please fill in all required fields.");
        return;
      }
      await registerMutation.mutateAsync(formData);

      setFormData({
        email: "",
        first_name: "",
        last_name: "",
        phone_number: "",
        country_code: "",
        branch_full_address: "",
        address: "",
      });
      setSuccessMsg("Registration successful, Code sent to Email");
      setTimeout(() => {
        navigate("/Superadminlogistic");
      }, 3000);
    } catch (error) {
      console.error("Registration failed:", error.message);
    }
  };

  return (
    <div className="register_logistic-officer">
      <div className="header">
        <IoMdArrowBack
          onClick={() => navigate("/Superadminlogistic")}
          className="icon"
        />
        <p>Register New Logistics Officer</p>
      </div>
      <div className="form">
        <label>
          First Name
          <input
            type="text"
            name="first_name"
            value={formData.first_name}
            onChange={handleChange}
            required
          />
        </label>

        <label>
          Last Name
          <input
            type="text"
            name="last_name"
            value={formData.last_name}
            onChange={handleChange}
            required
          />
        </label>

        <label>
          Email
          <input
            type="text"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </label>

        <label>
          Phone Number
          <div className="phone-input">
            <select
              name="country_code"
              value={formData.country_code}
              onChange={handleChange}
              required
            >
              <option value="+234">+234</option>
              <option value="+1">+1</option>
              <option value="+91">+91</option>
            </select>
            <input
              type="text"
              name="phone_number"
              value={formData.phone_number}
              onChange={handleChange}
              required
            />
          </div>
        </label>

        <label>
          Branch Full Address
          <select
            name="branch_full_address"
            value={formData.branch_full_address}
            onChange={handleChange}
            required
          >
            <option value="" disabled>
              Select Branch
            </option>
            <option value="Branch 1">Branch 1</option>
            <option value="Branch 2">Branch 2</option>
            <option value="Branch 3">Branch 2</option>
            <option value="Branch 4">Branch 2</option>
          </select>
        </label>

        <label>
          Address
          <input
            type="text"
            name="address"
            value={formData.address}
            onChange={handleChange}
            required
          />
        </label>

        <button onClick={handleRegister} disabled={registerMutation.isLoading}>
          {registerMutation.isLoading ? "Registering..." : "Register"}
        </button>

        {errorMessage && <p className="error-message">{errorMessage}</p>}
        {successMssg && <span>{successMssg}</span>}
        {registerMutation.isError && <p>{registerMutation.error.message}</p>}
      </div>
    </div>
  );
}

export default Superadminresgisterlogistics;
