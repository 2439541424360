import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Notfound.css'; 

function NotFound() {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/');
    }, 3000);

    return () => clearTimeout(timer); 
  }, [navigate]);

  return (
    <div className="not-found-container">
      <h1>404</h1>
      <h2>Page Not Found</h2>
      <p>You will be redirected to the homepage shortly...</p>
    </div>
  );
}

export default NotFound;
