import { useContext } from "react";
import { useInfiniteQuery } from "react-query";
import { AuthContext } from "../../Context/AuthContext";

const fetchDriverUsers = async ({ pageParam = 1 }, token) => {
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/users/drivers?page=${pageParam}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error('Error fetching driver users');
  }

  return response.json();
};

const useGetDriverUsers = () => {
  const { token } = useContext(AuthContext);
  
  return useInfiniteQuery(
    ['driverUsers'],
    ({ pageParam = 1 }) => fetchDriverUsers({ pageParam }, token),
    {
      getNextPageParam: (lastPage) => {
        const nextPage = lastPage.currentPage + 1;
        return nextPage <= Math.ceil(lastPage.totalDrivers / 10) ? nextPage : undefined;
      },
    }
  );
};

export default useGetDriverUsers;


