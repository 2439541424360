import { useQuery } from 'react-query';
import { useContext } from 'react';
import { AuthContext } from '../../../Context/AuthContext';

const useAdminTruck = (page) => {
  const { token, user } = useContext(AuthContext);

  const fetchAdminTruck = async () => {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/freight/get-trucks/admin/tohire?state=${user?.state}&page=${page}`, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });

    if (!response.ok) {
      throw new Error('Failed to fetch Trucks');
    }

    return response.json();
  };

  const { data, isLoading, isError } = useQuery(['adminorder', page], fetchAdminTruck);

  return { trucks: data?.trucks || [], totalTrucks: data?.totalTrucks || 0, isLoading, isError };
};

export default useAdminTruck;

