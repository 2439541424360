

import { useQuery } from 'react-query';

const useCarCollection = (page) => {
  const fetchCars = async () => {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/charterer/vehicle/car?page=${page}`, {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    if (!response.ok) {
      throw new Error('Failed to fetch cars');
    }

    return response.json();
  };

  const { data: carData = {}, isLoading, isError } = useQuery(['charteredCar', page], fetchCars);

  return { carData, isLoading, isError }; 
};

export default useCarCollection; 



