
import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom"; 
import GetProductById from "../lib/getProductById";
import useDeleteProduct from "../lib/useDeleteProduct";
import useEditProduct from "../lib/fetchEditProduct";
import { ReactComponent as CautionIcon } from "../../../../Assets/caution-svgrepo-com 1.svg";

const MerchantProDetail = () => {
  const { id } = useParams(); 
  const { data, isLoading, isError } = GetProductById(id);
  const [deleteOverlay, setDeleteOverlay] = useState(false);
  const [editOverlay, setEditOverlay] = useState(false);
  const [formData, setFormData] = useState({});
  const { deleteProduct, isLoading: deleteLoading } = useDeleteProduct();
  const { editProduct, isLoading: editLoading } = useEditProduct();
  const [items_value, setItesm_value] = useState("0.00")


  const handleDeleteOverlay = () => {
    setDeleteOverlay(true);
  }

  const handleEditOverlay = () => {
    setEditOverlay(true);
  };

  const handleDeleteProduct = async () => {
    try {
      await deleteProduct.mutateAsync(id);
      setDeleteOverlay(false);
    } catch (error) {
      console.error('Error deleting product:', error);
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  }

  useEffect(() => {
    if (data && data.product) {
      setFormData({
        name: data.product.name,
        description: data.product.description,
        brand: data.product.brand,
        color: data.product.color,
        sizeSmall: data.product.sizeSmall,
        sizeMedium: data.product.sizeMedium,
        sizeLarge: data.product.sizeLarge,
        sizeSmallextraLarge: data.product.sizeSmallextraLarge,
        sizeXXLarge: data.product.sizeXXLarge,
        sizeXXXLarge: data.product.sizeXXXLarge,
        price: data.product.price
      });
    }
  }, [data]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await editProduct.mutateAsync({ id, formData });
      setEditOverlay(false);
    } catch (error) {
      console.error('Error editing product:', error);
    }
  }

  const handleItems_valueChange = (event) => {
    let inputValue = event.target.value.replace(/[^\d]/g, '');
    inputValue = Number(inputValue) / 100;

    const items_value = inputValue.toLocaleString('en-US', {
      style: 'currency',
      currency: 'NGN', 
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    setItesm_value(items_value);
  };

  return (
    <>
      {deleteOverlay && (
        <div className="product-deletes">
          <div className="product-deletes-card">
            <CautionIcon/>
            <h3>Delete</h3>
            <p>Are you sure you want to delete this product?</p>
            <div className="btn">
              <button onClick={() => setDeleteOverlay(false)}>No</button>
              <button onClick={handleDeleteProduct}>
                {deleteLoading ? 'Deleting...' : 'Yes'}
              </button>
            </div>
          </div>
        </div>
      )}

      {editOverlay && (
        <div className="edit-overlay">
          <div className="edit-overlay-card">
            <h3>Edit product</h3>
            <form onSubmit={handleSubmit}>
              <span>
                <label>Name</label>
                <input type="text" name="name" value={formData.name} onChange={handleChange} />
              </span>
              <span>
                <label>Brand</label>
                <input type="text" name="brand" value={formData.brand} onChange={handleChange} />
              </span>
              <span>
                <label>Price</label>
                <input
                value={items_value}
                onChange={handleItems_valueChange}
                required
              />
              </span>
             <span>
              <label>Color</label>
              <input type="text" name="color" value={formData.color} onChange={handleChange} />
             </span>
             <span>
              <label>Small Size</label>
             <input type="text" name="sizeSmall" value={formData.sizeSmall} onChange={handleChange} />
             </span>
             <span>
              <label>Medium Size</label>
              <input type="text" name="sizeMedium" value={formData.sizeMedium} onChange={handleChange} />
             </span>
              <span>
                <label>Large Size</label>
                <input type="text" name="sizeLarge" value={formData.sizeLarge} onChange={handleChange} />
              </span>
              <span>
                <label>Small Extral Large</label>
                <input type="text" name="sizeSmallextraLarge" value={formData.sizeSmallextraLarge} onChange={handleChange} />
              </span>
              <span>
                <label>XX-Large</label>
                <input type="text" name="sizeXXLarge" value={formData.sizeXXLarge} onChange={handleChange} />
              </span>
              <span>
                <label>XXX-Large</label>
                <input type="text" name="sizeXXXLarge" value={formData.sizeXXXLarge} onChange={handleChange} />
              </span>
             <span>
              <label>Description</label>
              <textarea type="text" name="description" value={formData.description} onChange={handleChange} />
             </span>
              <div className="btn">
                <button onClick={() => setEditOverlay(false)}>No</button>
                <button type="submit">
                  {editLoading ? "EDITING..." : 'Submit'}</button>
              </div>
            </form>
          </div>
        </div>
      )}

      <div className="merchant-product-detail">
        {isLoading && <p style={{fontFamily:"Montserrat"}}>Loading...</p>}
        {isError && <p style={{fontFamily:"Montserrat"}}>Error: {isError}</p>}
        {data && data.product && ( 
          <>
            <div className="merchant-product-con1">
              <img src={data.product.images[0]} alt="" />
            </div>
            <div className="merchant-product-con2">
              <h3>{data.product.name}</h3>
              <p>{data.product.category}</p>
              <p> {data.product.color}</p>
              <p>{data.product.price
                      ? (data.product.price ).toLocaleString("en-US", {
                          style: "currency",
                          currency: "NGN",
                        })
                      : ""}</p>
              <p> {data.product.brand}</p> 
              <p> {data.product.description}</p>
              <div className="size-card ">
                {data.product.sizeLarge && <p> {data.product.sizeLarge}</p>}
                {data.product.sizeMedium && <p> {data.product.sizeMedium}</p>}
                {data.product.sizeSmall && <p> {data.product.sizeSmall}</p>}
                {data.product.sizeSmallextraLarge && <p> {data.product.sizeSmallextraLarge}</p>}
                {data.product.sizeXXLarge && <p> {data.product.sizeXXLarge}</p>}
                {data.product.sizeXXXLarge && <p> {data.product.sizeXXXLarge}</p>}
              </div>  
              <div className="btn">
                <button onClick={handleEditOverlay}>EDIT</button>
                <button onClick={handleDeleteOverlay}>DELETE</button>
              </div>
              
              <Link to='/MerchantProducts'>Back</Link>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default MerchantProDetail;
