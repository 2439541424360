import React, { useContext, useState } from "react";
import { ReactComponent as GetquoteIcon } from "../../Assets/QUOTE-text-svgrepo-com 1.svg";
import { GlobalContext } from "../../GlobalContext/GlobalContext";
import { ReactComponent as AnimationIcon } from "../../Assets/animation_llz85rwh_small 1.svg";
import { Fragment } from "react";
import useShippingCost from "../../libs/useShippingCost";
import useAllStates from "../../libs/getAllStates";
import { ReactComponent as CautionIcon } from "../../Assets/caution-svgrepo-com 1.svg";
import Spinner from "../Spinner/Spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const defaultForm = {
  sender_state: "",
  receiver_state: "",
  weight: "",
  quantity: "",
};

const QuoteMainForm = () => {
  const [form, setForm] = useState(defaultForm);
  const { isGetquoteOpen, setIsGetquoteOpen } = useContext(GlobalContext);
  const { sender_state, receiver_state, weight, quantity } = form;
  const [resData, setResData] = useState(null);
  const [items_value, setItems_value] = useState("");
  const [displayValidationError, setDisplayValidationError] = useState(false);
  const { mutation, isLoading, isError } = useShippingCost();
  const { data: allStates } = useAllStates();

  const handleSubmit = async () => {
    setIsGetquoteOpen(true);
    if (
      sender_state.length === 0 ||
      receiver_state.length === 0 ||
      weight.length === 0 ||
      quantity.length === 0 ||
      items_value.length === 0
    ) {
      setDisplayValidationError(true);
      setIsGetquoteOpen(false);
      return;
    }

    const numericPrice = parseFloat(items_value.replace(/[^0-9.-]+/g, ""));

    try {
      const data = await mutation.mutateAsync({
        sender_state: sender_state,
        receiver_state: receiver_state,
        weight: weight,
        quantity: quantity,
        items_value: numericPrice,
      });

      setResData(data);
      setDisplayValidationError(false);
    } catch (error) {
      setDisplayValidationError(false);
      setIsGetquoteOpen(false);
      if (error?.response?.data?.message) {
        toast.error(mutation.isError);
      } else {
        toast.error(
          "Sorry, we are not shipping to these state combination yet,Please try again later."
        );
      }
      console.error(error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
    setDisplayValidationError(false);
  };

  const handleDoneButtonClick = () => {
    setIsGetquoteOpen(false);
    setResData(null);
  };

  const handleItems_valueChange = (event) => {
    let inputValue = event.target.value.replace(/[^\d]/g, "");
    inputValue = Number(inputValue) / 100;

    const items_value = inputValue.toLocaleString("en-US", {
      style: "currency",
      currency: "NGN",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    setItems_value(items_value);
  };

  return (
    <Fragment>
      {isGetquoteOpen && (
        <div className="get-quote-pop-card">
          <div className="get-quote-pop-wrapper">
            {mutation.isLoading && <Spinner />}
            {isError && (
              <div className="error">
                <CautionIcon />
                {mutation.isError && <p>Error fetching shipping cost</p>}
                <button onClick={() => setIsGetquoteOpen(false)}>Okay</button>
              </div>
            )}
            {!isLoading && !isError && resData && (
              <>
                <h3>Shipment Cost Estimate</h3>
                <p>
                  This is just an estimated cost for your shipment. Actual price
                  may vary after creating a shipment.
                </p>
                <span>
                  <h3>Quantity: {resData?.quantity}</h3>
                  <h3>Weight: {resData?.weight}kg</h3>
                </span>
                <div className="get-quote-package">
                  <AnimationIcon />
                </div>
                <div className="get-qoute-cost">
                  <div className="wrapper">
                    <h3>Total shipping Cost:</h3>
                    <h3>
                      {resData?.totalShippingCost
                        ? resData.totalShippingCost.toLocaleString("en-US", {
                            style: "currency",
                            currency: "NGN",
                          })
                        : ""}
                    </h3>
                  </div>
                </div>
                <button onClick={handleDoneButtonClick}>Done</button>
              </>
            )}
          </div>
        </div>
      )}
      <div className="get-form-container">
        <div className="getquote-con">
          <span>
            <h3>Get Quote</h3>
            <p>Get a free Shipping Quote online</p>
          </span>
          <GetquoteIcon />
        </div>
        <form>
          <div className="input-combination">
            <span>
              <label>Origin State</label>
              <select
                name="sender_state"
                value={sender_state}
                onChange={handleChange}
                required
              >
                <option value="">Select Origin State</option>
                {allStates &&
                  allStates.map((state) => (
                    <option key={state._id} value={state.state}>
                      {state.state}
                    </option>
                  ))}
              </select>
            </span>

            <span>
              <label>Destination State</label>
              <select
                name="receiver_state"
                value={receiver_state}
                onChange={handleChange}
                required
              >
                <option value="">Select Destination State</option>
                {allStates &&
                  allStates.map((state) => (
                    <option key={state._id} value={state.state}>
                      {state.state}
                    </option>
                  ))}
              </select>
            </span>
          </div>

          <div className="input-combination">
            <span>
              <label>Weight(kg)</label>
              <input
                type="number"
                name="weight"
                placeholder="Weight"
                value={weight}
                onChange={handleChange}
                required
              />
            </span>

            <span>
              <label>Quantity</label>
              <input
                type="number"
                name="quantity"
                placeholder="Enter Quantity"
                value={quantity}
                onChange={handleChange}
                required
              />
            </span>
          </div>
          <div className="input-combination">
            <span>
              <label>Value</label>
              <input
                value={items_value}
                placeholder="Enter Items value"
                onChange={handleItems_valueChange}
                required
              />
            </span>
          </div>

          {displayValidationError && (
            <p
              style={{
                color: "red",
                fontFamily: "Montserrat",
                textAlign: "center",
                margin: "10px auto",
              }}
            >
              Please ensure that all fields are filled.
            </p>
          )}

          <button type="button" onClick={handleSubmit}>
            Get Quotes
          </button>
        </form>
        <ToastContainer className="toast-container" />
      </div>
    </Fragment>
  );
};

export default QuoteMainForm;
