import { useQuery } from 'react-query';
import axios from 'axios';
import { AuthContext } from '../../Context/AuthContext';
import { useContext } from 'react';

const useStatistics = () => {
  const { token } = useContext(AuthContext);

  const fetchStatistics = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/order/statistics`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error) {
      throw new Error('Error fetching statistics. Please try again.');
    }
  };

  return useQuery('statistics', fetchStatistics);
};

export default useStatistics;
