import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import GetTruckById from '../../../libs/fetchTrucksById';
import useEditTruck from '../../../libs/useEditTruck';
import useDeleteTruck from '../../../libs/useDeleteTruck';
import useMarkTruckAsOnRepair from '../../../libs/markTruckAsOnRepair';
import { ReactComponent as CautionIcon } from '../../../../../Assets/caution-svgrepo-com 1.svg';
import "./TruckDetail.css";

const TruckDetail = () => {
  const { id } = useParams();
  const { data, isLoading, isError } = GetTruckById(id);
  const [formData, setFormData] = useState({});
  const [editOverlay, setEditOverlay] = useState(false);
  const [deleteOverlay, setDeleteOverlay] = useState(false);
  const [repairOverlay, setRepairOverlay] = useState(false);
  const { editTruck, isLoading: editLoading, error: editError } = useEditTruck();
  const { deleteTruck, isLoading: deleteLoading, error: deleteError } = useDeleteTruck();
  const { mutate: markTruckAsOnRepair, isLoading: repairLoading, error: repairError } = useMarkTruckAsOnRepair();
  const [items_value, setItems_value] = useState('0.00');

  const handleDeleteOverlay = () => setDeleteOverlay(true);
  const handleEditOverlay = () => setEditOverlay(true);
  const handleRepairOverlay = () => setRepairOverlay(true);

  const handleDeleteTruck = async () => {
    try {
      await deleteTruck.mutateAsync(id);
      setDeleteOverlay(false);
    } catch (error) {
      console.error('Error deleting truck:', error);
    }
  };

  const handleMarkTruckAsOnRepair = async () => {
    try {
      await markTruckAsOnRepair(id);
      toast.success('Truck marked as on-repair successfully!');
    } catch (error) {
      toast.error('Error marking truck as on-repair.');
      console.error('Error marking truck as on-repair:', error);
    } 
  };

  useEffect(() => {
    if(!repairLoading && !repairError && markTruckAsOnRepair) {
      setRepairOverlay(false)
    }
  }, [repairLoading, repairError, markTruckAsOnRepair])

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (data) {
      setFormData({
        truckType: data.truckType,
        description: data.description,
        truckModel: data.truckModel,
        pricePerDay: data.pricePerDay,
      });
      setItems_value(
        data.pricePerDay.toLocaleString('en-US', {
          style: 'currency',
          currency: 'NGN',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      );
    }
  }, [data]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await editTruck.mutateAsync({ id, formData });
      setEditOverlay(false);
    } catch (error) {
      console.error('Error editing truck:', error);
    }
  };

  const handleItems_valueChange = (event) => {
    let inputValue = event.target.value.replace(/[^\d]/g, '');
    inputValue = Number(inputValue) / 100;

    const items_value = inputValue.toLocaleString('en-US', {
      style: 'currency',
      currency: 'NGN',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    setItems_value(items_value);
    setFormData((prevState) => ({
      ...prevState,
      pricePerDay: inputValue,
    }));
  };

  return (
    <>
      <ToastContainer />
      {deleteOverlay && (
        <div className="truck-deletes">
          <div className="truck-deletes-card">
            <CautionIcon />
            <h3>Delete</h3>
            <p>Are you sure you want to delete this product?</p>
            {deleteError && <p style={{ color: 'red' }}>{deleteError.message}</p>}
            <div className="btn">
              <button onClick={() => setDeleteOverlay(false)}>No</button>
              <button onClick={handleDeleteTruck}>
                {deleteLoading ? 'Deleting...' : 'Yes'}
              </button>
            </div>
          </div>
        </div>
      )}
      {editOverlay && (
        <div className="truck-edit-overlay">
          <div className="edit-overlay-card">
            <h3>Edit Vehicle</h3>
            {editError && <p style={{ color: 'red' }}>{editError.message}</p>}
            <form onSubmit={handleSubmit}>
              <span>
                <label>Price Per Day</label>
                <input
                  value={items_value}
                  onChange={handleItems_valueChange}
                  required
                />
              </span>
              <span>
                <label>Truck Type</label>
                <input type="text" name="truckType" value={formData.truckType} onChange={handleChange} />
              </span>
              <span>
                <label>Truck Model</label>
                <input type="text" name="truckModel" value={formData.truckModel} onChange={handleChange} />
              </span>
              <span>
                <label>Description</label>
                <textarea type="text" name="description" value={formData.description} onChange={handleChange} />
              </span>
              <div className="btn">
                <button type="button" onClick={() => setEditOverlay(false)}>No</button>
                <button type="submit">
                  {editLoading ? 'EDITING...' : 'Submit'}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {repairOverlay && (
        <div className="reset-status-overlay">
          <div className="card">
            <p>Are you sure you want to set this Truck on-repair?</p>
            {repairError && <p style={{ color: 'red' }}>{repairError.message}</p>}
            <div className="btn">
              <button onClick={() => setRepairOverlay(false)}>No</button>
              <button onClick={handleMarkTruckAsOnRepair} disabled={repairLoading}>
                {repairLoading ? 'REPAIRING...' : 'Yes'}
              </button>
            </div>
          </div>
        </div>
      )}

      <div className="freight-truck-details">
        {isLoading && <p style={{ fontFamily: 'Montserrat' }}>Loading...</p>}
        {isError && <p style={{ fontFamily: 'Montserrat' }}>Error: {isError.message}</p>}
        {data && (
          <>
            <div className="freight-truck-con1">
              <img src={data.images[0]} alt="" />
            </div>
            <div className="freight-truck-con2">
              <h3>{data.truckType}</h3>
              <p>{data.truckModel}</p>
              <h4>
                {data.pricePerDay
                  ? data.pricePerDay.toLocaleString('en-US', {
                      style: 'currency',
                      currency: 'NGN',
                    })
                  : ''}
              </h4>
              <p>{data.description}</p>
              <div className="btn">
                <button onClick={handleEditOverlay}>EDIT</button>
                <button onClick={handleDeleteOverlay}>DELETE</button>
                <button onClick={handleRepairOverlay}>REPAIR</button>
              </div>
              <Link to="/freighttruck">Back</Link>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default TruckDetail;



