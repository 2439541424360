import { useContext } from "react";
import { useInfiniteQuery } from "react-query";
import { AuthContext } from "../../Context/AuthContext";

const fetchRiders = async ({ pageParam = 1 }, token, user) => {
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/users/riders/${user.branch_full_address}?page=${pageParam}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error('Error fetching Riders');
  }

  return response.json();
};

const useRidersByBranch = () => {
  const { token, user } = useContext(AuthContext);
  
  return useInfiniteQuery(
    ['riders', user.branch_full_address],
    ({ pageParam = 1 }) => fetchRiders({ pageParam }, token, user), 
    {
      getNextPageParam: (lastPage) => {
        const nextPage = lastPage.currentPage + 1;
        return nextPage <= Math.ceil(lastPage.totalRiders / 10) ? nextPage : undefined;
      },
    }
  );
};

export default useRidersByBranch ;




