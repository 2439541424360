import { useContext, useEffect } from "react";
import { CartContext } from "../../../../Context/CartContext";
import { Fragment } from "react";
import "./CartPayment.css";

const CartItemPayment = () => {
  const { cartItems, totalCart } = useContext(CartContext);
  // console.log(cartItems)
  // console.log(totalCart); 

  // Store cart items and total to local storage
  useEffect(() => {
    localStorage.setItem("cartItems", JSON.stringify(cartItems));
    localStorage.setItem("totalCart", totalCart);
  }, [cartItems, totalCart]);

  return (
    <Fragment>
      <div className="cart-payment-wrapper">
        <div className="cart-list-payment">
          <div className="cart-card-payment">
            {cartItems.map((cartItem) => (
              <div className="cart-check-payment" key={cartItem.product_id}>
                <div className="cart-payment-con">
                  <div className="cart-payment-span-1">
                    <img src={cartItem.images[0]} alt={cartItem.decription} />
                    <h3>{cartItem.name}</h3>
                  </div>
                  <div className="cart-payment-span-2">
                    <p>
                      {cartItem.quantity && cartItem.price
                        ? (cartItem.quantity * cartItem.price).toLocaleString(
                            "en-US",
                            {
                              style: "currency",
                              currency: "NGN",
                            }
                          )
                        : 0}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="cart-payment-sumarry">
            <div className="order-payment-card">
              <span>
                <p>Subtotal</p>
                <h3>
                  {totalCart
                    ? totalCart.toLocaleString("en-US", {
                        style: "currency",
                        currency: "NGN",
                      })
                    : ""}
                </h3>
              </span>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default CartItemPayment;




