import React, { Fragment } from "react";
import useFetchDriverAssignmentById from "../Libs/fetchDriverAssignmentById";
import { FaLongArrowAltLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import allStatesAndCities from "../../utils/state_and_city";
import useUpdatePackageLeftOriginFacility from "../Libs/useUpdatePackageLeftOriginFacility";
import useUpdatePackageStatusToInTransit from "../Libs/useUpdatePackageStatusToInTransit";
import useUpdatePackageStatusToAtDistributionCenter from "../Libs/useUpdatePackageStatusToAtDistributionCenter";
import Spinner from "../../utils/spinner"
import "./Driverassigneddetails.css";

function formatCurrency(amount) {
  const formatter = new Intl.NumberFormat("en-NG", {
    style: "currency",
    currency: "NGN",
  });
  return formatter.format(amount);
}

function Driverassigndetails() {
  const { data, isLoading, isError, error } = useFetchDriverAssignmentById();
  const [updateOverlay, setUpdateOverlay] = useState(false);
  const [packageId, setPackageId] = useState(null);
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [updating, setUpdating] = useState(false);
  const [branchFullAddress, setBranchFullAddress] = useState("");
  const [errors, setErrors] = useState(null)

  const { updatePackageLeftOriginFacility } =
    useUpdatePackageLeftOriginFacility();
  const { updatePackageStatusToInTransit } =
    useUpdatePackageStatusToInTransit();
  const { updatePackageStatusToAtDistributionCenter } =
    useUpdatePackageStatusToAtDistributionCenter();

  const handleUpdate = async (e) => {
    e.preventDefault()
    setUpdating(true);
    setErrors(null)
    try {
      if (selectedStatus === "Left Origin Facility") {
        await updatePackageLeftOriginFacility(packageId, {
          state_id: selectedState,
          city: selectedCity,
        });
      } else if (selectedStatus === "In Transit") {
        await updatePackageStatusToInTransit(packageId, {
            state_id: selectedState,
            city: selectedCity,
        });
      } else if (selectedStatus === "At Distribution Center") {
        await updatePackageStatusToAtDistributionCenter(packageId, {
          state_id: selectedState,
          city: selectedCity,
          branch_full_address: branchFullAddress,
        });
      }

      setPackageId(null);
      setUpdateOverlay(false);
      setSelectedStatus("");
      setSelectedState("");
      setSelectedCity("");
      setBranchFullAddress("");
    } catch (error) {
      setErrors(error.message)
    } finally {
        setUpdating(false); 
      }
  };

  const navigate = useNavigate();

  return (
    <Fragment>
      {updateOverlay && (
        <div className="updatepkg-over">
          <div className="card">
            <p>Trip Status</p>
            <form onSubmit={handleUpdate}>
              <select
                value={selectedStatus}
                onChange={(e) => {
                  setSelectedStatus(e.target.value);
                  setSelectedState("");
                  setSelectedCity("");
                }}
              >
                <option>Select Status</option>
                <option value="Left Origin Facility">
                  Left Origin Facility
                </option>
                <option value="In Transit">In Transit</option>
                <option value="At Distribution Center">
                  At Distribution Center
                </option>
              </select>
                <>
                  <select
                    value={selectedState}
                    onChange={(e) => {
                      setSelectedState(e.target.value);
                      setSelectedCity("");
                    }}
                  >
                    <option value="">Select State</option>
                    {Object.keys(allStatesAndCities).map((state) => (
                      <option key={state} value={state}>
                        {state}
                      </option>
                    ))}
                  </select>
                  <select
                    value={selectedCity}
                    onChange={(e) => setSelectedCity(e.target.value)}
                  >
                    <option value="">Select City</option>
                    {selectedState &&
                      allStatesAndCities[selectedState].map((city) => (
                        <option key={city} value={city}>
                          {city}
                        </option>
                      ))}
                  </select>
                </>
           
              {selectedStatus === "At Distribution Center" && (
                <select
                  value={branchFullAddress}
                  onChange={(e) => setBranchFullAddress(e.target.value)}
                >
                  <option value="">Choose Center</option>
                  <option value="Branch 1">Branch 1</option>
                  <option value="Branch 2">Branch 2</option>
                  <option value="Branch 3">Branch 3</option>
                  <option value="Branch 4">Branch 4</option>
                  <option value="Branch 5">Branch 5</option>
                </select>
              )}

              <div className="btn">
              
                <button
                  onClick={() => {
                    setPackageId(null);
                    setUpdateOverlay(false);
                    setSelectedStatus("");
                    setSelectedState("");
                    setSelectedCity("");
                    setBranchFullAddress("");
                  }}
                >
                  Cancel
                </button>
                <button >
                  {updating ? "Updating..." : "Update"}
                </button>
              </div>
             {errors && <p>{errors}</p>}
            </form>
          </div>
        </div>
      )}

      <div className="assignment-details">
        {isError && <p>Error: {error.message}</p>}
        {isLoading && <Spinner/>}
        {data && data.driverAssignment.accept && (
          <div className="ass-details">
            {data.driverAssignment.accept.map((acceptId) => (
              <div key={acceptId} className="card">
                <div className="header">
                  <span>
                    <FaLongArrowAltLeft onClick={() => navigate("/Driveraccepted")} />
                    <h2>ID{acceptId.slice(0, 8).toUpperCase()}</h2>
                  </span>
                  <button
                    onClick={() => {
                      setPackageId(data.driverAssignment.package[0]._id);
                      setUpdateOverlay(true);
                    }}
                  >
                    Update package
                  </button>
                </div>
                <div className="receiver-card">
                  <p>RECEIVERS’S INFORMATION</p>
                  <div className="receiver">
                    <span>
                      <p>Full Name</p>
                      <h3>
                        {data.driverAssignment.package[0]?.receiver?.name ||
                          "N/A"}
                      </h3>
                    </span>
                    <span>
                      <p>Phone Number</p>
                      <h3>
                        {data.driverAssignment.package[0]?.receiver
                          ?.phone_number || "N/A"}
                      </h3>
                    </span>
                    <span>
                      <p>Address</p>
                      <h3>
                        {data.driverAssignment.package[0]?.receiver
                          ?.address_1 || "N/A"}
                      </h3>
                    </span>
                    <span>
                      <p>Zip Code</p>
                      <h3>
                        {data.driverAssignment.package[0]?.receiver?.zip_code ||
                          "N/A"}
                      </h3>
                    </span>
                    <span>
                      <p>State</p>
                      <h3>
                        {data.driverAssignment.package[0]?.receiver?.state ||
                          "N/A"}
                      </h3>
                    </span>
                  </div>
                  <p>PACKAGE INFORMAITON</p>
                  <div className="package-items">
                    <span>
                      <p>Weight</p>
                      <h3>
                        {data.driverAssignment.package[0]?.weight || "N/A"}
                      </h3>
                    </span>
                    <span>
                      <p>Quantity</p>
                      <h3>
                        {data.driverAssignment.package[0]?.quantity || "N/A"}
                      </h3>
                    </span>
                    <span>
                      <p>Description</p>
                      <h3>{data.driverAssignment.package[0]?.name || "N/A"}</h3>
                    </span>
                    <span>
                      <p>Shipping Fee</p>
                      <h3>
                        {data.driverAssignment.package[0]?.shipping_cost
                          ? formatCurrency(
                              data.driverAssignment.package[0]?.shipping_cost /
                                100
                            )
                          : "N/A"}
                      </h3>
                    </span>
                    <span>
                      <p>Estimated Delivery Date</p>
                      <h3>
                        {data.driverAssignment.package[0]
                          ?.estimated_delivery_date
                          ? new Date(
                              data.driverAssignment.package[0]?.estimated_delivery_date
                            ).toLocaleDateString("en-US", {
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            })
                          : "N/A"}
                      </h3>
                    </span>
                    <span>
                      <p>Current Status</p>
                      <h3>
                      {data.driverAssignment.package[0]?.status.slice(-1)[0]?.status || "N/A"}
                      </h3>
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </Fragment>
  );
}

export default Driverassigndetails;
