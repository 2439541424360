import React, { useState } from 'react';
import {NavLink as Link, Outlet } from 'react-router-dom';
import { Fragment } from 'react';
import { MdCarRental } from "react-icons/md";
import { IoSettingsOutline } from 'react-icons/io5';
import { SlMenu } from "react-icons/sl";
import { HiOutlineXMark } from "react-icons/hi2";
import { FaTruckFront } from "react-icons/fa6";
import { BsFillTruckFrontFill } from "react-icons/bs";
import { GiCarKey } from "react-icons/gi";
import { TbKeyOff } from "react-icons/tb";
import { MdGridView } from "react-icons/md";
import { TbSteeringWheel } from "react-icons/tb";
import { GiAutoRepair } from "react-icons/gi";
import Freightnavbar from '../Freight_Nav/freightnavbar';

const Freightdash = () => {
    const [isMobile, setIsMobile] = useState(false);

    const handleMenu = () => {
      setIsMobile(!isMobile)
    }
  
      const NavLink = ({ isActive }) => ({
          background: isActive ? '#284697' : '',
          color: isActive ? 'white' : ''
        });

        return (
            <Fragment>
             <Freightnavbar/>
             <div className='charter-nav'>
             <div className='charter-logo'>
               <img src='https://i.ibb.co/vvhxKCL/Frame-1-3x.png' alt='' />
             </div>
             <div className={`charter-mobile-link ${isMobile ? "mobile active" : ""}`} onClick={() => setIsMobile(false)}>
               <Link to='/' style={NavLink}>
               <MdGridView style={{fontSize:'24px'}} />
                 Overview
               </Link>
               <Link to='/neworderfreight' style={NavLink}>
                 <MdCarRental style={{fontSize:'24px'}} />
                 New Order
               </Link>
               <Link to='/freightbookrequest' style={NavLink}>
               <TbKeyOff style={{fontSize:'24px'}} />
                 Book Request
               </Link>
               <Link to='/startedfreight' style={NavLink}>
                 <BsFillTruckFrontFill style={{fontSize:'24px'}}/>
                 On-Trip
               </Link>
               <Link to='/completedfreight' style={NavLink}>
                 <GiCarKey style={{fontSize:'24px'}} />
                 Completed
               </Link>
               <Link to={'/onrepairtruck'}>
               <GiAutoRepair style={{fontSize:'24px'}}  />
               On-Repair
               </Link>
               <Link to='/freighttruck' style={NavLink}>
               <FaTruckFront style={{fontSize:'24px'}}  />
                 Truck
               </Link>
               <Link to='/adminorder' style={NavLink}>
          <TbSteeringWheel style={{fontSize:'24px'}}  />
           Admin Order
          </Link>
               <Link to='/freightsetting' style={NavLink}>
                 <IoSettingsOutline style={{fontSize:'24px'}} />
                 Setting
               </Link>
             </div>
     
             <div className="menu-icon" onClick={handleMenu}>
               {isMobile ? <HiOutlineXMark /> : <SlMenu /> }
             </div>
           </div>
           <Outlet />
            </Fragment>
         );
}

export default Freightdash;
