import React from "react";
import { Fragment } from "react";
import QuoteMainForm from "./QuoteMainForm";
import "./Getquote.css";

const Getquote = () => {
  return (
    <Fragment>
      <div className="get-mother-card">
        <h3>GET A FREE SHIPPING QUOTES ONLINE</h3>
        <p>
          Parcel and Crate, Whether Large or Compact, receive an immediate
          estimate for your shipping requirements. Complete
          the details of your shipment to unveil your quotation. If content,
          proceed seamlessly with your booking process.
        </p>
        <span>
          <div className="get-quote-card">
            <h2>1</h2>
            <h3>ENTER ORIGIN AND DESTINATION</h3>
          </div>
          <div className="get-quote-card">
            <h2>2</h2>
            <h3>COMPLETE YOUR SHIPMENT DETAILS</h3>
          </div>
          <div className="get-quote-card">
            <h2>3</h2>
            <h3>GET AN ESTIMATED QUOTE</h3>
          </div>
        </span>
      </div>
      <div className="get-quote-wrapper">
        <QuoteMainForm />
      </div>
    </Fragment>
  );
};

export default Getquote;
