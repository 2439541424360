
import React, { useState, useEffect, Fragment } from 'react';
import { CiSearch } from "react-icons/ci";
import { FaXmark } from "react-icons/fa6";
import { CiUser } from "react-icons/ci";
import { ReactComponent as SeatIcon } from "../../../../Assets/safety-seat-car-svgrepo-com 13x.svg";
import { BsFuelPump } from "react-icons/bs";
import { GiCarDoor } from "react-icons/gi";
import { TbSteeringWheel } from "react-icons/tb";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useBookRequest from '../libs/fetchBooked';
import useMarkVehicleAsBooked from '../libs/markVehicleAsBooked';
import useSetCharterOnTrip from '../libs/fetchOnTrip';
import Spinner from "../../../../Spinner/Spinner"
import "./Charter.css";

const Bookrequest = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [viewDetail, setViewDetail] = useState(false);
  const [selectedCharter, setSelectedCharter] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const { booked, isLoading , isError } = useBookRequest(currentPage);
  console.log(booked)
  const { mutate: markAsBooked, isLoading: isMarkingAsBooked } = useMarkVehicleAsBooked();
  const { mutate: setOnTrip, isLoading: isSettingOnTrip } = useSetCharterOnTrip();
  const [filteredData, setFilteredData] = useState([]);
  const itemsPerPage = 10;

  const totalItems = booked?.trips?.length || 0;
  const startIndex = (currentPage - 1) * itemsPerPage + 1;
  const endIndex = Math.min(startIndex + itemsPerPage - 1, totalItems);

  useEffect(() => {
    if (searchQuery) {
      const filtered = booked?.trips?.filter((charter) =>
        charter._id.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredData(filtered);
    } else {
      setFilteredData(booked?.trips);
    }
  }, [searchQuery, booked]);

  const handleViewDetail = (charter) => {
    setSelectedCharter(charter);
    setViewDetail(true);
  };

  const clampWords = (text, maxWords) => {
    const words = text.split(" ");
    const clampedWords = words.slice(0, maxWords);
    return clampedWords.join(" ");
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const handleMarkAsBooked = () => {
    if (selectedCharter) {
      markAsBooked(selectedCharter._id, {
        onSuccess: () => {
          setViewDetail(false);
          setFilteredData(prevData =>
            prevData.map(item =>
              item._id === selectedCharter._id
                ? { ...item, vehicle: { ...item.vehicle, isBooked: true } }
                : item
            )
          );
          toast.success('Vehicle marked as Booked successfully');
        },
        onError: (error) => {
          console.error("Error marking vehicle as Booked:", error);
          toast.error('Failed to mark vehicle as Booked');
        },
      });
    }
  };

 const handleSetOnTrip = () => {
  if (selectedCharter) {
    setOnTrip(selectedCharter.vehicle._id, {
      onSuccess: () => {
        setViewDetail(false);
        setFilteredData(prevData =>
          prevData.map(item =>
            item._id === selectedCharter._id
              ? { ...item, vehicle: { ...item.vehicle, isOnTrip: true }}
              : item
          )
        );
      },
      onError: (error) => {
        console.error("Error setting charter on trip:", error);
        toast.error('Charter must be hired before going on a trip');
      },
    });
  }
};


  return (
    <Fragment>
      {viewDetail && selectedCharter && (
        <div className="chartered-vehicle-detail">
          <div className="wrapper">
            <span>
              <h3>{selectedCharter._id}</h3>
              <button onClick={() => setViewDetail(false)}>
                <FaXmark style={{ fontSize: "20px" }} />
              </button>
            </span>
            <div className="chartered-vehicle-detail-span">
              <div className="card-1">
                <div className="wrapper">
                  <img src={selectedCharter?.vehicle?.images[0]} alt="" />
                  <div className="inner">
                    <h3>{selectedCharter?.vehicle?.vehicleModel}</h3>
                    <h4>
                      {selectedCharter?.totalAmount
                        ? selectedCharter?.totalAmount.toLocaleString("en-US", {
                            style: "currency",
                            currency: "NGN",
                          })
                        : ""}
                    </h4>
                    <span>
                      <div className="box">
                        <CiUser />
                        <p>Max No of people: {selectedCharter.vehicle.numberOfSeats}</p>
                      </div>
                    </span>
                    <span>
                      <div className="box">
                        <TbSteeringWheel />
                        <p>Power Type: {selectedCharter.vehicle.powerType}</p>
                      </div>
                    </span>
                    <span>
                      <div className="box">
                        <SeatIcon />
                        <p>{selectedCharter.vehicle.numberOfSeats} seats</p>
                      </div>
                      <div className="box">
                        <BsFuelPump />
                        <p>{selectedCharter.vehicle.fuelTankCapacity}L </p>
                      </div>
                      <div className="box">
                        <GiCarDoor />
                        <p>{selectedCharter.vehicle.doors} doors</p>
                      </div>
                    </span>
                  </div>
                </div>
                <div className="description-detail">
                  <h3>DESCRIPTION</h3>
                  <p>{selectedCharter.vehicle.description}</p>
                </div>
                   <div  className='btn'>
                <button onClick={handleMarkAsBooked} disabled={isMarkingAsBooked}>
                    { isMarkingAsBooked ? <Spinner/> : " Mark as Booked"}
                   </button>
               
      <button onClick={handleSetOnTrip} disabled={isSettingOnTrip}>
        {isSettingOnTrip ? <Spinner/> : 'Set On Trip'}
      </button>
        </div>
        <ToastContainer position="top-right" className="toast" />
              </div>

              <div className="card-2">
                <div className="wrapper">
                  <h3>Passenger Details</h3>
                  <span>
                    <p>Full Name:</p>
                    <p>{selectedCharter.passengerDetails?.fullName}</p>
                  </span>
                  <span>
                    <p>Email:</p>
                    <p>{selectedCharter.passengerDetails?.email}</p>
                  </span>
                  <span>
                    <p>Phone Number:</p>
                    <p>{selectedCharter.passengerDetails?.phoneNumber}</p>
                  </span>
                  <span>
                    <p>Address:</p>
                    <h4>{selectedCharter.passengerDetails?.address}</h4>
                  </span>
                  <span>
                    <p>Payment Method:</p>
                    <p>{selectedCharter.paymentMethod}</p>
                  </span>
                  <span>
                    <p>ID Type:</p>
                    <p>{selectedCharter.customerIdType}</p>
                  </span>
                  <span>
                    <p>ID Number:</p>
                    <p>{selectedCharter.customerIdNumber}</p>
                  </span>
                </div>
                <div className="wrapper">
                  <h3>Next of Kin Details</h3>
                  <span>
                    <p>Full Name:</p>
                    <p>{selectedCharter.passengerDetails?.nextOfKin?.fullName}</p>
                  </span>
                  <span>
                    <p>Phone Number:</p>
                    <p>{selectedCharter.passengerDetails?.nextOfKin?.phoneNumber}</p>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="charters">
        <div className="wrapper">
          <h3>Charters_Booked Request</h3>
          <form>
            <button>
              <CiSearch />
            </button>
            <input
              type="text"
              placeholder="Search Booked Order by Id"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </form>
        </div>
        <div className="charter-mothers">
          <table>
            <thead>
              <tr>
                <th>Hired ID</th>
                <th>Hire Location</th>
                <th>Vehicle Category</th>
                <th>Vehicle Model</th>
                <th>Hire From</th>
                <th>Hire To</th>
                <th>Amount</th>
                <th>Status</th>
                <th>On Trip</th>
                <th>Details</th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                Array.from({ length: 10 }).map((_, index) => (
                  <tr key={index}>
                    <td><Skeleton /></td>
                    <td><Skeleton /></td>
                    <td><Skeleton /></td>
                    <td><Skeleton /></td>
                    <td><Skeleton /></td>
                    <td><Skeleton /></td>
                    <td><Skeleton /></td>
                    <td><Skeleton /></td>
                    <td><Skeleton /></td>
                    <td><Skeleton /></td>
                  </tr>
                ))
              ) : isError ? (
                <tr>
                  <td colSpan="9"><p style={{textAlign:'center', fontFamily:'Montserrat'}}>No vehicle ready for booking</p></td>
                </tr>
              ) : (
                filteredData &&
                filteredData.map((trip) => (
                  <tr key={trip._id} className={trip?.vehicle?.isHired ? "booked" : ""}>
                    <td>{trip._id.slice(0, 5).toUpperCase()}</td>
                    <td>{clampWords(trip.pickupLocation, 4)}</td>
                    <td>{trip?.vehicle?.vehicleType}</td>
                    <td>{trip?.vehicle?.vehicleModel}</td>
                    <td>
                      {new Intl.DateTimeFormat("en-US", {
                        timeZone: "Africa/Lagos",
                      }).format(new Date(trip.startDate))}
                    </td>
                    <td>
                      {new Intl.DateTimeFormat("en-US", {
                        timeZone: "Africa/Lagos",
                      }).format(new Date(trip.endDate))}
                    </td>
                    <td>
                      {trip.totalAmount
                        ? trip.totalAmount.toLocaleString("en-US", {
                            style: "currency",
                            currency: "NGN",
                          })
                        : ""}
                    </td>
                    <td>
                      <span>
                        {trip?.vehicle?.isHired ? "Booked" :  "New Order" }
                      </span>
                    </td>
                    <td>
                      <span>
                        {trip?.vehicle?.onTrip ? "Yes" :  "No" }
                      </span>
                    </td>
                    <td>
                      <button onClick={() => handleViewDetail(trip)}>
                        View
                      </button>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
        <div className="pagination">
        <span>
            {`Showing ${currentPage} - ${endIndex} of ${totalItems}`}
          </span>
         <div className="btn">
         <button onClick={handlePrevPage} disabled={currentPage === 1}>
            Prev
          </button>
          <button
            onClick={handleNextPage}
            disabled={currentPage === booked?.totalPages}
          >
            Next
          </button>
         </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Bookrequest;

