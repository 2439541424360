import { useQuery } from 'react-query';
import axios from 'axios';
import { useContext } from 'react';
import { AuthContext } from '../../Context/AuthContext';

const useUserById = (id) => {
  const { token } = useContext(AuthContext);

  const fetchUserById = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/users/user/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data.user;
    } catch (error) {
      throw new Error('Error fetching user. Please try again.');
    }
  };

  return useQuery(['user', id], fetchUserById); 
};

export default useUserById;
