import React, { useState } from 'react';
import {NavLink as Link, Outlet } from 'react-router-dom';
import { Fragment } from 'react';
import CharterNavbar from '../CharterNav/charterNavbar';
import { MdCarRental } from "react-icons/md";
import { BsCarFront } from "react-icons/bs";
import { IoSettingsOutline } from 'react-icons/io5';
import { SlMenu } from "react-icons/sl";
import { HiOutlineXMark } from "react-icons/hi2";
import { FaCarTunnel } from "react-icons/fa6";
import { GiCarKey } from "react-icons/gi";
import { TbKeyOff } from "react-icons/tb";
import { TbSteeringWheel } from "react-icons/tb";
import { MdGridView } from "react-icons/md";
import "./CharterDash.css"

const Charterdash = () => {
  const [isMobile, setIsMobile] = useState(false);

  const handleMenu = () => {
    setIsMobile(!isMobile)
  }

    const NavLink = ({ isActive }) => ({
        background: isActive ? '#284697' : '',
        color: isActive ? 'white' : ''
      });


    return (
       <Fragment>
        <CharterNavbar/>
        <div className='charter-nav'>
        <div className='charter-logo'>
          <img src='https://i.ibb.co/vvhxKCL/Frame-1-3x.png' alt='' />
        </div>
        <div className={`charter-mobile-link ${isMobile ? "mobile active" : ""}`} onClick={() => setIsMobile(false)}>
        <Link to='/' style={NavLink}>
            <MdGridView style={{fontSize:'24px'}} />
            Overview
          </Link>
          <Link to='/charter' style={NavLink}>
            <MdCarRental style={{fontSize:'24px'}} />
            New Order
          </Link>
          <Link to='/bookrequest' style={NavLink}>
            <TbKeyOff style={{fontSize:'24px'}} />
            Booked Request
          </Link>
          <Link to='/startedcharter' style={NavLink}>
            <FaCarTunnel style={{fontSize:'24px'}} />
            Started
          </Link>
          <Link to='/completedcharter' style={NavLink}>
            <GiCarKey style={{fontSize:'24px'}} />
            Completed
          </Link>
          <Link to='/vehicle' style={NavLink}>
          <BsCarFront  style={{fontSize:'24px'}} />
            Vehicle
          </Link>
          <Link to='/order' style={NavLink}>
          <TbSteeringWheel style={{fontSize:'24px'}}  />
           Admin Order
          </Link>

         
          <Link to='/setting' style={NavLink}>
            <IoSettingsOutline style={{fontSize:'24px'}} />
            Setting
          </Link>
        </div>

        <div className="menu-icon" onClick={handleMenu}>
          {isMobile ? <HiOutlineXMark /> : <SlMenu /> }
        </div>
      </div>
      <Outlet />
       </Fragment>
    );
}

export default Charterdash;
