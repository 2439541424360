import { useQuery } from "react-query";
import { AuthContext } from "../../Context/AuthContext";
import { useContext } from "react";


const fetchUserCategories = async (userId, token) => {
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/categories/categories/user/${userId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.message || "Failed to fetch user categories");
  }

  return response.json();
};

const useUserCategories = () => {
  const { token, user } = useContext(AuthContext);

  return useQuery(
    ["userCategories", user.userId],
    () => fetchUserCategories(user.userId, token),
    {
      enabled: !!user.userId && !!token,
    }
  );
};

export default useUserCategories;
