import axios from "axios";
import { useMutation, useQueryClient } from "react-query";
import { useContext } from "react";
import { AuthContext } from "../../Context/AuthContext";

const useDeleteBanner = () => {
  const { token } = useContext(AuthContext);
  const queryClient = useQueryClient();

  const deleteBannerMutation = useMutation(
    (bannerId) =>
      axios.delete(
        `${process.env.REACT_APP_SERVER_URL}/banners/delete/${bannerId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("banners");
      },
    }
  );

  const deleteBanner = async (bannerId) => {
    try {
      await deleteBannerMutation.mutateAsync(bannerId);
    } catch (error) {
      console.error("Error deleting banner:", error);
      throw error;
    }
  };

  return { deleteBanner, isLoading: deleteBannerMutation.isLoading };
};

export default useDeleteBanner;