import { useContext } from 'react';
import { AuthContext } from '../../Context/AuthContext';
import { useQueryClient } from 'react-query'; 

const useUpdateFreightById = () => {
  const { token } = useContext(AuthContext);
  const queryClient = useQueryClient(); 

  const updateFreightById = async (userId,userData) => {
    console.log('Updating freight officer with data:', userData);
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/users/update/freightmanager/${userId}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(userData),
    });

    if (!response.ok) {
      throw new Error('Error updating freight officer');
    }

    queryClient.invalidateQueries('freightOfficers');

    return response.json();
  };

  return { updateFreightById };
};


export default useUpdateFreightById;
