const allStatesAndCities = {
  Lagos: [
    "Agege",
    "Ajeromi-Ifelodun",
    "Alimosho",
    "Amuwo-Odofin",
    "Apapa",
    "Badagry",
    "Epe",
    "Eti-Osa",
    "Ibeju-Lekki",
    "Ifako-Ijaiye",
    "Ikeja",
    "Ikorodu",
    "Kosofe",
    "Lagos Island",
    "Lagos Mainland",
    "Mushin",
    "Ojo",
    "Oshodi-Isolo",
    "Shomolu",
    "Surulere",
    "Ajao Estate",
    "Festac Town",
    "Igando",
    "Ikotun",
    "Ilupeju",
    "Ipaja",
    "Ojodu",
    "Orile-Agege",
    "Oworonshoki",
    "Yaba",
  ],

  Ogun: [
    "Abeokuta",
    "Ijebu-Ode",
    "Sagamu",
    "Ota",
    "Ifo",
    "Ado-Odo",
    "Ijebu-Igbo",
    "Ijebu-Imusin",
    "Ilaro",
    "Ikenne",
  ],
  Oyo: [
    "Ibadan",
    "Oyo",
    "Iseyin",
    "Ogbomoso",
    "Saki",
    "Igboho",
    "Eruwa",
    "Okeho",
    "Kishi",
    "Igbo-Ora",
  ],
  Osun: [
    "Osogbo",
    "Ile-Ife",
    "Ejigbo",
    "Ila-Orangun",
    "Iwo",
    "Ede",
    "Ikirun",
    "Ilesa",
    "Ilobu",
    "Ibokun",
    "Ire",
    "Inisa",
  ],
  Ekiti: [
    "Ado-Ekiti",
    "Ikere-Ekiti",
    "Efon Alaaye",
    "Oye-Ekiti",
    "Ijero-Ekiti",
    "Emure-Ekiti",
    "Ise-Ekiti",
    "Ilawe-Ekiti",
    "Ikole-Ekiti",
    "Aramoko-Ekiti",
  ],
  Ondo: [
    "Akure",
    "Ondo",
    "Owo",
    "Idanre",
    "Ikare",
    "Okitipupa",
    "Ore",
    "Irele",
    "Okitipupa",
    "Ilaje",
  ],
  Kwara: [
    "Ilorin",
    "Offa",
    "Erin",
    "Omu-Aran",
    "Kaiama",
    "Patigi",
    "Share",
    "Edu",
    "Ilorin South",
    "Ilorin East",
    "Ilorin West",
  ],
};

export default allStatesAndCities;
