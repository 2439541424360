import React from "react";
import { Link } from "react-router-dom";
import MarketNavbar from "../MarketNav/MarketNavbar";
import Usernavbar from "./usernavbar";
import { SlNotebook } from "react-icons/sl";
import "./UserProfile.css";

const Addressbook = () => {
  return (
    <>
      <MarketNavbar />
      <div className="customer-address-book">
        <Usernavbar />
        <div className="customer-address-book-con">
          <h3>Address Book</h3>
          <div className="wrapper">
            <div className="order-circle">
              <SlNotebook style={{ fontSize: "45px", color: " #d0300e" }} />
            </div>
            <h3>You have not added any address yet!</h3>
            <p>
              Add your shipping addresses here for a fast purchase experience!
              You will be able to add, modify or delete them at any time.
            </p>
            <Link>ADD NEW ADDRESS</Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Addressbook;
