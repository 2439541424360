
import React, { useState, useEffect } from "react";
import { CiSearch } from "react-icons/ci";
import { Fragment } from "react";
import useCharterOnTrip from "../libs/fetchStartedCharter";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import useResetVehicleStatus from "../libs/fetchResetStatus";
import "./Charter.css";
import Spinner from "../../../../Spinner/Spinner";
import { toast } from 'react-toastify';

const Startedcharter = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredData, setFilteredData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [resetOverlay, setResetOverlay] = useState(false);
  const [selectedTrip, setSelectedTrip] = useState(null);
  const { data, isLoading, isError } = useCharterOnTrip(currentPage);
  const { mutate: resetStatus, isLoading: isResetStatus } = useResetVehicleStatus();
  const itemsPerPage = 10;

  const totalItems = data?.trips?.length || 0;
  const startIndex = (currentPage - 1) * itemsPerPage + 1;
  const endIndex = Math.min(startIndex + itemsPerPage - 1, totalItems);

  useEffect(() => {
    if (data?.trips) {
      if (data.trips.length === 0) {
        toast.info("No data available.");
      }

      if (searchQuery) {
        const filtered = data.trips.filter((trip) =>
          trip._id.toLowerCase().includes(searchQuery.toLowerCase())
        );
        setFilteredData(filtered);
      } else {
        setFilteredData(data.trips);
      }
    }
  }, [searchQuery, data]);

  const handleNextPage = () => {
    if (currentPage < data?.totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleOpenResetOverlay = (trip) => {
    setSelectedTrip(trip);
    setResetOverlay(true);
  };

  const handleCloseResetOverlay = () => {
    setSelectedTrip(null);
    setResetOverlay(false);
  };

  const handleStatus = () => {
    if (selectedTrip) {
      resetStatus(
        { vehicleId: selectedTrip.vehicle._id, tripId: selectedTrip._id },
        {
          onSuccess: () => {
            toast.success('Charter and trip statuses reset successfully.');
            handleCloseResetOverlay();
          },
          onError: (error) => {
            toast.error(`Error resetting status: ${error.message}`);
          },
        }
      );
    }
  };

  return (
    <>
      {resetOverlay && (
        <div className="reset-status-overlay">
          <div className="card">
            <p>Are you sure you want to reset the status?</p>
            <div className="btn">
              <button onClick={handleCloseResetOverlay}>No</button>
              <button onClick={handleStatus}>
                {isResetStatus ? <Spinner /> : "Yes"}
              </button>
            </div>
          </div>
        </div>
      )}
      <Fragment>
        <div className="charters">
          <div className="wrapper">
            <h3>Charters_Started</h3>
            <form>
              <button type="button">
                <CiSearch />
              </button>
              <input
                type="text"
                placeholder="Search"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </form>
          </div>
          <div className="charter-mothers">
            <table>
              <thead>
                <tr>
                  <th>Hired ID</th>
                  <th>Hire Location</th>
                  <th>Vehicle Category</th>
                  <th>Vehicle Model</th>
                  <th>Amount</th>
                  <th>Status</th>
                  <th>Reset Status</th>
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  Array.from({ length: 10 }).map((_, index) => (
                    <tr key={index}>
                      <td><Skeleton /></td>
                      <td><Skeleton /></td>
                      <td><Skeleton /></td>
                      <td><Skeleton /></td>
                      <td><Skeleton /></td>
                      <td><Skeleton /></td>
                      <td><Skeleton /></td>
                    </tr>
                  ))
                ) : isError ? (
                  <tr>
                    <td colSpan="7"><p style={{textAlign:'center'}}>No vehicle on trip</p></td>
                  </tr>
                ) : (filteredData && filteredData.length > 0 ? (
                  filteredData.map((trip) => (
                    <tr key={trip._id}>
                      <td>{trip._id}</td>
                      <td>{trip.pickupLocation}</td>
                      <td>{trip.vehicle.vehicleType}</td>
                      <td>{trip.vehicle.vehicleModel}</td>
                      <td>
                        {trip.totalAmount
                          ? trip.totalAmount.toLocaleString("en-US", {
                            style: "currency",
                            currency: "NGN",
                          })
                          : ""}
                      </td>
                      <td>{trip.vehicle.onTrip ? "on-trip" : "No"}</td>
                      <td>
                        <button onClick={() => handleOpenResetOverlay(trip)}>Reset</button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="7">No trips found</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="pagination">
            <span>
              {`Showing ${startIndex} - ${endIndex} of ${totalItems}`}
            </span>
            <div className="btn">
              <button onClick={handlePrevPage} disabled={currentPage === 1}>
                Prev
              </button>
              <button
                onClick={handleNextPage}
                disabled={currentPage === data?.totalPages}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default Startedcharter;
