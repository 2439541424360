import React, { useState } from "react";
import useContact from "../libs/useContact";

const defaultForm = {
  fullname: "",
  phoneNumber: "",
  email: "",
  content: "",
};

const ContactForm = () => {
  const [formInput, setFormInput] = useState(defaultForm);
  const [error, setError] = useState(null);
  const [successMsg, setSuccessMsg] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { mutation } = useContact();

  const handleChange = (e) => {
    setFormInput({
      ...formInput,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      await mutation.mutateAsync(formInput);
      setSuccessMsg("Form is successfully submitted!");
    } catch (error) {
      setError(error.message);
    }

    setIsLoading(false);
    setFormInput(defaultForm);
    setTimeout(() => {
      setError(null);
      setSuccessMsg(null);
    }, 3000);
  };

  const { fullname, phoneNumber, email, content } = formInput;

  return (
    <div className="contact-form">
      <p>
        If you have a complaint, please reach out. Your feedback matters to us!
      </p>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          name="fullname"
          value={fullname}
          placeholder="Your Name"
          onChange={handleChange}
          required
        />
        <input
          type="number"
          name="phoneNumber"
          value={phoneNumber}
          placeholder="Your Phone Number"
          onChange={handleChange}
          required
        />
        <input
          type="email"
          name="email"
          value={email}
          placeholder="Your Email"
          onChange={handleChange}
          required
        />
        <textarea
          name="content"
          value={content}
          placeholder="Message"
          onChange={handleChange}
          required
        ></textarea>
        {error && <p style={{ color: "red" }}>{error}</p>}
        <button type="submit" disabled={isLoading}>
          {isLoading ? "SUBMITTING....." : "Send Message"}
        </button>
        {successMsg && (
          <p style={{ color: "green", fontFamily: "Montserrat" }}>
            {successMsg}
          </p>
        )}
      </form>
    </div>
  );
};

export default ContactForm;
