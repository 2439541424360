import { useQuery } from "react-query";


const fetchCategories = async () => {
  const response = await fetch(
    `${process.env.REACT_APP_SERVER_URL}/productcategory/get/product/categories`,
    {
      headers: {
       "Content-Type" : "application/json",
    
      },
      credentials: "include",
    }
  );

  if (!response.ok) {
    throw new Error("Failed to fetch product category");
  }


  const data = await response.json(); 

  return data;
};

const useFetchProductCategory= () => {
    return useQuery("productCategories", fetchCategories);;
};

export default useFetchProductCategory;
