
import React, { useState } from "react";
import { CiSearch } from "react-icons/ci";
import { BsFuelPump } from "react-icons/bs";
import { GiCarDoor } from "react-icons/gi";
import { FaXmark } from "react-icons/fa6";
import { TbSteeringWheel } from "react-icons/tb";
import { CiUser } from "react-icons/ci";
import { Fragment } from "react";
import useCompletedCharters from "../libs/fetchCompletedTrip";
import { ReactComponent as SeatIcon } from "../../../../Assets/safety-seat-car-svgrepo-com 13x.svg";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import "./Charter.css";

const Completedcharter = () => {
  const [viewDetail, setViewDetail] = useState(false);
  const [selectedCharter, setSelectedCharter] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const { data, isLoading, isError } = useCompletedCharters(currentPage);

  const itemsPerPage = 10;
  const totalItems = data?.completedCharters.length || 0;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage + 1;
  const endIndex = Math.min(startIndex + itemsPerPage - 1, totalItems);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleViewDetail = (charter) => {
    setSelectedCharter(charter);
    setViewDetail(true);
  };

  let filteredCharters = [];

  if (data && Array.isArray(data.completedCharters)) {
    filteredCharters = data.completedCharters.filter((charter) =>
      charter._id.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }

  return (
    <Fragment>
        {viewDetail && selectedCharter && ( 
        <div className="chartered-vehicle-detail">
          <div className="wrapper">
            <span>
              <h3>{selectedCharter._id}</h3> 
              <button onClick={() => setViewDetail(false)}>
                <FaXmark style={{ fontSize: "20px" }} />
              </button>
            </span>
            <div className="chartered-vehicle-detail-span">
              <div className="card-1">
                <div className="wrapper">
                  <img src={selectedCharter?.vehicle?.images[0]} alt="" /> 
                  <div className="inner">
                    <h3>{selectedCharter?.vehicle?.vehicleModel}</h3> 
                    <h4>
                    {selectedCharter?.totalAmount
                  ? selectedCharter?.totalAmount.toLocaleString("en-US", {
                      style: "currency",
                      currency: "NGN",
                    })
                  : ""}
                    </h4>
                    <span>
                      <div className="box">
                        <CiUser />
                        <p>Max No of peo : {selectedCharter?.vehicle?.numberOfSeats}</p> 
                      </div>
                    </span>
                    <span>
                      <div className="box">
                        <TbSteeringWheel />
                        <p>Power Type:{selectedCharter?.vehicle?.powerType}</p>
                      </div>
                    </span>
                    <span>
                      <div className="box">
                        <SeatIcon />
                        <p>{selectedCharter?.vehicle?.numberOfSeats} seats</p> 
                      </div>
                      <div className="box">
                        <BsFuelPump />
                        <p>{selectedCharter?.vehicle?.fuelTankCapacity}L </p> 
                      </div>
                      <div className="box">
                        <GiCarDoor />
                        <p>{selectedCharter?.vehicle?.doors} doors</p> 
                      </div>
                    </span>
                  </div>
                </div>
                <div className="description-detail">
                  <h3>DESCRIPTION</h3>
                  <p>{selectedCharter?.vehicle?.description}</p> 
                </div>
              </div>

              <div className="card-2">
                <div className="wrapper">
                  <h3>Passenger Details</h3>
                  <span>
                    <p>Full Name:</p>
                    <p>{selectedCharter?.tripDetails?.passengerDetails?.fullName}</p> 
                  </span>
                  <span>
                    <p>Email:</p>
                    <p>{selectedCharter.tripDetails?.passengerDetails?.email}</p> 
                  </span>
                  <span>
                    <p>Phone Number:</p>
                    <p>{selectedCharter.tripDetails?.passengerDetails?.phoneNumber}</p> 
                  </span>
                  <span>
                    <p>Address:</p>
                    <h4>{selectedCharter.tripDetails?.passengerDetails?.address}</h4> 
                  </span>
                  <span>
                    <p>Payment Method:</p>
                    <p>{selectedCharter.tripDetails?.paymentMethod}</p> 
                  </span>
                  <span>
                    <p>ID Type:</p>
                    <p>{selectedCharter.tripDetails?.customerIdType}</p> 
                  </span>
                  <span>
                    <p>ID Number:</p>
                    <p>{selectedCharter.tripDetails?.customerIdNumber}</p> 
                  </span>
                  
                </div>
                <div className="wrapper">
                  <h3>Next of Kin Details</h3>
                  <span>
                    <p>Full Name</p>
                    <p>{selectedCharter?.tripDetails?.passengerDetails?.nextOfKin?.fullName}</p> 
                  </span>
                  <span>
                    <p>Phone Number</p>
                    <p>{selectedCharter?.tripDetails?.passengerDetails?.nextOfKin?.phoneNumber}</p> 
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="charters">
        <div className="wrapper">
          <h3>Charters Completed</h3>
          <form>
            <button type="button">
              <CiSearch />
            </button>
            <input
              type="text"
              placeholder="Search completed trip by Id"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </form>
        </div>
        <div className="charter-mothers">
          <table>
            <thead>
              <tr>
                <th>Vehicle ID</th>
                <th>Vehicle Type</th>
                <th>State</th>
                <th>Vehicle Model</th>
                <th>Hire From</th>
                <th>Hire To</th>
                <th>Amount</th>
                <th>Status</th>
                <th>Details</th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                Array.from({ length: 10 }).map((_, index) => (
                  <tr key={index}>
                    <td><Skeleton /></td>
                    <td><Skeleton /></td>
                    <td><Skeleton /></td>
                    <td><Skeleton /></td>
                    <td><Skeleton /></td>
                    <td><Skeleton /></td>
                    <td><Skeleton /></td>
                    <td><Skeleton /></td>
                    <td><Skeleton /></td>
                  </tr>
                ))
              ) : isError ? (
                <tr>
                  <td colSpan="9">
                    <p style={{ textAlign: 'center' }}>Error fetching data</p>
                  </td>
                </tr>
              ) : (
                filteredCharters.map((charter) => (
                  <tr key={charter._id}>
                    <td>{charter._id.slice(0, 5).toUpperCase()}</td>
                    <td>{charter.vehicle.vehicleType}</td>
                    <td>{charter.vehicle.state}</td>
                    <td>{charter.vehicle.vehicleModel}</td>
                    <td>
                      {new Intl.DateTimeFormat("en-US", {
                        timeZone: "Africa/Lagos",
                      }).format(new Date(charter.tripDetails.startDate))}
                    </td>
                    <td>
                      {new Intl.DateTimeFormat("en-US", {
                        timeZone: "Africa/Lagos",
                      }).format(new Date(charter.tripDetails.endDate))}
                    </td>
                    <td>
                      {charter.tripDetails.totalAmount
                        ? charter.tripDetails.totalAmount.toLocaleString("en-US", {
                            style: "currency",
                            currency: "NGN",
                          })
                        : ""}
                    </td>
                    <td>
                      <div className="card">Completed</div>
                    </td>
                    <td> <button onClick={() => handleViewDetail(charter)}>View</button></td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
        {!isLoading && !isError && (
          <div className="pagination">
            <span>
              {`Showing ${startIndex} - ${endIndex} of ${totalItems}`}
            </span>
            <div className="btn">
              <button onClick={handlePrevPage} disabled={currentPage === 1}>
                Prev
              </button>
              <button
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
              >
                Next
              </button>
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default Completedcharter;

