import React, { useState } from "react";

const FaqSwitch = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [isOpen4, setIsOpen4] = useState(false);

  const toggleAnswer = () => {
    setIsOpen(!isOpen);
  };
  const toggleAnswer1 = () => {
    setIsOpen1(!isOpen1);
  };
  const toggleAnswer2 = () => {
    setIsOpen2(!isOpen2);
  };
  const toggleAnswer3 = () => {
    setIsOpen3(!isOpen3);
  };
  const toggleAnswer4 = () => {
    setIsOpen4(!isOpen4);
  };

  return (
    <div className="faq-container">
      <div className="question-container" onClick={toggleAnswer}>
        <span >
          {isOpen ? <i className="fa-solid fa-minus"></i> : <i className="fa-solid fa-plus"></i>}
        <h3>What services does SmartTran Express provide?</h3>
        </span>
      </div>
      {isOpen && (
        <div className="answer-container">
          <p>
            SmartTran Express offers comprehensive logistics solutions tailored
            for e-commerce businesses, including shipping, warehousing, and
            supply chain management.
          </p>
        </div>
      )}
  
      <div className="question-container" onClick={toggleAnswer1}>
        <span>
          {isOpen1 ? <i className="fa-solid fa-minus"></i> : <i className="fa-solid fa-plus"></i>}
        <h3>How can I track my shipment with SmartTran Express?</h3>
        </span>
      </div>
      {isOpen1 && (
        <div className="answer-container">
          <p>
            You can easily track your shipment by entering the tracking number
            provided at the time of booking on our website or mobile app.
          </p>
        </div>
      )}
  
      <div className="question-container" onClick={toggleAnswer2}>
        <span>
          {isOpen2 ? <i className="fa-solid fa-minus"></i> : <i className="fa-solid fa-plus"></i>}
        <h3>What regions does SmartTran Express operate in?</h3>
        </span>
      </div>
      {isOpen2 && (
        <div className="answer-container">
          <p>
            SmartTran Express operates globally, providing logistics support and
            e-commerce solutions across various regions.
          </p>
        </div>
      )}
  
      <div className="question-container" onClick={toggleAnswer3}>
        <span >
          {isOpen3 ? <i className="fa-solid fa-minus"></i> : <i className="fa-solid fa-plus"></i>}
        <h3>How does SmartTran Express ensure the security of my shipments?</h3>
        </span>
      </div>
      {isOpen3 && (
        <div className="answer-container">
          <p>
            We prioritize the security of your shipments through advanced
            tracking systems, secure warehouses, and strict adherence to
            industry-standard safety protocols.
          </p>
        </div>
      )}
  
      <div className="question-container" onClick={toggleAnswer4}>
        <span>
          {isOpen4 ? <i className="fa-solid fa-minus"></i> : <i className="fa-solid fa-plus"></i>}
        <h3>What types of goods does SmartTran Express handle?</h3>
        </span>
      </div>
      {isOpen4 && (
        <div className="answer-container">
          <p>
            SmartTran Express handles a wide range of goods, from small parcels
            to large freight, ensuring safe and efficient delivery for all types
            of products.
          </p>
        </div>
      )}
    </div>
  );
  
};

export default FaqSwitch;
