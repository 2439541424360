import { useContext } from "react";
import { useQuery } from "react-query";
import { AuthContext } from "../../Context/AuthContext";

const UserDataMetrics = () => {
  const { token } = useContext(AuthContext);

  const { data: userMetrics, isLoading, isError } = useQuery(
    ["userMetrics", token],
    async () => {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/users/metrics`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          credentials: 'include',
        }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch user metrics");
      }
      const data = await response.json();
      return data.metrics;
    },
    {
      onError: (error) => {
        console.error("Error fetching user metrics:", error);
      },
    }
  );

  return { userMetrics, isLoading, isError };
};

export default UserDataMetrics;

