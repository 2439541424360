// import React, { useState, useEffect } from "react";
// import { ReactComponent as SearchIcon } from "../../asset/iconamoon_search-light.svg";
// import { Link } from "react-router-dom";
// import usePreshipmentFetchShipmentByUser from "../libs/fetchPreShipments";
// import Spinner from "../../utils/spinner";
// import "./Logisticspickups.css";

// function Logissticspickups() {
//   const { data, isLoading, isError } = usePreshipmentFetchShipmentByUser();
//   const [shipments, setShipments] = useState([]);
//   const [searchQuery, setSearchQuery] = useState("");

//   useEffect(() => {
//     if (window.hasNewPickup) {
//       window.alert("New Pickup Alert!");
//       window.hasNewPickup = false;
//     }
//   }, []);

//   useEffect(() => {
//     setShipments(data?.shipments || []);
//   }, [data]);

//   const filteredShipments = shipments.filter((shipment) =>
//     shipment?.waybillNumber?.toLowerCase().includes(searchQuery.toLowerCase())
//   );

//   function isValidDate(dateString) {
//     const date = new Date(dateString);
//     return !isNaN(date.getTime());
//   }

//   return (
//     <div className="logistic-pickups">
//       <div className="header">
//         <p>Pick Up</p>
//         <span>
//           <SearchIcon />
//           <input
//             type="search"
//             placeholder="Search Order ID"
//             value={searchQuery}
//             onChange={(e) => setSearchQuery(e.target.value)}
//           />
//         </span>
//       </div>
//       <div className="pickup-table">
//         {isLoading && <Spinner />}
//         {isError && <p>{isError}</p>}
//         {filteredShipments.length > 0 ? (
//           <table>
//             <thead>
//               <tr>
//                 <th>Pre Waybill</th>
//                 <th>Sender’s Name</th>
//                 <th>Receiver’s Name</th>
//                 <th>Items</th>
//                 <th>Qty</th>
//                 <th>Cost(NGN)</th>
//                 <th>PickUp Date</th>
//                 <th>Time</th>
//                 <th>E.D Date</th>
//                 <th>Details</th>
//               </tr>
//             </thead>
//             <tbody>
//               {filteredShipments.map((shipment) => (
//                 <tr key={shipment._id}>
//                   <td>{shipment?.waybillNumber}</td>
//                   <td>{shipment?.sender.name}</td>
//                   <td>{shipment?.receiver.name}</td>
//                   <td>{shipment?.name}</td>
//                   <td>{shipment?.quantity}</td>
//                   <td>
//                     {shipment?.shippingCost
//                       ? (shipment.shippingCost / 100).toLocaleString("en-US", {
//                           style: "currency",
//                           currency: "NGN",
//                         })
//                       : ""}
//                   </td>

//                   <td>
//                     {isValidDate(shipment.pickupDate)
//                       ? new Date(shipment.pickupDate).toLocaleDateString()
//                       : "NA"}
//                   </td>

//                   <td>{shipment?.pickupTime || "NA"}</td>
//                   <td>
//                     {new Date(
//                       shipment.estimated_delivery_date
//                     ).toLocaleDateString()}
//                   </td>
//                   <td>
//                     <Link to={`/predetails/${shipment._id}`}>View Details</Link>
//                   </td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         ) : (
//           !isLoading && <p>No shipments found.</p>
//         )}
//       </div>
//     </div>
//   );
// }

// export default Logissticspickups;

import React, { useState, useEffect, useRef, useCallback } from "react";
import { ReactComponent as SearchIcon } from "../../asset/iconamoon_search-light.svg";
import { Link } from "react-router-dom";
import usePreshipmentFetchShipmentByUser from "../libs/fetchPreShipments";
import Spinner from "../../utils/spinner";
import "./Logisticspickups.css";

function Logissticspickups() {
  const { 
    data, 
    isLoading, 
    isError, 
    fetchNextPage, 
    hasNextPage, 
    isFetchingNextPage 
  } = usePreshipmentFetchShipmentByUser();
  
  const [searchQuery, setSearchQuery] = useState("");
  const observerRef = useRef();

  // Combine shipments across pages
  const allShipments = data?.pages.flatMap(page => page.shipments) || [];

  const lastProductElementRef = useCallback(
    node => {
      if (isFetchingNextPage) return;

      if (observerRef.current) observerRef.current.disconnect();

      observerRef.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && hasNextPage) {
          fetchNextPage();
        }
      }, { threshold: 1.0 });

      if (node) observerRef.current.observe(node);
    },
    [isFetchingNextPage, fetchNextPage, hasNextPage]
  );

  useEffect(() => {
    if (window.hasNewPickup) {
      window.alert("New Pickup Alert!");
      window.hasNewPickup = false;
    }
  }, []);

  const filteredShipments = allShipments.filter((shipment) =>
    shipment?.waybillNumber?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  function isValidDate(dateString) {
    const date = new Date(dateString);
    return !isNaN(date.getTime());
  }

  return (
    <div className="logistic-pickups">
      <div className="header">
        <p>Pick Up</p>
        <span>
          <SearchIcon />
          <input
            type="search"
            placeholder="Search Order ID"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </span>
      </div>
      <div className="pickup-table">
        {isLoading && <Spinner />}
        {isError && <p>{isError}</p>}
        {filteredShipments.length > 0 ? (
          <table>
            <thead>
              <tr>
                <th>Pre Waybill</th>
                <th>Sender’s Name</th>
                <th>Receiver’s Name</th>
                <th>Items</th>
                <th>Qty</th>
                <th>Cost(NGN)</th>
                <th>PickUp Date</th>
                <th>Time</th>
                <th>E.D Date</th>
                <th>Details</th>
              </tr>
            </thead>
            <tbody>
              {filteredShipments.map((shipment, index) => (
                <tr key={shipment._id} ref={index === filteredShipments.length - 1 ? lastProductElementRef : null}>
                  <td>{shipment?.waybillNumber}</td>
                  <td>{shipment?.sender.name}</td>
                  <td>{shipment?.receiver.name}</td>
                  <td>{shipment?.name}</td>
                  <td>{shipment?.quantity}</td>
                  <td>
                    {shipment?.shippingCost
                      ? (shipment.shippingCost / 100).toLocaleString("en-US", {
                          style: "currency",
                          currency: "NGN",
                        })
                      : ""}
                  </td>

                  <td>
                    {isValidDate(shipment.pickupDate)
                      ? new Date(shipment.pickupDate).toLocaleDateString()
                      : "NA"}
                  </td>

                  <td>{shipment?.pickupTime || "NA"}</td>
                  <td>
                    {new Date(
                      shipment.estimated_delivery_date
                    ).toLocaleDateString()}
                  </td>
                  <td>
                    <Link to={`/predetails/${shipment._id}`}>View Details</Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          !isLoading && <p>No shipments found.</p>
        )}
      </div>
       {isFetchingNextPage && (
        <div className="loading-more">
          <Spinner />
        </div>
      )}
    </div>
  );
}

export default Logissticspickups;
