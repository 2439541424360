import { useQuery } from 'react-query'; 

const useTopSellingLimit = () => {
  const { data, isLoading, isError } = useQuery('topsellingLimit', async () => {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/product/category/hot-deals`);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  });

  return { data, isLoading, isError }; 
};

export default useTopSellingLimit;
