import React from 'react';
import Chart from 'react-apexcharts'; 
import useRevenueData from '../libs/fetchCharterRevenue';
import useVehicleOrders from '../libs/fetch24HoursOrder';
import useVehicleStats from '../libs/fetchVehicleStat';
import "./Charter.css";


const Overview = () => {
  const { data: revenueData, isLoading: revenueLoading, isError: revenueError } = useRevenueData();
  const { data: vehicleStats, isLoading: vehicleLoading, isError: vehicleError } = useVehicleStats();
  const { data: vehicleOrders, isLoading: orderLoading, isError: orderError } = useVehicleOrders();

  const chartOptions = {
    chart: {
      id: "basic-bar"
    },
    xaxis: {
      categories: ['Today', 'This Month', 'This Year', 'All Time']
    }
  };

  const chartSeries = [
    {
      name: "Revenue",
      data: [
        revenueData ? revenueData.revenueToday : 0,
        revenueData ? revenueData.revenueThisMonth : 0,
        revenueData ? revenueData.revenueThisYear : 0,
        revenueData ? revenueData.totalRevenueAllTime : 0
      ]
    }
  ];

  return (
    <div className='charter-overview'> 
      <div className="wrapper">
        <h3>Charter Overview</h3>
      </div> 
      <div className="charter-overview-mothers">
        <div className="wrapper">
          <div className="card">
            <p>Total Vehicle</p>
            <h3>{vehicleStats ? vehicleStats.totalVehicles : 0}</h3>
          </div>
          <div className="card">
            <p>Total Vehicle on Trip</p>
            <h3>{vehicleStats ? vehicleStats.vehiclesOnTrip : 0}</h3>
          </div>
          <div className="card">
            <p>Total Order in last 24hrs</p>
            <h3>{vehicleOrders ? vehicleOrders.recentOrdersCount : 0}</h3>
          </div>
          <div className="card">
            <p>Total Monthly Revenue</p>
            <h3>
              {revenueData
                ? revenueData.revenueThisMonth.toLocaleString("en-US", {
                    style: "currency",
                    currency: "NGN",
                  })
                : 0}
            </h3>
          </div>
          <div className="card">
            <p>Total Revenue</p>
            <h3>
              {revenueData
                ? revenueData.totalRevenueAllTime.toLocaleString("en-US", {
                    style: "currency",
                    currency: "NGN",
                  })
                : 0}
            </h3>
          </div>
        </div>
        <div className="revenue">
          <h3>REVENUE</h3>
          {revenueLoading || vehicleLoading || orderLoading ? (
           <p style={{fontFamily:'Montserrat', textAlign:'center', width:'100%'}}>Loading...</p>
          ) : (revenueError || vehicleError || orderError) ? (
            <div>Error: {revenueError.message || vehicleError.message || orderError.message}</div>
          ) : (
            <Chart
              options={chartOptions}
              series={chartSeries}
              type="bar"
              width="700"
              fontFamily="Montserrat"
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default Overview;
