

import { useContext } from "react";
import { useQuery } from "react-query";
import { AuthContext } from "../../../../Context/AuthContext";

const GetProductById = (id) => {
  const { token } = useContext(AuthContext);

  const fetchProduct = async () => {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/product/products/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json"
      }
    });

    if (!response.ok) {
      throw new Error("Failed to fetch product");
    }

    return response.json();
  };

  const { data, isLoading, isError } = useQuery(["productDetails", id], fetchProduct); 

  return { data, isLoading, isError }; 
};

export default GetProductById;

