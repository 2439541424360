import { useInfiniteQuery } from 'react-query';

const fetchTrucks = async ({ pageParam = 1 }) => {
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/freight/get-trucks?page=${pageParam}`, {
    headers: {
      'Content-Type': 'application/json'
    }
  });

  if (!response.ok) {
    throw new Error('Failed to fetch user trucks');
  }

  return response.json();
};

const useUserTrucks = () => {
  return useInfiniteQuery('userTrucks', fetchTrucks, {
    getNextPageParam: (lastPage) => {
      if (lastPage.currentPage < lastPage.totalPages) {
        return lastPage.currentPage + 1;
      }
      return undefined;
    }
  });
};

export default useUserTrucks;


