import { useMutation, useQueryClient } from 'react-query';
import { useContext } from 'react';
import { AuthContext } from '../../../Context/AuthContext';

const markTruckAsBooked = async (truckId, token) => {
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/freight/book/${truckId}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error('Failed to mark Truck as Booked');
  }

  return response.json();
};

const useMarkTruckAsBooked = () => {
  const queryClient = useQueryClient();
  const { token } = useContext(AuthContext);

  return useMutation((truckId) => markTruckAsBooked(truckId, token), {
    onSuccess: () => {
      queryClient.invalidateQueries(['freightbookrequest']);
    },
  });
};

export default useMarkTruckAsBooked;
