import { useMutation, useQueryClient } from 'react-query';
import { useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../../Context/AuthContext';

const useDeletePost = () => {
  const { token } = useContext(AuthContext);
  const queryClient = useQueryClient();

  const deletePostMutation = useMutation(
    async (id) => {
      const response = await axios.delete(`${process.env.REACT_APP_SERVER_URL}/blogs/delete/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('blogs');
        console.log('Post deleted successfully');
      },
    }
  );

  const deletePost = async (id) => {
    try {
      await deletePostMutation.mutateAsync(id);
    } catch (error) {
      console.error('Error deleting post:', error);
    }
  };

  return {
    deletePost,
    isDeleting: deletePostMutation.isLoading,
  };
};

export default useDeletePost;
