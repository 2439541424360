import React from 'react';
import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import MarketNavbar from '../MarketNav/MarketNavbar';
import useRecommendedLimit from '../libs/fetchLimitRecommended';
import {ReactComponent as BagIcon} from "../../../Assets/bag-3-svgrepo-com 1.svg"
import {ReactComponent as CryingIcon} from "../../../Assets/crying-svgrepo-com 1.svg"
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';



const EmptyCart = () => {
    const { data: recommendedLimit, isLoading: recommendedLoading, isError: recommendedError } = useRecommendedLimit();


    return (
        <Fragment>
        <MarketNavbar/>
        <div className='empty-cart-container'>
            <h3>SHOPPING BAG</h3>
            <div className="empty-cart-card">
                <div className="bag">
                <CryingIcon style={{marginRight:'20px', fontSize:'40px'}}/>
                <BagIcon/>
                </div>
                <div className="empty-bag">
                    <h3>Your bag is empty!</h3>
                    <p>Browse our categories and discover our best deals!</p>
                    <Link to='/Market'>Start Shopping</Link>
                </div>
            </div>
        </div>
         <div className="special-product-con">
        <span>
          <h3>RECOMMENDED</h3>
          <Link to='/recommendedproduct'>VIEW ALL</Link>
        </span>
        {recommendedLoading ? (
          <div className="special-product-con">
    <Skeleton count={5} height={100} width={250} />
    <Skeleton count={5} height={10} width={200}  />
    <Skeleton count={5} height={10} width={180}  />
    <Skeleton count={5} height={10} width={150}  />
          </div>
        ) : recommendedError ? (
          <div>Error fetching recommended product</div>
        ) : Array.isArray(recommendedLimit.products) && recommendedLimit.products.length > 0 ? (
          <div className="special-product-card">
            {recommendedLimit.products.slice(0, 5).map((deal) => (
              <Link to={`/product/${deal._id}`} key={deal._id} className="special-product-info">
                <img src={deal.images[0]} alt="" />
                <p>{deal.name}</p>
                <h3>{deal.price
                  ? (deal.price).toLocaleString("en-US", {
                    style: "currency",
                    currency: "NGN",
                  })
                  : ""}</h3>
              </Link>
            ))}
          </div>
        ) : (
          <div className="errors-msg">No recommended product available</div>
        )}
      </div>
        </Fragment>
    );
}

export default EmptyCart;
