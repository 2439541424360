import "./markplaceadminorder.css"


const MarketplaceAdminorder = () => {
    return ( 
        <div className="markplace-admin-order-container">
<div className="header">
    <h1>Orders</h1>
</div>
        </div>
     );
}
 
export default MarketplaceAdminorder;