import { useContext } from 'react';
import { useQuery } from 'react-query';
import { AuthContext } from "../../../Context/AuthContext";

const useRequestedHires = (page) => {
  const { token, user } = useContext(AuthContext);

  const fetchRequestedHires = async () => {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/hiretruck/trucks/requestingBookingnotbooked?state=${user?.state}&page=${page}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });
    if (!response.ok) {
      throw new Error('Failed to fetch truck new order');
    }
    return response.json();
  };

  return useQuery(['requestedHires', page], fetchRequestedHires);
};

export default useRequestedHires;



