import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as SearchIcon } from "../../Assets/iconoir_search.svg";
import useChartererSearch from './CharterAdmin/libs/searchVehicleByState';
import { FaXmark } from "react-icons/fa6";
import './Chartering.css';

const Charteringservice = () => {
    const [selectedState, setSelectedState] = useState('');
    const [searchInitiated, setSearchInitiated] = useState(false);
    const { data, loading, error, searchByState } = useChartererSearch();
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 8;

    const handleSubmit = (event) => {
        event.preventDefault();
        if (selectedState && selectedState !== "Select State") {
            setSearchInitiated(true);
            searchByState(selectedState, 1);
            setCurrentPage(1); 
        }
    };

    useEffect(() => {
        if (searchInitiated) {
        }
    }, [ searchInitiated, selectedState, searchByState]);

    const handlePrevPage = () => {
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    };

    const handleNextPage = () => {
        setCurrentPage((prevPage) => Math.min(prevPage + 1, data?.totalPages));
    };

    const handleCharterSearch = () => {
        setSearchInitiated(false);
    };

    const startIndex = Math.min((currentPage - 1) * itemsPerPage + 1, data?.totalVehicles || 0);
    const endIndex = Math.min(startIndex + itemsPerPage - 1, data?.totalVehicles || 0);

    return (
        <div className="charter-service">
            <h3>CHARTERING</h3>
            <p>
                Select a state and browse thousands of vehicles shared by local hosts, find the perfect vehicle for all
                kinds of occasions and budgets
            </p>

            <form onSubmit={handleSubmit}>
                <select
                    name="state"
                    required
                    value={selectedState}
                    onChange={(e) => setSelectedState(e.target.value)}
                >
                    <option value="Select State">Select State</option>
                    <option value="Lagos">Lagos</option>
                    <option value="Oyo">Oyo</option>
                    <option value="Osun">Osun</option>
                    <option value="Ondo">Ondo</option>
                    <option value="Ogun">Ogun</option>
                    <option value="Ekiti">Ekiti</option>
                    <option value="Kwara">Kwara</option>
                </select>
                <button type="submit"><SearchIcon /></button>
            </form>

            {searchInitiated && (
                <div className="search-charter-overlay">
                    <span>
                        <button onClick={handleCharterSearch}><FaXmark /></button>
                    </span>
                    <div className="card">
                        {loading && (
                            <div style={{ textAlign: 'center', fontFamily: 'Montserrat' }}>
                                Loading...
                            </div>
                        )}
                        {!loading && error && <div className='error'>{error}</div>}
                        {!loading && !error && data?.vehicles.length === 0 && <p>No vehicles found</p>}
                        {!loading && !error && data?.vehicles.length > 0 && (
                            <div className="charter-search-results">
                                {data.vehicles.map((item) => (
                                    <Link key={item._id} className="charter-result-item" to={`/car-details/${item._id}`}>
                                        <img src={item.images[0]} alt={item.vehicleModel} />
                                        <h4>{item.vehicleModel}</h4>
                                        <p>Price per Day: {item.pricingPerDay}</p>
                                    </Link>
                                ))}
                            </div>
                        )}
                    </div>
                    {!loading && data?.totalVehicles > 0 && (
                        <div className="wrapper">
                            <div className="pagination">
                                <span>{`Showing ${startIndex} - ${endIndex} of ${data?.totalVehicles || 0}`}</span>
                                <div className="btn">
                                    <button onClick={handlePrevPage} disabled={currentPage === 1}>
                                        Prev
                                    </button>
                                    <button onClick={handleNextPage} disabled={currentPage === data.totalPages}>
                                        Next
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}

            <div className="charter-category">
                <h3>Browse by Category</h3>
                <span>
                    <Link to='/carcharterservice'>
                        <img src="https://i.ibb.co/vZcZnTG/img-0166-1404x1112-1-3x.png" alt="" />
                        <h3>Cars</h3>
                    </Link>
                    <Link to='/jeepcharterservice'>
                        <img src="https://i.ibb.co/RctTydv/2015-toyota-highlander-pic-3030150199300269075-1024x768.jpg" alt="" />
                        <h3>Jeeps</h3>
                    </Link>
                    <Link to='/siennacharterservice'>
                        <img src="https://i.ibb.co/D9wX7GH/C5-UJCFJSKQX3-ZX.webp" alt="" />
                        <h3>Sienna</h3>
                    </Link>
                    <Link to='/buscharterservice'>
                        <img src="https://i.ibb.co/ZHtxFKw/01-l3.jpg" alt="" />
                        <h3>Bus</h3>
                    </Link>
                    <Link to='/executivecharter'>
                        <img src="https://i.ibb.co/Ld269bK/Volvo-Bus-Front-Product-Imgs.jpg" alt="" />
                        <h3>Executive Bus</h3>
                    </Link>
                </span>
            </div>
        </div>
    );
};

export default Charteringservice;


