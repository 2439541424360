import { useMutation, useQueryClient } from 'react-query';
import { useContext } from 'react';
import { AuthContext } from '../../Context/AuthContext';

const useAssignPackagesToRider = () => {
  const { token } = useContext(AuthContext);
  const queryClient = useQueryClient();

  const assignPackagesToRider = async ({ userId, packageIds }) => {
    const response = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/packages/assign-packages-rider`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          userId,
          packageIds,
        }),
      }
    );

    if (!response.ok) {
      throw new Error('Error assigning packages to rider');
    }
    queryClient.invalidateQueries('packagesByCreator');
    queryClient.invalidateQueries('riderAssignment');
    queryClient.invalidateQueries('packagesByBranch');

    return response.json();
  };

  return useMutation(assignPackagesToRider);
};

export default useAssignPackagesToRider;


