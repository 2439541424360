import React from 'react';
import Chart from 'react-apexcharts';
import useTruckStats from "../../libs/fetchTruckStats"
import useTruckOrders from "../../libs/fetchFreight24HoursOrder"
import useFreightRevenueData from '../../libs/fetchFreightRevenue';
import "./FreightCharter.css";


 const Freightoverview = () => {
    const { data: revenueData, isLoading: revenueLoading, isError: revenueError } = useFreightRevenueData();
    const { data: truckStats, isLoading: truckLoading, isError: truckError } = useTruckStats();
    const { data: truckOrders, isLoading: orderLoading, isError: orderError } = useTruckOrders();

    const chartOptions = {
        chart: {
          id: "basic-bar"
        },
        xaxis: {
          categories: ['Today', 'This Month', 'This Year', 'All Time']
        }
      };
    
      const chartSeries = [
        {
          name: "Revenue",
          data: [
            revenueData ? revenueData.revenueToday : 0,
            revenueData ? revenueData.revenueThisMonth : 0,
            revenueData ? revenueData.revenueThisYear : 0,
            revenueData ? revenueData.totalRevenueAllTime : 0
          ]
        }
      ];
      return (
        <div className='freight-overview'> 
          <div className="wrapper">
            <h3>Freight Overview</h3>
          </div> 
          <div className="freight-overview-mother">
            <div className="wrapper">
              <div className="card">
                <p>Total Trucks</p>
                <h3>{truckStats ? truckStats.totalFreights : 0}</h3>
              </div>
              <div className="card">
                <p>Total Truck on Trip</p>
                <h3>{truckStats ? truckStats.freightsOnTrip : 0}</h3>
              </div>
              <div className="card">
                <p>Total Order in last 24hrs</p>
                <h3>{truckOrders ? truckOrders.recentOrdersCount : 0}</h3>
              </div>
              <div className="card">
                <p>Total Monthly Revenue</p>
                <h3>
                  {revenueData
                    ? revenueData.revenueThisMonth.toLocaleString("en-US", {
                        style: "currency",
                        currency: "NGN",
                      })
                    : 0}
                </h3>
              </div>
              <div className="card">
                <p>Total Revenue</p>
                <h3>
                  {revenueData
                    ? revenueData.totalRevenueAllTime.toLocaleString("en-US", {
                        style: "currency",
                        currency: "NGN",
                      })
                    : 0}
                </h3>
              </div>
            </div>
            <div className="revenue">
              <h3>REVENUE</h3>
              {revenueLoading || truckLoading || orderLoading ? (
               <p style={{fontFamily:'Montserrat', textAlign:'center', width:'100%'}}>Loading...</p>
              ) : (revenueError || truckError || orderError) ? (
                <div>Error: {revenueError.message || truckError.message || orderError.message}</div>
              ) : (
                <Chart
                  options={chartOptions}
                  series={chartSeries}
                  type="bar"
                  width="700"
                  fontFamily="Montserrat"
                />
              )}
            </div>
          </div>
        </div>
      );
}

export default Freightoverview;
