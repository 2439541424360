import { useContext } from 'react';
import { useQueryClient } from 'react-query';
import { AuthContext } from '../../Context/AuthContext';

const useDeleteHomePreShipmentById = () => {
  const { token } = useContext(AuthContext);
  const queryClient = useQueryClient();

  const deleteHomePreShipmentById = async (id) => {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/prehome/delete-homepreshipment/${id}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
       
      },
      withCredentials: true,
    });

    if (!response.ok) {
      throw new Error(`Error deleting home pre-shipment with ID: ${id}`);
    }


    queryClient.invalidateQueries('preShipment');

    return response.json();
  };

  return { deleteHomePreShipmentById };
};

export default useDeleteHomePreShipmentById;
