import { useContext } from 'react';
import { useQuery } from 'react-query';
import { AuthContext } from "../../../../Context/AuthContext";

const useRequestedHires = (currentPage) => {
  const { token } = useContext(AuthContext);

  const fetchRequestedHires = async () => {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/charterer/requested-hires?page=${currentPage}&perPage=10`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });
    if (!response.ok) {
      throw new Error('Failed to fetch requested hires');
    }
    return response.json();
  };

  return useQuery(['requestedHires', currentPage], fetchRequestedHires);
};

export default useRequestedHires;



