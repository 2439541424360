import "./Home.css";
import { Fragment, useContext, useState } from "react";
import { Link } from "react-router-dom";
import Spinner from "../Spinner/Spinner";
import GetQuoteForm from "../Getquote/GetQuoteForm";
import FaqSwitch from "../../Faq/FaqSwitch";
import { formatDate } from "../../utils/formatDate";
import { ReactComponent as SearchIcon } from "../../Assets/iconoir_search.svg";
import { ReactComponent as ShipIcon } from "../../Assets/box-svgrepo-com 1.svg";
import { ReactComponent as MarketIcon } from "../../Assets/receipt-text-svgrepo-com 1.svg";
import { ReactComponent as QuoteIcon } from "../../Assets/shop-svgrepo-com 1.svg";
import { ReactComponent as LocationIcon } from "../../Assets/location-svgrepo-com 1.svg";
import { ReactComponent as MonitorIcon } from "../../Assets/monitor-alt-3-svgrepo-com 1.svg";
import { ReactComponent as PackageIcon } from "../../Assets/package-delivery-box-13-svgrepo-com 1.svg";
import { ReactComponent as PlaneIcon } from "../../Assets/paper-plane-svgrepo-com 1.svg";
import { ReactComponent as ReturntIcon } from "../../Assets/return-svgrepo-com 1.svg";
import { useNavigate } from "react-router-dom";
import { HiXMark } from "react-icons/hi2";
import { GlobalContext } from "../../GlobalContext/GlobalContext";
import {ReactComponent as CautionIcon} from "../../Assets/caution-svgrepo-com 1.svg"

const defaultForm = {
  tracking_number: "",
};

const Home = () => {
  const { isCancelOpen, setIsCancelOpen } = useContext(GlobalContext);
  const [formField, setFormField] = useState(defaultForm);
  const { tracking_number } = formField;
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [trackingData, setTrackingData] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormField({ ...formField, [name]: value });
  };


  const handleTrackingSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setIsCancelOpen(true);
    setError(null);

    try {
      const res = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/packages/trackpackage`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formField),
        }
      );

      const resData = await res.json();

      if (!res.ok) {
        throw new Error(resData.message);
      }

      setTrackingData(resData);
      setIsCancelOpen(true);
      setFormField(defaultForm);
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const navigate = useNavigate();

  

  return (
    <Fragment>
      {isCancelOpen && (
        <div className="tracking-mother-card">
          <div className="wrapper">
            {isLoading && <Spinner />}
            {error && (
              <div className="error">
                <CautionIcon/>
                <p>{error}</p>
                <button onClick={() => setIsCancelOpen(false)}>Okay</button>
              </div>
            )}
            {!isLoading && !error && trackingData && (
              <Fragment>
                <span>
                  <h3>Track Shipment</h3>
                  <button onClick={() => setIsCancelOpen(false)}>
                    <HiXMark />
                  </button>
                </span>
                <div className="wrapper-span">
                  <p>Delivery Address</p>
                </div>
                <div className="tracking-from">
                  <h3>From</h3>
                  <p>
                    {trackingData.sender.address_1}, {trackingData.sender.state}
                  </p>
                </div>
                <div className="tracking-to">
                  <h3>To</h3>
                  <p>
                    {trackingData.receiver.address_1},{" "}
                    {trackingData.receiver.state}
                  </p>
                </div>
                <div className="tracking-history">
                  <h3>Tracking History</h3>
                  <div className="tracking-span">
                    <span>
                      <h3>Waybill Number</h3>
                      <p>
                        {trackingData.waybill &&
                          trackingData.waybill.waybillNumber}
                      </p>
                    </span>
                    <span>
                     <div className="delivery-date">
                     <h3>Estimated Delivery Date</h3>
                     </div>
                      <p>
                        {new Date(
                          trackingData.estimatedDeliveryDate
                        ).toLocaleDateString()}
                      </p>
                    </span>
                  </div>
                  <h2>Status</h2>
                  <div className="tracking-status">
                    {trackingData.statusHistory.map((status, index) => (
                      <ul key={index}>
                        <div className="box-1">
                          <p>{status.status}</p>

                          {status.state && <p>State: {status.state}</p>}
                          {status.city && <p>City: {status.city}</p>}
                          {status.receivedBy && (
                            <h3>Received By : {status.receivedBy}</h3>
                          )}
                        </div>

                        {/* <p>{new Date(status.createdAt).toLocaleString()}</p> */}
                        <p>{formatDate(status.createdAt)}</p>
                      </ul>
                    ))}
                  </div>
                </div>
              </Fragment>
            )}
          </div>
        </div>
      )}

      <div className="home">
        <div className="home-container1">
          <div className="home-overlay">
            <div className="wrapper">
              <div className="home-con1">
                <h2>Your Smart Logistics and E-commerce Solution</h2>
                <p>
                  Your one-stop solution for efficient logistics and seamless
                  e-commerce experiences. Choose smart, choose us.
                </p>
                <form onSubmit={handleTrackingSubmit}>
                  <input
                    type="text"
                    placeholder="Enter your tracking number"
                    name="tracking_number"
                    value={tracking_number}
                    onChange={handleChange}
                    required
                  />
                  <button type="submit" disabled={isLoading}>
                    <SearchIcon />
                  </button>
                </form>
              </div>
              <div className="img-brand"></div>
            </div>
          </div>
        </div>
        <div className="home-con3">
          <Link to="/Sender" className="ship-now">
            <h3>SHIP NOW</h3>
            <ShipIcon />
          </Link>
          <Link to="/Market" className="market-box">
            <h3>MARKET PLACE</h3>
            <MarketIcon />
          </Link>
          <Link to="/GetQuote" className="get-qoute">
            <h3>GET QOUTE</h3>
            <QuoteIcon />
          </Link>
        </div>
        <div className="tracking">
          <form onSubmit={handleTrackingSubmit}>
            <input
              type="text"
              placeholder="Enter your tracking number"
              name="tracking_number"
              value={tracking_number}
              onChange={handleChange}
              required
            />
            <button type="submit" disabled={isLoading}>
              <SearchIcon />
            </button>
          </form>
        </div>
        <div className="login">
          <button onClick={() => navigate("/Login")}>Login</button>
        </div>
        <div className="home-discover">
          <div className="discover-wrapper">
            <div className="box1">
              <div className="inner">
                <p>Company</p>
                <h3>
                  Discover Our Identity At SmartTrans Express, where innovation
                  and dedication converge.
                </h3>
              </div>
              <div className="image">
                <img
                  src="https://i.ibb.co/0fpkwCR/Four-Flags-Mockup-1-2x.png"
                  alt=""
                />
              </div>
            </div>
            <div className="box2">
              <img src="https://i.ibb.co/SQ3hHTd/Downloader-1-2x.png" alt="" />
              <div className="words">
                <p>We are more than a logistics and e-commerce company</p>

                <p>
                  we're architects of seamless journeys. Our story unfolds
                  through a commitment to excellence, propelled by cutting-edge
                  technology and a customer-centric ethos. SmartTrans Express
                  embodies reliability, efficiency and a relentless pursuit of
                  smart solutions. Meet our passionate team, the driving force
                  behind every successful delivery. As you explore into our
                  identity, you'll find a blend of expertise and a relentless
                  pursuit of excellence. Join us in unlocking the possibilities
                  of smart logistics and e-commerce, where your satisfaction is
                  our ultimate goal.
                </p>
                <Link to="/About">More about us</Link>
              </div>
            </div>
          </div>
        </div>
        <div className="home-container-2">
          <div className="wrapper">
            <div className="box">
              <div className="wrapper-circle-mother-1">
                <div className="wrapper-circle-1">
                  <PackageIcon />
                </div>
              </div>
              <h3>Shipping Made Simple</h3>
              <p>
                Effortlessly oversee the delivery of your items with our
                user-friendly shipping management
              </p>
            </div>
            <div className="box">
              <div className="wrapper-circle-mother-2">
                <div className="wrapper-circle-2">
                  <ReturntIcon />
                </div>
              </div>
              <h3>Return Solutions at Your Fingertips</h3>
              <p>
                Easily handle product returns through our hassle-free and
                accessible return management system
              </p>
            </div>
            <div className="box">
              <div className="wrapper-circle-mother-3">
                <div className="wrapper-circle-3">
                  <PlaneIcon />
                </div>
              </div>
              <h3>Tailor Your Delivery Experience</h3>
              <p>
                Customize how you receive your packages, putting you in control
                of your delivery preferences
              </p>
            </div>
            <div className="box">
              <div className="wrapper-circle-mother-4">
                <div className="wrapper-circle-4">
                  <LocationIcon />
                </div>
              </div>
              <h3>Update Your Delivery Locations</h3>
              <p>
                Conveniently modify and organize your delivery destinations for
                accurate and efficient order Processing.
              </p>
            </div>
            <div className="box">
              <div className="wrapper-circle-mother-5">
                <div className="wrapper-circle-5">
                  <MonitorIcon />
                </div>
              </div>
              <h3>Monitor Return Progress</h3>
              <p>
                Stay in the loop about the status of your returns, allowing you
                to track the journey back seamlessly.
              </p>
            </div>
          </div>
        </div>
        <div className="home-container-3">
          <span>
            <h3>Friendly Service</h3>
            <p>
              Our dependable and budget-friendly services ensure peace of mind
              without breaking the bank
            </p>
          </span>
          <div className="wrapper">
            <div className="friend-service-con1">
              <img src="https://i.ibb.co/9t2ZsXX/Downloader-4-2x.png" alt="" />
            </div>
            <div className="friend-service-con2">
              <h3>Friendly Service</h3>
              <p>
                Our dependable and budget-friendly services ensure peace of mind
                without breaking the bank
              </p>
              <div className="friend-box-1">
                <span>
                  <div className="friend-square"></div>
                  <h3>Unprecedented Reliability and Affordability</h3>
                </span>
                <p>
                  dedicated to providing budget-friendly solutions that ensure
                  peace of mind, be it shipping packages, managing logistics, or
                  exploring e-commerce offerings. Count on us for seamless
                  experiences that prioritize your satisfaction, delivering
                  exceptional value without straining your wallet.
                </p>
              </div>
              <div className="friend-box-2">
                <div className="friend-card-1">
                  <span>
                    <div className="friend-square"></div>
                    <h3>Friendly Service Ethos</h3>
                  </span>
                  <p>
                    Our core values center around a friendly service ethos,
                    acknowledging the importance of reliability in every
                    transaction. At SmartTrans Express, our services are
                    meticulously designed to meet your needs without
                    compromising quality. A budget-friendly approach guarantees
                    exceptional value without compromising on trust. From swift
                    deliveries to efficient logistics management, we ensure a
                    dependable, cost-effective, and, above all, friendly
                    experience.
                  </p>
                </div>
                <div className="friend-card-2">
                  <span>
                    <div className="friend-square"></div>
                    <h3>Part of Our Valued Community</h3>
                  </span>
                  <p>
                    At SmartTrans Express, you're not just a customer; you're a
                    valued community member. We prioritize your satisfaction by
                    merging reliability and affordability, offering a practical
                    choice for your logistics and e-commerce needs. Choose us
                    for a dependable, budget-friendly journey that ensures your
                    experience remains as smooth as possible.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="home-container4">
          <div className="wrapper">
            <GetQuoteForm />
            <div className="empty-container"></div>
            <div className="home-blog-container">
              <span>
                <h3>Don’t miss our latest News and Articles</h3>
                <Link to="/Blog">View more </Link>
              </span>
              <div className="home-blog-line"></div>
              <div className="home-blog-con">
                <h3>Don’t miss our latest News and Articles</h3>
                <div className="home-blog-box">
                  <img src="https://i.ibb.co/sCkvs2P/Frame-34-2x.png" alt="" />
                  <h3>Revolutionizing Last-Mile Delivery</h3>
                  <p>
                    Revolutionizing Last-Mile Delivery in 2024 signifies a
                    paradigm shift in logistics, driven by advanced technologies
                    like autonomous vehicles, drones, and predictive analytics.
                    Streamlining operations, reducing delivery times, and
                    enhancing customer experiences, these innovations mark a
                    pivotal moment in the industry's evolution.
                  </p>
                </div>
                <div className="home-blog-box">
                  <img
                    src="https://i.ibb.co/X7h28Rq/Frame-34-2x-1.png"
                    alt=""
                  />
                  <h3>Sustainable Supply Chains</h3>
                  <p>
                    Sustainable Supply Chains in 2024 epitomize an eco-friendly
                    revolution. From circular economies to carbon-neutral
                    practices, businesses prioritize environmental
                    responsibility. Reducing carbon footprints and fostering
                    ethical sourcing, these trends redefine success by
                    harmonizing profitability with planet-friendly principles.
                  </p>
                </div>
                <div className="home-blog-box">
                  <img src="https://i.ibb.co/9T2z8Rf/Frame-34-zx.png" alt="" />
                  <h3>AI and Robotics Reshaping Warehousing</h3>
                  <p>
                    AI and Robotics Reshaping Warehousing in 2024 epitomize a
                    technological renaissance. Automation enhances efficiency,
                    accelerates order fulfillment, and minimizes errors. Smart
                    warehouses leverage artificial intelligence for predictive
                    maintenance, optimizing storage, and transforming logistics
                    into a realm of unprecedented precision and speed.
                  </p>
                </div>
                <Link to="/Blog">View More</Link>
              </div>
            </div>
          </div>
        </div>
        <div className="home-faq-container">
          <h3>Frequently asked Questions and Answers</h3>
          <p>FAQs for SmartTran Express - Logistics and E-commerce</p>
          <div className="wrapper">
            <FaqSwitch />
          </div>
          <Link to="/FaqComponent">See all Faq</Link>
        </div>
      </div>
    </Fragment>
  );
};

export default Home;
