import React, { useRef, useState, useEffect } from "react";
import { ReactComponent as ImageIcon } from "../asset/Image.svg";
import "./imagePiker.css"

const ImageUpload = ({ onChange }) => {
  const [file, setFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState("");
  const selectFile = useRef();

  useEffect(() => {
    if (!file) {
      setPreviewUrl("");
      return;
    }

    const fileReader = new FileReader();
    fileReader.onload = () => {
      setPreviewUrl(fileReader.result);
    };
    fileReader.readAsDataURL(file);
  }, [file]);

  const selectedFile = (event) => {
    let pickedFile = null;

    if (event.target.files && event.target.files.length === 1) {
      pickedFile = event.target.files[0];
      setFile(pickedFile);
      onChange(pickedFile);
    } else {
      setFile(null);
      onChange(null);
    }
  };

  const selectImageHandler = () => {
    selectFile.current.click();
  };

  return (
    <div className="file-form-container">
      <input
        type="file"
        id="image"
        accept=".jpg,.png,.jpeg"
        ref={selectFile}
        onChange={selectedFile}
        required
        hidden
      />
      <div className="image-upload">
        <div className="image-preview">
          {previewUrl ? (
            <img src={previewUrl} alt="preview" className="preview-image" style={{ width: "100px", height: "100px" }} />
          ) : (
            <div className="image-icon">
            <ImageIcon className="placeholder-icon" onClick={selectImageHandler} />
            <p>Upload Headline Image</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ImageUpload;

