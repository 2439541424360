import React, { useState } from 'react';
import useChangePassword from '../lib/fetchMerchantPassChange';
import Spinner from '../../../Spinner/Spinner';
import { LiaEyeSlashSolid } from 'react-icons/lia';
import { LiaEyeSolid } from 'react-icons/lia';

const defaultformField = {
  oldPassword: '',
  newPassword: '',
};

const ChangePasswordForm = () => {
  const [formField, setFormField] = useState(defaultformField);
  const { oldPassword, newPassword } = formField;
  const [showPass1, setShowPass1] = useState(false);
  const [showPass2, setShowPass2] = useState(false);

  const { loading, error, success, changePassword } = useChangePassword();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormField({ ...formField, [name]: value });
  };

  const handleShowPass1 = () => {
    setShowPass1(!showPass1);
  };

  const handleShowPass2 = () => {
    setShowPass2(!showPass2);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    changePassword(oldPassword, newPassword);
    setFormField(defaultformField)
  };
  

  return (
    <div className="merchant-password-card">
        <h3>Change Password</h3>
      <form onSubmit={handleSubmit}>
        <span>
          <label>Old Password </label>
          <div className="merchant-pass">
            <input
              type={showPass1 ? 'text' : 'password'}
              name="oldPassword"
              value={oldPassword}
              onChange={handleChange}
              required
            />
            <wrapper onClick={handleShowPass1}>
              {showPass1 ? <LiaEyeSlashSolid /> : <LiaEyeSolid />}
            </wrapper>
          </div>
        </span>

        <span>
          <label>New Password</label>
          <div className="merchant-pass">
            <input
              type={showPass2 ? 'text' : 'password'}
              name="newPassword"
              value={newPassword}
              onChange={handleChange}
              required
            />
            <wrapper onClick={handleShowPass2}>
              {showPass2 ? <LiaEyeSlashSolid /> : <LiaEyeSolid />}
            </wrapper>
          </div>
        </span>
        <button type="submit" disabled={loading}>
          {loading ? <Spinner /> : 'Change Password'}
        </button>
        {error && <p style={{ color: 'red', fontFamily: 'Montserrat' }}>{error}</p>}
        {success && <p style={{ color: 'green', fontFamily: 'Montserrat' }}>{success}</p>}
      </form>
    </div>
  );
};

export default ChangePasswordForm;