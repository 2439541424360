import { useInfiniteQuery } from 'react-query';

const fetchTrendingDeal = async ({ pageParam = 1 }) => {
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/product/category/trending-deals?page=${pageParam}`, {
    headers: {
      'Content-Type': 'application/json'
    }
  });

  if (!response.ok) {
    throw new Error('Failed to fetch products');
  }

  return response.json();
};

const useTrendingDeals = () => {
  return useInfiniteQuery('trendingDeals', fetchTrendingDeal, {
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.currentPage + 1;
      return nextPage <= Math.ceil(lastPage.totalProducts / 10) ? nextPage : undefined;
    }
  });
};

export default useTrendingDeals;


