// import { useQuery } from "react-query";
// import { useContext } from "react";
// import { AuthContext } from "../../Context/AuthContext";

// const fetchChartererOfficers = async (token) => {
//   const response = await fetch(
//     `${process.env.REACT_APP_SERVER_URL}/users/charterer`,
//     {
//       headers: {
//         Authorization: `Bearer ${token}`,
//       },
//       credentials: "include",
//     }
//   );

//   if (!response.ok) {
//     throw new Error("Failed to fetch Charterer officers");
//   }

//   return response.json();
// };

// const useFetchChartererOfficers = () => {
//   const { token } = useContext(AuthContext);

//   return useQuery("chartererOfficers", () => fetchChartererOfficers(token));
// };

// export default useFetchChartererOfficers;

import { useInfiniteQuery } from 'react-query';
import { useContext } from 'react';
import { AuthContext } from "../../Context/AuthContext";

const fetchChartererOfficers = async ({ pageParam = 1 }, token) => {
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/users/charterer?page=${pageParam}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error('Failed to fetch Charterer officers');
  }

  return response.json();
};

const useFetchChartererOfficers = () => {
  const { token } = useContext(AuthContext);

  return useInfiniteQuery(
    ['chartererOfficers'], 
    ({ pageParam = 1 }) => fetchChartererOfficers({ pageParam }, token),
    {
      getNextPageParam: (lastPage) => {
        const { currentPage, totalChartererManagers } = lastPage;
        const totalPages = Math.ceil(totalChartererManagers / 10); 
        return currentPage < totalPages ? currentPage + 1 : undefined;
      },
    }
  );
};

export default useFetchChartererOfficers;







