import { useQuery } from 'react-query';
import { AuthContext } from '../../Context/AuthContext';
import { useContext } from 'react';

const useDriverAssignment = (assignmentId) => {
 const {token , user} = useContext(AuthContext)

  return useQuery(['driverAssignment', assignmentId], async () => {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/packages/driver-assignments/${user.userId}`, {
      headers: {
        Authorization: `Bearer ${token}`, 
      },
    });

    if (!response.ok) {
      const errorData = await response.json(); 
      throw new Error(errorData.message || 'Network response was not ok');
    }

    return response.json(); 
  });
};

export default useDriverAssignment;
