import { useMutation, useQueryClient } from 'react-query';
import { useContext } from 'react';
import { AuthContext } from '../../../Context/AuthContext';

const markTruckAsOnRepair = async (truckId, token) => {
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/freight/trucks/${truckId}/onRepair`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error('Failed to mark truck as on-repair');
  }

  return response.json();
};

const useMarkTruckAsOnRepair = () => {
  const { token } = useContext(AuthContext);
  const queryClient = useQueryClient();

  return useMutation((truckId) => markTruckAsOnRepair(truckId, token), {
    onSuccess: () => {
      queryClient.invalidateQueries('trucks');
    },
  });
};

export default useMarkTruckAsOnRepair;
