import { useInfiniteQuery } from 'react-query';
import { useContext } from 'react';
import { AuthContext } from "../../Context/AuthContext";

const fetchDispatchData = async ({ pageParam = 1 }, token) => {
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/packages/dispatch?page=${pageParam}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error('Failed to fetch dispatch data');
  }

  return response.json();
};

const useDispatchData = () => {
  const { token } = useContext(AuthContext);
  return useInfiniteQuery(
    ['dispatchData'], 
    ({ pageParam = 1 }) => fetchDispatchData({ pageParam }, token),
    {
      getNextPageParam: (lastPage) => {
        const nextPage = lastPage.currentPage + 1;
        return nextPage <= Math.ceil(lastPage.totalDispatches / 10) ? nextPage : undefined;
      },
    }
  );
};

export default useDispatchData;




