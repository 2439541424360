import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { MdKeyboardDoubleArrowRight } from 'react-icons/md';
import Skeleton from 'react-loading-skeleton'; 
import 'react-loading-skeleton/dist/skeleton.css';
import useBusCollection from '../CharterAdmin/libs/fetchBusCollection';
import './AllCharter.css'

const Buscharterservice = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 8;
    const { busData, isLoading, isError } = useBusCollection(currentPage);

    useEffect(() => {
        if (busData && busData.currentPage) {
            setCurrentPage(busData.currentPage);
        }
    }, [busData]);

    const handleScroll = useCallback(() => {
        if (
            window.innerHeight + document.documentElement.scrollTop === 
            document.documentElement.offsetHeight
        ) {
            if (currentPage < busData.totalPages) {
              
            }
        }
    }, [currentPage, busData]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [handleScroll]);

    if (isError) {
        return <div className='error'>No Bus available now</div>;
    }

    if (isLoading) {
        return (
            <div className='vehicle-collection'>
                <span>
                    <Link to='/charteringservice'>Home</Link>
                    <MdKeyboardDoubleArrowRight />
                    <h3>Buses</h3>
                </span>
                <h3>BUS COLLECTIONS</h3>
                <div className="wrapper">
                    {[...Array(8)].map((_, index) => (
                        <div key={index} className="vehicle-list">
                            <Skeleton height={250} width={300} count={1} />
                        </div>
                    ))}
                </div>
            </div>
        );
    }

    const { buses, totalBuses } = busData; 

    if (!buses || buses.length === 0) {
        return <div>No buses available</div>; 
    }

    const handlePrevPage = () => {
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
      };
    
      const handleNextPage = () => {
        setCurrentPage((prevPage) => Math.min(prevPage + 1, busData.totalPages));
      };

    const totalPages = Math.ceil(totalBuses / itemsPerPage);
    const startIndex = Math.min((currentPage - 1) * itemsPerPage + 1, totalBuses);
    const endIndex = Math.min(startIndex + itemsPerPage - 1, totalBuses);
  

    const clampWords = (text, maxWords) => {
        const words = text.split(' ');
        const clampedText = words.slice(0, maxWords).join(' ');
        return words.length > maxWords ? `${clampedText}...` : clampedText;
    };

    return (
        <div className='vehicle-collection'>
            <span>
                <Link to='/charteringservice'>Home</Link>
                <MdKeyboardDoubleArrowRight />
                <h3>Buses</h3>
            </span>
            <h3>BUS COLLECTIONS</h3>
            <div className="wrapper">
               {buses.map(bus => (
                    <div key={bus._id} className="vehicle-list">
                        <img src={bus.images[0]} alt={bus.vehicleModel} />
                        <h4>{bus.vehicleModel}</h4>
                        <h3><p>Price Per Day:</p>{bus.pricingPerDay
                      ? (bus.pricingPerDay ).toLocaleString("en-US", {
                          style: "currency",
                          currency: "NGN",
                        })
                      : ""}</h3>
                        <p>{bus.isHired ? 'Not available' : 'Available'}</p>
                        <p>{clampWords(bus.description, 5)}<Link to={`/car-details/${bus._id}`}>view details</Link></p>
                    </div>
                ))}
            </div>
            <div className="vehicle-collection-pagination">
                <span>{`Showing ${startIndex} - ${endIndex} of ${totalBuses}`}</span>
                <div className="btn">
                    <button onClick={handlePrevPage} disabled={currentPage === 1}>
                        Prev
                    </button>
                    <button onClick={handleNextPage} disabled={currentPage === totalPages}>
                        Next
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Buscharterservice;
