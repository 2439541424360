import React, { Fragment, useState } from "react";
import { ReactComponent as SearchIcon } from "../../asset/iconamoon_search-light.svg";
import useDriverAssignment from "../Libs/useDriverAssignment";
import { ReactComponent as LocaIcon } from "../../asset/fluent_location-20-regular.svg";
import { ReactComponent as BoxIcon } from "../../asset/box-tick-svgrepo-com 1.svg";
import useAcceptPackage from "../Libs/useAcceptPackage";
import useRejectPackage from "../Libs/rejectPackage";
import Spinner from "../../utils/spinner"
import Driveraccepted from "../Driver_accpted/Driveraccepted";
import "./Driverhome.css";


function Driverhome() {
  const {
    data: assignmentData,
    isLoading,
    isError,
    error,
  } = useDriverAssignment();

  const acceptPackageMutation = useAcceptPackage();
  const rejectPackageMutation = useRejectPackage()
  const [searchQuery, setSearchQuery] = useState("");
  const [selectPkgId, setSelectedPkgId] = useState(null);
  const [assignmentId, setAssignmentId] = useState(null)
  const [acceptOverlay, setAcceptOverlay] = useState(false);
  const [acceptLoading, setAccepLoading] = useState(false);
  const [accptError, setAcceptError] = useState(null);
  const [rejectLoading, setRejectLoading] = useState(false);
  const [rejectError, setRejectError] = useState(null);
  const [rejectOverlay, setRejectOverlay] = useState(false)


  const filteredAssignments = assignmentData?.driverAssignments
    .filter(
      (assignment) =>
        assignment.package[0].receiver.state
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        assignment.package[0].receiver.address_1
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        assignment.package[0].name
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
    )
    .reverse();

  const handleAcceptPackage = async () => {
    setAccepLoading(true);

    try {
      await acceptPackageMutation.mutateAsync(selectPkgId);
    } catch (error) {
      setAcceptError(error.message);
    } finally {
      setSelectedPkgId(null);
      setAcceptOverlay(false);
      setAccepLoading(false);
    }
  };

  const handleRejectPackage = async () => {
    setRejectLoading(true);
    try {
      await rejectPackageMutation.mutateAsync(assignmentId);
    } catch (error) {
      setRejectError(error.message);
    } finally {
      setAssignmentId(null);
      setRejectOverlay(false);
      setRejectLoading(false);
    }
  };

  return (
    <Fragment>
      {acceptOverlay && (
        <div className="accept-over-con">
          <div className="card">
            <div className="box">
              <BoxIcon />
            </div>
            <h3>Accept Trip</h3>
            <p>Do you want to accept this Trip?</p>
            <div className="btn">
              <button
                onClick={() => {
                  setSelectedPkgId(null);
                  setAcceptOverlay(false);
                }}
              >
                No
              </button>
              <button onClick={handleAcceptPackage} disabled={acceptLoading}>
                {acceptLoading ? "Accepting..." : "Yes"}
              </button>
            </div>
            {accptError && <p>{accptError}</p>}
          </div>
        </div>
      )}

      {
        rejectOverlay && (
          <div className="accept-over-con">
          <div className="card">
            <div className="box">
              <BoxIcon />
            </div>
            <h3>Reject Trip</h3>
            <p>Do you want to reject this Trip?</p>
            <div className="btn">
              <button
                onClick={() => {
                  setAssignmentId(null)
                  setRejectOverlay(false);
                }}
              >
                No
              </button>
              <button onClick={handleRejectPackage} disabled={rejectLoading}>
                {rejectLoading ? "Rejecting..." : "Yes"}
              </button>
            </div>
            {rejectError && <p>{rejectError}</p>}
          </div>
        </div>
        )
      }
      <div className="home-account-container">
        <div className="my-assignment">
          <span>
            <SearchIcon />
            <input
              type="text"
              placeholder="Search Trips"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </span>

          <div className="assignment">
            {isLoading && <Spinner/>}
            {isError && <p>Error: {error.message}</p>}
            {!isLoading && !isError && (
              <>
                {!isLoading && !isError && (
                  <>
                    {filteredAssignments.length === 0 ? (
                      <p>No matching assignments found.</p>
                    ) : (
                      filteredAssignments.map((assignment) => (
                        <div key={assignment._id} className="assignment-card">
                          <h2>ID {assignment._id.slice(0, 8).toUpperCase()}</h2>
                          <div className="in">
                            <span>{`${assignment.package[0].name}`}</span>
                            <p>
                              {new Date(
                                assignment.package[0].dateCreated
                              ).toLocaleString()}
                            </p>
                          </div>
                          <div className="inner">
                            <p>
                              <LocaIcon />
                              {`${assignment.package[0].receiver.state}, ${assignment.package[0].receiver.address_1}`}
                            </p>
                            <span>
                              <p>Estimated Delivery Date:</p>
                              <h3>
                                {" "}
                                {new Date(
                                  assignment.package[0].estimated_delivery_date
                                ).toLocaleDateString()}
                              </h3>
                            </span>
                          </div>
                          <p>
                            Accepted:{" "}
                            {assignment?.accept?.length > 0 ? "Yes" : "No"}
                          </p>
                          <div className="btn">
                            <button onClick={() => {
                               setAssignmentId(assignment._id);
                               setRejectOverlay(true)
                            }} disabled={assignment.accept.length > 0}>Reject</button>
                            
                            <button
                              onClick={() => {
                                setSelectedPkgId(assignment.package[0]._id);
                                setAcceptOverlay(true);
                              }}
                              disabled={assignment.accept.length > 0}
                            >
                              Accept
                            </button>
                          </div>
                        </div>
                      ))
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>
        <Driveraccepted/>
      </div>
    </Fragment>
  );
}

export default Driverhome;
