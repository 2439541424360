import { useContext } from 'react';
import axios from 'axios';
import { useQuery } from 'react-query';
import { AuthContext } from '../../Context/AuthContext';


const useRidersByBranch = () => {
  const { token, user } = useContext(AuthContext);

  const fetchRiders = async () => {
    const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/users/riders/${user.branch_full_address}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  };

  const { data: ridersData, isLoading, isError } = useQuery(['riders'], fetchRiders);

  return {
    ridersData,
    isLoading,
    isError,
  };
};

export default useRidersByBranch;
