import PublicMessage from "../../libs/fetchPublicMessage";
import "./SupportMessage.css"

const SupportMessage = () => {
    return (
       <div className="support-message-container">
        <div className="header">
            <p>Messages</p>
        </div>
        <PublicMessage/>
       </div>
    );
}

export default SupportMessage;
