import { useQuery } from "react-query";

const fetchCurrentUser = async (token) => {
  const response = await fetch(
    `${process.env.REACT_APP_SERVER_URL}/users/current-user`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );

  if (!response.ok) {
    throw new Error("Failed to fetch current user data");
  }

  const userData = await response.json();
  return userData;
};

export const useGetCurrentUser = (token) => {
  return useQuery("currentUser", () => fetchCurrentUser(token), {
    enabled: !!token, 
  });
};

export default fetchCurrentUser;