import { useContext, useState, useEffect } from 'react';
import { AuthContext } from '../../../../Context/AuthContext';


const useChangePassword = () => {
  const { user, token } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);


  const changePassword = async (oldPassword, newPassword) => {
    setLoading(true);
    setError(null);

    try {
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/users/change-password/${user.userId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ oldPassword, newPassword }),
      });

      if (!response.ok) {
        throw new Error('Failed to change password');
      }

      setSuccess('Password changed successfully');
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    if (success) {
      setSuccess(success);
      const timer = setTimeout(() => {
        setSuccess('');
      }, 3000); 
      return () => clearTimeout(timer);
    }
  }, [success]);

  return { loading, error, success, changePassword };
};

export default useChangePassword;