import React from "react";
import { Fragment } from "react";
import { Link , Outlet} from "react-router-dom";
import MarketNavbar from "./MarketNav/MarketNavbar";
import SimpleSlider from "./Banner/BannerSlider";
import { CiApple } from "react-icons/ci";
import { TbRibbonHealth } from "react-icons/tb";
import { IoHomeOutline } from "react-icons/io5";
import { GiPressureCooker } from "react-icons/gi";
import { GiSmartphone } from "react-icons/gi";
import { HiOutlineComputerDesktop } from "react-icons/hi2";
import { PiTelevision } from "react-icons/pi";
import { IoShirtOutline } from "react-icons/io5";
import { LiaBabySolid } from "react-icons/lia";
import { PiGameController } from "react-icons/pi";
import { GiWeightLiftingUp } from "react-icons/gi";
import { TbToolsKitchen2 } from "react-icons/tb";
import { PiBeerBottleDuotone } from "react-icons/pi";
import { CiHome } from "react-icons/ci";
import useRecommendedLimit from "./libs/fetchLimitRecommended.js";
import useTrendingLimit from "./libs/fetchLimitTrending";
import useTopSellingLimit from "./libs/fetchLimitTopselling";
import useTodayDealLimit from "./libs/fetchLimitTodayDeal"
import useFetchProductCategory from "../../libs/getProductCategories.js";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import "./Market.css";


const iconsMap = {
  "Super Market": <CiApple />,
  "Health & Beauty": <TbRibbonHealth />,
  "Home & Office": <IoHomeOutline />,
  "Appliances": <GiPressureCooker />,
  "Phones & Tablets": <GiSmartphone />,
  "Computing": <HiOutlineComputerDesktop />,
  "Electronics": <PiTelevision />,
  "Fashion": <IoShirtOutline />,
  "Baby Products": <LiaBabySolid />,
  "Gaming": <PiGameController />,
  "Sporting Goods": <GiWeightLiftingUp />,
  "Drink & Groceries": <PiBeerBottleDuotone />,
  "Kitchen Utensils": <TbToolsKitchen2 />,
  "Building Materials": <CiHome />
};


const Market = () => {

  const {data: trendingLimit, isLoading, isError } = useTrendingLimit();
  const {data: todayDealLimit, isLoading: todayLoading, isError: todayError} = useTodayDealLimit();
  const {data: topsellingLimit, isLoading: topLoading, isError: topError} = useTopSellingLimit();
  const {data: recommendedLimit, isLoading: recommendedLoading, isError:recommendedError} = useRecommendedLimit();
  const { data, isLoading: productCategoryLoading, isError: productCategoryError } = useFetchProductCategory(); 

  return (
    <Fragment>
    <MarketNavbar/>
<div className="market-mother-cards">
<div className="market-con-1">
          {productCategoryLoading ? (
            <div className="market-con-1">
              <Skeleton count={14} height={10} width={200}/>
            </div>
          ) : productCategoryError ? ( 
            <div>Error loading categories</div>
          ) : data?.categories?.length > 0 ? (  
            data.categories.map((category) => (
              <Link to={`/${category.categoryName.toLowerCase().replace(/ & /g, "").replace(/\s/g, "")}`} key={category._id}>
                {iconsMap[category.categoryName] || <CiHome />} 
                {category.categoryName}
              </Link>
            ))
          ) : (
            <p style={{fontFamily: 'Montserrat'}}>No categories available</p>
          )}
        </div>
  <div className="market-con-2">
    <SimpleSlider/>
  </div>
  <div className="market-con-3">
    <div className="card-1">
    <h3>Where selling meets simplicity</h3>
    <p>
      Connect with customers, showcase your products, and turn your
      passion into profit seamlessly.
    </p>
    <Link to='/MerchantForm'>Become a merchant</Link>
    </div>
    <div className="card-2">
      <div className="overlay">
      <Link>Shop Now</Link>
      </div>
    </div>
    <div className="card-3">
    <div className="overlay">
      <Link>Shop Now</Link>
      </div>
    </div>
    <div className="card-4">
    <div className="overlay">
      <Link>Shop Now</Link>
      </div>
    </div>
  </div>
</div>
<div className="special-product-con">
  <span>
    <h3>TRENDING</h3>
    <Link to='/alltrendingproducts'>VIEW ALL</Link>
  </span>
  {isLoading ? (
    <div className="special-product-con">
    <Skeleton count={5} height={100} width={250} />
    <Skeleton count={5} height={10} width={200}  />
    <Skeleton count={5} height={10} width={180}  />
    <Skeleton count={5} height={10} width={150}  />
    </div>
  ) : isError ? (
    <div className="errors-msg">Error fetching trending deals</div>
  ) : Array.isArray(trendingLimit.trendingProducts) && trendingLimit.trendingProducts.length > 0 ? (
    <div className="special-product-card">
      {trendingLimit.trendingProducts.slice(0, 5).map((deal) => (
        <Link to={`/product/${deal._id}`} key={deal._id} className="special-product-info">
          <img src={deal.images[0]} alt="" />
          <p>{deal.name}</p>
          <h3>{deal.price
            ? (deal.price).toLocaleString("en-US", {
                style: "currency",
                currency: "NGN",
              })
            : ""}</h3>
        </Link>
      ))}
    </div>
  ) : (
    <div className="errors-msg">No trending deals available</div>
  )}
</div>
<div className="special-product-con">
  <span>
  <h3>TODAY DEALS</h3>
  <Link to='/alltodaydealproduct'>VIEW ALL</Link>
  </span>
  {todayLoading ? (
     <div className="special-product-con">
    <Skeleton count={5} height={100} width={250} />
    <Skeleton count={5} height={10} width={200}  />
    <Skeleton count={5} height={10} width={180}  />
    <Skeleton count={5} height={10} width={150}  />
     </div>
  ) : todayError ? (
    <div  className="errors-msg">Error fetching today deals</div>
  ) : Array.isArray(todayDealLimit.todaysDealProducts) && todayDealLimit.todaysDealProducts.length > 0 ? (
    <div className="special-product-card">
      {todayDealLimit.todaysDealProducts.slice(0, 5).map((deal) => (
        <Link to={`/product/${deal._id}`} key={deal._id} className="special-product-info">
          <img src={deal.images[0]} alt="" />
          <p>{deal.name}</p>
          <h3>{deal.price
            ? (deal.price).toLocaleString("en-US", {
                style: "currency",
                currency: "NGN",
              })
            : ""}</h3>
        </Link>
      ))}
    </div>
  ) : (
    <div  className="errors-msg">No today deals available</div>
  )}
</div>
<div className="special-product-con">
  <span>
  <h3>TOP SELLING ITEMS</h3>
  <Link to='/alltopsellingproduct'>VIEW ALL</Link>
  </span>
  {topLoading ? (
    <div className="special-product-con">
    <Skeleton count={5} height={100} width={250} />
    <Skeleton count={5} height={10} width={200}  />
    <Skeleton count={5} height={10} width={180}  />
    <Skeleton count={5} height={10} width={150}  />
    </div>
  ) : topError ? (
    <div className="errors-msg">Error fetching recommended product</div>
  ) : Array.isArray(topsellingLimit.hotDealProducts) && topsellingLimit.hotDealProducts.length > 0 ? (
    <div className="special-product-card">
      {topsellingLimit.hotDealProducts.slice(0, 5).map((deal) => (
        <Link to={`/product/${deal._id}`} key={deal._id} className="special-product-info">
          <img src={deal.images[0]} alt="" />
          <p>{deal.name}</p>
          <h3>{deal.price
            ? (deal.price).toLocaleString("en-US", {
                style: "currency",
                currency: "NGN",
              })
            : ""}</h3>
        </Link>
      ))}
    </div>
  ) : (
    <div className="errors-msg">No recommended product available</div>
  )}
</div>
<div className="special-product-con">
  <span>
  <h3>RECOMMENDED</h3>
  <Link to='/recommendedproduct'>VIEW ALL</Link>
  </span>
  {recommendedLoading ? (
    <div className="special-product-con">
    <Skeleton count={5} height={100} width={250} />
    <Skeleton count={5} height={10} width={200}  />
    <Skeleton count={5} height={10} width={180}  />
    <Skeleton count={5} height={10} width={150}  />
    </div>
  ) : recommendedError ? (
    <div className="errors-msg">Error fetching recommended product</div>
  ) : Array.isArray(recommendedLimit.products) && recommendedLimit.products.length > 0 ? (
    <div className="special-product-card">
      {recommendedLimit.products.slice(0, 5).map((deal) => (
        <Link to={`/product/${deal._id}`} key={deal._id} className="special-product-info">
          <img src={deal.images[0]} alt="" />
          <p>{deal.name}</p>
          <h3>{deal.price
            ? (deal.price).toLocaleString("en-US", {
                style: "currency",
                currency: "NGN",
              })
            : ""}</h3>
        </Link>
      ))}
    </div>
  ) : (
    <div className="errors-msg">No recommended product available</div>
  )}
</div>
<Outlet/>
    </Fragment>
  );
};
export default Market;





 
