import { ReactComponent as FrameIcon } from "../../asset/Frame 1001.svg";
import { useState } from "react";
import ItemsData from "../libs/items";
import useFormDataSender from "../libs/sender";
import useFormDataReceiver from "../libs/receiver";
import useShippingCost from "../libs/useShippingCost";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "../../utils/spinner";

function Logisticsitems() {
  const navigate = useNavigate();
  const [summaryformData, handlesummaryChange] = ItemsData("summaryData");
  const [receiverFormData] = useFormDataReceiver("recieverFormData");
  const [formData] = useFormDataSender("shipFormData");
  const [items_value, setItems_value] = useState(summaryformData.items_value || "");

  const { mutation } = useShippingCost();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const numericItemsValue = parseFloat(items_value.replace(/[^0-9.-]+/g, ""));

    try {
      const data = await mutation.mutateAsync({
        sender_state: formData.state,
        receiver_state: receiverFormData.state,
        weight: summaryformData.weight,
        quantity: summaryformData.quantity,
        items_value: numericItemsValue, 
      });

      if (data) {
        localStorage.setItem("shippingSummaryData", JSON.stringify(data));
        navigate("/Logisticssummary");
      }
    } catch (error) {
      console.error(error);
      toast.error("Sorry we are not shipping to these state combination yet, Please try again later.");

      setTimeout(() => {
        navigate('/Logiscticsender');
      }, 2000);
    }
  };

  const handleItems_valueChange = (event) => {
    let inputValue = event.target.value.replace(/[^\d]/g, ""); 
    inputValue = Number(inputValue) / 100; 

    
    const formattedValue = inputValue.toLocaleString("en-US", {
      style: "currency",
      currency: "NGN",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    setItems_value(formattedValue);


    handlesummaryChange({
      target: { name: "items_value", value: inputValue.toString() },
    });
  };

  return (
    <div className="logistics-sender">
      <div className="header">
        <p>Create Package</p>
      </div>
      <div className="ship-form">
        <FrameIcon />
        <form onSubmit={handleSubmit}>
          <p>What are you Shipping?</p>
          <div className="inner">
            <label>
              Category
              <select
                name="category"
                value={summaryformData.category}
                onChange={handlesummaryChange}
                required
              >
                <option value="">Select</option>
                <option value="Electronics">Electronics</option>
                <option value="Food Stuff">Food Stuff</option>
                <option value="Appliance">Appliance</option>
              </select>
            </label>

            <label>
              Select Item
              <select
                name="item"
                value={summaryformData.item}
                onChange={handlesummaryChange}
                required
              >
                <option value="">Select</option>
                <option value="Freezer">Freezer</option>
                <option value="Rice">Rice</option>
                <option value="Iron">Iron</option>
                <option value="Fan">Fan</option>
                <option value="Chair">Chair</option>
                <option value="Table">Table</option>
              </select>
            </label>

            <label>
              Weight range (kg)
              <input
                type="tel"
                name="weight"
                value={summaryformData.weight}
                onChange={handlesummaryChange}
                required
              />
            </label>

            <label>
              Quantity
              <input
                type="text"
                name="quantity"
                value={summaryformData.quantity}
                onChange={handlesummaryChange}
                required
              />
            </label>

            <label>
              Value (Naira)
              <input
                type="tel"
                name="items_value"
                placeholder="Enter value"
                value={items_value || ""}
                onChange={handleItems_valueChange}
                required
              />
            </label>

            <label>
              Estimated delivery date
              <input
                type="date"
                name="estimated_delivery_date"
                value={summaryformData.estimated_delivery_date || ""}
                onChange={handlesummaryChange}
                required
              />
            </label>

            <label>
              Delivery Method
              <select
                name="delivery_method"
                value={summaryformData.delivery_method || ""}
                required
                onChange={handlesummaryChange}
              >
                <option value="">Select Delivery Method</option>
                <option value="Home">Home</option>
                <option value="Office">Office</option>
              </select>
            </label>

            <label>
              Payment Method
              <select
                name="payment_method"
                value={summaryformData.payment_method || ""}
                required
                onChange={handlesummaryChange}
              >
                <option value="">Select Payment Method</option>
                <option value="Cash">Cash</option>
                <option value="POS">POS</option>
              </select>
            </label>
          </div>
          <div className="btn">
            <button onClick={() => navigate("/Logisticsreceiever")}>
              Back
            </button>
            <button type="submit">
              {mutation.isLoading ? <Spinner /> : "Proceed"}
            </button>
          </div>
        </form>
        <ToastContainer style={{ width: "80%" }} position="top-right"  />
      </div>
    </div>
  );
}

export default Logisticsitems;


