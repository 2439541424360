import { useMutation, useQueryClient } from 'react-query';
import { AuthContext } from '../../Context/AuthContext';
import { useContext } from 'react';

const registerLogisticsOfficer = async (logisticsOfficerData, token) => {
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/users/register/logisticmanager`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(logisticsOfficerData),
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message); 
  }

  return data; 
};

const useRegisterLogisticsOfficer = () => {
  const queryClient = useQueryClient();
  const { token } = useContext(AuthContext);

  return useMutation((logisticsOfficerData) => registerLogisticsOfficer(logisticsOfficerData, token), {
    onSuccess: () => {
      queryClient.invalidateQueries('logisticsOfficers');
    },
  });
};

export default useRegisterLogisticsOfficer;

