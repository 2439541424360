import { useQuery } from 'react-query';
import axios from 'axios';
import { AuthContext } from "../../Context/AuthContext";
import { useContext } from 'react';

const usePackagesByBranch = () => {
  const { token, user } = useContext(AuthContext);

  const fetchPackagesByBranch = async () => {
    const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/packages/getbybranch/${user.branch_full_address}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data.packages;
  };

  return useQuery(['packagesByBranchWith', user.branch_full_address], fetchPackagesByBranch);
};

export default usePackagesByBranch;
