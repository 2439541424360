
import { useQuery } from 'react-query';
import { useContext } from 'react';
import { AuthContext } from '../Context/AuthContext';

const useTodayMessagesCount = () => {
  const { token } = useContext(AuthContext);

  const fetchTodayMessagesCount = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/publicmsgs/total-messages-currentday`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }

      const data = await response.json();
      return data.totalMessageCountLast24Hours;
    } catch (error) {
      throw new Error('Failed to fetch data');
    }
  };

  const { data: todayMessageCount, isLoading, isError } = useQuery('todayMessageCount', fetchTodayMessagesCount);

  return { todayMessageCount, loading: isLoading, error: isError };
};

export default useTodayMessagesCount;




