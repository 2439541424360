
import { Link, useNavigate } from "react-router-dom";
import { Fragment } from "react";
import { AuthContext } from "../../../Context/AuthContext";
import { useContext } from "react";
import { FaRegUser } from "react-icons/fa6";
import { CiShop } from "react-icons/ci";
import { SlLike } from "react-icons/sl";
import { GoMail } from "react-icons/go";
import { SlSettings } from "react-icons/sl";
import "./UserProfile.css"



const UserDropdown = () => {
    const{isLoggedIn, Logout} = useContext(AuthContext)
    const navigate = useNavigate()

const handleLogOut = () => {
    Logout();
    navigate('/Market')
}

    return (
         <Fragment>
               {!isLoggedIn && 
   <div className="user-dropdown-card">
    <Link to='/Login'><h3>Login/Signup</h3></Link>
    </div>
    }
               {isLoggedIn && 
   <div className="user-dropdown-card">
    <span>
    <Link to='/UserProfile'><FaRegUser style={{fontSize:'20px'}} />My Profile</Link>
    <Link to='/customerorder'><CiShop style={{fontSize:'20px'}} />Orders</Link>
    <Link to='/customersaveditem'><SlLike style={{fontSize:'20px'}} />Saved items</Link>
    <Link to='/customermsginbox'><GoMail style={{fontSize:'20px'}}  />Inbox</Link>
    <Link to='/accountmanagement'><SlSettings style={{fontSize:'20px'}}  />Account Management</Link>
    </span>
    <button onClick={handleLogOut}>Logout</button>
    </div>
    }
</Fragment>
    );
}

export default UserDropdown;
