import React from 'react';
import { Link } from 'react-router-dom';
import {ReactComponent as TwitterIcon} from "../Assets/codicon_twitter.svg"
import {ReactComponent as FacebookIcon} from "../Assets/simple-icons_facebook.svg"
import {ReactComponent as InstagramIcon} from "../Assets/grommet-icons_instagram.svg"
import {ReactComponent as LinkedinIcon} from "../Assets/mingcute_linkedin-fill.svg"
import {ReactComponent as LogoIcon} from "../Assets/Frame 1.svg"
import "./Footer.css"

const Footer = () => {
    return (
        <div className='footer-container'>
            <div className="wrapper">
            <div className="footer-box">
                <Link to='/'><LogoIcon/></Link>
            </div>
            <div className="footer-box">
                <footer>&copy;2024 STEX. All rights reserved.</footer>
            </div>
            <div className="footer-box">
                <div className="quick-link">
                    <Link to='/About'>About</Link>
                    <div className="quick-line"></div>
                    <Link to='Blog'>Blog</Link>
                    <div className="quick-line"></div>
                    <Link to='/Privacy'>Privacy & Terms</Link>
                    <div className="quick-line"></div>
                    <Link to='/Contact'>Contact</Link>
                </div>
                <div className="footer-span">
                <p>Follow us</p>
                 <span>
                    <a href="/"><TwitterIcon/></a>
                    <a href="/"><FacebookIcon/></a>
                    <a href="/"><InstagramIcon/></a>
                    <a href="/"><LinkedinIcon/></a>
                 </span>
                </div>
            </div>
            <div className="footer-mobile">
            <footer>&copy;2024 STEX. All rights reserved.</footer>
            </div>
            </div>
        </div>
    );
}

export default Footer;
