
import { useQuery } from 'react-query';

const useSiennaCollection = (page) => {
  const fetchSiennas = async () => {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/charterer/vehicle/sienna?page=${page}`, {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    if (!response.ok) {
      throw new Error('Failed to fetch siennas');
    }

    return response.json();
  };

  const { data: siennaData = {}, isLoading, isError } = useQuery(['charteredSienna', page], fetchSiennas);

  return { siennaData, isLoading, isError }; 
};

export default useSiennaCollection; 
