import { useMutation } from 'react-query';
import { useContext } from 'react';
import { AuthContext } from '../../Context/AuthContext';

const useRegisterChartererManager = () => {
  const { token } = useContext(AuthContext);

  const registerChartererManager = async (formData) => {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/users/register/charterer`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(formData),
    });

    if (!response.ok) {
      throw new Error('Charterer manager registration failed');
    }

    return response.json();
  };

  return useMutation(registerChartererManager);
};

export default useRegisterChartererManager;
