import { useInfiniteQuery } from 'react-query';
import { useContext } from 'react';
import { AuthContext } from "../../Context/AuthContext";

const fetchAllPackage = async ({ pageParam = 1 }, token) => {
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/packages/getallpackages?page=${pageParam}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error('No shipment found');
  }

  return response.json();
};

const useAllPackage = () => {
  const { token } = useContext(AuthContext);
  return useInfiniteQuery(
    ['allPackage'], 
    ({ pageParam }) => fetchAllPackage({ pageParam }, token),
    {
      getNextPageParam: (lastPage) => {
        const nextPage = lastPage.currentPage + 1;
        return nextPage <= Math.ceil(lastPage.totalPackages / 10) ? nextPage : undefined;
      },
    }
  );
};

export default useAllPackage;

