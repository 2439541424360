import React, { useState } from 'react';
import useOnRepairStatus from '../../libs/useOnRepairStatus';
import useOnRepairTruck from '../../libs/fetchOnRepairTruck';
import { CiSearch } from "react-icons/ci";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import Spinner from "../../../../Spinner/Spinner";
import { toast } from 'react-toastify';
import "./FreightCharter.css";

const Onrepairtruck = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');
    const [resetOverlay, setResetOverlay] = useState(false);
    const [selectedTruck, setSelectedTruck] = useState(null);
    const { data, isLoading, isError } = useOnRepairTruck(currentPage);
    const resetOnRepairStatus = useOnRepairStatus();
  
    const itemsPerPage = 8;

    const totalItems = data?.totalTrucks || 0;
  const startIndex = (currentPage - 1) * itemsPerPage + 1;
  const endIndex = Math.min(startIndex + itemsPerPage - 1, totalItems);

  const handleNextPage = () => {
    if (currentPage < (data?.totalPages || 1)) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };
  
  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
        setCurrentPage(1);
    };

    const trucks = data?.trucks || [];

    const filteredData = trucks.filter(truck =>
        truck._id.includes(searchQuery)
    );

  

    const handleCloseResetOverlay = () => {
        setSelectedTruck(null);
        setResetOverlay(false);
    };

    const handleStatus = () => {
        if (selectedTruck) {
          resetOnRepairStatus.mutate(
            { id: selectedTruck },
            {
              onSuccess: () => {
                toast.success('Truck statuses reset successfully.');
                handleCloseResetOverlay();
              },
              onError: (error) => {
                toast.error(`Error resetting status: ${error.message}`);
              },
            }
          );
        }
    };

    return (
       <>
        {resetOverlay && (
          <div className="reset-status-overlay">
            <div className="card">
              <p>Are you sure you want to reset the status?</p>
              <div className="btn">
                <button onClick={handleCloseResetOverlay}>No</button>
                <button onClick={handleStatus}>
                  {resetOnRepairStatus.isLoading ? <Spinner /> : "Yes"}
                </button>
              </div>
            </div>
          </div>
        )}

        <div className="charters">
          <div className="wrapper">
            <h3>Freight_Truck On-Repair</h3>
            <form onSubmit={(e) => e.preventDefault()}>
              <button type="submit">
                <CiSearch />
              </button>
              <input
                type="text"
                placeholder="Search New Order by Id"
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </form>
          </div>
          <div className="charter-mothers">
            <table>
              <thead>
                <tr>
                  <th>Image</th>
                  <th>Truck ID</th>
                  <th>Truck Model</th>
                  <th>Truck Type</th>
                  <th>Length</th>
                  <th>Price</th>
                  <th>Status</th>
                  <th>Reset Status</th>
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  Array.from({ length: 10 }).map((_, index) => (
                    <tr key={index}>
                      <td><Skeleton /></td>
                      <td><Skeleton /></td>
                      <td><Skeleton /></td>
                      <td><Skeleton /></td>
                      <td><Skeleton /></td>
                      <td><Skeleton /></td>
                      <td><Skeleton /></td>
                      <td><Skeleton /></td>
                    </tr>
                  ))
                ) : isError ? (
                  <tr>
                    <td colSpan="8" style={{ textAlign: 'center', fontFamily: 'Montserrat' }}>
                      No Truck on-repair available now
                    </td>
                  </tr>
                ) : (
                  filteredData.map(truck => (
                    <tr key={truck._id}>
                         <td>
                        {truck.images && truck.images.length > 0 && (
                          <img
                            src={truck.images[0]}
                            alt="Truck"
                            style={{ width: '50px', height: '50px',objectFit:'cover' }}
                          />
                        )}
                      </td>
                      <td>{truck._id}</td>
                      <td>{truck.truckModel}</td>
                      <td>{truck.truckType}</td>
                      <td>{truck.truckLength}</td>
                      <td>
                        {truck.pricePerDay
                          ? truck.pricePerDay.toLocaleString("en-US", {
                              style: "currency",
                              currency: "NGN",
                            })
                          : ""}
                      </td>
                      <td>{truck.onRepairing ? "on-repair" : "Available"}</td>
                      <td><button onClick={() => {
                        setSelectedTruck(truck._id);
                        setResetOverlay(true);
                      }}>Reset</button></td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
          <div className="pagination">
            <span>
              {`Showing ${startIndex } - ${endIndex} of ${totalItems}`}
            </span>
            <div className="btn">
              <button onClick={handlePrevPage} disabled={currentPage === 1}>
                Prev
              </button>
              <button
                onClick={handleNextPage}
                disabled={currentPage === (data?.totalPages || 1)}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </>
    );
};

export default Onrepairtruck;
