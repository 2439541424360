import { useInfiniteQuery } from 'react-query';
import { useContext } from 'react';
import { AuthContext } from "../../Context/AuthContext";

const fetchDispatchDetailsByCreatorId = async ({ pageParam = 1 }, token, user) => {
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/packages/by-creator/${user.userId}?page=${pageParam}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error('Failed to fetch dispatch details');
  }

  return response.json();
};

const useDispatchDetailsByCreatorId = () => {
  const { token, user } = useContext(AuthContext);

  return useInfiniteQuery(
    ['dispatchDetailsByCreatorId', user.userId],
    ({ pageParam = 1 }) => fetchDispatchDetailsByCreatorId({ pageParam }, token, user),
    {
      getNextPageParam: (lastPage) => {
        const nextPage = lastPage.currentPage + 1;
        return nextPage <= Math.ceil(lastPage.totalDispatches / 10) ? nextPage : undefined;
      },
    }
  );
};

export default useDispatchDetailsByCreatorId;
