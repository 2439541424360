import { Fragment } from "react";
import useFetchDispatchDetails from "../libs/useFetchDispatchDetails";
import { IoMdArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import Spinner from "../../utils/spinner";
import "./super_admin_dispachdetails.css";

const formatDate = (dateString) => {
  const options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  return new Intl.DateTimeFormat("en-US", options).format(new Date(dateString));
};

function SuperAdminDispatchDetails() {
  const {
    data: dispatchDetails,
    isLoading,
    isError,
  } = useFetchDispatchDetails();
  const navigate = useNavigate();
  console.log(dispatchDetails);

  if (isError) {
    return <p>Error loading dispatch details</p>;
  }

  return (
    <Fragment>
      <div className="super-admin-dispatch-details-container">
        {isLoading && <Spinner />}
        <div className="header">
          <IoMdArrowBack
            onClick={() => navigate("/Superadmindispatches")}
            className="icon"
          />
          <p>Dispatch Details</p>
        </div>
        <div className="dispatch-card">
          {!isLoading && !isError && (
            <>
              <div className="header">
                <p>
                  ID {dispatchDetails.dispatch?._id.toUpperCase().slice(0, 8)}
                </p>
                <p>
                  {formatDate(
                    dispatchDetails.dispatch?.packages[0]?.dateCreated
                  )}
                </p>
              </div>
              <div className="sender">
                <p>SENDER’S INFO</p>
              </div>
              <div className="sender-card">
                <div className="info">
                  <p>Full name</p>
                  <span>
                    {dispatchDetails.dispatch?.packages[0]?.sender?.name}
                  </span>
                </div>
                <div className="info">
                  <p>Email</p>
                  <span>
                    {dispatchDetails.dispatch?.packages[0]?.sender?.email}
                  </span>
                </div>
                <div className="info">
                  <p>Phone</p>
                  <span>
                    {
                      dispatchDetails.dispatch?.packages[0]?.sender
                        ?.phone_number
                    }
                  </span>
                </div>
                <div className="info">
                  <p>Home Address</p>
                  <span>
                    {dispatchDetails.dispatch?.packages[0]?.sender?.address_1}
                  </span>
                </div>
                <div className="info">
                  <p>Other Address</p>
                  <span>
                    {dispatchDetails.dispatch?.packages[0]?.sender?.address_2}
                  </span>
                </div>
                <div className="info">
                  <p>State</p>
                  <span>
                    {dispatchDetails.dispatch?.packages[0]?.sender?.state}
                  </span>
                </div>
                <div className="info">
                  <p>Zip Code</p>
                  <span>
                    {dispatchDetails.dispatch?.packages[0]?.sender?.zip_code}
                  </span>
                </div>
              </div>
              <div className="sender">
                <p>RECEIVER’S INFO</p>
              </div>
              <div className="sender-card">
                <div className="info">
                  <p>Full name</p>
                  <span>
                    {dispatchDetails.dispatch?.packages[0]?.receiver?.name}
                  </span>
                </div>
                <div className="info">
                  <p>Email</p>
                  <span>
                    {dispatchDetails.dispatch?.packages[0]?.receiver?.email}
                  </span>
                </div>
                <div className="info">
                  <p>Phone</p>
                  <span>
                    {
                      dispatchDetails.dispatch?.packages[0]?.receiver
                        ?.phone_number
                    }
                  </span>
                </div>
                <div className="info">
                  <p>Home Address</p>
                  <span>
                    {dispatchDetails.dispatch?.packages[0]?.receiver?.address_1}
                  </span>
                </div>
                <div className="info">
                  <p>Other Address</p>
                  <span>
                    {dispatchDetails.dispatch?.packages[0]?.receiver?.address_2}
                  </span>
                </div>
                <div className="info">
                  <p>State</p>
                  <span>
                    {dispatchDetails.dispatch?.packages[0]?.receiver?.state}
                  </span>
                </div>
                <div className="info">
                  <p>Zip Code</p>
                  <span>
                    {dispatchDetails.dispatch?.packages[0]?.receiver?.zip_code}
                  </span>
                </div>
              </div>
              <div className="sender">
                <p>ITEM(S) INFO</p>
              </div>
              <div className="sender-card">
                <div className="info">
                  <p>Item</p>
                  <span>{dispatchDetails.dispatch?.packages[0]?.name}</span>
                </div>
                <div className="info">
                  <p>Weight</p>
                  <span>{dispatchDetails.dispatch?.packages[0]?.weight}</span>
                </div>
                <div className="info">
                  <p>Quantity</p>
                  <span>{dispatchDetails.dispatch?.packages[0]?.quantity}</span>
                </div>
                <div className="info">
                  <p>Est. Delivery date</p>
                  <span>
                    {formatDate(
                      dispatchDetails.dispatch?.packages[0]
                        ?.estimated_delivery_date
                    )}
                  </span>
                </div>
                <div className="info">
                  <p>Valued at</p>
                  <span>{dispatchDetails.dispatch?.packages[0]?.value}</span>
                </div>
                <div className="info">
                  <p>Shipping Fee</p>
                  <span>
                    {dispatchDetails.dispatch?.packages[0]?.shipping_cost
                      ? (
                          dispatchDetails.dispatch?.packages[0]?.shipping_cost /
                          100
                        ).toLocaleString("en-US", {
                          style: "currency",
                          currency: "NGN",
                        })
                      : ""}
                  </span>
                </div>
                <div className="info">
                  <p>Pyament Method</p>
                  <span>
                    {dispatchDetails.dispatch?.packages[0]?.payment_method}
                  </span>
                </div>
                <div className="info">
                  <p>Tracking Number</p>
                  <span>
                    {dispatchDetails.dispatch?.packages[0]?.tracking_number}
                  </span>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </Fragment>
  );
}

export default SuperAdminDispatchDetails;
