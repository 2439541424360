import { useContext } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { AuthContext } from "../../../../Context/AuthContext";

const useBookRequest = (page) => {
  const { token } = useContext(AuthContext);
  const queryClient = useQueryClient();

  const fetchBookRequest = async () => {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/charterer/booked?page=${page}&perPage=10`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });
    if (!response.ok) {
      throw new Error('Failed to fetch Booked Vehicle');
    }
    return response.json();
  };

  const { data: booked = [], isLoading, isError } = useQuery(
    ['BookedRequest', page],
    fetchBookRequest,
    {
      onSuccess: () => {
        queryClient.invalidateQueries('adminVehicle');
      },
    }
  );

  return { booked, isLoading, isError };
};

export default useBookRequest;
