import React, { useState } from 'react';
import { CiSearch } from "react-icons/ci";
import useOnTripTruck from '../../libs/fetchOnTripTruck';
import useResetTruckStatus from '../../libs/resetOnTripTruck';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import Spinner from "../../../../Spinner/Spinner";
import { toast } from 'react-toastify';
import "./FreightCharter.css";

const Startedfreight = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [resetOverlay, setResetOverlay] = useState(false);
  const [selectedTrip, setSelectedTrip] = useState(null);
  const itemsPerPage = 8;

  const { data, isLoading, isError } = useOnTripTruck(currentPage, itemsPerPage);
  const resetTruckStatus = useResetTruckStatus();

  const handleNextPage = () => {
    if (currentPage < data?.totalPages) {
      setCurrentPage(prevPage => prevPage + 1);
    }
  };
  
  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(prevPage => prevPage - 1);
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };

  const trucksOnTrip = data?.trucksOnTrip || [];

  const filteredData = trucksOnTrip.filter(truck =>
    truck._id.includes(searchQuery)
  );

  const totalPages = data?.totalPages || 1;
  const totalTrucksOnTrip = data?.totalTrucksOnTrip || 0;
  const startIndex = (currentPage - 1) * itemsPerPage + 1;
  const endIndex = Math.min(startIndex + itemsPerPage - 1, totalTrucksOnTrip);

  const handleCloseResetOverlay = () => {
    setSelectedTrip(null);
    setResetOverlay(false);
  };

  const handleStatus = () => {
    if (selectedTrip) {
      resetTruckStatus.mutate(
        { id: selectedTrip },
        {
          onSuccess: () => {
            toast.success('Charter and trip statuses reset successfully.');
            handleCloseResetOverlay();
          },
          onError: (error) => {
            toast.error(`Error resetting status: ${error.message}`);
          },
        }
      );
    }
  };

  return (
    <>
      {resetOverlay && (
        <div className="reset-status-overlay">
          <div className="card">
            <p>Are you sure you want to reset the status?</p>
            <div className="btn">
              <button onClick={handleCloseResetOverlay}>No</button>
              <button onClick={handleStatus}>
                {resetTruckStatus.isLoading ? <Spinner /> : "Yes"}
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="charters">
        <div className="wrapper">
          <h3>Freight_Started</h3>
          <form onSubmit={(e) => e.preventDefault()}>
            <button type="submit">
              <CiSearch />
            </button>
            <input
              type="text"
              placeholder="Search New Order by Id"
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </form>
        </div>
        <div className="charter-mothers">
          <table>
            <thead>
              <tr>
                <th>Truck ID</th>
                <th>Full Name</th>
                <th>Phone</th>
                <th>Truck Type</th>
                <th>Total Amount</th>
                <th>Hire Date</th>
                <th>Return Date</th>
                <th>Status</th>
                <th>Reset Status</th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                Array.from({ length: itemsPerPage }).map((_, index) => (
                  <tr key={index}>
                    <td><Skeleton /></td>
                    <td><Skeleton /></td>
                    <td><Skeleton /></td>
                    <td><Skeleton /></td>
                    <td><Skeleton /></td>
                    <td><Skeleton /></td>
                    <td><Skeleton /></td>
                    <td><Skeleton /></td>
                    <td><Skeleton /></td>
                  </tr>
                ))
              ) : isError ? (
                <tr>
                  <td colSpan="9" style={{ textAlign: 'center', fontFamily: 'Montserrat' }}>
                    No Truck on-trip available now
                  </td>
                </tr>
              ) : (
                filteredData.map(truck => (
                  <tr key={truck._id}>
                    <td>{truck._id.slice(0, 5).toUpperCase()}</td>
                    <td>{truck.fullName}</td>
                    <td>{truck.phoneNumber}</td>
                    <td>{truck.truckId.truckType}</td>
                    <td>
                      {truck.price
                        ? truck.price.toLocaleString("en-US", {
                            style: "currency",
                            currency: "NGN",
                          })
                        : ""}
                    </td>
                    <td>{new Date(truck.hireDate).toLocaleDateString()}</td>
                    <td>{new Date(truck.returnDate).toLocaleDateString()}</td>
                    <td>{truck.truckId.onTrip ? "on-trip" : "No"}</td>
                    <td><button onClick={() => {
                      setSelectedTrip(truck.truckId._id);
                      setResetOverlay(true);
                    }}>Reset</button></td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
        <div className="pagination">
          <span>
            {`Showing ${startIndex} - ${endIndex} of ${totalTrucksOnTrip}`}
          </span>
          <div className="btn">
            <button onClick={handlePrevPage} disabled={currentPage === 1}>
              Prev
            </button>
            <button
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Startedfreight;
