import { useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import { AuthContext } from '../../Context/AuthContext';
import { useContext } from 'react';

const useUpdatePackageStatusToDelivered = () => {
  const { token } = useContext(AuthContext);
  const queryClient = useQueryClient();

  const mutation = useMutation(async (data) => {
    const [packageId, updatedData] = data;

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/updatepackages/updatePackageStatusToDelivered/${packageId}`,
        updatedData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return response.data;
    } catch (error) {
      throw error;
    }
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries('riderAssignment');
      queryClient.invalidateQueries('packagesByBranch');
    },
  });

  return {
    mutation,
    updatePackageStatusToDelivered: async (packageId, updatedData) => {
      try {
        const result = await mutation.mutateAsync([packageId, updatedData]);
        return result;
      } catch (error) {
        throw error;
      }
    },
  };
};

export default useUpdatePackageStatusToDelivered;

