import { useState, useEffect } from "react";
import axios from "axios";

const useSearchProducts = (letter) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    const fetchSearchResults = async () => {
      if (letter.trim() === "") {
        setSearchResults([]);
        return;
      }

      setIsLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/product/search?letter=${letter}`
        );
        setSearchResults(response.data);
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchSearchResults();

    // Cleanup function
    return () => {
      // Any cleanup code if needed
    };
  }, [letter]);

  return { isLoading, error, searchResults };
};

export default useSearchProducts;
