import { useQuery } from "react-query";
import { useContext } from "react";
import { AuthContext } from "../../Context/AuthContext";

const fetchCategories = async (token) => {
  const response = await fetch(
    `${process.env.REACT_APP_SERVER_URL}/productcategory/get/product/categories`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      credentials: "include",
    }
  );

  if (!response.ok) {
    throw new Error("Failed to fetch product category");
  }

  return response.json();
};

const useFetchCategory= () => {
  const { token } = useContext(AuthContext);

  return useQuery("productCatories", () => fetchCategories(token));
};

export default useFetchCategory;
