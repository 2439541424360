import React, { useState, useEffect } from 'react';
import { CiSearch } from "react-icons/ci";
import { FaXmark } from "react-icons/fa6";
import useCompletedTruck from '../../libs/fetchCompletedTruck';
import { ReactComponent as TruckIcon } from "../../../../Assets/iconoir_truck-length.svg";
import { ReactComponent as Tonnage } from "../../../../Assets/la_weight-hanging.svg";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import './FreightCharter.css';

const Completedfreight = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [viewDetail, setViewDetail] = useState(false);
  const [selectedTruck, setSelectedTruck] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const { data, isLoading, isError } = useCompletedTruck(currentPage);
  const itemsPerPage = 8;

  useEffect(() => {
    if (data && data.results) {
      if (searchQuery) {
        const filteredCompletedHires = data.results.filter(hire =>
          hire._id.toLowerCase().includes(searchQuery.toLowerCase())
        );
        setFilteredData(filteredCompletedHires);
      } else {
        setFilteredData(data.results);
      }
    } else {
      setFilteredData([]);
    }
  }, [searchQuery, data]);

  const totalItems = data?.totalResults || 0;
  const startIndex = (currentPage - 1) * itemsPerPage + 1;
  const endIndex = Math.min(startIndex + itemsPerPage - 1, totalItems);

  const handleNextPage = () => {
    if (currentPage < (data?.totalPages || 1)) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };
  
  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };

  const handleViewDetail = (hire) => {
    setSelectedTruck(hire);
    setViewDetail(true);
  };

  return (
    <>
      {viewDetail && selectedTruck && (
        <div className="freight-hire-detail">
          <div className="wrapper">
            <span>
              <h3>{selectedTruck._id}</h3>
              <button onClick={() => setViewDetail(false)}>
                <FaXmark style={{ fontSize: "20px" }} />
              </button>
            </span>
            <div className="freight-hire-detail-span">
              <div className="card-1">
                <div className="wrapper">
                  <img src={selectedTruck?.truckInfo?.images ? selectedTruck?.truckInfo?.images[0] : ""} alt="" />
                  <div className="inner">
                    <h3>{selectedTruck?.truckInfo?.truckType}</h3>
                    <h4>
                      {selectedTruck?.truckInfo?.pricePerDay
                        ? selectedTruck?.truckInfo?.pricePerDay.toLocaleString("en-US", {
                            style: "currency",
                            currency: "NGN",
                          })
                        : ""}
                    </h4>
                    <span>
                      <TruckIcon />
                      <p>Truck Length:</p>
                      {selectedTruck.truckInfo?.truckLength}
                    </span>
                    <span>
                      <Tonnage />
                      <p>Tonnage:</p>
                      {selectedTruck.truckInfo?.tonnage}
                    </span>
                  </div>
                </div>
                <div className="freight-hire-description">
                  <h3>DESCRIPTION</h3>
                  <p>{selectedTruck?.truckInfo?.description}</p>
                </div>
              </div>
              <div className="card-2">
                <div className="wrapper">
                  <h3>Hirer Details</h3>
                  <span>
                    <p>Full Name:</p>
                    <p>{selectedTruck.hireInfo?.fullName}</p>
                  </span>
                  <span>
                    <p>Contact Name:</p>
                    <p>{selectedTruck.hireInfo?.contactName}</p>
                  </span>
                  <span>
                    <p>Email:</p>
                    <p>{selectedTruck.hireInfo?.email}</p>
                  </span>
                  <span>
                    <p>Phone Number:</p>
                    <p>{selectedTruck.hireInfo?.phoneNumber}</p>
                  </span>
                  <span>
                    <p>Address 1:</p>
                    <p>{selectedTruck.hireInfo?.address1}</p>
                  </span>
                  <span>
                    <p>Address 2:</p>
                    <p>{selectedTruck.hireInfo?.address2}</p>
                  </span>
                  <span>
                    <p>Zip-Code:</p>
                    <p>{selectedTruck.hireInfo?.zipcode}</p>
                  </span>
                  <span>
                    <p>Destination:</p>
                    <p>{selectedTruck.hireInfo?.destination}</p>
                  </span>
                  <span>
                    <p>Pick Up Location:</p>
                    <p>{selectedTruck.hireInfo?.pickUpLocation}</p>
                  </span>
                  <span>
                    <p>Hire Date:</p>
                    <h4>{new Date(selectedTruck.hireDate).toLocaleDateString()}</h4>
                  </span>
                  <span>
                    <p>Return Date:</p>
                    <h4>{new Date(selectedTruck.returnDate).toLocaleDateString()}</h4>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="charters">
        <div className="wrapper">
          <h3>Freight_Completed</h3>
          <form onSubmit={(e) => e.preventDefault()}>
            <button type="submit">
              <CiSearch />
            </button>
            <input
              type="text"
              placeholder="Search Completed Order by Id"
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </form>
        </div>
        <div className="charter-mothers">
          {isLoading ? (
            <table>
              <thead>
                <tr>
                  <th>Truck ID</th>
                  <th>Full Name</th>
                  <th>Truck Type</th>
                  <th>Total Amount</th>
                  <th>Hire Date</th>
                  <th>Return Date</th>
                  <th>Status</th>
                  <th>Details</th>
                </tr>
              </thead>
              <tbody>
                {Array.from({ length: 10 }).map((_, index) => (
                  <tr key={index}>
                    <td><Skeleton /></td>
                    <td><Skeleton /></td>
                    <td><Skeleton /></td>
                    <td><Skeleton /></td>
                    <td><Skeleton /></td>
                    <td><Skeleton /></td>
                    <td><Skeleton /></td>
                    <td><Skeleton /></td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : isError ? (
            <p style={{textAlign:'center', fontFamily:'Montserrat'}}>No Completed Freight Orders available</p>
          ) : (
            <table>
              <thead>
                <tr>
                  <th>Truck ID</th>
                  <th>Full Name</th>
                  <th>Truck Type</th>
                  <th>Total Amount</th>
                  <th>Hire Date</th>
                  <th>Return Date</th>
                  <th>Status</th>
                  <th>Details</th>
                </tr>
              </thead>
              <tbody>
                {filteredData.map((hire) => (
                  <tr key={hire._id}>
                    <td>{hire._id.slice(0, 5).toUpperCase()}</td>
                    <td>{hire.hireInfo.fullName}</td>
                    <td>{hire.truckInfo.truckType}</td>
                    <td>
                      {hire.price
                        ? hire.price.toLocaleString("en-US", {
                            style: "currency",
                            currency: "NGN",
                          })
                        : ""}
                    </td>
                    <td>{new Date(hire.hireDate).toLocaleDateString()}</td>
                    <td>{new Date(hire.returnDate).toLocaleDateString()}</td>
                    <td>Completed</td>
                    <td><button onClick={() => handleViewDetail(hire)}>View</button></td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
        <div className="pagination">
          <span>{`Showing ${startIndex} - ${endIndex} of ${totalItems}`}</span>
          <div className="btn">
            <button onClick={handlePrevPage} disabled={currentPage === 1}>
              Prev
            </button>
            <button
              onClick={handleNextPage}
              disabled={currentPage === (data?.totalPages || 1)}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Completedfreight;
