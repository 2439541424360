import { useQuery } from 'react-query';

const useFetchAllStates = () => {

  const fetchAllStates = async () => {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/states/getstates`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch all states');
    }

    return response.json();
  };

  return useQuery('allStates', fetchAllStates);
};

export default useFetchAllStates;