import React, { useState, useContext, Fragment } from "react";
import { AuthContext } from "../../Context/AuthContext";
import useCreateCategory from "../libs/categoryHooks";
import useUserCategories from "../libs/fetchCategoryHooks";
import { ReactComponent as CautionIcon } from "../../asset/caution-svgrepo-com 1.svg";
import useDeleteCategory from "../libs/deleteCategoryHooks";
import useUpdateCategory from "../libs/updateCategory";
import Spinner from "../../utils/spinner";
import "./Logisticcreatecategory.css";

function Logisticcreatecategory() {
  const { user } = useContext(AuthContext);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const creator = user.userId;
  const [openPopUp, setPopUp] = useState(false);
  const [msg, setMsg] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [deleteOverLay, setDeleteOverlay] = useState(false);
  const [updateOverlay, setUpdateOverlay] = useState(false);
  const [updateName, setUpdateName] = useState("");
  const [updateDescription, setUpdateDescription] = useState("");

  const createCategoryMutation = useCreateCategory();
  const deleteCategoryMutation = useDeleteCategory();
  const updateCategoryMutation = useUpdateCategory();
  const { data: userCategories, isLoading, isError } = useUserCategories();

  const handleUpdateButtonClick = (categoryId) => {
    const selectedCategory = userCategories.categories.find(
      (category) => category._id === categoryId
    );
    setSelectedId(categoryId);
    setUpdateName(selectedCategory?.name || "");
    setUpdateDescription(selectedCategory?.description || "");
    setUpdateOverlay(true);
  };

  const handleUpdateCategory = async (e) => {
    e.preventDefault();
    try {
      await updateCategoryMutation.mutateAsync({
        categoryId: selectedId,
        categoryData: { name: updateName, description: updateDescription },
      });
      setUpdateOverlay(false);
      setSelectedId(null);
    } catch (error) {
      console.error("Error updating category:", error);
    }
  };

  const handleDeleteCategory = async () => {
    try {
      await deleteCategoryMutation.mutateAsync(selectedId);
      setDeleteOverlay(false);
      setSelectedId(null);
    } catch (error) {
      console.error("Error deleting category:", error);
    }
  };

  const handleCreateCategory = async (e) => {
    e.preventDefault();

    try {
      await createCategoryMutation.mutateAsync({
        name,
        description,
        creator,
      });
      setName("");
      setDescription("");
      setMsg("Category created successfully");
      setTimeout(() => {
        setPopUp(false);
        setMsg(false);
      }, 3000);
    } catch (error) {
      console.error("Error creating category:", error);
    }
  };

  return (
    <Fragment>
      {updateOverlay && (
        <div className="logs-update-container">
          <form onSubmit={handleUpdateCategory} className="card">
            <p>Update Category</p>
            <input
              type="text"
              placeholder="Category Name"
              value={updateName}
              onChange={(e) => setUpdateName(e.target.value)}
              autoFocus 
            />
            <input
              type="text"
              placeholder="Items Name"
              value={updateDescription}
              onChange={(e) => setUpdateDescription(e.target.value)}
            />
            <div className="btn">
              <button
                type="button"
                onClick={() => {
                  setSelectedId(null);
                  setUpdateOverlay(false);
                }}
              >
                Cancel
              </button>
              <button type="submit">
                {updateCategoryMutation.isLoading ? "Updating" : "Update"}
              </button>
            </div>
          </form>
        </div>
      )}
      {deleteOverLay && (
        <div className="logs-deleted-container">
          <div className="card">
            <CautionIcon />
            <h3>Delete</h3>
            <p>Are you sure you want to delete this Category?</p>
            <div className="btn">
              <button onClick={() => setDeleteOverlay(false)}>No</button>
              <button onClick={handleDeleteCategory}>
                {deleteCategoryMutation.isLoading ? "Deleting" : "Yes"}
              </button>
            </div>
          </div>
        </div>
      )}
      {openPopUp && (
        <div className="logsitcs-create-category">
          <form onSubmit={handleCreateCategory}>
            <p>Add New Category</p>
            <label>
              Category Name
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </label>
            <label>
              Item Name
              <input
                type="text"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </label>

            <div className="btn">
              <button onClick={() => setPopUp(false)}>Cancel</button>
              <button type="submit" disabled={createCategoryMutation.isLoading}>
                {createCategoryMutation.isLoading ? "Saving" : "Save"}
              </button>
            </div>
            {msg && <span>{msg}</span>}
            {createCategoryMutation.error && (
              <p>{createCategoryMutation.error}</p>
            )}
          </form>
        </div>
      )}
      <div className="logistics-create-category">
        <div className="header">
          <p>Create Category</p>
          <button onClick={() => setPopUp(true)}>Create Category</button>
        </div>

        <div className="logistic-user-category">
          <p>Categories</p>
          {isLoading && <Spinner />}
          {isError && <p>{isError}</p>}
          {userCategories &&
            userCategories.categories.map((category) => (
              <div key={category._id} className="category-item">
                <div className="card">
                  <p>{category?.name}</p>
                  <p>{category?.description}</p>
                </div>
                <div className="btn">
                  <button
                    type="button"
                    onClick={() => handleUpdateButtonClick(category._id)}
                  >
                    Update
                  </button>
                  <button
                    onClick={() => {
                      setSelectedId(category._id);
                      setDeleteOverlay(true);
                    }}
                  >
                    Delete
                  </button>
                </div>
              </div>
            ))}
        </div>
      </div>
    </Fragment>
  );
}

export default Logisticcreatecategory;

