import { ReactComponent as SearchIcon } from "../../Assets/iconoir_search.svg";
import React, { useRef, useState, useCallback, Fragment } from "react";
import useSetHotDeal from "../libs/useSetHotDeal";
import useSetTrendingDeal from "../libs/useSetTrendingDeal";
import useSetTodaysDeal from "../libs/useSetTodaysDeal";
import useProduct from "../libs/useProduct";
import Spinner from "../../utils/spinner";
import "./markplaceproducts.css";

const MarketplaceProducts = () => {
  const { isLoading, error, data, fetchNextPage, hasNextPage, isFetchingNextPage } = useProduct();
  const { setHotDeal, isLoading: setHotDealLoading } = useSetHotDeal();
  const { setTrendingDeal, isLoading: setTrendingLoading } = useSetTrendingDeal();
  const { setTodaysDeal, isLoading: setTodayDealLoading } = useSetTodaysDeal();
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedPid, setSelectedPid] = useState(null);
  const [overlay, setOverlay] = useState(false);

  const filteredProducts = data
    ? data.pages.flatMap(page => page.products).filter(
        (product) =>
          product.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          product.category.toLowerCase().includes(searchQuery.toLowerCase()) ||
          product.brand.toLowerCase().includes(searchQuery.toLowerCase())
      )
    : [];

  const selectedProduct = selectedPid
    ? filteredProducts.find((product) => product._id === selectedPid)
    : null;

  const handleMarkAsHotDeal = async () => {
    try {
      await setHotDeal(selectedPid);
      setOverlay(false);
      setSelectedPid(null);
    } catch (error) {
      console.error("Error setting hot deal:", error);
    }
  };

  const handleMarkAsTrendingDeal = async () => {
    try {
      await setTrendingDeal(selectedPid);
      setOverlay(false);
      setSelectedPid(null);
    } catch (error) {
      console.error("Error setting trending deal:", error);
    }
  };

  const handleMarkAsTodaysDeal = async () => {
    try {
      await setTodaysDeal(selectedPid);
      setOverlay(false);
      setSelectedPid(null);
    } catch (error) {
      console.error("Error setting today's deal:", error);
    }
  };

  const observerRef = useRef();
  const lastProductElementRef = useCallback((node) => {
    if (isFetchingNextPage) return;

    if (observerRef.current) observerRef.current.disconnect();

    observerRef.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasNextPage) {
        fetchNextPage();
      }
    }, { threshold: 0.5 });

    if (node) observerRef.current.observe(node);
  }, [isFetchingNextPage, fetchNextPage, hasNextPage]);

  return (
    <Fragment>
      {overlay && (
        <div className="products_overlay">
          <div className="card">
            <div className="btn">
              {!selectedProduct || !selectedProduct.isHotDeal ? (
                <button
                  disabled={setHotDealLoading}
                  onClick={handleMarkAsHotDeal}
                >
                  {setHotDealLoading ? <Spinner /> : "Mark as Hot Deal"}
                </button>
              ) : null}
              {!selectedProduct || !selectedProduct.isTrending ? (
                <button
                  onClick={handleMarkAsTrendingDeal}
                  disabled={setTrendingLoading}
                >
                  {setTrendingLoading ? <Spinner /> : "Mark as Trending"}
                </button>
              ) : null}
              {!selectedProduct || !selectedProduct.isTodaysDeal ? (
                <button
                  onClick={handleMarkAsTodaysDeal}
                  disabled={setTodayDealLoading}
                >
                  {setTodayDealLoading ? <Spinner /> : "Mark as Today's Deal"}
                </button>
              ) : null}
            </div>
            <button
              onClick={() => {
                setSelectedPid(null);
                setOverlay(false);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
      <div className="market_place_products">
        <div className="header">
          <h1>All products</h1>
          <span>
            <SearchIcon />
            <input
              type="search"
              placeholder="Search products by name, brand, or category"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </span>
        </div>

        <div className="products_table">
          {isLoading && <Spinner />}
          {error && <p style={{ fontFamily: 'Montserrat', fontSize: '14px' }}>Error: {error.message}</p>}
          {filteredProducts.length === 0 && !isLoading && (
            <p style={{ fontFamily: 'Montserrat', fontSize: '14px' }}>No products found.</p>
          )}
          {filteredProducts.length > 0 && (
            <table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Category</th>
                  <th>Brand</th>
                  <th>Price</th>
                  <th>Image</th>
                  <th>Small</th>
                  <th>Medium</th>
                  <th>Large</th>
                  <th>Hot Deal</th>
                  <th>Today Deal</th>
                  <th>Trending</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {filteredProducts.map((product, index) => {
                  if (index === filteredProducts.length - 1) {
                    return (
                      <tr key={product._id} ref={lastProductElementRef}>
                        <td>{product.name}</td>
                        <td>{product.category}</td>
                        <td>{product.brand || "NA"}</td>
                        <td>{product.price}</td>
                        <td>
                          <img
                            src={product.images[0]}
                            alt={product.name}
                            style={{ width: "40px", height: "40px" }}
                          />
                        </td>
                        <td>{product.sizeSmall || "NA"}</td>
                        <td>{product.sizeMedium || "NA"}</td>
                        <td>{product.sizeLarge || "NA"}</td>
                        <td>{product.isHotDeal ? "Yes" : "No"}</td>
                        <td>{product.isTodaysDeal ? "Yes" : "No"}</td>
                        <td>{product.isTrending ? "Yes" : "No"}</td>
                        <td>
                          <button
                            onClick={() => {
                              setSelectedPid(product._id);
                              setOverlay(true);
                            }}
                          >
                            Mark as
                          </button>
                        </td>
                      </tr>
                    );
                  } else {
                    return (
                      <tr key={product._id}>
                        <td>{product.name}</td>
                        <td>{product.category}</td>
                        <td>{product.brand || "NA"}</td>
                        <td>{product.price}</td>
                        <td>
                          <img
                            src={product.images[0]}
                            alt={product.name}
                            style={{ width: "40px", height: "40px" }}
                          />
                        </td>
                        <td>{product.sizeSmall || "NA"}</td>
                        <td>{product.sizeMedium || "NA"}</td>
                        <td>{product.sizeLarge || "NA"}</td>
                        <td>{product.isHotDeal ? "Yes" : "No"}</td>
                        <td>{product.isTodaysDeal ? "Yes" : "No"}</td>
                        <td>{product.isTrending ? "Yes" : "No"}</td>
                        <td>
                          <button
                            onClick={() => {
                              setSelectedPid(product._id);
                              setOverlay(true);
                            }}
                          >
                            Mark as
                          </button>
                        </td>
                      </tr>
                    );
                  }
                })}
              </tbody>
            </table>
          )}
        </div>
        {isFetchingNextPage && (
          <div className="loading-more">
            <Spinner />
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default MarketplaceProducts;

