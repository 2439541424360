import { useQuery } from 'react-query';
import { useContext } from 'react';
import { AuthContext } from '../Context/AuthContext';

const useTotalMessagesCount = () => {
  const { token } = useContext(AuthContext);

  const fetchTotalMessagesCount = async () => {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/publicmsgs/total-messages-count`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch data');
    }

    const data = await response.json();
    return data.totalMessageCount;
  };

  const { data: totalMessageCount, isLoading, isError } = useQuery('totalMessagesCount', fetchTotalMessagesCount);

  return { totalMessageCount, loading: isLoading, error: isError };
};

export default useTotalMessagesCount;
