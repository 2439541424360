import React, { useContext } from "react";
import FetchAdminMessage from "../../libs/fetchAdminMessage";
import { GlobalContext } from "../../GlobalContext/GlobalContext";
import Spinner from "../../Spinner/Spinner";
import { ReactComponent as CautionIcon } from "../../Assets/caution-svgrepo-com 1.svg";
import useDeleteMessage from "../../libs/deleteMessage";
import "./AdminChat.css";

const AdminChat = () => {
  const { adminMessages, isLoading, isError } = FetchAdminMessage();
  const {
    deleteBroadcastMessage,
    setDeleteBroadcastMessage,
    messageId,
    setMessageId,
  } = useContext(GlobalContext);

  const { isDeleting, handleDeleteMessage } = useDeleteMessage();

  return (
    <div className="admin-chat-container">
      <div className="header">
        <p>Broadcast Message</p>
      </div>

      {isLoading && <Spinner />}
      {isError && <p>Error fetching admin messages</p>}

      {adminMessages && (
        <div className="admin-chat-card">
          {deleteBroadcastMessage && (
            <div className="delete-broadcast-message">
              <div className="wrapper">
                <CautionIcon />
                <h3>Delete</h3>
                <p>Are you sure you want to delete the selected message?</p>
                <span>
                  <button
                    onClick={() => {
                      setDeleteBroadcastMessage(false);
                      setMessageId(null);
                    }}
                  >
                    No
                  </button>
                  <button
                    onClick={async () => {
                      try {
                        await handleDeleteMessage(messageId);
                        setDeleteBroadcastMessage(false);
                        setMessageId(null);
                      } catch (error) {
                        console.error("Error deleting message:", error);
                      }
                    }}
                    disabled={isDeleting}
                  >
                    {isDeleting ? "Deleting..." : "Yes"}
                  </button>
                </span>
              </div>
            </div>
          )}
          <ul className="admin-chat-info">
            {adminMessages.map((message) => (
              <li key={message.id}>
                <span>
                  <p>
                    Sender: {message?.sender.first_name}{" "}
                    {message?.sender.last_name}
                  </p>
                  <p> {new Date(message?.timestamp).toLocaleString()}</p>
                </span>
                <p>{message?.content}</p>
                <div className="button-card">
                  <button
                    onClick={() => {
                      setDeleteBroadcastMessage(true);
                      setMessageId(message.id);
                    }}
                  >
                    Delete
                  </button>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default AdminChat;
