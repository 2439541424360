import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { AuthContext } from '../../Context/AuthContext';
import { useContext } from 'react';

const fetchDispatchDetails = async (dispatchId, token) => {
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/packages/dispatch/${dispatchId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    credentials: 'include',
  });

  if (!response.ok) {
    throw new Error('Failed to fetch dispatch details');
  }

  return response.json();
};

const useFetchDispatchDetails = () => {
  const { token } = useContext(AuthContext);
  const { id: dispatchId } = useParams();

  return useQuery(['dispatchDetails', dispatchId], () =>
    fetchDispatchDetails(dispatchId, token)
  );
};

export default useFetchDispatchDetails;
