import React, { useState } from "react";
import { Link } from "react-router-dom";
import { CiSearch } from "react-icons/ci";
import { FiPlusCircle } from "react-icons/fi";
import { CiUser } from "react-icons/ci";
import { BsThreeDotsVertical } from "react-icons/bs";
import { ReactComponent as SeatIcon } from "../../../../Assets/safety-seat-car-svgrepo-com 13x.svg";
import useCharteredVehicles from "../libs/fetchChartererVehicles";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import "./Vehicle.css";

const Vehicle = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 8;
  const { vehicles, isLoading, isError } = useCharteredVehicles(currentPage);
  const [searchQuery, setSearchQuery] = useState('');

  const filteredVehicles = vehicles?.vehicles?.filter(vehicle => {
    return (
      vehicle.vehicleType.toLowerCase().includes(searchQuery.toLowerCase()) ||
      vehicle.vehicleModel.toLowerCase().includes(searchQuery.toLowerCase())
    );
  });

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const totalPages = Math.ceil(vehicles.totalVehicles / itemsPerPage);
  const vehicleData = filteredVehicles || vehicles.vehicles;

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const startIndex = Math.min((currentPage - 1) * itemsPerPage + 1, vehicles.totalVehicles);
  const endIndex = Math.min(startIndex + itemsPerPage - 1, vehicles.totalVehicles);

  return (
    <div className="vehicles">
      <div className="wrapper">
        <h3>Vehicles</h3>
        <span>
          <Link to="/addvehicle">
            <FiPlusCircle />
            Add Vehicles
          </Link>
          <form>
            <button>
              <CiSearch />
            </button>
            <input type="text" placeholder="Search vehicles" value={searchQuery} onChange={handleSearchChange} />
          </form>
        </span>
      </div>
      <div className="vehicles-mother">
        {isLoading ? (
          Array(itemsPerPage).fill().map((_, index) => (
            <div className="car-card" key={index}>
              <Skeleton height={150} width={250} count={1}/>
              <div className="wrapper">
                <div className="car-span">
                  <Skeleton width={100} />
                  <Skeleton width={100} />
                </div>
                <Skeleton width={150} />
                <span>
                  <SeatIcon />
                  <Skeleton width={100} />
                </span>
                <span>
                  <CiUser />
                  <Skeleton width={100} />
                </span>
                <span>
                  <Skeleton width={100} />
                </span>
                <Skeleton width={100} />
              </div>
            </div>
          ))
        ) : isError ? (
          <p style={{textAlign:'center', fontFamily:'Montserrat'}}>No vehicle available</p>
        ) : (
          vehicleData.map((vehicle) => (
            <div className="car-card" key={vehicle._id}>
              <img src={vehicle.images[0]} alt="" />
              <div className="wrapper">
                <div className="car-span">
                  <h4>{vehicle.vehicleType}</h4>
                  <Link to={`/vehicle/${vehicle._id}`}><BsThreeDotsVertical /></Link>
                </div>
                <h4>{vehicle.vehicleModel}</h4>
                <span>
                  <SeatIcon />
                  <p>{vehicle.numberOfSeats} seats</p>
                </span>
                <span>
                  <CiUser />
                  <p>Max no of people: {vehicle.maxNumberOfPeople}</p>
                </span>
                <span>
                  <p>State: {vehicle.state}</p>
                </span>
                <h3>{vehicle.pricingPerDay
                  ? (vehicle.pricingPerDay).toLocaleString("en-US", {
                    style: "currency",
                    currency: "NGN",
                  })
                  : ""}</h3>
              </div>
            </div>
          ))
        )}
      </div>
      <div className="pagination">
        <span>{`Showing ${startIndex} - ${endIndex} of ${vehicles.totalVehicles}`}</span>
        <div className="btn">
          <button onClick={handlePrevPage} disabled={currentPage === 1}>
            Prev
          </button>
          <button onClick={handleNextPage} disabled={currentPage === totalPages}>
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default Vehicle;


