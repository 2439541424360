import { Fragment, useState, useContext } from "react";
import useFetchCategory from "../libs/useFetchcategory";
import Spinner from "../../utils/spinner";
import { AuthContext } from "../../Context/AuthContext";
import { CiEdit } from "react-icons/ci";
import { MdDeleteOutline } from "react-icons/md";
import useCreateProductCategory from "../libs/useCreateProductCategory";
import useUpdateProductCategory from "../libs/useEditProductCategory";
import useDeleteProductCategory from "../libs/useDeleteProductCategory";
import { ReactComponent as CautionIcon } from "../../asset/caution-svgrepo-com 1.svg";
import "./Marketplacecategory.css";

function Marketplacecategory() {
  const { user } = useContext(AuthContext);
  const { data, isLoading, isError, error } = useFetchCategory();
  const createCategoryMutation = useCreateProductCategory();
  const updateCategoryMutation = useUpdateProductCategory();
  const deleteCategoryMutation = useDeleteProductCategory();
  const creator = user.userId;
  const categories = data?.categories || [];
  const [selectId, setSelectedId] = useState(null);
  const [addOverlay, setAddoverlay] = useState(false);
  const [categoryName, setCategoryname] = useState("");
  const [updateName, setUpdateName] = useState("");
  const [updateOverlay, setUpdateOverlay] = useState(false);
  const [deleteOverLay, setDeleteOverlay] = useState(false);
  const [msg, setMsg] = useState(false);

  const handleCreateCategory = async (e) => {
    e.preventDefault();
    try {
      await createCategoryMutation.mutateAsync({
        categoryName,
        creator,
      });
      setCategoryname("");
      setMsg("Category created successfully");
      setTimeout(() => {
        setAddoverlay(false);
        setMsg(false);
      }, 3000);
    } catch (error) {
      console.error("Error creating category:", error);
    }
  };

  const handleUpdateButtonClick = (categoryId) => {
    const selectedCategory = categories.find(
      (category) => category._id === categoryId
    );
    setSelectedId(categoryId);
    setUpdateName(selectedCategory?.categoryName || "");
    setUpdateOverlay(true);
  };

  const handleUpdateCategory = async (e) => {
    e.preventDefault();
    try {
      await updateCategoryMutation.mutateAsync({
        categoryId: selectId,
        categoryData: { categoryName: updateName },
      });
      setUpdateOverlay(false);
      setSelectedId(null);
    } catch (error) {
      console.error("Error updating category:", error);
    }
  };

  const handleDeleteCategory = async () => {
    try {
      await deleteCategoryMutation.mutateAsync(selectId);
      setDeleteOverlay(false);
      setSelectedId(null);
    } catch (error) {
      console.error("Error deleting category:", error);
    }
  };

  return (
    <Fragment>
      {updateOverlay && (
        <div className="addproduct-category">
          <form onSubmit={handleUpdateCategory}>
            <h1>Update Category</h1>
            <label>
              Category Name
              <input
                type="text"
                value={updateName}
                onChange={(e) => setUpdateName(e.target.value)}
                autoFocus
              />
            </label>

            <div className="btn">
              <button
                type="button"
                onClick={() => {
                  setSelectedId(null);
                  setUpdateOverlay(false);
                }}
              >
                Cancel
              </button>
              <button type="submit">
                {updateCategoryMutation.isLoading ? "Updating..." : "Update"}
              </button>
            </div>
          </form>
        </div>
      )}

      {addOverlay && (
        <div className="addproduct-category">
          <form onSubmit={handleCreateCategory}>
            <h1>Add New Category</h1>
            <label>
              Category Name
              <input
                type="text"
                value={categoryName}
                onChange={(e) => setCategoryname(e.target.value)}
              />
            </label>
            <div className="btn">
              <button onClick={() => setAddoverlay(false)}>Cancel</button>
              <button type="submit" disabled={createCategoryMutation.isLoading}>
                {createCategoryMutation.isLoading ? "Saving..." : "Save"}
              </button>
            </div>
            {msg && <span>{msg}</span>}
            {createCategoryMutation.error && (
              <p>{createCategoryMutation.error}</p>
            )}
          </form>
        </div>
      )}

      {deleteOverLay && (
        <div className="addproduct-category">
          <div className="card">
            <CautionIcon />
            <h3>Delete</h3>
            <p>Are you sure you want to delete this Category?</p>
            <div className="btn">
              <button onClick={() => setDeleteOverlay(false)}>Cancel</button>
              <button onClick={handleDeleteCategory}>
                {deleteCategoryMutation.isLoading ? "Deleting..." : "Yes"}
              </button>
            </div>
          </div>
        </div>
      )}

      <div className="merchant-category-container">
        <div className="header">
          <h1>Categories</h1>
          <button onClick={() => setAddoverlay(true)}>Add New Category</button>
        </div>
        <div className="product-category">
          {isLoading && <Spinner />}
          {!isLoading && isError && <p>{error.message}</p>}
          {categories.map((category) => (
            <div key={category._id} className="card">
              <p>{category.categoryName}</p>

              <div className="btn">
                <button
                  type="button"
                  onClick={() => handleUpdateButtonClick(category._id)}
                >
                  <CiEdit />
                </button>
                <button
                  onClick={() => {
                    setSelectedId(category._id);
                    setDeleteOverlay(true);
                  }}
                >
                  <MdDeleteOutline />
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Fragment>
  );
}

export default Marketplacecategory;
