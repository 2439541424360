import React, { useContext } from 'react';
import UserDataFetcher from '../../libs/getUserbyUserId';
import { AuthContext } from '../../Context/AuthContext';
import { Link, useNavigate } from 'react-router-dom';
import './Setting.css';

const SupportSetting = () => {
  const { isLoading, isError, adminData } = UserDataFetcher();
  const {Logout} = useContext(AuthContext);
  const navigate = useNavigate()

  const handleLogout = () => {
    Logout();  
    navigate('/Login');  
  };

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (isError) {
    return <p>Error fetching data</p>;
  }

  return (
    <div className='support-setting-container'>
      <div className='header'>
        <p>Setting</p>
      </div>
      <div className='setting-wrapper'>
        <span>
        <p>First Name</p>
        <p>{adminData?.user?.first_name}</p>
        </span>
        <span>
        <p>Last Name</p>
        <p>{adminData?.user?.last_name}</p>
        </span>
        <span>
        <p>Email</p>
        <p>{adminData?.user?.email}</p>
        </span>
        <span>
        <p>Phone Number</p>
        <p>{adminData?.user?.phone_number}</p>
        </span>
        <span>
        <p>Address</p>
        <p>{adminData?.user?.address}</p>
        </span>
        <span>
        <p>Password</p>
        <Link to='/SupportChangePass'>Change Password</Link>
        </span>
      </div>
      <div className="logout-button">
      <button onClick={handleLogout}>Logout</button>
      </div>
    </div>
  );
};

export default SupportSetting;
