import axios from "axios";
import { useMutation, useQueryClient } from "react-query";
import { useContext } from "react";
import { AuthContext } from "../../Context/AuthContext";

const useDeletePackage = () => {
  const { token } = useContext(AuthContext);
  const queryClient = useQueryClient();

  const deletePackageMutation = useMutation(
    (packageId) =>
      axios.delete(
        `${process.env.REACT_APP_SERVER_URL}/packages/delete-package/${packageId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("packagesByCreator");
        queryClient.invalidateQueries("dispatchDetailsByCreatorId");
        queryClient.invalidateQueries("recentPackages");
      },
    }
  );

  const deletePackage = async (packageId) => {
    try {
      await deletePackageMutation.mutateAsync(packageId);
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  return { deletePackage, isLoading: deletePackageMutation.isLoading };
};

export default useDeletePackage;
