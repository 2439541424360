import axios from "axios";
import { useMutation, useQueryClient } from "react-query";

const useContact = () => {
  const queryClient = useQueryClient();

  const fetchPublicMessage = async ({ content, fullname, email, phoneNumber }) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/publicmsgs/send`, {
        content: content,
        fullname: fullname,
        email: email,
        phoneNumber: phoneNumber,
      });

      console.log(response);

      return response.data;
    } catch (error) {
      console.error(error);
      throw new Error('Error fetching public message');
    }
  };

  const mutation = useMutation(fetchPublicMessage, {
    onSuccess: () => {
      queryClient.invalidateQueries("publicMessages");
    },
  });

  return { mutation };
};

export default useContact;
