import { useInfiniteQuery } from 'react-query';

const fetchHomeProducts = async ({ pageParam = 1 }) => {
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/product/category/home/office?page=${pageParam}`, {
    headers: {
      'Content-Type': 'application/json'
    }
  });

  if (!response.ok) {
    throw new Error('Failed to fetch products');
  }

  return response.json();
};

const  useHomeOfficeProducts = () => {
  return useInfiniteQuery('homeProducts', fetchHomeProducts, {
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.currentPage + 1;
      return nextPage <= Math.ceil(lastPage.totalProducts / 10) ? nextPage : undefined;
    }
  });
};

export default  useHomeOfficeProducts;

