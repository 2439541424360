
import { useMutation, useQueryClient } from 'react-query';
import { useContext } from 'react';
import { AuthContext } from '../../../../Context/AuthContext';


const resetVehicleStatus = async (vehicleId, tripId, token) => {
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/charterer/resetStatus/${vehicleId}/${tripId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error('Failed to reset vehicle status');
  }

  return response.json();
};

const useResetVehicleStatus = () => {
  const queryClient = useQueryClient();
  const { token } = useContext(AuthContext);

  return useMutation(({ vehicleId, tripId }) => resetVehicleStatus(vehicleId, tripId, token), {
    onSuccess: () => {
      queryClient.invalidateQueries(['bookrequest']);
    },
  });
};

export default useResetVehicleStatus;