import { Fragment, useEffect, useState, useContext } from "react";
import { AuthContext } from "../../Context/AuthContext";
import { ReactComponent as FrameIcon } from "../../asset/Frame 1003.svg";
import { ReactComponent as EditIcon } from "../../asset/system-uicons_write.svg";
import { useNavigate } from "react-router-dom";
import useFormDataSender from "../libs/sender";
import useFormDataReceiver from "../libs/receiver";
import usePackageCreate from "../libs/usePackageCreate";
import ItemsData from "../libs/items";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";


function Logisticssummary() {
  const { user } = useContext(AuthContext);
  const [totalShippingCost, setTotalShippingCost] = useState(null);
  const navigate = useNavigate();
  const [formData] = useFormDataSender("shipFormData");
  const [receiverFormData] = useFormDataReceiver("recieverFormData");
  const [summaryformData] = ItemsData("summaryData");

  const { createPackage, mutation, receipt, receiptData } = usePackageCreate();

  useEffect(() => {
    const storedData = localStorage.getItem("shippingSummaryData");
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      setTotalShippingCost(parsedData.totalShippingCost);
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const storedData = localStorage.getItem("shippingSummaryData");
    const shippingCost = storedData
      ? JSON.parse(storedData).totalShippingCost
      : 0;

    const packageData = {
      name: summaryformData.item,
      weight: parseFloat(summaryformData.weight),
      quantity: parseInt(summaryformData.quantity),
      items_value: parseFloat(summaryformData.items_value),
      shipping_cost: parseFloat(shippingCost),
      creator: user.userId,
      sender: {
        name: formData.name,
        contact_name: formData.contact_name,
        phone_number: formData.phone_number,
        email: formData.email,
        address_1: formData.address_1,
        address_2: formData.address_2,
        zip_code: formData.zip_code,
        state: formData.state,
      },
      receiver: {
        name: receiverFormData.name,
        contact_name: receiverFormData.contact_name,
        phone_number: receiverFormData.phone_number,
        email: receiverFormData.email,
        address_1: receiverFormData.address_1,
        address_2: receiverFormData.address_2,
        zip_code: receiverFormData.zip_code,
        state: receiverFormData.state,
      },
      estimated_delivery_date: new Date(
        summaryformData.estimated_delivery_date
      ),
      payment_method: summaryformData.payment_method,
      delivery_method: summaryformData.delivery_method,
    };

    if (summaryformData.pickupOption) {
      packageData.pickupOption = summaryformData.pickupOption;
    }

    try {
      await createPackage(packageData);
    } catch (error) {
      console.error("Error creating package:", error);
      if (error.response) {
        console.error("Server responded with:", error.response.data);
      }
    }
  };

  const handlePrint = async () => {
    const pdf = new jsPDF();

    if (receipt && receiptData) {
      const receiptContainer = document.querySelector(
        ".package-receipt-wrapper"
      );
      const canvas = await html2canvas(receiptContainer);
      const imgData = canvas.toDataURL("image/png");
      pdf.addImage(imgData, "PNG", 10, 10, 200, 200);
      pdf.save("receipt.pdf");
      navigate("/Logisticspackages");
    }
  };

  return (
    <Fragment>
      {receipt && receiptData && (
        <div className="receipt-container">
          <div className="in">
          <button onClick={handlePrint}>Print</button>
          </div>
          <div className="package-receipt-wrapper">
            <div className="logo-card">
              <div className="logo-box">
                <img
                  src="https://i.ibb.co/pxkX0P0/Frame-1171277793-2x.png"
                  alt=""
                />
              </div>
              <h3>
                {new Date(
                  receiptData.package?.dateCreated
                ).toLocaleDateString()}
              </h3>
            </div>
            <h3>Dear Customer</h3>
            <p>The following is proof of delivery for Waybill Number :</p>
            <div className="package-receipt-span">
              <h3>{receiptData?.waybill.waybillNumber}</h3>
              <img
                src={`data:image/png;base64,${receiptData.waybill.barcode}`}
                alt="Barcode"
              />
            </div>
            <div className="package-receipt-info">
              <div className="package-recipt-con-1">
                <span>
                  <h3>From</h3>
                </span>
                <span>
                  <p>{receiptData.package?.sender.name}</p>
                  <p>{receiptData.package?.sender.address_1}</p>
                  <p>{receiptData.package?.sender.phone_number}</p>
                  <p>{receiptData.package?.sender.state}</p>
                </span>
              </div>
              <div className="package-recipt-con-2">
                <span>
                  <h3>To</h3>
                </span>
                <span>
                  <p>{receiptData.package?.receiver.name}</p>
                  <p>{receiptData.package?.receiver.address_1}, </p>
                  <p>{receiptData.package?.receiver.phone_number}</p>
                  <p>{receiptData.package?.receiver.state}</p>
                </span>
              </div>
              <div className="package-recipt-con-3">
                <span>
                  <h3>Payment mode</h3>
                  <p>{receiptData.package?.payment_method}</p>
                </span>
              </div>
            </div>
            <div className="package-trip-card">
              <div className="package-trip-span-1">
                <h3>{receiptData.package?.sender.state}</h3>
                <h3>- </h3>
                <h3>{receiptData.package?.receiver.state}</h3>
              </div>
              <div className="package-trip-span-2">
                <span>
                  <p>Pce/Shpt Weight</p>
                  <h3>{receiptData.package?.weight}kg</h3>
                </span>
                <span>
                  <p>page</p>
                  <h3>1/1</h3>
                </span>
              </div>
            </div>
            <div className="package-info-wrapper">
              <div className="package-info-wrapper-1">
                <h3>Category</h3>
                <h3>Weight</h3>
                <h3>Quantity</h3>
                <h3>Item-Value</h3>
                <h3>Cost</h3>
                <h3>VAT</h3>
                <h3>Total</h3>
              </div>
              <div className="package-info-wrapper-2">
                <p>{receiptData.package?.name}</p>
                <p>{receiptData.package?.weight}kg</p>
                <p>{receiptData.package?.quantity}</p>
                <p>{receiptData.package?.items_value}</p>
                <p>
                  {(receiptData.package?.shipping_cost / 10)?.toLocaleString(
                    "en-NG",
                    {
                      style: "currency",
                      currency: "NGN",
                    }
                  )}
                </p>
                <p>N/A</p>
                <h4>
                  {(receiptData.package?.shipping_cost / 10)?.toLocaleString(
                    "en-NG",
                    {
                      style: "currency",
                      currency: "NGN",
                    }
                  )}
                </h4>
              </div>
            </div>
            <div className="package-support">
              <p>Call:013300242</p>/<p>www.stex.com </p>/
              <p> Email:hello@stex.com</p>
            </div>
            <div className="package-condition">
              <h3>CONDITIONS FOR CARRIAGE</h3>
              <span>
                <p>
                  1. The AWB is the legal contract document that covers the
                  carriage of documents, parcels, and cargo.
                </p>
                <p>
                  2. Goods must be certified in good condition by the shipper
                  and the representative of coral courier before acceptance.
                </p>
                <p>
                  2.1 The standard tariff of the company applies to the carriage
                  of every shipment.
                </p>
                <p>
                  2.2 Payments must be completed before shipments are recorded
                  for deliveries except when the said shipper is on accounts
                  with the company.
                </p>
                <p>
                  2.3 The company has the right to inspect all shipments without
                  prior notice of the shipper which includes the right to open
                  and examine the shipment.
                </p>
                <p>
                  2.4 The receiver/consignee must confirm 100% condition of
                  shipment before signing to receive at the point of collection
                  from the courier.
                </p>
                <p>
                  3.0 There is provision for (HOLD FOR COLLECTION). This service
                  can be for 2-3 days to enable the customer to pick from the
                  nearest location.
                </p>
                <p>
                  3.1 The value of each consignment must be declared! Where
                  otherwise, the company shall assume the value to be
                  (2,000NGN).
                </p>
                <p>
                  3.2 10% of the declared value shall be charged on every
                  declared goods as insurance.
                </p>
                <p>
                  3.3 In case of a missing item or damage in transit, the
                  company shall be liable to the limit of the liability.
                </p>
                <p>
                  3.4 The courier company is not liable for any loss or damage
                  that is special or indirect even if the risk (FINANCIAL OR
                  OTHERWISE) associated with such loss or damage was
                  communicated to the courier after the acceptance of the
                  shipment.
                </p>
                <p>
                  Claims are limited to the clause above. However, if the value
                  of the shipment was declared the shipper is required to submit
                  a claim in writing to the customer service within 24 hours of
                  delivery.
                </p>
                <p>
                  4.0 The company has the right to employ or subcontract to act
                  for it in the delivery of goods and services; and shall have
                  no responsibility or liabilities to the shipper.
                </p>
              </span>
            </div>
          </div>
        </div>
      )}

      <form onClick={handleSubmit} className="logistics-sender">
        <div className="header">
          <p>Create Package</p>
        </div>
        <div className="summary">
          <FrameIcon />
          <div className="sender-info">
            <div className="in">
              <p>Ship From/Return to</p>
              <button onClick={() => navigate("/Logiscticsender")}>
                Edit <EditIcon />{" "}
              </button>
            </div>
            <div className="info">
              <p>{formData.name}</p>
              <p>{formData.contact_name}</p>
              <p>{formData.address_1}</p>
              <p>
                {formData.state} {formData.zip_code}
              </p>
              <p>{formData.phone_number}</p>
            </div>
          </div>

          <div className="sender-info">
            <div className="in">
              <p>Ship to</p>
              <button onClick={() => navigate("/Logisticsreceiever")}>
                Edit <EditIcon />{" "}
              </button>
            </div>
            <div className="info">
              <p>{receiverFormData.name}</p>
              <p>{receiverFormData.contact_name}</p>
              <p>{receiverFormData.address_1}</p>
              <p>
                {receiverFormData.state} {receiverFormData.zip_code}
              </p>
              <p>{receiverFormData.phone_number}</p>
            </div>
          </div>
          <div className="sum">
            <div className="in">
              <p>Item Summary</p>
              <button onClick={() => navigate("/Logisticsitems")}>
                Edit <EditIcon />
              </button>
            </div>
            <div className="sum-items">
              <div className="in">
                <p>Delivery Items</p>
              </div>
              <div className="items">
                <span>
                  <h3>Category</h3>
                  <p>{summaryformData.category}</p>
                </span>
                <span>
                  <h3>Weight</h3>
                  <p>{summaryformData.weight}</p>
                </span>
                <span>
                  <h3>Quantity</h3>
                  <p>{summaryformData.quantity}</p>
                </span>
                <span>
                  <h3>Value(NGN)</h3>
                  <p>{summaryformData.items_value}</p>
                </span>
              </div>
            </div>
          </div>
          <div className="ship-cost">
            <div className="in">
              <p>Estimate Cost</p>
            </div>
            <div className="cost">
              <p>
                {" "}
                {totalShippingCost !== null
                  ? (totalShippingCost / 10).toLocaleString("en-US", {
                      style: "currency",
                      currency: "NGN",
                    })
                  : "N/A"}
              </p>
            </div>
          </div>
          {mutation.errorMsg && (
            <p style={{ color: "red" }}>{mutation.errorMsg}</p>
          )}

          <button
            type="submit"
            disabled={mutation.isLoading}
            className="submit"
          >
            {mutation.isLoading ? "creating..." : "Submit"}
          </button>
        </div>
      </form>
    </Fragment>
  );
}

export default Logisticssummary;
