
import { useContext } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { AuthContext } from "../../../Context/AuthContext";

const useBookRequest = (page, itemsPerPage) => {
  const { token, user } = useContext(AuthContext);
  const queryClient = useQueryClient();

  const fetchBookRequest = async () => {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/hiretruck/trucks/requestingBooking?state=${user?.state}&page=${page}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });
    if (!response.ok) {
      throw new Error('Failed to fetch Booked truck');
    }
    const data = await response.json();
    console.log("Fetched data:", data);
    return data;
  };

  const { data, isLoading, isError } =  useQuery(
    ['BookedRequest', page],
    fetchBookRequest,
    {
      onSuccess: () => {
        queryClient.invalidateQueries('adminorder');
      },
    }
  );

  return {
    hireTrucks: data?.hireTrucks || [],
    totalHireTrucks: data?.totalHireTrucks || 0,
    isLoading,
    isError
  };
};

export default useBookRequest;




