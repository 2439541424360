
import useTotalMessagesCount from '../../libs/fetchTotalMessage';
import useTodayMessagesCount from '../../libs/fetchTodayMessage';
import useOpenMessagesCount from '../../libs/fetchOpenMessage';
import useClosedMessagesCount from '../../libs/fetchClosedMessage';
import useRecentMessagesCount from '../../libs/fetchRecentMessage';
import { Link } from 'react-router-dom';
import Spinner from "../../Spinner/Spinner"
import './OverView.css';

const OverView = () => {
    const { totalMessageCount, loading: totalLoading, error: totalError } = useTotalMessagesCount();
    const { todayMessageCount, loading: todayLoading, error: todayError } = useTodayMessagesCount();
    const { openMessageCount, loading: openLoading, error: openError } = useOpenMessagesCount();
    const { closedMessageCount, loading: closedLoading, error: closedError } = useClosedMessagesCount();
    const { recentMessage, loading: recentLoading, error: recentError } = useRecentMessagesCount();
    console.log(recentMessage)
  
    if (totalLoading || todayLoading || openLoading || closedLoading || recentLoading) {
        console.log("Loading...");
      return <Spinner/>;
    }
  
    if (totalError || todayError || openError || closedError || recentError) {
      return <p>Error: {totalError || todayError || openError || closedError || recentError}</p>;
    }


  const clampWords = (text, maxWords) => {
    const words = text.split(" ");
    const clampedWords = words.slice(0, maxWords);
    return clampedWords.join(" ");
  };
  
    return (
      <div className="admin-chat-container">
        <div className="header">
          <p>Over View</p>
        </div>
        <div className="wrapper">
          <div className="over-view-box">
            <p>TOTAL MSG </p>
            <h3 style={{color:'blue'}}> {totalMessageCount}</h3>
          </div>
          <div className="over-view-box">
            <p>TOTAL MSG FOR TODAY</p>
            <h3 style={{color:'orange'}}>{todayMessageCount}</h3>
          </div>
          <div className="over-view-box">
            <p>TOTAL OPEN MSG</p>
            <h3 style={{color:"red"}}>{openMessageCount}</h3>
          </div>
          <div className="over-view-box">
            <p>TOTAL CLOSED MSG</p>
            <h3 style={{color:"green"}}>{closedMessageCount}</h3>
          </div>
        </div>
        <div className="recent-message">
        <span>
            <h3>RECENT MESSAGES</h3>
            <Link to='/SupportMessage'>View All</Link>
            </span>
        <div className="recent-message-card">
          <table>
            <thead>
              <tr>
                <th>Full Name</th>
                <th>Email</th>
                <th>Phone Number</th>
                <th>Content</th>
                <th>Created At</th>
                <th>Tickets</th>
              </tr>
            </thead>
            <tbody>
              {recentMessage.map(message => (
                <tr key={message._id} className="message-item">
                  <td>{message.fullname}</td>
                  <td>{message.email}</td>
                  <td>{message.phoneNumber}</td>
                  <td>{clampWords(message.content, 5)}</td>
                   <td>
                    {new Intl.DateTimeFormat("en-US", {
                      timeZone: "Africa/Lagos",
                    }).format(new Date(message.createdAt))}
                  </td>
                  <td>{message.tickets[0]?.ticketCode}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      </div>
    );
  };
  
  export default OverView;
