import { useContext } from 'react';
import { useQuery } from 'react-query';
import { AuthContext } from "../../../Context/AuthContext";

const useOnTripTruck = (page, itemsPerPage) => {
  const { token, user } = useContext(AuthContext);

  const fetchOnTripTruck = async () => {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/hiretruck/on-trip?state=${user?.state}&page=${page}&limit=${itemsPerPage}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });
    if (!response.ok) {
      throw new Error('Failed to fetch truck on Trip');
    }
    const data = await response.json();
    return {
      trucksOnTrip: data.trucksOnTrip,
      totalTrucksOnTrip: data.totalTrucksOnTrip || 0,
      totalPages: data.totalPages || 1
    };
  };

  return useQuery(['onTripTruck', page], fetchOnTripTruck, {
    keepPreviousData: true
  });
};

export default useOnTripTruck;



