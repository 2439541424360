import { useQuery } from 'react-query';
import axios from 'axios';
import { useContext } from 'react';
import { AuthContext } from '../../Context/AuthContext';

const useBusinessMerchant = (userId) => {
  const { token } = useContext(AuthContext);

  const fetchBusinessMerchant = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/users/merchants/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data.merchant;
    } catch (error) {
      throw new Error('Error fetching merchant data. Please try again.');
    }
  };

  return useQuery(['businessMerchant', userId], fetchBusinessMerchant); 
};

export default useBusinessMerchant;
