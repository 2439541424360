import React from 'react';
import { FaRegUser } from "react-icons/fa";
import { IoMdArrowDropdown } from "react-icons/io";
import UserDataFetcher from '../../../../libs/getUserbyUserId';
import "./FreightNav.css"

const Freightnavbar = () => {
    const {adminData} = UserDataFetcher()
    return (
        <div className='freight-navbar'>
            <h3>Welcome back, <span>{adminData?.user?.first_name} {adminData?.user?.last_name} </span></h3>
            <div className="freight-combination">
            <span>
            <FaRegUser />
            </span>
            <IoMdArrowDropdown />
            </div>
        </div>
    );
}

export default Freightnavbar;
