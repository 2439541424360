import React from "react";
import "./About.css";
import { Fragment } from "react";
import SimpleSlider from "../../Slider/AboutSider";


const About = () => {
  return (
    <Fragment>
      <div className="about-mother-container">
        <SimpleSlider />
      </div>
      <div className="about-card-1">
        <h3>Discover Our Identity</h3>
        <p>
          At SmartTrans Express, where innovation and dedication converge. We
          are more than a logistics and e-commerce company; we're architects of
          seamless journeys. Our story unfolds through a commitment to
          excellence, propelled by cutting-edge technology and a
          customer-centric ethos. SmartTrans Express embodies reliability,
          efficiency, and a relentless pursuit of smart solutions. Meet our
          passionate team, the driving force behind every successful delivery.
          As you explore into our identity, you'll find a blend of expertise and
          a relentless pursuit of excellence. Join us in unlocking the
          possibilities of smart logistics and e-commerce, where your
          satisfaction is our ultimate goal.
        </p>
      </div>
      <div className="about-card-2">
        <h3>MEET OUR TEAM</h3>
        <p>Behind Every Delivery, There's a Smart Team</p>
        <div className="wrapper">
          <div className="team-image">
            <img src="" alt="" />
            <h3>Name</h3>
            <p>Position</p>
          </div>
          <div className="team-image">
            <img src="" alt="" />
            <h3>Name</h3>
            <p>Position</p>
          </div>
          <div className="team-image">
            <img src="" alt="" />
            <h3>Name</h3>
            <p>Position</p>
          </div>
        </div>
      </div>
      <div className="about-card-3">
        <h3>Why Choose Us</h3>
        <div className="wrapper">
          <div className="about-box">
            <h3>Unprecedented Reliability and Affordability</h3>
            <p>
              dedicated to providing budget-friendly solutions that ensure peace
              of mind, be it shipping packages, managing logistics, or exploring
              e-commerce offerings. Count on us for seamless experiences that
              prioritize your satisfaction, delivering exceptional value without
              straining your wallet.
            </p>
          </div>
          <div className="about-box">
            <h3>Friendly Service Ethos</h3>
            <p>
              Our core values center around a friendly service ethos,
              acknowledging the importance of reliability in every transaction.
              At SmartTrans Express, our services are meticulously designed to
              meet your needs without compromising quality. A budget-friendly
              approach guarantees exceptional value without compromising on
              trust. From swift deliveries to efficient logistics management, we
              ensure a dependable, cost-effective, and, above all, friendly
              experience
            </p>
          </div>
          <div className="about-box">
            <h3>Part of Our Valued Community</h3>
            <p>
              At SmartTrans Express, you're not just a customer; you're a valued
              community member. We prioritize your satisfaction by merging
              reliability and affordability, offering a practical choice for
              your logistics and e-commerce needs. Choose us for a dependable,
              budget-friendly journey that ensures your experience remains as
              smooth as possible. Smart Solutions, Smarter Logistics
            </p>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default About;
