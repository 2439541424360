import "./Superadminsettingss.css"
import UserDataFetcher from "../libs/Getadminbyid"
import {  useContext } from "react";
import { AuthContext } from "../../Context/AuthContext";
import { useNavigate } from "react-router-dom";

function Superadminsetting() {
    const { Logout } = useContext(AuthContext);
    const { adminData } = UserDataFetcher();
    const navigate = useNavigate()
  return (
    <div className="super_admin_settings_container">
      <div className="header">
        <p>Settings</p>
      </div>

      <div className="setting">
        <span>
            <h3>First Name</h3>
            <p>{adminData?.user?.first_name}</p>
        </span>
        <span>
            <h3>Last Name</h3>
            <p>{adminData?.user?.last_name}</p>
        </span>
        <span>
            <h3>Email</h3>
            <p>{adminData?.user?.email}</p>
        </span>
        <span>
            <h3>Password</h3>
            <button onClick={() => navigate("/Superadminchangepass")}>Change Password</button>
        </span>
        <span>
            <button className="log-out" onClick={() => Logout()}>Log out</button>
        </span>
        
      </div>
    </div>
  )
}

export default Superadminsetting
