    import { useMutation, useQueryClient } from 'react-query';
    import { useContext } from 'react';
    import { AuthContext } from '../../../../Context/AuthContext';

    const setCharterOnTrip = async (id, token) => {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/charterer/onTrip/${id}`, {
        method: 'PUT',
        headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        },
    });

    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to set charter on trip');
    }

    return response.json();
    };

    const useSetCharterOnTrip = () => {
    const queryClient = useQueryClient();
    const { token } = useContext(AuthContext);

    return useMutation((id) => setCharterOnTrip(id, token), {
        onSuccess: () => {
        queryClient.invalidateQueries('startedcharter');
        },
    });
    };

    export default useSetCharterOnTrip;
