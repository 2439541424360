import React from "react";
import { Fragment } from "react";
import FaqMainSwitch from "./FaqMainSwitch";
import "./Faq.css";

const FaqComponent = () => {
  return (
    <Fragment>

    <div className="faq-banner">
      <h3>FREQUENTLY ASKED QUESTIONS AND ANSWERS</h3>
    </div>
    <div className="faq-mother-card">
        <FaqMainSwitch/>
    </div>
    </Fragment>
  );
};

export default FaqComponent;
