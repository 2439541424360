import "./super_admin_overview.css";
import PackageDataMetrics from "../libs/GetPackagemetric";
import UserDataMetrics from "../libs/GetusrMetric";
import fetchAllPackages from "../libs/fetchAllPackages";
import { Link } from "react-router-dom";
import Spinner from "../../utils/spinner";

const formatDate = (dateString) => {
  const options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  return new Intl.DateTimeFormat("en-US", options).format(new Date(dateString));
};

const Superadminoverview = () => {
  const { packageMetrics, isLoading, isError } = PackageDataMetrics();
  const { userMetrics } = UserDataMetrics();
  const { packages } = fetchAllPackages();

  return (
    <div className="super_admin_overview_container">
      <div className="header">
        <h1>Overview</h1>
      </div>
      <div className="card-container">
        <div className="box">
          <h2>Total orders</h2>
          <span>More than yesterday</span>
          <p>{packageMetrics?.metrics?.totalPackages}</p>
        </div>
        <div className="box">
          <h2>Total Signed Up Users</h2>
          <span>New Users : {userMetrics?.totalUsers}</span>
          <div className="in">
            <p>{userMetrics?.totalUsers}</p>
            <Link>View Users</Link>
          </div>
        </div>
        <div className="box">
          <h2>Total Logistics Officer</h2>
          <span>Active: {userMetrics?.logisticsManagers}</span>
          <p>{userMetrics?.logisticsManagers}</p>
        </div>
        <div className="box">
          <h2>Total Drivers</h2>
          <span>Active: {userMetrics?.drivers}</span>
          <p>{userMetrics?.drivers}</p>
        </div>
        <div className="box">
          <h2>Total Rriders</h2>
          <span>Active: {userMetrics?.riders}</span>
          <p>{userMetrics?.riders}</p>
        </div>
        <div className="box">
          <h2>Total Market place Adm</h2>
          <span>Active: {userMetrics?.marketplaceManagers}</span>
          <p>{userMetrics?.marketplaceManagers}</p>
        </div>
        <div className="box">
          <h2>Total Merchants</h2>
          <span>Active: {userMetrics?.merchants}</span>
          <p>{userMetrics?.merchants}</p>
        </div>
        <div className="box">
          <h2>Total Custtomers</h2>
          <span>Active: {userMetrics?.customers}</span>
          <p>{userMetrics?.customers}</p>
        </div>
      </div>
      <div className="packages">
        <div className="pkgs">
          <p>Recent Packages</p>
          <Link to="/superadminviewallpackage">View all</Link>
        </div>
        <div className="packages-data">
          {isError && <p>No packages available</p>}
          {Array.isArray(packages?.packages)
            ? packages?.packages.map((pkg) => (
                <div className="pg-table" key={pkg.id}>
                  <p>{pkg?.tracking_number}</p>
                  <p>{formatDate(pkg?.dateCreated)}</p>
                  <p>
                    {pkg?.shipping_cost
                      ? (pkg.shipping_cost / 100).toLocaleString("en-US", {
                          style: "currency",
                          currency: "NGN",
                        })
                      : ""}
                  </p>
                  <p>{pkg?.status[pkg.status.length - 1]?.status}</p>
                  <p>{pkg?.receiver?.name}</p>
                  <Link to={`/packages/${pkg.id}`}>Details</Link>
                </div>
              ))
            : isLoading && (
                <p>
                  <Spinner />
                </p>
              )}
        </div>
      </div>
    </div>
  );
};

export default Superadminoverview;
