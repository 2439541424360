import { useQuery } from 'react-query';
import { useContext } from 'react';
import { AuthContext } from '../../Context/AuthContext';

const fetchTotalPackagesById = async (userId, token) => {
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/packages/total-packages/${userId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error('Failed to fetch total packages');
  }

  return response.json();
};

const useTotalPackagesById = () => {
  const { user, token } = useContext(AuthContext);

  return useQuery(['totalPackages', user.userId, token], () =>
    fetchTotalPackagesById(user.userId, token)
  );
};

export default useTotalPackagesById;
