
import { FaRegUser } from "react-icons/fa";
import { IoMdArrowDropdown } from "react-icons/io";
import UserDataFetcher from "../../libs/getUserbyUserId";

const SupportNavBar = () => {
    const {adminData} = UserDataFetcher()
    return (
        <div className='support-navbar'>
            <h3>Welcome back, <span>{adminData?.user?.first_name} {adminData?.user?.last_name} </span></h3>
            <div className="user-combination">
            <span>
            <FaRegUser />
            </span>
            <IoMdArrowDropdown />
            </div>
        </div>
    );
}

export default SupportNavBar;
