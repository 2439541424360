import { useQuery } from 'react-query';

const useGetBanners = () => {
  return useQuery('banners', async () => {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/banners/getbanners`);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  });
};

export default useGetBanners;
