import { useState, useEffect } from 'react';

const SummaryData = (key) => {
  const [summaryformData, setSummaryFormData] = useState(() => {
    const storedData = localStorage.getItem(key);
    return storedData ? JSON.parse(storedData) : {};
  });

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(summaryformData));
  }, [summaryformData, key]);

  const handlesummaryChange = (e) => {
    const { name, value } = e.target;
    setSummaryFormData({
      ...summaryformData,
      [name]: value,
    });
  };

  return [summaryformData, handlesummaryChange];
};

export default SummaryData;
