
import { Link } from 'react-router-dom';
import { CiApple } from "react-icons/ci";
import { TbRibbonHealth } from "react-icons/tb";
import { IoHomeOutline } from "react-icons/io5";
import { GiPressureCooker } from "react-icons/gi";
import { GiSmartphone } from "react-icons/gi";
import { HiOutlineComputerDesktop } from "react-icons/hi2";
import { PiTelevision } from "react-icons/pi";
import { CiHome } from "react-icons/ci";
import { useState } from 'react';
import { Fragment } from 'react';
import { IoShirtOutline } from "react-icons/io5";
import { LiaBabySolid } from "react-icons/lia";
import { PiGameController } from "react-icons/pi";
import { GiWeightLiftingUp } from "react-icons/gi";
import { TbToolsKitchen2 } from "react-icons/tb";
import { PiBeerBottleDuotone } from "react-icons/pi";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
import { BsChatDots } from "react-icons/bs";
import useFetchProductCategory from '../../../libs/getProductCategories';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import "./ProductCategory.css"


const iconsMap = {
  "Super Market": <CiApple />,
  "Health & Beauty": <TbRibbonHealth />,
  "Home & Office": <IoHomeOutline />,
  "Appliances": <GiPressureCooker />,
  "Phones & Tablets": <GiSmartphone />,
  "Computing": <HiOutlineComputerDesktop />,
  "Electronics": <PiTelevision />,
  "Fashion": <IoShirtOutline />,
  "Baby Products": <LiaBabySolid />,
  "Gaming": <PiGameController />,
  "Sporting Goods": <GiWeightLiftingUp />,
  "Drink & Groceries": <PiBeerBottleDuotone />,
  "Kitchen Utensils": <TbToolsKitchen2 />,
  "Building Materials": <CiHome />
};

const CateMenu = () => {
    const [helpCenterVisible, setHelpCenterVisible] = useState(false);
    const { data, isLoading: productCategoryLoading, isError: productCategoryError } = useFetchProductCategory();


  const handleHelpCenterToggle = () => {
    setHelpCenterVisible(!helpCenterVisible);
  };

    return (
        <Fragment> 
        <div className='category-menu'>
        <div className="help-center-switch" onClick={handleHelpCenterToggle}>
           <span>
           <h3>NEED  HELP?</h3>
            {helpCenterVisible ? <IoIosArrowUp /> : <IoIosArrowDown />}
           </span>
          
          {helpCenterVisible && <div className="help-center-card">
            <Link to='/returnpolicy'>FAQS</Link>
            {/* <Link>Payment Option</Link> */}
            <Link to='/trackyourorder'>Track an Order</Link>
            <Link to='/returnpolicy'>Return & Refund</Link>
            <a href="/Contact">Contact Us</a>
            <div className="help-center-line">
            <a href="/" target='blank'><BsChatDots  style={{fontSize:'22px'}}/>Chat with Support Team</a>
            </div>
          </div> }
          </div>
             <div className="wrapper">
                <span>
                    <h3>PRODUCTS CATEGORIES</h3>
                    <Link to='/recommendedproduct'>See All</Link>
                </span>
                {productCategoryLoading ? (
            <div className="mobile-category">
              <Skeleton count={14} height={10} width={200}/>
            </div>
          ) : productCategoryError ? ( 
            <p style={{fontFamily:'Montserrat'}}>Error loading categories</p>
          ) : data?.categories?.length > 0 ? (  
            data.categories.map((category) => (
              <div className="mobile-category">
                <Link to={`/${category.categoryName.toLowerCase().replace(/ & /g, "").replace(/\s/g, "")}`} key={category._id}>
                {iconsMap[category.categoryName] || <CiHome />} 
                {category.categoryName}
              </Link>
              </div>
            ))
          ) : (
            <p style={{fontFamily: 'Montserrat'}}>No categories available</p>
          )}
             </div>
             <Link to='/MerchantForm'>Become a merchant</Link>
        </div>
        </Fragment>
    );
}

export default CateMenu;
