import { useMutation, useQueryClient } from 'react-query';
import { useContext } from 'react';
import { AuthContext } from '../../Context/AuthContext';

const useDeleteBroadMsg = () => {
  const queryClient = useQueryClient();
  const { token } = useContext(AuthContext);

  const deleteMessageMutation = useMutation(
    (id) => deleteMessage(id, token),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('adminMessages');
      },
    }
  );

  const handleDeleteMessage = async (id) => {
    try {
      await deleteMessageMutation.mutateAsync(id);
    } catch (error) {
      console.error('Error deleting message:', error);
    }
  };

  return {
    isDeleting: deleteMessageMutation.isLoading,
    handleDeleteMessage,
  };
};

const deleteMessage = async (id, token) => {
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/messages/delete/${id}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`, 
    },
  });

  if (!response.ok) {
    throw new Error('Failed to delete message');
  }

  return response.json();
};

export default useDeleteBroadMsg;
