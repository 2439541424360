import { useQuery } from 'react-query';
import axios from 'axios';
import { useContext } from 'react';
import { AuthContext } from '../../Context/AuthContext';

const useVerifyMerchant = (merchantId) => {
  const { token } = useContext(AuthContext);

  const fetchMerchantVerification = async () => {
    try {
      const response = await axios.patch(`${process.env.REACT_APP_SERVER_URL}/users/merchant/verify/${merchantId}`, {}, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      throw new Error('Error verifying merchant. Please try again.');
    }
  };

  return useQuery(['verifyMerchant', merchantId], fetchMerchantVerification);
};

export default useVerifyMerchant;
