import React, { useState } from 'react';
import { IoMdArrowBack } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import useSendMessageToalluser from '../libs/useSendMessageToallUser';
import { useContext } from 'react';
import { AuthContext } from '../../Context/AuthContext';

import './Superadminbroadcast.css';

function Superadminbroadcast() {
  const navigate = useNavigate();
  const { sendMessage } = useSendMessageToalluser();
  const [messageContent, setMessageContent] = useState('');
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState(false)
  const [error, setError] = useState(null);
  const { user } = useContext(AuthContext);

  const handleSendMessage = async () => {
    try {
      setLoading(true);
      setError(null);
      await sendMessage(user.userId, messageContent);
      setMsg("Messege sent to users successfully.")
      setMessageContent("")
      setTimeout(() => {
        setMsg(false)
      }, 3000)
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="super-admin-broadcast">
      <div className="header">
        <IoMdArrowBack onClick={() => navigate('/Superadminchat')} className='icon' />
        <p>Admin Broadcast</p>
      </div>

      <form className="broadcast-card">
        <textarea
          placeholder="Broadcast Message"
          value={messageContent}
          onChange={(e) => setMessageContent(e.target.value)}
        ></textarea>
        <button type="button" onClick={handleSendMessage} disabled={loading}>
          {loading ? 'Sending...' : 'Send'}
        </button>
        {error && <p className="error-message">{error}</p>}
        {msg && <p>{msg}</p>}
      </form>
    </div>
  );
}

export default Superadminbroadcast;
