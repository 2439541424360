import { useQuery } from 'react-query';

const fetchTruckDetails = async (id) => {
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/freight/get-trucks/${id}`, {
    headers: {
      'Content-Type': 'application/json'
    }
  });

  if (!response.ok) {
    throw new Error('Failed to fetch truck details');
  }

  return response.json();
};

const useSelectedTruck = (id) => {
  return useQuery(['selectedTruck', id], () => fetchTruckDetails(id));
};

export default useSelectedTruck;
