import React, { useContext, useRef, useCallback, Fragment } from "react";
import { Link } from 'react-router-dom';
import MarketNavbar from '../../MarketNav/MarketNavbar';
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import useTodayDeals from '../../libs/fetchTodayDeal';
import { CartContext } from "../../../../Context/CartContext";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { ToastContainer, toast } from 'react-toastify';
import Spinner from "../../../Spinner/Spinner";
import 'react-toastify/dist/ReactToastify.css';
import 'react-loading-skeleton/dist/skeleton.css';
import './AllSpecialProduct.css';

const AllTodayDealProduct = () => {
    const {
        data,
        isLoading,
        isError,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage
      } = useTodayDeals();
    
      const { addItemToCart } = useContext(CartContext);
    
      const notify = () => toast.success("Product added successfully to cart!");
    
      const handleAddToCart = (product) => {
        addItemToCart(product);
        notify();
      };
    
      const clampDescription = (description, maxLength) => {
        if (description.length <= maxLength) return description;
        return description.slice(0, maxLength) + "...";
      };
    
      const observerRef = useRef();
      const lastProductElementRef = useCallback(node => {
        if (isFetchingNextPage) return;
    
        if (observerRef.current) observerRef.current.disconnect();
    
        observerRef.current = new IntersectionObserver(entries => {
          if (entries[0].isIntersecting && hasNextPage) {
            fetchNextPage();
          }
        }, {
          threshold: 1.0
        });
    
        if (node) observerRef.current.observe(node);
      }, [isFetchingNextPage, fetchNextPage, hasNextPage]);
    
      if (isLoading) {
        return (
          <div className="product-category-mother">
            <MarketNavbar />
            <div className="product-category-wrapper">
              {Array.from({ length: 10 }).map((_, index) => (
                <div className="category-product-infos" key={index}>
                  <Skeleton height={100} />
                  <Skeleton height={10} width={200} />
                  <Skeleton height={10} width={180} />
                  <Skeleton height={10} width={150} />
                  <div className="btnn">
                    <Skeleton height={30} width={150} />
                  </div>
                </div>
              ))}
            </div>
            <ToastContainer position="top-right" className="toast"/>
          </div>
        );
      }
    
      if (isError) {
        return <p style={{ fontFamily: 'Montserrat', textAlign: 'center' }}>Error loading products.</p>;
      }
    
      return (
        <Fragment>
          <MarketNavbar />
          <div className="product-category-mothers">
            <div className="heading">
              <div className="wrapper">
                <Link to="/Market">Home</Link>
                <MdKeyboardDoubleArrowRight />
                <p>Today Deals</p>
              </div>
              <span>
                <h3>TODAY DEALS PRODUCTS</h3>
              </span>
            </div>
            <div className="product-category-wrapper">
              {data?.pages.map((page, pageIndex) =>
                page.todaysDealProducts.map((product, productIndex) => {
                  const isLastProduct =
                    pageIndex === data.pages.length - 1 &&
                    productIndex === page.todaysDealProducts.length - 1;
    
                  return (
                    <div
                      className="category-product-info"
                      key={product._id}
                      ref={isLastProduct ? lastProductElementRef : null}
                    >
                      <Link to={`/product/${product._id}`} className="image">
                        {product.images[0] ? (
                          <img src={product.images[0]} alt={product.name} />
                        ) : (
                          <Skeleton height={150} />
                        )}
                      </Link>
                      <span>
                        <p>{product.name}</p>
                        <h3>
                          {product.price
                            ? product.price.toLocaleString("en-US", {
                                style: "currency",
                                currency: "NGN",
                              })
                            : null}
                        </h3>
                      </span>
                      <p>{clampDescription(product?.description, 25)}</p>
                      <div className="btnn">
                          <button onClick={() => handleAddToCart(product)}>Add to Cart</button>
                        </div>
                    </div>
                  );
                })
              )}
            </div>
            {isFetchingNextPage && (
              <div className="loading-more"><Spinner/></div>
            )}
            {/* {!hasNextPage && (
              <p style={{ textAlign: "center", fontFamily: 'Montserrat' }}>
                No more products to display.
              </p>
            )} */}
            <ToastContainer position="top-right" className="toast"/>
          </div>
        </Fragment>
      );
};

export default AllTodayDealProduct;

