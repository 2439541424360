import { useInfiniteQuery } from 'react-query';

const fetchBuildingProducts = async ({ pageParam = 1 }) => {
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/product/category/building/material?page=${pageParam}`, {
    headers: {
      'Content-Type': 'application/json'
    }
  });

  if (!response.ok) {
    throw new Error('Failed to fetch products');
  }

  return response.json();
};

const useBuildingProduct = () => {
  return useInfiniteQuery('buildingProducts', fetchBuildingProducts, {
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.currentPage + 1;
      return nextPage <= Math.ceil(lastPage.totalProducts / 10) ? nextPage : undefined;
    }
  });
};

export default useBuildingProduct;

