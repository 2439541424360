import React, { useRef, useCallback, useState } from "react";
import { Link } from "react-router-dom";
import { FaXmark } from "react-icons/fa6";
import useUserTrucks from "./libs/fetchUserTrucks";
import useSearchTrucks from "./libs/useSearchTrucks"; 
import "./Freight.css";

const TruckSelection = () => {
  const [searchOverlay, setSearchOverlay] = useState(false);
  const [searchCriteria, setSearchCriteria] = useState({
    truckType: '',
    tonnage: '',
    truckLength: ''
  });
  const { searchResults, isSearching, searchError, searchTrucks } = useSearchTrucks(); 

  const {
    data,
    isLoading,
    isError,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage
  } = useUserTrucks();

  const observerRef = useRef();
  const lastTruckElementRef = useCallback(node => {
    if (isFetchingNextPage) return;
    if (observerRef.current) observerRef.current.disconnect();
    observerRef.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasNextPage) {
        fetchNextPage();
      }
    });
    if (node) observerRef.current.observe(node);
  }, [isFetchingNextPage, fetchNextPage, hasNextPage]);

  if (isLoading) {
    return <p style={{ fontFamily: 'Montserrat', textAlign: 'center' }}>Loading...</p>;
  }

  if (isError) {
    return <p style={{ fontFamily: 'Montserrat', textAlign: 'center' }}>Error loading trucks.</p>;
  }

  const handleTruckClick = (truck) => {
    localStorage.setItem('selectedTruck', JSON.stringify(truck));
  };

  const handleSearchChange = (e) => {
    const { name, value } = e.target;
    setSearchCriteria({ ...searchCriteria, [name]: value });
  };

  const handleSearchSubmit = async (e) => {
    e.preventDefault();
    await searchTrucks(searchCriteria);
    setSearchOverlay(true);
  };

  return (
    <>
      {searchOverlay && (
        <div className="search-truck-overlay">
          <span><button onClick={() => setSearchOverlay(false)}><FaXmark /></button></span>
          <div className="search-results">
            {isSearching ? (
              <p style={{ fontFamily: 'Montserrat', textAlign: 'center' }}>Searching...</p>
            ) : searchError ? (
              <p style={{ fontFamily: 'Montserrat', textAlign: 'center' }}>{searchError}</p>
            ) : searchResults.length > 0 ? (
              searchResults.map(truck => (
                <Link
                  to={`/selectedtruck/${truck._id}`}
                  className="wrapper"
                  key={truck._id}
                  onClick={() => handleTruckClick(truck)}
                >
                  <img src={truck.images[0]} alt={truck.truckType} />
                 <span>
                 <h3>{truck.truckType}</h3>
                    <p>State: {truck.state}</p>
                    <h4>{truck.pricePerDay
                      ? (truck.pricePerDay).toLocaleString("en-US", {
                        style: "currency",
                        currency: "NGN",
                      })
                      : ""}</h4>
                 </span>
                </Link>
              ))
            ) : (
              <p style={{ fontFamily: 'Montserrat', textAlign: 'center' }}>No trucks found.</p>
            )}
          </div>
        </div>
      )}
      <div className="truck-selection">
        <div className="truck-selection-card-1">
          <form onSubmit={handleSearchSubmit}>
         <span>
         <select name="truckType" value={searchCriteria.truckType} onChange={handleSearchChange} >
              <option value="">Truck </option>
              <option value="Mini Truck">Mini Truck</option>
              <option value="Pickup Truck">Pickup Truck</option>
              <option value="Cargo Van">Cargo Van</option>
              <option value="Small Box Truck">Small Box Truck</option>
              <option value="Medium Box Truck">Medium Box Truck</option>
              <option value="Large Box Truck">Large Box Truck</option>
              <option value="Semi-Trailer Truck ">Semi-Trailer Truck</option>
            </select>
            <select name="tonnage" value={searchCriteria.tonnage} onChange={handleSearchChange} >
              <option value="">Tonnage</option>
              <option value="1 ton">1 ton</option>
              <option value="1.5 tons">1.5 tons</option>
              <option value="2 tons">2 tons</option>
              <option value="3 to 5 tons">3 to 5 tons</option>
              <option value="6 to 8 tons">6 to 8 tons</option>
              <option value="10 to 15 tons">10 to 15 tons</option>
              <option value="20 to 25 tons">20 to 25 tons</option>
            </select>
            <select name="truckLength" value={searchCriteria.truckLength} onChange={handleSearchChange} >
              <option value="">Length</option>
              <option value="10 to 12 feet">10 to 12 feet</option>
              <option value="15 to 20 feet">15 to 20 feet</option>
              <option value="20 to 24 feet">20 to 24 feet</option>
              <option value="24 to 28 feet">24 to 28 feet</option>
              <option value="28 to 32 feet">28 to 32 feet</option>
            </select>
            <button type="submit">
              {isSearching ? "Searching..." : "Search"}
              </button>
         </span>
              <div className="btn">
              <button type="submit">
              {isSearching ? "Searching..." : "Search"}
              </button>
              </div>
          </form>
        </div>
        <div className="truck-selection-card-2">
          <h3>Select Truck</h3>
          <span>
            {data.pages.map((page, pageIndex) =>
              page.trucks.map((truck, truckIndex) => {
                const isLastElement = pageIndex === data.pages.length - 1 && truckIndex === page.trucks.length - 1;
                return (
                  <Link
                    to={`/selectedtruck/${truck._id}`}
                    className="wrapper"
                    key={truck._id}
                    ref={isLastElement ? lastTruckElementRef : null}
                    onClick={() => handleTruckClick(truck)}
                  >
                    <img src={truck.images[0]} alt={truck.truckType} />
                    <div className="truck-selection-info">
                      <h3>{truck.truckType}</h3>
                      <p>State: {truck.state}</p>
                      <h4>{truck.pricePerDay
                        ? (truck.pricePerDay).toLocaleString("en-US", {
                          style: "currency",
                          currency: "NGN",
                        })
                        : ""}</h4>
                    </div>
                  </Link>
                );
              })
            )}
          </span>
          {isFetchingNextPage && <p style={{ fontFamily: 'Montserrat', textAlign: 'center' }}>Loading more trucks...</p>}
        </div>
      </div>
    </>
  );
};

export default TruckSelection;
