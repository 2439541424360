import { useContext, useState } from "react";
import { useQuery } from "react-query";
import { AuthContext } from "../Context/AuthContext";

const UserDataFetcher = () => {
  const { user, token } = useContext(AuthContext);
  const [adminData, setAdminData] = useState(null);

  const { isLoading, isError } = useQuery(
    ["userData", user?.userId, token],
    async () => {
    
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/users/user/${user.userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
        }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch user data");
      }
      return response.json();
    },
  
    {
      onSuccess: (data) => {
        setAdminData(data);
  
      },
    }
  );

  return { isLoading, isError, adminData };
};

export default UserDataFetcher;