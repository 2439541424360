// import React, { useState } from "react";
// import Spinner from "../../Spinner/Spinner";
// import axios from "axios";
// import { useNavigate } from "react-router-dom";
// import "./ForgetPass.css";

// const defaultForm = {
//   email: "",
// };

// const ForgetPassword = () => {
//   const [formInput, setFormInput] = useState(defaultForm);
//   const [isLoading, setIsLoading] = useState(false);
//   const [error, setError] = useState(null);
//   const { email } = formInput;

//   const navigate = useNavigate();

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormInput({ ...formInput, [name]: value });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setIsLoading(true);

//     try {
//       const response = await axios.post(
//         `${process.env.REACT_APP_SERVER_URL}/users/request-password-resset`,
//         formInput
//       );

//       if (response.status !== 200) {
//         throw new Error(response.data.message);
//       }

//       setIsLoading(false);
//       setFormInput(defaultForm);


//       navigate("/ResetPassword");
//     } catch (error) {
//       setError(error.message);
//       setIsLoading(false);
//     }
//   };

//   return (
//     <div className="forget-password-form">
//       <h3>Forget Password</h3>
//       <form onSubmit={handleSubmit}>
//         <span>
//           <label>Email Address</label>
//           <input
//             type="email"
//             name="email"
//             value={email}
//             onChange={handleChange}
//             required
//           />
//         </span>
//         {error && (
//           <p style={{ color: "red", fontFamily: "Montserrat" }}>{error}</p>
//         )}
//         <button type="submit" disabled={isLoading}>
//           {isLoading ? <Spinner /> : "Confirm"}
//         </button>
//       </form>
//     </div>
//   );
// };

// export default ForgetPassword;


import React, { useState } from "react";
import Spinner from "../../Spinner/Spinner";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./ForgetPass.css";

const defaultForm = {
  email: "",
};

const ForgetPassword = () => {
  const [formInput, setFormInput] = useState(defaultForm);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { email } = formInput;

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormInput({ ...formInput, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/users/request-password-resset`,
        formInput
      );

      if (response.status !== 200) {
        throw new Error(response.data.message);
      }

      localStorage.setItem('userEmail', email);

      setIsLoading(false);
      setFormInput(defaultForm);

      navigate("/ResetPassword");
    } catch (error) {
      setError(error.message);
      setIsLoading(false);
    }
  };

  return (
    <div className="forget-password-form">
      <h3>Forget Password</h3>
      <form onSubmit={handleSubmit}>
        <span>
          <label>Email Address</label>
          <input
            type="email"
            name="email"
            value={email}
            onChange={handleChange}
            required
          />
        </span>
        {error && (
          <p style={{ color: "red", fontFamily: "Montserrat" }}>{error}</p>
        )}
        <button type="submit" disabled={isLoading}>
          {isLoading ? <Spinner /> : "Confirm"}
        </button>
      </form>
    </div>
  );
};

export default ForgetPassword;

