import React, { useState } from 'react';
import { IoMdArrowBack } from 'react-icons/io';
import { LiaEyeSolid, LiaEyeSlashSolid } from 'react-icons/lia';
import { useNavigate } from 'react-router-dom';
import useChangePassword from '../libs/useChangePassword';
import './Superadminchangepass.css';

function Superadminchangepass() {
  const navigate = useNavigate();
  const { changePassword } = useChangePassword();
  const [showPass, setShowPass] = useState(false);
  const [showPass2, setShowPass2] = useState(false);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  const handleToggleShowPass = (field) => {
    if (field === 'oldPassword') {
      setShowPass((prev) => !prev);
    } else if (field === 'newPassword') {
      setShowPass2((prev) => !prev);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      setError(null);
      setSuccessMessage(null);

      await changePassword({
        oldPassword,
        newPassword,
      }, {
        onSuccess: () => {
          setSuccessMessage('Password changed successfully');
          setTimeout(() => {
            setSuccessMessage('');
            navigate('/Superadminsetting');
          }, 3000);
        },
        onError: (error) => {
          console.error('Error changing password:', error.message);
          setError('Failed to change password');
        },
        onSettled: () => {
          setLoading(false);
        },
      });
    } catch (error) {
      console.error('Error changing password:', error.message);
      setError('Failed to change password');
      setLoading(false);
    }
  };

  return (
    <div className="super-admin-change-pass">
      <div className="header">
        <IoMdArrowBack className="icon" onClick={() => navigate('/Superadminsetting')} />
        <p>Change password</p>
      </div>
      <form className="form-setting" onSubmit={handleSubmit}>
        <label>
          Enter Old Password
          <div className="inner">
            <input
              type={showPass ? 'text' : 'password'}
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
            />
            {showPass ? (
              <LiaEyeSolid className="iconp" onClick={() => handleToggleShowPass('oldPassword')} />
            ) : (
              <LiaEyeSlashSolid className="iconp" onClick={() => handleToggleShowPass('oldPassword')} />
            )}
          </div>
        </label>
        <label>
          Enter New Password
          <div className="inner">
            <input
              type={showPass2 ? 'text' : 'password'}
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            {showPass2 ? (
              <LiaEyeSolid className="iconp" onClick={() => handleToggleShowPass('newPassword')} />
            ) : (
              <LiaEyeSlashSolid className="iconp" onClick={() => handleToggleShowPass('newPassword')} />
            )}
          </div>
        </label>
        <button type="submit" disabled={loading}>
          {loading ? 'Saving...' : 'Save'}
        </button>
        {error && <p className="error-message">{error}</p>}
        {successMessage && <p className="success-message">{successMessage}</p>}
      </form>
    </div>
  );
}

export default Superadminchangepass;
