
import "./Spinner.css"
const Spinner = () => {
    return (
        <div className="custom-loader"></div>
    );
}

export default Spinner;

