import { useInfiniteQuery } from 'react-query';
import { useContext } from 'react';
import { AuthContext } from "../../Context/AuthContext";

const fetchDrivers = async ({ pageParam = 1 }, token) => {
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/users/drivers?page=${pageParam}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error('Failed to fetch drivers');
  }

  return response.json();
};

const useFetchDrivers = () => {
  const { token } = useContext(AuthContext);

  return useInfiniteQuery(
    ['drivers'], 
    ({ pageParam = 1 }) => fetchDrivers({ pageParam }, token),
    {
      getNextPageParam: (lastPage) => {
        const { currentPage, totalDrivers } = lastPage; 
        const totalPages = Math.ceil(totalDrivers / 10); 
        return currentPage < totalPages ? currentPage + 1 : undefined;
      },
    }
  );
};

export default useFetchDrivers;








