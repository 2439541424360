// import React, { createContext, useState } from "react";


// export const GlobalContext = createContext({
//   isCancelOpen: false,
//   isGetquoteOpen: false,
//   isPublicMessageOpen: false,
//   messageId: null,
// });

// export const GlobalProvider = ({ children }) => {
//   const [isCancelOpen, setIsCancelOpen] = useState(false);
//   const [isGetquoteOpen, setIsGetquoteOpen] = useState(false);
//   const [isPublicMessageOpen, setIsPublicMessageOpen] = useState(false);
//   const [deleteBroadcastMessage, setDeleteBroadcastMessage] = useState(false);
//   const [messageId, setMessageId] = useState(null);
//   const [sessionsId, setSessionsId] = useState(false)


//   const sessionId = localStorage.getItem('sessionId');
//   const value = {
//     isCancelOpen,
//     setIsCancelOpen,
//     isGetquoteOpen,
//     setIsGetquoteOpen,
//     isPublicMessageOpen,
//     setIsPublicMessageOpen,
//     deleteBroadcastMessage,
//     setDeleteBroadcastMessage,
//     messageId,
//     setMessageId,
//     sessionsId,
//     setSessionsId
//   };

//   return (
//     <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
//   );
// };



import React, { createContext, useState, useEffect } from "react";

export const GlobalContext = createContext({
  isCancelOpen: false,
  isGetquoteOpen: false,
  isPublicMessageOpen: false,
  messageId: null,
  sessionId: null,
});

export const GlobalProvider = ({ children }) => {
  const [isCancelOpen, setIsCancelOpen] = useState(false);
  const [isGetquoteOpen, setIsGetquoteOpen] = useState(false);
  const [isPublicMessageOpen, setIsPublicMessageOpen] = useState(false);
  const [deleteBroadcastMessage, setDeleteBroadcastMessage] = useState(false);
  const [messageId, setMessageId] = useState(null);
  const [sessionId, setSessionId] = useState(localStorage.getItem('sessionId'));

  useEffect(() => {
    localStorage.setItem('sessionId', sessionId);
  }, [sessionId]);

  const value = {
    isCancelOpen,
    setIsCancelOpen,
    isGetquoteOpen,
    setIsGetquoteOpen,
    isPublicMessageOpen,
    setIsPublicMessageOpen,
    deleteBroadcastMessage,
    setDeleteBroadcastMessage,
    messageId,
    setMessageId,
    sessionId,
    setSessionId,
  };

  return (
    <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
  );
};
