import { useContext } from 'react';
import { useQuery} from 'react-query';
import { AuthContext } from "../../../../Context/AuthContext";

const useAdminVehicle = (currentPage) => {
  const { token } = useContext(AuthContext);

  const fetchAdminVehicle = async () => {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/charterer/vehicles/admin?page=${currentPage}&perPage=6`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });
    if (!response.ok) {
      throw new Error('Failed to fetch Admin vehicles');
    }
    return response.json();
  };

  return useQuery(['adminVehicle', currentPage], fetchAdminVehicle, {
  
  });
};

export default useAdminVehicle;




