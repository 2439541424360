import React, { useState } from "react";

const FaqMainSwitch = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [isOpen4, setIsOpen4] = useState(false);
  const [isOpen5, setIsOpen5] = useState(false);
  const [isOpen6, setIsOpen6] = useState(false);
  const [isOpen7, setIsOpen7] = useState(false);
  const [isOpen8, setIsOpen8] = useState(false);
  const [isOpen9, setIsOpen9] = useState(false);
  const [isOpen10, setIsOpen10] = useState(false);
  const [isOpen11, setIsOpen11] = useState(false);
  const [isOpen12, setIsOpen12] = useState(false);
  const [isOpen13, setIsOpen13] = useState(false);
  const [isOpen14, setIsOpen14] = useState(false);
  const [isOpen15, setIsOpen15] = useState(false);
  const [isOpen16, setIsOpen16] = useState(false);
  const [isOpen17, setIsOpen17] = useState(false);

  const toggleAnswer = () => {
    setIsOpen(!isOpen);
  };
  const toggleAnswer1 = () => {
    setIsOpen1(!isOpen1);
  };
  const toggleAnswer2 = () => {
    setIsOpen2(!isOpen2);
  };
  const toggleAnswer3 = () => {
    setIsOpen3(!isOpen3);
  };
  const toggleAnswer4 = () => {
    setIsOpen4(!isOpen4);
  };
  const toggleAnswer5 = () => {
    setIsOpen5(!isOpen5);
  };
  const toggleAnswer6 = () => {
    setIsOpen6(!isOpen6);
  };
  const toggleAnswer7 = () => {
    setIsOpen7(!isOpen7);
  };
  const toggleAnswer8 = () => {
    setIsOpen8(!isOpen8);
  };
  const toggleAnswer9 = () => {
    setIsOpen9(!isOpen9);
  };
  const toggleAnswer10 = () => {
    setIsOpen10(!isOpen10);
  };
  const toggleAnswer11 = () => {
    setIsOpen11(!isOpen11);
  };
  const toggleAnswer12 = () => {
    setIsOpen12(!isOpen12);
  };
  const toggleAnswer13 = () => {
    setIsOpen13(!isOpen13);
  };
  const toggleAnswer14 = () => {
    setIsOpen14(!isOpen14);
  };
  const toggleAnswer15 = () => {
    setIsOpen15(!isOpen15);
  };
  const toggleAnswer16 = () => {
    setIsOpen16(!isOpen16);
  };
  const toggleAnswer17 = () => {
    setIsOpen17(!isOpen17);
  };

  return (
    <div className="faq-container">
      <div className="question-container" onClick={toggleAnswer}>
        <span>
        {isOpen ? <i className="fa-solid fa-minus"></i> : <i className="fa-solid fa-plus"></i>}
        <h3>What services does SmartTran Express provide?</h3>
        </span>
      </div>
      {isOpen && (
        <div className="answer-container">
          <p>
            SmartTran Express offers comprehensive logistics solutions tailored
            for e-commerce businesses, including shipping, warehousing, and
            supply chain management.
          </p>
        </div>
      )}
      <div className="question-container" onClick={toggleAnswer1}>
        <span >
        {isOpen1 ? <i className="fa-solid fa-minus"></i> : <i className="fa-solid fa-plus"></i>}
        <h3>How can I track my shipment with SmartTran Express?</h3>
        </span>
      </div>
      {isOpen1 && (
        <div className="answer-container">
          <p>
            you can easily track your shipment by entering the tracking number
            provided at the time of booking on our website or mobile app.
          </p>
        </div>
      )}
      <div className="question-container" onClick={toggleAnswer2}>
        <span >
        {isOpen2 ? <i className="fa-solid fa-minus"></i> : <i className="fa-solid fa-plus"></i>}
        <h3>What regions does SmartTran Express operate in?</h3>
        </span>
      </div>
      {isOpen2 && (
        <div className="answer-container">
          <p>
            SmartTran Express operates globally, providing logistics support and
            e-commerce solutions across various regions.
          </p>
        </div>
      )}
      <div className="question-container" onClick={toggleAnswer3}>
        <span>
        {isOpen ? <i className="fa-solid fa-minus"></i> : <i className="fa-solid fa-plus"></i>}
        <h3>How does SmartTran Express ensure the security of my shipments?</h3>
        </span>
      </div>
      {isOpen3 && (
        <div className="answer-container">
          <p>
            We prioritize the security of your shipments through advanced
            tracking systems, secure warehouses, and strict adherence to
            industry-standard safety protocols.
          </p>
        </div>
      )}
      <div className="question-container" onClick={toggleAnswer4}>
        <span>
        {isOpen4 ? <i className="fa-solid fa-minus"></i> : <i className="fa-solid fa-plus"></i>}
        <h3>What types of goods does SmartTran Express handle?</h3>
        </span>
      </div>
      {isOpen4 && (
        <div className="answer-container">
          <p>
            SmartTran Express handles a wide range of goods, from small parcels
            to large freight, ensuring safe and efficient delivery for all types
            of products.
          </p>
        </div>
      )}
      <div className="question-container" onClick={toggleAnswer5}>
        <span>
        {isOpen5 ? <i className="fa-solid fa-minus"></i> : <i className="fa-solid fa-plus"></i>}
        <h3> How can I partner with SmartTran Express for my e-commerce business? </h3>
        </span>
      </div>
      {isOpen5 && (
        <div className="answer-container">
          <p>
            To partner with SmartTran Express, simply reach out to our business
            development team through our website, and they will assist you in
            setting up logistics solutions tailored to your business needs.
          </p>
        </div>
      )}
      <div className="question-container" onClick={toggleAnswer6}>
        <span>
        {isOpen6 ? <i className="fa-solid fa-minus"></i> : <i className="fa-solid fa-plus"></i>}
        <h3>What is the average delivery time for domestic shipments?</h3>
        </span>
      </div>
      {isOpen6 && (
        <div className="answer-container">
          <p>
            The average delivery time for domestic shipments with SmartTran
            Express varies based on the destination, but we strive to provide
            timely and efficient services.
          </p>
        </div>
      )}
      <div className="question-container" onClick={toggleAnswer7}>
        <span>
        {isOpen7 ? <i className="fa-solid fa-minus"></i> : <i className="fa-solid fa-plus"></i>}
        <h3>
          Are there any size or weight restrictions for shipments with SmartTran
          Express?
        </h3>
        </span>
      </div>
      {isOpen7 && (
        <div className="answer-container">
          <p>
            SmartTran Express accommodates shipments of various sizes and
            weights. Please refer to our shipping guidelines for specific
            details.
          </p>
        </div>
      )}
      <div className="question-container" onClick={toggleAnswer8}>
        <span>
        {isOpen8 ? <i className="fa-solid fa-minus"></i> : <i className="fa-solid fa-plus"></i>}
        <h3>
          How can I get a quote for shipping services with SmartTran Express?
        </h3>
        </span>
      </div>
      {isOpen8 && (
        <div className="answer-container">
          <p>
            You can obtain a shipping quote by filling out our online quote
            request form on the website or contacting our customer service team
            directly.
          </p>
        </div>
      )}
      <div className="question-container" onClick={toggleAnswer9}>
        <span>
        {isOpen9 ? <i className="fa-solid fa-minus"></i> : <i className="fa-solid fa-plus"></i>}
        <h3>Does SmartTran Express offer packaging services?</h3>
        </span>
      </div>
      {isOpen9 && (
        <div className="answer-container">
          <p>
            Yes, SmartTran Express provides packaging services to ensure the
            safe and secure transport of your goods.
          </p>
        </div>
      )}
      <div className="question-container" onClick={toggleAnswer10}>
        <span>
        {isOpen10 ? <i className="fa-solid fa-minus"></i> : <i className="fa-solid fa-plus"></i>}
        <h3>Can I schedule a specific delivery time for my shipments?</h3>
        </span>
      </div>
      {isOpen10 && (
        <div className="answer-container">
          <p>
            While we strive to provide timely deliveries, specific delivery time
            scheduling is currently not available. You can track your shipment
            for real-time updates.
          </p>
        </div>
      )}
      <div className="question-container" onClick={toggleAnswer11}>
        <span>
        {isOpen11 ? <i className="fa-solid fa-minus"></i> : <i className="fa-solid fa-plus"></i>}
        <h3>What payment options are accepted by SmartTran Express?</h3>
        </span>
      </div>
      {isOpen11 && (
        <div className="answer-container">
          <p>
            We accept various payment methods, including credit cards, bank
            transfers, and other secure online payment options.
          </p>
        </div>
      )}
      <div className="question-container" onClick={toggleAnswer12}>
        <span>
        {isOpen12 ? <i className="fa-solid fa-minus"></i> : <i className="fa-solid fa-plus"></i>}
        <h3>
          How does SmartTran Express handle customs clearance for international
          shipments?
        </h3>
        </span>
      </div>
      {isOpen12 && (
        <div className="answer-container">
          <p>
            Yes, SmartTran Express specializes in international shipping,
            offering reliable and cost-effective solutions for cross-border
            e-commerce.
          </p>
        </div>
      )}
      <div className="question-container" onClick={toggleAnswer13}>
        <span>
        {isOpen13 ? <i className="fa-solid fa-minus"></i> : <i className="fa-solid fa-plus"></i>}
        <h3>
          What technology does SmartTran Express use for shipment tracking?
        </h3>
        </span>
      </div>
      {isOpen13 && (
        <div className="answer-container">
          <p>
            SmartTran Express employs advanced tracking technology, including
            GPS and RFID systems, to provide real-time and accurate shipment
            tracking.
          </p>
        </div>
      )}
      <div className="question-container" onClick={toggleAnswer14}>
        <span>
        {isOpen14 ? <i className="fa-solid fa-minus"></i> : <i className="fa-solid fa-plus"></i>}
        <h3>
          Can I change the delivery address after my shipment has been
          dispatched?
        </h3>
        </span>
      </div>
      {isOpen14 && (
        <div className="answer-container">
          <p>
            Changes to the delivery address post-dispatch may be challenging,
            but you can contact our customer service for assistance, and we will
            do our best to help.
          </p>
        </div>
      )}
      <div className="question-container" onClick={toggleAnswer15}>
        <span>
        {isOpen15 ? <i className="fa-solid fa-minus"></i> : <i className="fa-solid fa-plus"></i>}
        <h3>Are there any hidden fees with SmartTran Express services?</h3>
        </span>
      </div>
      {isOpen15 && (
        <div className="answer-container">
          <p>
            At SmartTran Express, transparency is at the core of our business
            model. We believe in clear and straightforward pricing without any
            hidden fees. Our commitment includes:Comprehensive Pricing
            Structure,No Surprise Charges,and Customized Solutions
          </p>
        </div>
      )}
      <div className="question-container" onClick={toggleAnswer16}>
        <span>
        {isOpen16 ? <i className="fa-solid fa-minus"></i> : <i className="fa-solid fa-plus"></i>}
        <h3>
          How does SmartTran Express handle returns for e-commerce businesses?
        </h3>
        </span>
      </div>
      {isOpen16 && (
        <div className="answer-container">
          <p>
            We understand the significance of efficient and customer-friendly
            return processes for e-commerce businesses. SmartTran Express
            streamlines return procedures by:User-Friendly Return
            Portal,Flexible Return Options, and Transparent Communication
          </p>
        </div>
      )}
      <div className="question-container" onClick={toggleAnswer17}>
        <span>
        {isOpen17 ? <i className="fa-solid fa-minus"></i> : <i className="fa-solid fa-plus"></i>}
        <h3>
          What measures does SmartTran Express take to minimize environmental
          impact?
        </h3>
        </span>
      </div>
      {isOpen17 && (
        <div className="answer-container">
          <p>
            At SmartTran Express, we are committed to minimizing our
            environmental impact by implementing various sustainable practices
            throughout our logistics and e-commerce operations. Our initiatives
            include:Green Fleet Management,Optimized Route Planning,Packaging
            Solutions,Paperless Operations and Ventilated warehousing
          </p>
        </div>
      )}
      
    </div>
  );
};

export default FaqMainSwitch;
